import * as React from "react";
import { FunctionComponent, useState } from "react";
import { inductosenseBoldOrange } from "Styling/Palette/BrandColours";
import ScatterPlotGraph from "Components/Graphs/Scatter/ScatterPlotGraph";
import Sensor from "Model/Sensor";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DateCoordinate } from "../../Types/DateCoordinate";
import NoDataMessage from "Panels/Composite/Primary/NoDataMessage";
import ReadingTrendPoint from "../../Model/ReadingTrendPoint";
import DropDownListControlled from "../../Components/Input/DropDownListControlled";
import { FormControlLabel } from "@mui/material";
import ArrayPanel from "./ArrayPanel";
import FirstIfNotEmpty from "../../Utilities/FirstIfNotEmpty";
import { Readinganalysisstatus } from "@inductosense/typescript-fetch";

const leftArrow = "\u2190";
const ellipsis = "\u2026";

dayjs.extend(utc);

interface TrendGraphPanelProps {
    plots: {
        sensor: Sensor;
        readingPoints: ReadingTrendPoint[];
    }[];
    onSensorClick: (sensor: Sensor) => void;
    ignoredReadings: ReadingTrendPoint[];
    highlightedReading: ReadingTrendPoint | null;
    onHoveredReadingChange(reading: ReadingTrendPoint | null): void;
    onReadingDoubleClicked?(reading: ReadingTrendPoint): void;
    onReadingRightClicked?(reading: ReadingTrendPoint): void;
    onReadingsDeleteRequested?(readings: ReadingTrendPoint[]): void;
    showDeleteButton?: boolean;
    unitsMode: "imperial" | "metric";
    showLegend?: boolean;
    tabletMode: boolean;
    showRecalculatingMessage?: boolean;
}

const TrendGraphPanel: FunctionComponent<TrendGraphPanelProps> = (props) => {const mapThicknessToUnits = (thicknessInMetres: number | undefined | null) => {
        if (thicknessInMetres === null || thicknessInMetres === undefined) return undefined;

        if (props.unitsMode === "imperial") {
            return thicknessInMetres * 39.37; // convert to inches
        } else {
            return thicknessInMetres * 1000; // convert to mm
        }
    }

    const mapReadingToPoint = (reading: ReadingTrendPoint) => {
        const thicknessUnits = mapThicknessToUnits(reading.latestThicknessM);

        return {
            x: reading.timestamp,
            y: thicknessUnits !== undefined ? thicknessUnits : 0
        };
    }

    const mapPointToReading = (inputPoint: DateCoordinate | null) => {
        if (!inputPoint) return null;

        console.log("plots", props.plots.length);
        for (const plot of props.plots) {
            for (const reading of plot.readingPoints) {
                const readingPoint = mapReadingToPoint(reading);

                // TODO: Is returning false if null correct
                if (readingPoint?.x === inputPoint.x /*&& readingPoint?.y === inputPoint.y*/) {
                    return reading;
                }
            }
        }

        return null;
    }

    const onHoveredPointChange = (point: DateCoordinate | null) => {
        const reading = mapPointToReading(point);
        props.onHoveredReadingChange(reading);
    }

    const onPointDoubleClicked = (point: DateCoordinate) => {
        const { onReadingDoubleClicked } = props;

        const reading = mapPointToReading(point);
        if (reading !== null && onReadingDoubleClicked) onReadingDoubleClicked(reading);
    }

    function isNotNull<T>(argument: T | null): argument is T {
        return argument !== null;
    }

    const onPointsDeleteRequested = (points: DateCoordinate[]) => {
        const { onReadingsDeleteRequested } = props;

        const readings = points.map(p => mapPointToReading(p)).filter(isNotNull);
        if (onReadingsDeleteRequested) onReadingsDeleteRequested(readings);
    }

    const onPointRightClicked = (point: DateCoordinate) => {
        const { onReadingRightClicked } = props;

        const reading = mapPointToReading(point);
        if (reading !== null && onReadingRightClicked) {
            onReadingRightClicked(reading);
        }
    }

    const NoSensorMessage = () => {
        return (
            <NoDataMessage>
                <span style={{ paddingRight: 20 }}>{leftArrow}</span>
                <span>Select a sensor to show its trend graph{ellipsis}</span>
            </NoDataMessage>
        );
    }

    const arrayNodes = props.plots.length === 1 ?
        [...new Set(props.plots[0].readingPoints
            .sort((a, b) => a.txChannel - b.txChannel)
            .sort((a, b) => a.rxChannel - b.rxChannel)
            .map(p => `Tx ${p.txChannel} Rx ${p.rxChannel}`))] :
        null;

    const [selectedNode, setSelectedNode] = useState<string>("Hexagonal");

    const Graph = () => {
        //const { ignoredReadings, highlightedReading } = props;


        //const groupNames = [props.plots.map(p => `Tx ${p} Rx ${p.rxChannel}`);

        const allReadings = props.plots.map(p => p.readingPoints).flat();
        const groupNames = [...new Set(props.plots.map(p => p.readingPoints
            .sort((a, b) => a.txChannel - b.txChannel)
            .sort((a, b) => a.rxChannel - b.rxChannel)
            .map(q => `Tx ${q.txChannel} Rx ${q.rxChannel}`)
        ).flat())];
        //const groupNames = allPoints.map(p => `Tx ${p.txChannel} Rx ${p.rxChannel}`);
        console.log("groupNames", [...new Set(groupNames)]);

        const plots = groupNames.filter(g => selectedNode === "All" || selectedNode === g).map(g => {
            const highlightedPoint = (props.highlightedReading /*&& props.highlightedReading.sensorId === g.sensor.id*/) ?
                mapReadingToPoint(props.highlightedReading)
                : null;

            return {
                label: g,
                errorDates: allReadings
                    .filter(q => q.analysisStatus !== Readinganalysisstatus.Successful)
                    .filter(q => `Tx ${q.txChannel} Rx ${q.rxChannel}` === g)
                    .map(r => mapReadingToPoint(r)),
                points: allReadings
                    .filter(q => q.analysisStatus === Readinganalysisstatus.Successful)
                    .filter(q => `Tx ${q.txChannel} Rx ${q.rxChannel}` === g)
                    .map(r => mapReadingToPoint(r)),
                outliers: [],
                highlightedPoint,
                onEditClick: () => null // () => props.onSensorClick(plot.sensor)
            };
        });

        const firstPlot = FirstIfNotEmpty(props.plots);
        console.log("the plots", firstPlot);

        // tx = rx
        /*const arrayCoordsTxRx = [
            { x: 0, y: 0 },
            { x: 0, y: 1 },
            { x: 0, y: 2 },
            { x: 1, y: 0 },
            { x: 1, y: 1 },
            { x: 1, y: 2 },
            { x: 2, y: 0 },
            { x: 2, y: 1 },
            { x: 2, y: 2 },
            { x: 3, y: 0 },
            { x: 3, y: 1 },
            { x: 3, y: 2 },
            { x: 4, y: 0 },
            { x: 4, y: 1 },
            { x: 4, y: 2 },
            { x: 5, y: 0 }, // Remove x: 5
            { x: 5, y: 1 },
            { x: 5, y: 2 }
        ];*/

        /*const arrayCoordsDiamondTxRx = [
            { x: 0, y: 0 },
            { x: 0, y: 2 },
            { x: 2, y: 0 },
            { x: 0, y: 4 },
            { x: 2, y: 2 },
            { x: 4, y: 0 },
            { x: 0, y: 6 },
            { x: 2, y: 4 },
            { x: 4, y: 2 },
            { x: 6, y: 0 },
            { x: 0, y: 8 },
            { x: 2, y: 6 },
            { x: 4, y: 4 },
            { x: 6, y: 2 },
            { x: 8, y: 0 },
            { x: 0, y: 0 }
        ].map(p => ({ x: p.x / 2, y: p.y / 2 }));*/


        /*
         *  The diamond display for high density array sensor has the following mapping for
         *  pause-echo (Rx == Tx) coordinates,
         *
         *                                      (0, 0)
         *                              (0, 1)          (1, 0) 
         *                      (0, 2)          (1, 1)          (2, 0)  
         *              (0, 3)          (1, 2)          (2, 1)          (3, 0)   
         *      (0, 4)          (1, 3)          (2, 2)          (3, 1)          (4, 0)    
         *              (1, 4)          (2, 3)          (3, 2)          (4, 1)   
         *                      (2, 4)          (3, 3)          (4, 2)   
         *                              (3, 4)          (4, 3)
         *                                      (4, 4) 
         *
         *  The high density array sensor has the following index layout:
         *                                      
         *                                      ( 1  )
         *                                              ( 2  ) 
         *                                      ( 3  )          ( 4  )  
         *                                              ( 5  )          ( 7  )   
         *                                      ( 6  )          ( 8  )          ( 11 )    
         *                                              ( 9  )          ( 12 )   
         *                                      ( 10 )          ( 13 )   
         *                                              ( 14 )
         *                                      ( 15 )   
         *
         *  There for arrayCoordsDiamondTxRx is populated in the order that matching the diamond display mapping coordinates
         *  to the high density array sensor location index layout.
         */                  
        
        const arrayCoordsHexagonalTxRx = [
            { x: 0, y: 0 },
            { x: 1, y: 0 },
            { x: 1, y: 1 },
            { x: 2, y: 0 },
            { x: 2, y: 1 },
            { x: 2, y: 2 },
            { x: 3, y: 0 },
            { x: 3, y: 1 },
            { x: 3, y: 2 },
            { x: 3, y: 3 },
            { x: 4, y: 0 },
            { x: 4, y: 1 },
            { x: 4, y: 2 },
            { x: 4, y: 3 },
            { x: 4, y: 4 }
        ];

        const arrayCoordsGridTxRx = [
            { x: 2, y: 0 },
            { x: 1, y: 0 },
            { x: 0, y: 0 },
            { x: 2, y: 1 },
            { x: 1, y: 1 },
            { x: 0, y: 1 },
            { x: 2, y: 2 },
            { x: 1, y: 2 },
            { x: 0, y: 2 },
            { x: 2, y: 3 },
            { x: 1, y: 3 },
            { x: 0, y: 3 },
            { x: 2, y: 4 },
            { x: 1, y: 4 },
            { x: 0, y: 4 }
        ];

        const arrayHexagonalData = {
            readingAnalyses: firstPlot?.readingPoints
                .map(p => ({
                    Name: p.sensorId || "Untitled",
                    Tooltip: `Tx ${p.txChannel} Rx ${p.rxChannel}`,
                    TransmitElement: arrayCoordsHexagonalTxRx[p.txChannel],
                    ReceiveElement: arrayCoordsHexagonalTxRx[p.rxChannel],
                    datetime: p.timestamp,
                    thicknessMm: (p.latestThicknessM || 0) * 1000
            })) || []
        };

        const arrayGridData = {
            readingAnalyses: firstPlot?.readingPoints
                .map(p => ({
                    Name: p.sensorId || "Untitled",
                    Tooltip: `Tx ${p.txChannel} Rx ${p.rxChannel}`,
                    TransmitElement: arrayCoordsGridTxRx[p.txChannel],
                    ReceiveElement: arrayCoordsGridTxRx[p.rxChannel],
                    datetime: p.timestamp,
                    thicknessMm: (p.latestThicknessM || 0) * 1000
                })) || []
        };

        const above = <div style={{ marginBottom: 10 }}>
            {arrayNodes && arrayNodes.length > 1 ?
                <FormControlLabel
                    label=<>Array node&nbsp;</>
                    labelPlacement="start"
                    control={
                        <>&nbsp; <DropDownListControlled<string>
                            options={["All", "Grid", "Hexagonal", "Trend", ...arrayNodes]}
                            labelFor={n => n}
                            selectedOption={selectedNode}
                            onChange={newSelectedNode => setSelectedNode(newSelectedNode)}
                        /></>
                    }
                />
                : undefined}
        </div>;

        const mean = (n: number[]) => n.reduce((a, b) => a + b) / n.length;

        const possibleDates = allReadings.map(r => r.timestamp)
            .filter((date, i, self) => self.findIndex(d => d.getTime() === date.getTime()) === i);

        switch (selectedNode) {
            case "Grid":
                return <ArrayPanel
                    onNodeClick={(_coordsp, node) => {
                        if (node) setSelectedNode(node.Tooltip);
                    }}
                    arrayData={arrayGridData}
                    showGrid={true}
                    aboveSegment={above}
                />;
            case "Hexagonal":
                return <ArrayPanel
                    onNodeClick={(_coordsp, node) => {
                        if (node) setSelectedNode(node.Tooltip);
                    }}
                    arrayData={arrayHexagonalData}
                    showGrid={false}
                    aboveSegment={above}
                />;
            case "Trend":
                return <ScatterPlotGraph
                    aboveSegment={above}
                    plots={[
                        {
                            points: possibleDates.map(d =>
                            ({
                                x: new Date(d),
                                y: mean(allReadings
                                    .filter(q => q.analysisStatus === Readinganalysisstatus.Successful)
                                    .map(r => mapReadingToPoint(r))
                                    .filter(p => p.x.getTime() === d.getTime())
                                    .map(p => p.y))
                            })),
                            label: "Average"
                        },
                        {
                            points: possibleDates.map(d =>
                            ({
                                x: new Date(d),
                                y: Math.min(...allReadings
                                    .filter(q => q.analysisStatus === Readinganalysisstatus.Successful)
                                    .map(r => mapReadingToPoint(r))
                                    .filter(p => p.x.getTime() === d.getTime())
                                    .map(p => p.y))
                            })),
                            label: "Minimum"
                        }
                    ]}
                    startXAxisScaleFrom="minimumValue"
                    startYAxisScaleFrom="minimumValue"
                    xAxisLabel="Time of reading"
                    yAxisLabel={props.unitsMode === "imperial" ? "Thickness (\")" : "Thickness (mm)"}
                    onHoveredPointChange={point => onHoveredPointChange(point)}
                    onPointDoubleClicked={point => onPointDoubleClicked(point)}
                    onPointRightClicked={point => onPointRightClicked(point)}
                    showDeleteButton={props.showDeleteButton}
                    onPointsDeleteRequested={points => onPointsDeleteRequested(points)}
                    pointsRadius={props.tabletMode ? 12 : 5}
                    styles={{
                        container: {},
                        points: {},
                        outliers: { fill: "darkgrey" },
                        highlight: {
                            opacity: 0.5,
                            fill: inductosenseBoldOrange
                        },
                        trendLine: {}
                    }}
                    yUnits={props.unitsMode === "imperial" ? "\"" : "mm"}
                    resetIfThisChanges={"".concat(...props.plots.map(p => p.sensor.id))}
                    showLegend={props.showLegend}
                    showRecalculatingMessage={props.showRecalculatingMessage}
                />
            default:
                return <ScatterPlotGraph
                    aboveSegment={above}
                    plots={plots}
                    startXAxisScaleFrom="minimumValue"
                    startYAxisScaleFrom="minimumValue"
                    xAxisLabel="Time of reading"
                    yAxisLabel={props.unitsMode === "imperial" ? "Thickness (\")" : "Thickness (mm)"}
                    onHoveredPointChange={point => onHoveredPointChange(point)}
                    onPointDoubleClicked={point => onPointDoubleClicked(point)}
                    onPointRightClicked={point => onPointRightClicked(point)}
                    showDeleteButton={props.showDeleteButton}
                    onPointsDeleteRequested={points => onPointsDeleteRequested(points)}
                    pointsRadius={props.tabletMode ? 12 : 5}
                    styles={{
                        container: {},
                        points: {},
                        outliers: { fill: "darkgrey" },
                        highlight: {
                            opacity: 0.5,
                            fill: inductosenseBoldOrange
                        },
                        trendLine: {}
                    }}
                    warningValue={props.plots.length === 1 ? mapThicknessToUnits(props.plots[0].sensor.warningParameters.warningThicknessInMetres) : undefined}
                    criticalValue={props.plots.length === 1 ? mapThicknessToUnits(props.plots[0].sensor.warningParameters.criticalThicknessInMetres) : undefined}
                    yUnits={props.unitsMode === "imperial" ? "\"" : "mm"}
                    resetIfThisChanges={"".concat(...props.plots.map(p => p.sensor.id))}
                    showLegend={props.showLegend}
                    showRecalculatingMessage={props.showRecalculatingMessage}
                />
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", backgroundColor: "white", "display": "flex", border: "dashed 1px #000" }}>
            <div style={{ padding: 5, "flex": 1 }}>
                {
                    props.plots.length !== 0
                        ? Graph()
                        : NoSensorMessage()
                }
            </div>
        </div>
    );
}

export default TrendGraphPanel;
