// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!./Animations/AllAnimations.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js!./Animations/Timings.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js!./Depth/ZIndices.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js!./Fonts/Lato.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../node_modules/css-loader/dist/cjs.js!./Palette/AlertColours.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../node_modules/css-loader/dist/cjs.js!./Palette/BrandColours.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../node_modules/css-loader/dist/cjs.js!./Spacing/Padding.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../node_modules/css-loader/dist/cjs.js!./Spacing/Margins.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../node_modules/css-loader/dist/cjs.js!./MaterialUiOverrides.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    height: 100%;
    font-size: 16px;
    font-family: lato, sans-serif;
    background-color: var(--inductosense-smoke-grey);
}

body {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 var(--margin-below-headings);
}

input, label {
    font-family: lato;
}

#root {
    height: 100%;
}


`, "",{"version":3,"sources":["webpack://./Styling/GlobalStyles.css"],"names":[],"mappings":"AAUA;IACI,YAAY;IACZ,eAAe;IACf,6BAA6B;IAC7B,gDAAgD;AACpD;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["@import \"Animations/AllAnimations.css\";\n@import \"Animations/Timings.css\";\n@import \"Depth/ZIndices.css\";\n@import \"Fonts/Lato.css\";\n@import \"Palette/AlertColours.css\";\n@import \"Palette/BrandColours.css\";\n@import \"Spacing/Padding.css\";\n@import \"Spacing/Margins.css\";\n@import \"MaterialUiOverrides.css\";\n\nhtml {\n    height: 100%;\n    font-size: 16px;\n    font-family: lato, sans-serif;\n    background-color: var(--inductosense-smoke-grey);\n}\n\nbody {\n    margin: 0;\n}\n\nh1, h2, h3, h4, h5, h6 {\n    margin: 0 0 var(--margin-below-headings);\n}\n\ninput, label {\n    font-family: lato;\n}\n\n#root {\n    height: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
