import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import IconButton from "Components/Buttons/IconButton";
import { Delete } from "@mui/icons-material";

interface DeleteIconButtonProps {
    isDisabled?: boolean;
    onClick(event?: MouseEvent): void;
    size?: "small" | "medium";
}

const DeleteIconButton: FunctionComponent<DeleteIconButtonProps> = ({ isDisabled, onClick, size }) => (
    <IconButton
        icon={<Delete />}
        isDisabled={isDisabled}
        onClick={event => onClick(event)}
        size={size}
    />
);

export default DeleteIconButton;
