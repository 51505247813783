import * as React from "react";
import { FunctionComponent, CSSProperties } from "react";
import { LinearProgress } from "@mui/material";

interface ProgressBoxProps {
    progress?: number;
    progressText?: string;
    description?: string;
}

const progressContentStyle: CSSProperties = {
    paddingLeft: 15,
    paddingRight: 15
};

const ProgressBox: FunctionComponent<ProgressBoxProps> = ({ description, progress, progressText }) => {
    return (
        <div style={progressContentStyle}>
            {description ? <p>{description}</p> : null}
            <LinearProgress variant="determinate" value={progress} />
            <p>{progressText}</p>
        </div>
    );
};

export default ProgressBox;
