import * as React from "react";
import { compare, PointsInCartesianSpace } from "Types/CartesianSpace";
import { Component, CSSProperties } from "react";
import { getLinearRegressionFunction } from "Utilities/LinearRegression";
import { LinearOrTimeScale, ScaleOutput } from "Types/D3Extensions";
import { ScaleContinuousNumeric } from "d3";

interface LinearRegressionTrendLineProps<Range> {
    points: PointsInCartesianSpace;
    xScale: LinearOrTimeScale;
    yScale: ScaleContinuousNumeric<Range, ScaleOutput>;
    style?: CSSProperties;
    extrapolate?: boolean;
    gradientAnnotation?: boolean;
    yUnits?: string;
    textColour?: string;
}

export default class LinearRegressionTrendLine<Range> extends Component<LinearRegressionTrendLineProps<Range>> {
    render() {
        const { points, style, xScale, yScale, extrapolate, gradientAnnotation, yUnits, textColour = "black" } = this.props;

        const xCoordinates = points.map(p => p.x);
        const trendLineFunction = getLinearRegressionFunction(points);
        const sortedXCoordinates = [...xCoordinates].sort(compare);
        const minimumX = extrapolate ? xScale.domain()[0] : sortedXCoordinates[0];
        const maximumX = extrapolate ? xScale.domain()[1] : sortedXCoordinates[points.length - 1];

        const xdelta = ((maximumX as Date).getTime() - (minimumX as Date).getTime()) / (1000 * 60 * 60 * 24 * 365);
        const ydelta = trendLineFunction(maximumX) - trendLineFunction(minimumX);
        const gradient = ydelta / xdelta;

        return (
            <>
                {
                    gradientAnnotation ?
                        <text
                            x={xScale(minimumX)}
                            y={yScale(trendLineFunction(minimumX)) as number - 15}
                            style={{ fontSize: 10, fill: textColour }}
                        >
                            {gradient < 0 ? `${-gradient.toFixed(4)} ${yUnits}/year` : "No corrosion"}
                        </text>
                    : null
                }
                <line
                    x1={xScale(minimumX)}
                    x2={xScale(maximumX)}
                    y1={yScale(trendLineFunction(minimumX))}
                    y2={yScale(trendLineFunction(maximumX))}
                    style={style}
                    />
            </>
        );
    }
}
