import * as React from "react";
import { Component, CSSProperties } from "react";
import { LinearOrTimeScale, ScaleOutput } from "Types/D3Extensions";
import { ScaleContinuousNumeric } from "d3";
import { XUnit } from "Types/CartesianSpace";

interface YEqualsZeroLineProps<Range> {
    xMaximum: XUnit;
    xScale: LinearOrTimeScale;
    yScale: ScaleContinuousNumeric<Range, ScaleOutput>;
    style?: CSSProperties;
}

export default class YEqualsZeroLine<Range> extends Component<YEqualsZeroLineProps<Range>> {
    render() {
        const { style, xMaximum, xScale, yScale } = this.props;

        return (
            <line
                x1={0}
                x2={xScale(xMaximum)}
                y1={yScale(0)}
                y2={yScale(0)}
                style={style}
            />
        );
    }
}
