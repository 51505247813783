import * as React from "react";
import { FunctionComponent } from "react";

interface DisplacementTextProps {
    unitsMode: "imperial" | "metric";
    displacementInMetres: number | null | undefined;
    tooltip?: string;
    className?: string;
}

const DisplacementText: FunctionComponent<DisplacementTextProps> = ({ displacementInMetres, unitsMode, tooltip, className }) => {
    if (displacementInMetres === null || displacementInMetres === undefined) return null;

    if (unitsMode === "imperial") {
        const displacementInches = displacementInMetres / 0.0254;

        return <span title={tooltip} className={className}>
            {displacementInches.toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}&quot;
        </span>;
    } else {
        const displacementMm = displacementInMetres * 1000;

        return <span title={tooltip} className={className}>
            {displacementMm.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}mm
        </span>;
    }
}

export default DisplacementText;
