import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import { Dcuser } from "@inductosense/typescript-fetch";
import DcUserForm from "./DcUserForm";

interface DcUserFormModalPanelProps {
    shouldBeShown: boolean;
    initialUser?: Dcuser;
    onClose(): void;
    onUserUpdated(): void;
}

interface DcUserFormModalPanelState {
    isSaving: boolean;
}

export default class DcUserFormModalPanel extends Component<DcUserFormModalPanelProps, DcUserFormModalPanelState> {
    constructor(props: DcUserFormModalPanelProps) {
        super(props);

        this.state = {
            isSaving: false
        }
    }

    render() {
        const { isSaving } = this.state;

        return (
            <ModalPanel
                content={
                    <DcUserForm initialUser={this.props.initialUser}
                        onSave={() => this.onSave()}
                        onSaveComplete={() => this.onSaveComplete()}
                        onSaveFailed={() => this.onSaveFailed()}
                        onCancel={() => this.props.onClose()} />
                }
                title={this.formTitle()}
                titleAlignment="centre"
                onClose={() => !isSaving && this.props.onClose()
    }
                shouldBeShown={this.props.shouldBeShown}
            />
        );
    }

    formTitle() {
        if (this.props.initialUser) {
            return "Edit User";
        } else {
            return "Create User";
        }
    }

    private onSave() {
        this.setState({ isSaving: true });
    }

    private onSaveComplete() {
        this.props.onClose();
        this.props.onUserUpdated();

        this.setState({ isSaving: false });
    }

    private onSaveFailed() {
        this.setState({ isSaving: false });
    }
}
