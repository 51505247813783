// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --splash-screen-z-index: 1;

    --modal-first-level-z-index: 3;
    --modal-second-level-z-index: 4;

    --ui-blocking-backdrop-z-index: 5;
    --ui-blocking-invisible-backdrop-z-index: 6;
}
`, "",{"version":3,"sources":["webpack://./Styling/Depth/ZIndices.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;;IAE1B,8BAA8B;IAC9B,+BAA+B;;IAE/B,iCAAiC;IACjC,2CAA2C;AAC/C","sourcesContent":[":root {\n    --splash-screen-z-index: 1;\n\n    --modal-first-level-z-index: 3;\n    --modal-second-level-z-index: 4;\n\n    --ui-blocking-backdrop-z-index: 5;\n    --ui-blocking-invisible-backdrop-z-index: 6;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
