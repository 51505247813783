import * as React from "react";
import { FunctionComponent, useState } from "react";
import { inductosenseBoldOrange } from "Styling/Palette/BrandColours";
import ScatterPlotGraphForReadingTrend from "Components/Graphs/Scatter/ScatterPlotGraphForReadingTrend";
import Sensor from "Model/Sensor";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DateCoordinateForReadingTrend } from "../../Types/DateCoordinateForReadingTrend";
import NoDataMessage from "./Primary/NoDataMessage";
import ReadingTrendPoint from "../../Model/ReadingTrendPoint";
import { Readinganalysisstatus } from "@inductosense/typescript-fetch";
import DropDownListControlled from "../../Components/Input/DropDownListControlled";
import { FormControlLabel } from "@mui/material";
import TrendGraphPanelArray from "Components/ArrayTest/TrendGraphPanelArray";

const leftArrow = "\u2190";
const ellipsis = "\u2026";

dayjs.extend(utc);

interface TrendGraphPanelProps {
    plots: {
        sensor: Sensor;
        readingPoints: ReadingTrendPoint[];
    }[];
    onSensorClick: (sensor: Sensor) => void;
    ignoredReadings: ReadingTrendPoint[];
    highlightedReading: ReadingTrendPoint | null;
    onHoveredReadingChange(reading: ReadingTrendPoint | null): void;
    onReadingDoubleClicked?(reading: ReadingTrendPoint): void;
    onReadingRightClicked?(reading: ReadingTrendPoint): void;
    onReadingsDeleteRequested?(readings: ReadingTrendPoint[]): void;
    showDeleteButton?: boolean;
    unitsMode: "imperial" | "metric";
    showLegend?: boolean;
    tabletMode: boolean;
    showRecalculatingMessage?: boolean;
}

const TrendGraphPanel: FunctionComponent<TrendGraphPanelProps> = (props) => {
    // Add condition if array? *

    if (props.plots.map(p => p.readingPoints).flat().filter(r => r.txChannel > 0 || r.rxChannel > 0).length > 0) {
        return <TrendGraphPanelArray
            {...props}
        />;
    }

    const mapThicknessToUnits = (thicknessInMetres: number | undefined | null) => {
        if (thicknessInMetres === null || thicknessInMetres === undefined) return undefined;

        if (props.unitsMode === "imperial") {
            return thicknessInMetres * 39.37; // convert to inches
        } else {
            return thicknessInMetres * 1000; // convert to mm
        }
    }

    const mapThicknessToUnitsFormat = (thicknessInMetres: number | undefined | null) => {
        if (thicknessInMetres === null || thicknessInMetres === undefined) return undefined;

        if (props.unitsMode === "imperial") {
            return `${(thicknessInMetres * 39.37).toFixed(3)}\u2033`; // convert to inches, keeps 3 decimal places
        } else {
            return `${(thicknessInMetres * 1000).toFixed(2)} mm`; // convert to mm, keeps 2 decimal places
        }
    }

    const mapTemperatureToUnitsFormat = (temperatureInCelsius: number | undefined | null) => {
        if (temperatureInCelsius === null || temperatureInCelsius === undefined) return undefined;

        if (temperatureInCelsius == 32767 || temperatureInCelsius == -32767) return "NA";

        if (props.unitsMode === "imperial") {
            return `${(temperatureInCelsius * 9 / 5 + 32).toFixed(2)}\u00b0F`; // convert to Fahrenheit 
        } else {
            return `${(temperatureInCelsius).toFixed(2)}\u00b0C`; // remain at Celsius
        }
    }

    const mapTimeToFormat = (date: Date | undefined | null) => {
        if (date === null || date === undefined) return undefined;

        const hours = date.getHours().toString().padStart(2, "0"); // Get hours and format as two digits
        const minutes = date.getMinutes().toString().padStart(2, "0"); // Get minutes and format as two digits
        const seconds = date.getSeconds().toString().padStart(2, "0"); // Get seconds and format as two digits
        const day = date.getDate().toString().padStart(2, "0"); // Get day and format as two digits
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (add 1 as months are zero-based) and format as two digits
        const year = date.getFullYear();

        const formattedDateTime = `${day}/${month}/${year}  ${hours}:${minutes}:${seconds}`;
        return formattedDateTime;
    }

    const mapReadingToPoint = (reading: ReadingTrendPoint) => {
        const thicknessUnits = mapThicknessToUnits(reading.latestThicknessM);
        const thicknessInUnitesFormat = mapThicknessToUnitsFormat(reading.latestThicknessM);
        const temperatureInUnitsFormat = mapTemperatureToUnitsFormat(reading.temperatureInDegreesC);
        const timeInFormat = mapTimeToFormat(reading.timestamp);

        return {
            x: reading.timestamp,
            y: thicknessUnits !== undefined ? thicknessUnits : 0,
            displayedTime: timeInFormat != undefined ? timeInFormat : "Unknown",
            displayedThickness: thicknessInUnitesFormat != undefined ? thicknessInUnitesFormat : "Unknown",
            displayedTemperature: temperatureInUnitsFormat !== undefined ? temperatureInUnitsFormat : "Unknown",
            peakWindowSelectionMode: reading.peakWindowSelectionMode !== undefined ? reading.peakWindowSelectionMode : 0
        };
    }

    const mapPointToReading = (inputPoint: DateCoordinateForReadingTrend | null) => {
        if (!inputPoint) return null;

        console.log("plots", props.plots.length);
        for (const plot of props.plots) {
            for (const reading of plot.readingPoints) {
                const readingPoint = mapReadingToPoint(reading);

                // TODO: Is returning false if null correct
                if (readingPoint?.x === inputPoint.x /*&& readingPoint?.y === inputPoint.y*/) {
                    return reading;
                }
            }
        }

        return null;
    }

    const onHoveredPointChange = (point: DateCoordinateForReadingTrend | null) => {
        const reading = mapPointToReading(point);
        props.onHoveredReadingChange(reading);
    }

    const onPointDoubleClicked = (point: DateCoordinateForReadingTrend) => {
        const { onReadingDoubleClicked } = props;

        const reading = mapPointToReading(point);
        if (reading !== null && onReadingDoubleClicked) onReadingDoubleClicked(reading);
    }

    function isNotNull<T>(argument: T | null): argument is T {
        return argument !== null;
    }

    const onPointsDeleteRequested = (points: DateCoordinateForReadingTrend[]) => {
        const { onReadingsDeleteRequested } = props;

        const readings = points.map(p => mapPointToReading(p)).filter(isNotNull);
        if (onReadingsDeleteRequested) onReadingsDeleteRequested(readings);
    }

    const onPointRightClicked = (point: DateCoordinateForReadingTrend) => {
        const { onReadingRightClicked } = props;

        const reading = mapPointToReading(point);
        if (reading !== null && onReadingRightClicked) {
            onReadingRightClicked(reading);
        }
    }

    const NoSensorMessage = () => {
        return (
            <NoDataMessage>
                <span style={{ paddingRight: 20 }}>{leftArrow}</span>
                <span>Select a sensor to show its trend graph{ellipsis}</span>
            </NoDataMessage>
        );
    }

    const arrayNodes = props.plots.length === 1 ?
        [...new Set(props.plots[0].readingPoints
            .sort((a, b) => a.txChannel - b.txChannel)
            .sort((a, b) => a.rxChannel - b.rxChannel)
            .map(p => `Tx ${p.txChannel} Rx ${p.rxChannel}`))] :
        null;

    const [selectedNode, setSelectedNode] = useState<string | undefined>(undefined);

    const Graph = () => {
        const { ignoredReadings, highlightedReading } = props;

        const plots = props.plots.map(plot => {
            const readings = plot.readingPoints
                .filter(p => selectedNode === undefined || `Tx ${p.txChannel} Rx ${p.rxChannel}` === selectedNode)
                .filter(reading => !ignoredReadings.some(ignored => ignored.id === reading.id));

            // TODO: Handle unsuccessful ones

            const includedReadings: ReadingTrendPoint[] = readings.filter(r => r.analysisStatus === Readinganalysisstatus.Successful);
            const errorReadings: ReadingTrendPoint[] = readings.filter(r => r.analysisStatus !== Readinganalysisstatus.Successful);

            // TODO: Remove outliers?
            const points: DateCoordinateForReadingTrend[] | undefined = includedReadings?.map(r => mapReadingToPoint(r))
                .filter((r): r is DateCoordinateForReadingTrend => r !== null);
            const outliers: DateCoordinateForReadingTrend[] | undefined = ignoredReadings?.map(reading => mapReadingToPoint(reading))
                .filter((r): r is DateCoordinateForReadingTrend => r !== null);
            const errorDates = errorReadings.map(reading => mapReadingToPoint(reading));
            const highlightedPoint = (highlightedReading /* TODO && highlightedReading.sensorId === plot.sensor.id*/) ?
                mapReadingToPoint(highlightedReading)
                : null;

            return {
                label: plot.sensor.description || "Sensor",
                errorDates,
                points,
                outliers,
                highlightedPoint,
                onEditClick: () => props.onSensorClick(plot.sensor)
            };
        });

        return (
            <ScatterPlotGraphForReadingTrend
                aboveSegment={
                    <div style={{ marginBottom: 10 }}>
                        {arrayNodes && arrayNodes.length > 1 ?
                            <FormControlLabel
                                label="Array node"
                                labelPlacement="start"
                                control={
                                    <>&nbsp; <DropDownListControlled<string>
                                        options={arrayNodes}
                                        labelFor={n => n}
                                        selectedOption={selectedNode}
                                        onChange={newSelectedNode => setSelectedNode(newSelectedNode)}
                                    /></>
                                }
                            />
                            : undefined}
                    </div>
                }
                plots={plots}
                startXAxisScaleFrom="minimumValue"
                startYAxisScaleFrom="minimumValue"
                xAxisLabel="Time of reading"
                yAxisLabel={props.unitsMode === "imperial" ? "Thickness (\")" : "Thickness (mm)"}
                onHoveredPointChange={point => onHoveredPointChange(point)}
                onPointDoubleClicked={point => onPointDoubleClicked(point)}
                onPointRightClicked={point => onPointRightClicked(point)}
                showDeleteButton={props.showDeleteButton}
                onPointsDeleteRequested={points => onPointsDeleteRequested(points)}
                pointsRadius={props.tabletMode ? 12 : 5}
                styles={{
                    container: {},
                    points: {},
                    outliers: { fill: "darkgrey" },
                    highlight: {
                        opacity: 0.5,
                        fill: inductosenseBoldOrange
                    },
                    trendLine: {}
                }}
                warningValue={props.plots.length === 1 ? mapThicknessToUnits(props.plots[0].sensor.warningParameters.warningThicknessInMetres) : undefined}
                criticalValue={props.plots.length === 1 ? mapThicknessToUnits(props.plots[0].sensor.warningParameters.criticalThicknessInMetres) : undefined}
                yUnits={props.unitsMode === "imperial" ? "\"" : "mm"}
                resetIfThisChanges={"".concat(...props.plots.map(p => p.sensor.id))}
                showLegend={props.showLegend}
                showRecalculatingMessage={props.showRecalculatingMessage}
            />
        );
    };

    return (
        <div style={{ width: "100%", height: "100%", backgroundColor: "white", "display": "flex" }}>
            <div style={{ padding: 5, "flex": 1 }}>
                {
                    props.plots.length !== 0
                        ? Graph()
                        : NoSensorMessage()
                }
            </div>
        </div>
    );
}

export default TrendGraphPanel;
