import NumberIsNegativeValidator from "Validation/Numeric/NumberIsNegativeValidator";
import StringRepresentsARealNumberOrNothingValidator from "Validation/Strings/StringRepresentsARealNumberOrNothingValidator";
import Validator from "Validation/Validator";

export default class StringRepresentsANegativeNumberOrNothingValidator implements Validator<string> {
    errorMessage = "Must be a negative number";

    isValid(value: string | null | undefined) {
        const isANumberOrNothingValidator = new StringRepresentsARealNumberOrNothingValidator();
        const isANegativeNumberValidator = new NumberIsNegativeValidator();

        if (value === null || value === undefined || value === "") return true;
        if (!isANumberOrNothingValidator.isValid(value)) return false;

        return isANegativeNumberValidator.isValid(+value);
    }
}
