import { Sensorgroup, Sensor } from "@inductosense/typescript-fetch";

export default function GetAllSensorsFromGroup(sensorGroup: Sensorgroup) {
    const allSensors = new Map<string, Sensor>();

    function addSensorsToLookup(sensorGroup: Sensorgroup) {
        sensorGroup.sensors?.forEach(sensor => allSensors.set(sensor.rfid || sensor.id, sensor));
        sensorGroup.children?.forEach(group => addSensorsToLookup(group));
    }

    addSensorsToLookup(sensorGroup);

    return allSensors;
}
