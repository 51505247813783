import Validator from "Validation/Validator";

export default class StringRepresentsAValidRfidValidator implements Validator<string> {
    constructor(readonly errorMessage = "Must be a valid RFID", readonly prefix = "") { }

    isValid(value: string | null | undefined) {
        if (value === null || value === undefined) return true;
        if (!value.startsWith(this.prefix)) return false;

        return /^[0-9a-fA-F]{24}$/.test(value);
    }
}
