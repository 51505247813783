import { Link, CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { Alert } from "@mui/material";
import RDCTestPanel from "RDC/RDCTestPanel";
import * as React from "react";
import { Component } from "react";
import RRCPanel from "RRC/RRCPanel";
import * as MainCGI from "Services/Electron/MainCGI";
import Services from "Services/Platform/Services";
import UiBlockingState from "Types/UiBlockingState";
import View from "Types/View";
import UiBlockingOverlay from "Views/AsyncOverlay/UiBlockingOverlay";
import GatewaysView from "Views/Gateways/GatewaysView";
import MainDashboard from "Views/MainDashboard";
import MaterialsView from "Views/Materials/MaterialsView";
import RecalculationDashboard from "Views/Recalculation";
import DcScanningOverviewForm from "../DC/DcScanningOverviewForm";
import UiSettings from "../Model/UiSettings";
import LoginFormModalPanel from "../Panels/Composite/Modals/LoginFormModalPanel";
import FlexibleAndFixedPanel from "../Panels/FlexibleAndFixedPanel";
import ModalPanel from "../Panels/ModalPanel";
import RDCScanningOverviewForm from "../RDC/RDCScanningOverviewForm";
import SpiderPanel from "../Spider/SpiderPanel";
import DeploymentsDashboard from "./DeploymentsDashboard";
import ExportDashboard from "./Export";
import ImportDashboard from "./Import";
import InstallConfigScreen from "./InstallConfigScreen";
import UserListView from "./UserListPanel";
import { manifest, ManifestType } from "./manifest";

interface TopLevelViewProps {
    onReadyToRender(): void;
    onFailedToAuthenticate(response: Response): void;
}

interface TopLevelViewState {
    isAuthenticated: boolean;
    isSyncingData: boolean;
    isShowingDbUpdateModal: boolean;
    isInitialLoad: boolean;
    isAuthenticating: boolean;
    uiBlockingState: UiBlockingState;
    activeView: View;
    loginErrorMessage?: string;
    progressTextLines: string[];
    uiSettings?: UiSettings;
    serverConnectionDetected: boolean;
    tabletMode: boolean;
    syncErrors?: string;
    showingErrors: boolean;
    availableTenants?: string[];
    tenantName?: string;
    manifest: ManifestType;
    areGatewaysEnabled: boolean;
}

const prefixCls = "toplevel"

class TopLevelView extends Component<TopLevelViewProps, TopLevelViewState> {
    constructor(props: TopLevelViewProps) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: false,
            isInitialLoad: true,
            isSyncingData: false,
            isShowingDbUpdateModal: false,
            uiBlockingState: false,
            activeView: "dashboard",
            serverConnectionDetected: false,
            progressTextLines: [],
            tabletMode: false,
            showingErrors: false,
            tenantName: undefined,
            manifest: {
                loginScreenMessage: null,
                showPortalLink: false,
                disableUsersScreen: false,
                showMicrosoftLogin: false,
                slides: [],
                deviceHomeImgs: [],
                serverOptions: []
            },
            areGatewaysEnabled: false,
        };
    }

    async componentDidMount() {
        this.setState({
            isAuthenticating: false,
            isAuthenticated: false,
            isInitialLoad: false,
            tabletMode: localStorage.getItem("tabletMode") === "true"
        });

        this.setState({ manifest });

        setInterval(async () => {
            if (Services.isSessionActive()) {
                await Services.sendAliveMessage();
            }
        }, 3000);

        try {
            const response = await fetch("https://static.inductosense.cloud/idart/manifest.json", { cache: "no-store" });
            const manifestOnline: ManifestType = await response.json();

            console.log("manifestOnline", manifestOnline);

            this.setState({ manifest: manifestOnline });
        }
        catch {
            console.log("User is offline");
        }
    }

    async componentDidUpdate(_prevProps: TopLevelViewProps, prevState: TopLevelViewState) {
        if (this.state.activeView === "dashboard" && prevState.activeView !== "dashboard") {
            await this.checkServerAccessible();
        }

        if (this.state.isAuthenticating !== prevState.isAuthenticating) {
            await this.checkServerAccessible();
        }
    }

    async checkServerAccessible() {
        try {
            this.setState({ serverConnectionDetected: false });

            const urlPrefix = await Services.getUrlPrefix();
            const response = await fetch(urlPrefix + "/sessions/active");

            if (response.ok) {  // Check if HTTP status is 2xx
                this.setState({ serverConnectionDetected: true });
            } else {
                console.error("Server responded with status: ", response.status);
            }
        } catch (error) {
            console.error("Error during fetch operation: ", error);
            // Handle error, provide user feedback
        }
    }

    render() {
        const { isAuthenticated, isAuthenticating, isInitialLoad, activeView, } = this.state;
        const myTheme = createTheme({
            palette: {
                primary: {
                    main: "#EC6F41"
                },
                secondary: {
                    main: "#555"
                }
            }
        });

        const styleState = !isAuthenticated && !isAuthenticating && !isInitialLoad && !(["offlineDashboard", "rrc", "spider", "installConfig", "rdcScanning", "wandScanningOffline", "keyConfig"].includes(activeView));

        return <ThemeProvider theme={myTheme}>
            <div 
                className={`${prefixCls}`}
                style={{ borderColor: !styleState ? "#EC6F41" : "" ,
                    borderStyle: !styleState ? "solid" : "" ,
                    borderWidth: !styleState ? "4px 0px 0px" : "",
                    height: !styleState ? "calc(100vh - 4px)" : "100vh",
                    width: "100%",
                    overflowY: "hidden"
                }}
                onPointerDown={ev => {
                    if (ev.pointerType === "mouse") {
                        setTimeout(() => this.setState({ tabletMode: false }), 200);
                        localStorage.setItem("tabletMode", "false");
                    } else if (ev.pointerType === "touch") {
                        setTimeout(() => this.setState({ tabletMode: true }), 200);
                        localStorage.setItem("tabletMode", "true");
                    }
                }}
            >
                {this.main()}
            </div>
        </ThemeProvider>;
    }

    main() {
        const { isAuthenticated, isAuthenticating, isInitialLoad, activeView, loginErrorMessage,
            serverConnectionDetected, syncErrors, manifest } = this.state;

        if (!isAuthenticated && !isAuthenticating && !isInitialLoad && !(["offlineDashboard", "rrc", "spider", "installConfig", "rdcScanning", "wandScanningOffline", "keyConfig"].includes(activeView))) {
            return (
                <LoginFormModalPanel
                    shouldBeShown={true}
                    onRequestOfflineView={view => this.setState({ activeView: view })}
                    onLoginClick={this.onLoginClick.bind(this)}
                    errorMessage={loginErrorMessage} // TODO: this needed?
                    allowLogin={serverConnectionDetected}
                    showWorkOffline={MainCGI.isServiceAvailable()}
                    manifest={manifest}
                />
            );
        }

        if (!this.state.isAuthenticated && this.state.activeView === "dashboard") return null;
        return (
            <>
                <ModalPanel
                    shouldBeShown={this.state.showingErrors}
                    onClose={() => this.setState({ showingErrors: false })}
                    content={<div style={{ width: 800 }}>{this.state.syncErrors}</div>}
                />
                <FlexibleAndFixedPanel
                    topFixedContent={syncErrors ?
                        <Alert severity="error">
                            Upload and/or download of data failed &nbsp;
                            <Link href="#" onClick={(ev: React.MouseEvent) => {
                                ev.preventDefault();
                                this.setState({ showingErrors: true })
                            }}>
                                Show details
                            </Link>
                        </Alert> : undefined
                    }
                    flexibleContent={this.activeView()}
                />
                <UiBlockingOverlay uiBlockingState={this.state.uiBlockingState} />
            </>
        );
    }

    async dbUpdate(ForceReUpload = false) {

        if (MainCGI.isServiceAvailable()) {

            this.setState({ isSyncingData: true });

            // Workaround until we fix the token issued immediatley being invalid
            // The .NET code can't refresh the token in response to a 401
            // So we send an alive message to ensure authentication headers for DB update
            await Services.sendAliveMessage();

            const syncResult = await MainCGI.uploadDbAuto(ForceReUpload);
            this.setState({ isSyncingData: false, isShowingDbUpdateModal: false });

            // TODO: Unsubscribe status updates...
            console.log("syncResult", syncResult);

            if (syncResult && syncResult.syncErrors.length > 0) {
                this.setState({ syncErrors: JSON.stringify(syncResult.syncErrors) });
            }
        }

        this.setState({ isSyncingData: false, isShowingDbUpdateModal: false }); // TODO: Should have this??
    }

    async onLoginClick(refreshToken: string, tenantName: string, availableTenants: string[], rememberMe: boolean) {
        try {
            await Services.completeAuthentication(refreshToken, tenantName, rememberMe);

            this.setState({
                uiSettings: Services.getUiSettings(),
                isAuthenticated: true,
                isAuthenticating: false,
                isInitialLoad: false,
                availableTenants,
                tenantName
            });

            await this.dbUpdate();

        } catch (exception) {
            // A typeerror is thrown when the server couldn't be reached at all
            if (exception instanceof Response && exception.status === 403) {
                this.setState({ isAuthenticating: false, loginErrorMessage: "Incorrect username/password" })
            } else if (exception instanceof Response && exception.status === 404) {
                this.setState({ isAuthenticating: false, loginErrorMessage: "Incorrect server URL" })
            } else if (exception instanceof Response && exception.status === 400) { // TODO: Check exact error
                this.setState({ isAuthenticating: false, loginErrorMessage: "Please enter a tenant in Advanced Settings" })
            } else {
                throw exception;
            }
        }
        //this.props.onReadyToRender();
    }

    activeView() {
        // TODO: Store UI settings offline
        const uiSettings: UiSettings = this.state.uiSettings !== undefined ?
            this.state.uiSettings :
            { unitsMode: "metric", temperatureUnits: "celsius" };

        const onClose = () => this.changeActiveView("dashboard");

        const showLines = this.state.progressTextLines.slice(-5);

        const dbSyncModal = <ModalPanel
            showBranding
            shouldBeShown={true}
            content={
                <>
                    <div style={{ textAlign: "center" }}><CircularProgress /></div>
                    <ul>{showLines.map((p, i) => {
                            return <li style={{ listStyleType: "square", opacity: 1 - (showLines.length - (i + 1)) / 5 }} key={i}>{p}</li>
                        })}
                    </ul>
                </>}
        />;

        if (this.state.isShowingDbUpdateModal) {
            return dbSyncModal;
        }

        switch (this.state.activeView) {
            case "dashboard": return <MainDashboard
                key={this.state.tenantName}
                isSyncingData={this.state.isSyncingData}
                tabletMode={this.state.tabletMode}
                changeActiveView={newView => this.changeActiveView(newView)}
                uiSettings={uiSettings}
                onSignOut={async () => {
                    // TODO: Check finished sync

                    Services.clearTokens();
                    Services.setSessionActive(false);
                    Services.ClearAuthenticationTokens();
                    this.setState({
                        isAuthenticating: false,
                        isAuthenticated: false,
                        isInitialLoad: false
                    });
                    await this.checkServerAccessible();
                }}
                onAdvancedConfig={() => this.setState({ activeView: "installConfig" })}
                onRequestFullSync={() => this.dbUpdate(true)}
                availableTenants={this.state.availableTenants}
                onTenantChange={newTenant => this.setState({ tenantName: newTenant })}
                tenantName={this.state.tenantName}

            />;
            case "rrc": return <RRCPanel />;
            case "materials": return <MaterialsView onClose={onClose} uiSettings={uiSettings} />;
            case "deployments": return <DeploymentsDashboard  areGatewaysEnabled={this.state.areGatewaysEnabled} onDbUploadRequired={async () => {
                await this.dbUpdate()}} onGatewayClick={()=>{this.changeActiveView("gateways")}} onClose={onClose} manifest={this.state.manifest} uiSettings={uiSettings} />;
            case "installConfig":
                if (this.state.isSyncingData) {
                    console.log("showing sync...");
                    return dbSyncModal;
                }
                return <InstallConfigScreen onClose={() =>
                    this.setState({
                        activeView: "dashboard",
                        isAuthenticated: false,
                        isAuthenticating: false,
                        isInitialLoad: false
                    })}
                    manifest={this.state.manifest}
                />;
            case "rdcTest":
                return <RDCTestPanel onClose={onClose} />;
            case "rdcScanning":
                return <><RDCScanningOverviewForm uiSettings={uiSettings} onClose={onClose} onDbUploadRequired={async () => {
                    await this.dbUpdate();
                }} />{this.state.isSyncingData ? dbSyncModal : null}</>;
            case "wandScanning":
                return <DcScanningOverviewForm uiSettings={uiSettings} onClose={onClose} onDbUploadRequired={async () => {
                    console.log("updating data...");
                    await this.dbUpdate();
                    console.log("updated data");
                }} />;
            case "wandScanningOffline":
                return <DcScanningOverviewForm uiSettings={uiSettings} onClose={onClose} onDbUploadRequired={() => Promise.resolve()} />;
            case "users": return <UserListView manifest={this.state.manifest} onClose={onClose} />;
            case "spider": return <SpiderPanel />;
            case "import": return <ImportDashboard onClose={onClose} />;
            case "export": return <ExportDashboard onClose={onClose} />;
            case "recalculation": return <RecalculationDashboard onClose={onClose} />;
            case "gateways": return <GatewaysView onDbUploadRequired={async () => {await this.dbUpdate()}} onClose={onClose} uiSettings={uiSettings} />;

            default: throw new Error("Unhandled active view encountered.");
        }
    }

    private async changeActiveView(newView: View) {
        if (["rdcScanning", "wandScanning"].includes(newView) && this.state.isSyncingData) {
            this.setState({ activeView: newView, isShowingDbUpdateModal: true });
        } else {
            if(newView === "deployments"){
                const gatewayStatus = await Services.Gateways.getGatewayEnabled();
                this.setState({
                    areGatewaysEnabled: gatewayStatus.gatewayEnabled
                });
            }
            this.setState({ activeView: newView });
        }
    }
}

export default TopLevelView;
