import * as React from "react";
import { Component } from "react";
import { Gateway } from "@inductosense/typescript-fetch";
import ModalPanel from "Panels/ModalPanel";
import UiSettings from "../../../Model/UiSettings";

interface LogsModalProps {
    gateway?: Gateway;
    shouldBeShown: boolean;
    onClose(): void;
    uiSettings: UiSettings;
}

export default class LogsModal
    extends Component<LogsModalProps> {

    constructor(props: LogsModalProps) {
        super(props);
    }

    render() {
        const { onClose, shouldBeShown,gateway } = this.props;

        return (
            <ModalPanel
                title={`${gateway?.displayName} logText`}
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={this.logs(gateway)}
                isGatewayLogs={true}
            />
        );
    }

    private logs(gateway: Gateway | undefined) {
        return <div style={{wordWrap: "break-word"}}>{gateway?.status && gateway.status.logText}</div>
    }

}
