
export default class AliveSemaphore {
    private _count: number;

    constructor(count: number) {
        this._count = count;
    }

    async acquire() {
        while (this._count <= 0) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }
        this._count -= 1;
    }

    release() {
        this._count += 1;
    }
}
