import { Device, Iotdeployment, Iotdeploymentpost, Deploymentattemptlog, Devicedeployments } from "@inductosense/typescript-fetch";
import { Link, Table, TableBody,TableRow,TableCell, TableContainer, Button, TableHead, CircularProgress, TableSortLabel, Tooltip, FormControl, OutlinedInput } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import { Component, CSSProperties } from "react";
import * as MainCGI from "Services/Electron/MainCGI";
import Services from "Services/Platform/Services";
import * as PhysicalRDCDevice from "Services/Electron/PhysicalRDCDevice";
import ButtonPackage from "../Components/Buttons/Button";
import NoSingalSvg from "Graphics/Icons/NoSingalSvg.svg";
import IconButton from "../Components/Buttons/IconButton";
import EditIcon from "../Components/Graphics/Icons/EditIcon";
import LogsGatewayIcon from "Components/Graphics/Icons/LogsGatewayIcon";
import BatteryLevelIndicator from "../Components/Graphics/StatusIndicators/BatteryLevelIndicator";
import IntervalDisplay from "../Components/Text/IntervalDisplay";
import RelativeDate from "../Components/Text/RelativeDate";
import SensorGroup from "../Model/SensorGroup";
import BatteryModalPanel from "../RDC/BatteryModalPanel";
import CreateDeploymentModalPanel from "../RDC/CreateDeploymentModalPanel";
import DeploymentLogIcon from "RDC/DeploymentLogIcon";
import DeviceErrorLogsModalPanel from "RDC/DeviceErrorLogsModalPanel";
import ShowLogsForDeploymentModalPanel from "RDC/ShowLogsForDeploymentModalPanel";
import TemperatureModalPanel from "RDC/TemperatureModalPanel";
import TemperatureText from "../Components/Text/TemperatureText";
import UiSettings from "../Model/UiSettings";
import DeviceDataExtractionLogsModalPanel from "RDC/DeviceDataExtractionLogsModalPanel";
import EditRDCModalPanel from "./CreateRDCModalPanel";
import ScanResponse from "./ScanResponse";
import SignalStrengthIcon from "../Components/Graphics/Icons/SignalStrengthIcon";
import { Buffer } from "buffer";
import InductosenseEmblem from "Components/Graphics/Logos/InductosenseEmblem";
import NoDataSvg from "Graphics/Icons/NoDataSvg.svg";
import merge from "Utilities/Merge";
import ScannedDevice from "RDC/ScannedDevice";
import MoreIcon from "@mui/icons-material/More";
import ModalPanel from "Panels/ModalPanel";
import CustomizeTable from "Components/Tables/Table";
import ReadingsTakenModalPanel from "./ReadingsTakenModalPanel";
import TableColumn from "Types/TableColumn";
import DeviceDbStatus from "./DeviceDbStatus";
import { SystemUpdateAlt } from "@mui/icons-material";
import ButtonsFormFooter from "../Forms/Shared/ButtonsFormFooter";
import CustomizeButton from "../Components/Buttons/Button";
import CameraButton from "../Components/Buttons/Composite/CameraButton";
import TakenReading from "../Model/TakenReading";
import DownloadDataButton from "../Components/Buttons/Composite/DownloadDataButton";
import UpdateConfigButton from "../Components/Buttons/Composite/UpdateConfigButton";
import Switch from "@mui/material/Switch";
import CassiaSvg from "Graphics/Icons/CassiaSvg.svg";


type SortOptions = "location" | "device" | "gateway" | "lastExtracted" | "configuration" | "battery" | "temperature" | "rssi" ;

const footerStyle: React.CSSProperties = {
    marginLeft: 20,
    display:"inline-block",
    marginRight: 20
}

interface RDCDeviceManagerFormProps {
    onGatewayClick?():void,
    onClick?():void;
    isGateway?: boolean;
    uiSettings: UiSettings;
    deviceIds?: string[];
    rootSensorGroup: SensorGroup | undefined;
    onDbUploadRequired(): Promise<void>;
    areGatewaysEnabled?: boolean;
}

interface RDCDeviceManagerFormState {
    scanResponse?: ScanResponse;
    createDeploymentModalDeviceIndex: number | undefined;
    showLogsForDeployment?: Iotdeployment;
    dbDevices?: Devicedeployments[];
    showBatteryForDevice?: Device;
    showTemperatureForDevice?: Device;
    showDataExtractionLogsForDevice?: Device;
    showErrorLogsForDevice?: Device;
    creatingDevice: boolean;
    sortBy?: SortOptions;
    order: "asc" | "desc";
    searchRdc: string;
    loading?: boolean;
    isShowScanRdc: boolean;
    showMoreRdcOperationModal: boolean;
    rdcIndex?: number;
    allDevices?: DeviceDbStatus[];
    isUpdatingFirmware: boolean;
    operationErrorMessage?: string;
    isTakingAdHoc: boolean;
    progressText?: string;
    showReplugMessage: boolean,
    firmwareUpdatePrompt?: { currentVersion: string; newVersion: string; firmwareType: string };
    deploymentUpdatePrompt?: { currentDeployment: string; newDeployment: string; deploymentUpdateAvailable: boolean };
    extractionOfferPrompt?: { offer: true };
    readingsTaken?: TakenReading[];
    isDownloadingData: boolean;
    isUpdatingConfig: boolean;
    isDisplayScanRdcBtn: boolean;
    desktopMessageShouldBeShown: boolean;
    checked: boolean;
    isAutoScanFirst: boolean;
    isNeedReFindDongle: boolean;
    isSort: boolean;
}

// TODO: Remove duplication between controls
const unappliedChangesStyle: CSSProperties = {
    fontStyle: "italic",
    color: "brown"
}

const deviceAWOLStyle: CSSProperties = {
    color: "red",
    paddingRight: "18px"
}

const allowableScheduleDelayInMs = 3 * 24 * 3600 * 1000;

const commonTableHeaderStyle: CSSProperties = {
    fontSize: "12pt",
    fontFamily: "lato",
    fontWeight: "bold"
};

const disabledTableHeaderStyle: CSSProperties = {
    cursor: "default",
    color: "darkgrey"
};

const rdcDeviceContainerStyle: CSSProperties = {
    display: "flex"
};

const rdcDeviceDetailsContainerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    paddingLeft: "10px"
};

const rdcDeviceDetailsNameStyle: CSSProperties = {
    fontSize: "medium"
};

export default class RDCDeviceManagerForm extends Component<RDCDeviceManagerFormProps, RDCDeviceManagerFormState> {
    private readonly tableColumns: TableColumn<ScannedDevice>[];

    intervalId: NodeJS.Timeout | null = null;

    timeoutId: NodeJS.Timeout | null = null;

    constructor(props: RDCDeviceManagerFormProps) {
        super(props);

        this.state = {
            loading: false,
            scanResponse:undefined,
            createDeploymentModalDeviceIndex: undefined,
            creatingDevice: false,
            sortBy: "location",
            order: "desc",
            searchRdc: "",
            isShowScanRdc: false,
            showMoreRdcOperationModal: false,
            isUpdatingFirmware: false,
            isTakingAdHoc: false,
            showReplugMessage: false,
            isDownloadingData: false,
            isUpdatingConfig: false,
            isDisplayScanRdcBtn: false,
            desktopMessageShouldBeShown: false,
            checked: false,
            isAutoScanFirst: true,
            isNeedReFindDongle: false,
            isSort: true,
        };

        this.tableColumns = [
            {
                id: "rdcDevice",
                minimumWidth: 132,
                titleRenderer: () => this.tableHeaderTitle("RDC Device"),
                cellRenderer:  rdcDevice => this.rdcDeviceCell(rdcDevice)
            },
            {
                id: "signalStrength",
                minimumWidth: 132,
                titleRenderer: () => this.tableHeaderTitle("Signal Strength (RSSI)"),
                cellRenderer: rdcDevice => this.signalStrength(rdcDevice)
            },
            {
                id: "firmwareUpdate",
                minimumWidth: 60,
                titleRenderer: () => this.tableHeaderTitle("Firmware Update"),
                cellRenderer: rdcDevice => this.firmwareUpdateCell(rdcDevice)
            },
            {
                id: "adhoc",
                minimumWidth: 60,
                titleRenderer: () => this.tableHeaderTitle("Take Live Readings"),
                cellRenderer: rdcDevice => this.adhocCell(rdcDevice)
            },
            {
                id: "download",
                titleRenderer: () => this.tableHeaderTitle("Download Data"),
                cellRenderer: rdcDevice => this.downloadDataCell(rdcDevice)
            },
            {
                id: "update",
                titleRenderer: () => this.tableHeaderTitle("Update Configuration"),
                cellRenderer: rdcDevice => this.updateConfigurationCell(rdcDevice)
            },
        ];
    }

    async componentDidMount() {
        await this.loadDeployments();
        if (MainCGI.isServiceAvailable()) {
            this.setState({isDisplayScanRdcBtn:true});
            MainCGI.subscribeStatusUpdate(args => {
                this.setState({ progressText: args.status });
            });
    
            PhysicalRDCDevice.subscribeUpdateOffers(arg => {
                this.setState({ firmwareUpdatePrompt: arg });
                return false;
            });
    
            PhysicalRDCDevice.subscribeExtractionOffers(() => {
                this.setState({ extractionOfferPrompt: { offer: true } });
                return false;
            });
    
            PhysicalRDCDevice.subscribeDeploymentUpdateOffers(arg => {
                this.setState({ deploymentUpdatePrompt: arg });
                return false;
            });
        }
    }

    startScanning = () => {
        this.scanForDevices();
        if (!this.intervalId) {
          this.intervalId = setInterval(() => {
            this.scanForDevices();
          }, 30 * 1000);
        }
    };
    
    stopScanning = () => {
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      };

    async loadDeployments() {
        const dbDevices = await Services.IoTDeployments.getDevicesIotDeployments(this.props.deviceIds);
        this.setState({ dbDevices: dbDevices });
    }

    handleChange = (event: { target: { checked: boolean; } }) => {
        this.setState({checked: event.target.checked})
        if (event.target.checked) {
            this.startScanning();
          } else {
            this.stopScanning();
          }
};

    tableHeaderTitle(label: string) {
        return (
            <div style={merge(commonTableHeaderStyle, (Array.from(this.state.scanResponse?.results || [])).length === 0 ? disabledTableHeaderStyle : {})}>
                {label}
            </div>
        );
    }

    rdcDeviceCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex.slice(-12) == rdcDevice.macAddressHex.slice(-12));

        return (
            <div style={rdcDeviceContainerStyle}>
                <div><InductosenseEmblem width={55} height={55} /></div>
                <div style={rdcDeviceDetailsContainerStyle}> 
                    <div style={rdcDeviceDetailsNameStyle}>{dbDevice?.locationTag}</div>
                    <div>{rdcDevice.macAddressHex}</div>
                    <div>{ dbDevice != undefined && dbDevice.lastDownloadDateTime
                        ? <>Last sync <RelativeDate date={dbDevice.lastDownloadDateTime} /></> : null}
                    </div>
                </div>
            </div>
        );
    }

    toHex(input: number[]) {
        return Buffer.from(input).toString("hex").toUpperCase();
    }

    isApplied(input: Devicedeployments) {
        return input.latestSuccessfulDeployment?.id === input.latestDeployment?.id;
    }

    defaultValue(input: number | null | undefined, defaultVal: number) {
        if (input === undefined || input === null) return defaultVal;

        return input;
    }

    compareDbDevices(a:any, b: any) {
        const { sortBy } = this.state;
        if (sortBy === "device") {
            return this.toHex(a.device.macAddress || []).localeCompare(this.toHex(b.device.macAddress || []));
        } else if (sortBy === "battery") {
            return (a.latestDataExtractionAttemptLog?.batteryPercentage || 0) - (b.latestDataExtractionAttemptLog?.batteryPercentage || 0);
        } else if (sortBy === "temperature") {
            return (this.defaultValue(a.latestDataExtractionAttemptLog?.deviceTemperatureInDegreesCelsius, -274)) - (this.defaultValue(b.latestDataExtractionAttemptLog?.deviceTemperatureInDegreesCelsius, -274))
        } else if (sortBy === "configuration") {
            return +this.isApplied(a) - +this.isApplied(b);
        } else if (sortBy === "lastExtracted") {
            return (a.latestSuccessfulDataExtractionAttemptLog?.dateTime.getTime() || 0) - (b.latestSuccessfulDataExtractionAttemptLog?.dateTime.getTime() || 0);
        } else if (sortBy === "location") {
            return (a.latestDataExtractionAttemptLog?.locationTag || "").localeCompare(b.latestDataExtractionAttemptLog?.locationTag || "");
        }else if (sortBy === "rssi") {
            if (a.scanRdcRssi === undefined && b.scanRdcRssi === undefined) {
                return 0;
            }
            if (a.scanRdcRssi === undefined) {
                return 1; 
            }
            if (b.scanRdcRssi === undefined) {
                return -1; 
            }
            return b.scanRdcRssi - a.scanRdcRssi; 
        } else {
            return a.device.updated.getTime() - b.device.updated.getTime();
        }
    }

    compareDbDevicesWithOrder(a: Devicedeployments, b: Devicedeployments) {
        const { order } = this.state;
        return this.compareDbDevices(a, b) * ((order === "asc" || !this.state.isSort) ? 1 : -1);
    }

    rdcRows(dbDevices: any[]) {
        return dbDevices
            .sort((a, b) => this.compareDbDevicesWithOrder(a, b))
            .map((dbDevice, index) => this.rdcRow(dbDevice, index));
    }

    rdcRow(dbDevice: any, index: number) {
        const currentTime = new Date();
        const fiveMinutes = 5 * 60 * 1000;
        const timeDifference = dbDevice.time && Math.abs(currentTime.getTime()-dbDevice.time.getTime());
        const isNotDisplay = dbDevice.time ? (timeDifference - fiveMinutes >= 0 &&  this.state.checked ? true : false) : true; 
        const extractionLog = dbDevice.latestDataExtractionAttemptLog;
        const battery = extractionLog?.batteryPercentage || null;

        const latestDeployment = dbDevice.latestDeployment;
        const appliedDeployment = dbDevice.latestSuccessfulDeployment;
        const unappliedDeployment = latestDeployment?.id !== appliedDeployment?.id ? latestDeployment : undefined;

        let deviceGateway : {
            signalStrength?: number | null,
            gateway?: { displayName?: string },
            lastSeen?: Date;
        } = {};

        if(dbDevice.deviceGateways && dbDevice.deviceGateways.length===1){
            deviceGateway = dbDevice.deviceGateways[0];
        }else if(dbDevice.deviceGateways.length > 1){
            const latestTime = dbDevice.deviceGateways.reduce((latest:any, current:any) => {
                const latestDate = new Date(latest.lastSeen);
                const currentDate = new Date(current.lastSeen);
                return latestDate > currentDate ? latest : current;
            });
            deviceGateway = latestTime;
        }

        const signalStrength = deviceGateway.signalStrength || null ;

        const deviceGatewayName = deviceGateway.gateway?.displayName || null ;

        const temperature = extractionLog?.deviceTemperatureInDegreesCelsius || null;

        const alertScheduleDelay = appliedDeployment != null && this.props.deviceIds != null && (this.getEarliestScheduleActivationTime(appliedDeployment) + allowableScheduleDelayInMs) < Date.now();

        const macAddressHex = this.toHex(dbDevice.device.macAddress || []);

        const isConnctionRDC = !isNotDisplay ? dbDevice.moreOperation : false;

        const gatewayTooltipContent = () => {
            if(deviceGateway.lastSeen){
                const updateTime = new Date(deviceGateway.lastSeen);
                const year = updateTime.getFullYear();
                const month = updateTime.getMonth() + 1;
                const day = updateTime.getDate();
                const hour = updateTime.getHours();
                const minute = updateTime.getMinutes();
        
                const showLastSeenTime = (hour < 10 ? "0" + hour : hour) + ":" + (minute < 10 ? "0" + minute : minute)  + " on " + day + "/" +  (month < 10 ? "0" + month : month) + "/" + year;
        
                return <div style={{ color: "white" }}>
                    <div>Last seen by&nbsp;{deviceGatewayName}</div>
                    <div>at&nbsp;{showLastSeenTime}</div>
                </div>
            }
            else{
                return null
            }
        }

        return  <TableRow sx={{"&:hover":{background: "#f9ede7"}, background: (index !== undefined && index % 2 !== 0) ? "#fafafa" : ""}} key={index}>
            <TableCell align="center">
                <div style={{ paddingRight: "18px" }}>
                    { appliedDeployment !== undefined ? <div>{appliedDeployment.locationTag}</div> : null}
                    {
                        unappliedDeployment !== undefined && unappliedDeployment.locationTag !== appliedDeployment?.locationTag
                        ? <div
                            style={unappliedChangesStyle}
                            title="New location tag - either not applied or not connected to internet since being applied"
                        >
                            {unappliedDeployment.locationTag}
                        </div>
                        : null
                    }
                </div>
            </TableCell>
            { this.state.isShowScanRdc && <TableCell align="center">
                    <div style={{ paddingRight: "18px" }}>
                    { isConnctionRDC && dbDevice.scanRdcRssi && <SignalStrengthIcon rssi={dbDevice.scanRdcRssi}/> }
                    </div>
                </TableCell> }
            <TableCell align="center">
                <span style={{ "verticalAlign": "middle",paddingRight: "18px" }} title={`System delay ${dbDevice.device.systemDelayInSeconds}`}>
                    {macAddressHex}<br />
                    <span style={{ color: "#888" }}>({dbDevice.device.labelSerialNumber || dbDevice.device.serialNumber})</span>
                </span>
            </TableCell>
            {(this.props.isGateway || this.props.areGatewaysEnabled) && <TableCell align="center">
                {(dbDevice.deviceGateways && dbDevice.deviceGateways.length > 0) ? <Tooltip title={gatewayTooltipContent()} >
                    <div onClick={!this.props.isGateway ? this.props.onGatewayClick : undefined} style={{ paddingRight: "18px", cursor: "pointer" }}>
                        { 
                            signalStrength ? <div style={{display:"flex", alignItems: "center",justifyContent:"center"}}>
                                        <SignalStrengthIcon rssi={signalStrength} isGateway={true}/>
                                </div>:
                                <div style={{display:"flex",alignItems: "center",justifyContent:"center"}}>
                                    <CassiaSvg style={{width:"18px",marginRight:"4px"}}/>
                                    <NoSingalSvg />
                                </div>
                        }
                    </div>
                </Tooltip>: null}
            </TableCell>}
            <TableCell align="center">
                {
                    battery !== null ?
                        <Link
                            href="#"
                            onClick={() => this.setState({ showBatteryForDevice: dbDevice.device })}
                        >
                            <BatteryLevelIndicator levelInPercent={battery} />
                            <div style={{ verticalAlign: "middle" }}>{battery}%</div>
                        </Link>
                        : null
                }
            </TableCell>
            <TableCell align="center">
                {
                    temperature !== null ?
                        <Link
                            href="#"
                            onClick={() => this.setState({ showTemperatureForDevice: dbDevice.device })}>
                            <span style={{ verticalAlign: "middle" }}>
                                <TemperatureText temperatureInCelsius={temperature} temperatureUnits={this.props.uiSettings.temperatureUnits} />
                            </span>
                        </Link>
                        : ""
                }
            </TableCell>
            <TableCell align="center">
                <Link
                    href="#"
                    onClick={() => this.setState({ showDataExtractionLogsForDevice: dbDevice.device })}
                    className={`rdcExtractionLogsButton${macAddressHex}`}
                >
                    <span style={alertScheduleDelay ? deviceAWOLStyle : {paddingRight: "18px"}} >
                        {dbDevice.latestSuccessfulDataExtractionAttemptLog ?
                            <RelativeDate date={dbDevice.latestSuccessfulDataExtractionAttemptLog?.dateTime} />
                            : "none"
                        }
                    </span>
                </Link>
                </TableCell>
                <TableCell align="center">
                    <div style={{ paddingRight: "18px" }}>
                        {appliedDeployment !== undefined ?
                            <div title="Last schedule confirmed to be applied">
                                <span style={{ "verticalAlign": "middle" }}>
                                    <IntervalDisplay
                                        prefix={`${appliedDeployment.schedules.length} sensors every `}
                                        valueSeconds={this.getIntervalSeconds(appliedDeployment)}
                                    />
                                </span>&nbsp;
                                <Link onClick={() => this.setState({ showLogsForDeployment: appliedDeployment })}>
                                    {this.deploymentLogIcon(appliedDeployment.logs)}
                                </Link><br />
                            </div>
                            : null
                        }
                        {unappliedDeployment !== undefined ?
                            <div title="New schedule - either not applied or not connected to internet since being applied">
                                <span style={{ "verticalAlign": "middle", ...unappliedChangesStyle }}>
                                    <IntervalDisplay
                                        prefix={`${unappliedDeployment.schedules.length} sensors every `}
                                        valueSeconds={this.getIntervalSeconds(unappliedDeployment)}
                                    />
                                </span>&nbsp;

                                <Link onClick={() => this.setState({ showLogsForDeployment: unappliedDeployment })}>
                                    {this.deploymentLogIcon(unappliedDeployment.logs)}<br />
                                </Link>
                            </div>
                            : null
                        }
                    </div>
                </TableCell>
                <TableCell align="center">
                    <div style={{ display: "flex", justifyContent: "center" }} >
                    <Tooltip title="View Error Logs" >
                            <div>
                                <IconButton
                                    testId="device-test"
                                    icon={<LogsGatewayIcon color="primary" />}
                                    onClick={() => this.setState({ showErrorLogsForDevice: dbDevice.device })}
                                    isProhibited={!Services.userHasPolicy("AddIoTDeviceConfiguration")}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title="Edit Configuration" >
                            <div>
                                <IconButton
                                    icon={<EditIcon color="primary"/>}
                                    className={`rdcEditButton${macAddressHex}`} // TODO: Better to use id?
                                    isProhibited={!Services.userHasPolicy("AddIoTDeviceConfiguration")}
                                    onClick={() => this.createDeployment(index)}
                                />
                            </div>
                        </Tooltip>
                        {isConnctionRDC && <Tooltip title="Operations">
                            <div>
                                <IconButton
                                    icon={ <MoreIcon color="primary"/> }
                                    onClick={() => isConnctionRDC && this.setState({showMoreRdcOperationModal:true,rdcIndex:index}) }
                                    isProhibited={!Services.userHasPolicy("AddIoTDeviceConfiguration")}
                                />
                            </div>
                        </Tooltip>}
                    </div>
                </TableCell>
            </TableRow>
    }

    onColumnClick(newSortBy: SortOptions) {
        const { sortBy, order } = this.state;
        this.setState({isSort: (this.state.isAutoScanFirst|| this.state.isNeedReFindDongle) ? false : true});
        if (newSortBy === sortBy) {
            this.setState({ "order": order === "asc" || (this.state.isAutoScanFirst|| this.state.isNeedReFindDongle) ? "desc" : "asc" });
        } else {
            this.setState({ "sortBy": newSortBy });
        }
    }

    async scanForDevices() {
        if(this.state.isAutoScanFirst || this.state.isNeedReFindDongle){       
            this.setState({allDevices: await PhysicalRDCDevice.allDevices()});
            const initResult = await PhysicalRDCDevice.initRdc();
            const timeout = (ms: number | undefined)=> {
                return new Promise((_, reject) => {
                    this.timeoutId = setTimeout(() => {
                        reject(new Error("Dongle connect timed out"));
                    }, ms);
                });
            }

            if (!initResult) {
                try {
                    await Promise.race([PhysicalRDCDevice.findDongle(), timeout(10_000)]);
                    this.setState({isNeedReFindDongle:false});
                } catch (error) {
                    this.setState({checked: false,isNeedReFindDongle:true});
                    this.stopScanning(); 
                } finally {
                    this.setState({checked: false,isNeedReFindDongle:true});
                    this.stopScanning(); 
                }
            }

            if (this.timeoutId !== null) {
                clearTimeout(this.timeoutId);
                this.timeoutId = null
            }
        }
       
        const scanResponse: ScanResponse = await PhysicalRDCDevice.autoScanForDevices();

        if(scanResponse?.results.length === 0 && scanResponse !== undefined){
            this.setState({ checked: false });
            this.stopScanning();
        }

        this.setState({ scanResponse });

        const oldData = this.state.dbDevices;

        if(scanResponse.results && scanResponse.results.length > 0 && oldData && oldData.length > 0){
            this.setState({isShowScanRdc: true})

            for(let i = 0; i < scanResponse.results.length; i++){
                const index = oldData?.findIndex(obj => this.toHex(obj.device.macAddress || []).slice(-12) == scanResponse.results[i].macAddressHex.slice(-12));
                if (index !== undefined && oldData[index]) {
                    (oldData[index] as any).time = new Date();
                    (oldData[index] as any).moreOperation = true;
                    (oldData[index] as any).scanRdcRssi = scanResponse.results[i].rssi;
                    (oldData[index] as any).rdcData = scanResponse.results[i];
                }
            }

        }
        this.state.isAutoScanFirst && this.onColumnClick("rssi");
        scanResponse.results && this.setState({ dbDevices: oldData });
        this.state.isAutoScanFirst && this.setState({isAutoScanFirst: false});
    }

    contentTable(data:any) {
        return (
                    <CustomizeTable
                        isModalTable={true}
                        columns={this.tableColumns}
                        rows={ [data.rdcData] }
                        getKey={row => row.macAddressHex}
                        minimumHeaderHeight={36}
                        shouldReduceRowHeight
                    />
        );
    }

    signalStrength(rdcDevice: ScannedDevice) {
        return <SignalStrengthIcon rssi={rdcDevice.rssi}/>
    }

    private firmwareUpdateCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex === rdcDevice.macAddressHex);
        if (!dbDevice?.dbDeviceId) return null;

        return (
            <IconButton
                icon={<SystemUpdateAlt color={"primary"}/>}
                onClick={this.onFirmwareUpdateClick.bind(this, rdcDevice, dbDevice.dbDeviceId)}
            />
        );
    }

    private async onFirmwareUpdateClick(rdcDevice: ScannedDevice, dbDeviceId: string) {
        this.setState({ isUpdatingFirmware: true });

        const firmwareUpdateResult = await PhysicalRDCDevice.firmwareUpdate(rdcDevice.macAddressHex, dbDeviceId,
            rdcDevice.isLongRangeMode, rdcDevice.rssi); // TODO: Complete firmware update function
            
        await this.props.onDbUploadRequired();

        if (!firmwareUpdateResult.result) {
            const details = firmwareUpdateResult.errorMessage ? ("\n\n" + firmwareUpdateResult.errorMessage) : "";

            if (firmwareUpdateResult.connectionError) {
                this.setState({ operationErrorMessage: "Unable to connect to device. Reposition tablet/laptop and try again" + details });
            } else {
                this.setState({ operationErrorMessage: "Taking live readings failed" + details });
            }

            this.setState({ isTakingAdHoc: false });
            return;
        }

        this.setState({
            isUpdatingFirmware: false
        });
    }

    private adhocCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex === rdcDevice.macAddressHex);
        if (!dbDevice?.dbDeviceId) return null;

        return (
            <CameraButton
                color={"primary"}
                className={"adHocButton adHocButton" + rdcDevice.macAddressHex}
                isDisabled={false}
                onClick={this.onAdhocClick.bind(this, rdcDevice, dbDevice.dbDeviceId)}
            />
        );
    }

    private async onAdhocClick(rdcDevice: ScannedDevice, dbDeviceId: string) {
        this.setState({ isTakingAdHoc: true });

        this.state.checked && this.stopScanning();

        const adhocResult = await PhysicalRDCDevice.triggerAdhoc(rdcDevice.macAddressHex, dbDeviceId,
            rdcDevice.isLongRangeMode, rdcDevice.rssi);

        await this.props.onDbUploadRequired();

        if (!adhocResult.result) {
            const details = adhocResult.errorMessage ? ("\n\n" + adhocResult.errorMessage) : "";

            if (adhocResult.connectionError) {
                this.setState({ operationErrorMessage: "Unable to connect to device. Reposition tablet/laptop and try again" + details });
            } else {
                this.setState({ operationErrorMessage: "Taking live readings failed" + details });
            }

            this.setState({ isTakingAdHoc: false }); // TODO: Move this to separate from db download?
            return;
        }

        this.setState({
            readingsTaken: adhocResult.readings,
            isTakingAdHoc: false
        });

        this.state.checked && this.startScanning();
    }

    private downloadDataCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex === rdcDevice.macAddressHex);
        if (!dbDevice?.dbDeviceId) return null;

        return (
            <DownloadDataButton
                color={"primary"}
                className={"dataDownloadButton dataDownloadButton" + rdcDevice.macAddressHex}
                isDisabled={false}
                onClick={this.onDownloadDataClick.bind(this, rdcDevice, dbDevice.dbDeviceId)}
            />
        );
    }

    private async onDownloadDataClick(rdcDevice: ScannedDevice, dbDeviceId: string) {
        this.setState({ isDownloadingData: true });

        this.state.checked && this.stopScanning();

        const syncResult = await PhysicalRDCDevice.downloadDeviceData(rdcDevice.macAddressHex, dbDeviceId,
            rdcDevice.isLongRangeMode, rdcDevice.rssi, false);

        this.setState({
            isDownloadingData: false
        });

        await this.props.onDbUploadRequired(); // TODO: Switching back and forth issue

        if (!syncResult.result) {
            if (syncResult.connectionError) {
                this.setState({ operationErrorMessage: "Unable to connect to device. Reposition tablet/laptop according to user guide and try again" });
            } else {
                this.setState({ operationErrorMessage: "Sync failed. Please wait a minute and try again" });
            }

            return;
        }

        this.setState({ allDevices: await PhysicalRDCDevice.allDevices() });
        this.state.checked && this.startScanning();
    }

    private updateConfigurationCell(rdcDevice: ScannedDevice) {
        const { allDevices } = this.state;
        const dbDevice = allDevices?.find(d => d.macAddressHex === rdcDevice.macAddressHex);
        if (!dbDevice?.dbDeviceId) return null;

        return (
            <UpdateConfigButton
                color={"primary"}
                className={"updateConfigButton updateConfigButton" + rdcDevice.macAddressHex}
                isDisabled={false}
                onClick={this.onUpdateConfigClick.bind(this, rdcDevice, dbDevice.dbDeviceId)}
            />
        );
    }

    private async onUpdateConfigClick(rdcDevice: ScannedDevice, dbDeviceId: string) {
        this.setState({ isUpdatingConfig: true });

        this.state.checked && this.stopScanning();

        const syncResult = await PhysicalRDCDevice.updateDeviceConfig(rdcDevice.macAddressHex, dbDeviceId,
            rdcDevice.isLongRangeMode, rdcDevice.rssi, false);

        this.setState({
            isUpdatingConfig: false // TODO: Device config modal?
        });

        await this.props.onDbUploadRequired(); // TODO: Switching back and forth issue

        if (!syncResult.result) {
            if (syncResult.connectionError) {
                this.setState({ operationErrorMessage: "Unable to connect to device. Reposition tablet/laptop according to user guide and try again" });
            } else {
                this.setState({ operationErrorMessage: syncResult.errorMessage || "Sync failed. Please wait a minute and try again" });
            }

            return;
        }

        this.setState({ allDevices: await PhysicalRDCDevice.allDevices() });
        this.state.checked && this.startScanning();
    }

    render() {
        const { isShowScanRdc, searchRdc, dbDevices, createDeploymentModalDeviceIndex, showLogsForDeployment,
            showBatteryForDevice, showTemperatureForDevice, showDataExtractionLogsForDevice,
            showErrorLogsForDevice, creatingDevice, sortBy, order, rdcIndex, isUpdatingFirmware, operationErrorMessage, progressText, firmwareUpdatePrompt, deploymentUpdatePrompt, checked,
            isTakingAdHoc, isDownloadingData, isUpdatingConfig,  desktopMessageShouldBeShown, isDisplayScanRdcBtn, extractionOfferPrompt, readingsTaken
        } = this.state;
        const { showMoreRdcOperationModal } = this.state;
        const { rootSensorGroup,isGateway } = this.props;
        if (rootSensorGroup === undefined || dbDevices === undefined) return <CircularProgress />;
        const selectedDbDevice = createDeploymentModalDeviceIndex !== undefined ? dbDevices[createDeploymentModalDeviceIndex] : undefined;

        return <>
            { isGateway ?                
                <div style={{margin: "20px 0",fontSize:"20px",fontWeight:"bolder"}}>Automatically Associated Devices</div>
                :
                <h2 style={{margin: "20px 0"}}>RDC Management</h2>
            }
            <div style={{marginBottom:"20px",display:"flex", alignItems:"center",position:"relative"}}>
                <FormControl>
                    <OutlinedInput placeholder="Search Location" color="primary" 
                        sx={{
                            height:"38px", 
                            marginRight:"20px",
                            "& .MuiOutlinedInput-notchedOutline":{
                                borderColor:" #ec6f41",
                            }
                        }} 
                        onChange={(e) => {this.setState({searchRdc:e.target.value})}} />
                </FormControl>
                <Button 
                    variant="contained"
                    startIcon={<SearchIcon />}
                    sx = {{
                        padding:"3px 20px",
                        "&:hover": {
                            background: "#a54d2d",
                            border: "unset"
                        },
                        border:"1px solid #ec6f41",
                        backgroundColor: "#ec6f41",
                        color: "#fff",
                        lineHeight:"23px",
                        fontSize: "14px",
                        textTransform: "none",
                        height:"38px"
                    }}
                    onClick={async () => {
                        const dbDeviceData = await Services.IoTDeployments.getDevicesIotDeployments(this.props.deviceIds);
                        const data =   dbDeviceData.filter((item)=>(item.latestSuccessfulDeployment?.locationTag.toLowerCase().includes( searchRdc.toLowerCase())  || item.latestDeployment?.locationTag.toLowerCase().includes( searchRdc.toLowerCase())
                            ))
                        searchRdc ? this.setState({ dbDevices: data }) : this.setState({ dbDevices: dbDeviceData });
                    }}
                >
                        Search
                </Button>
                { !isGateway && <div style={footerStyle} hidden={!Services.userHasPolicy("ChangeDevices")}>
                    <Button 
                        variant="contained"
                        startIcon={<AddIcon />}
                        sx = {{
                            padding:"3px 20px",
                            "&:hover": {
                                background: "#a54d2d",
                                border: "unset"
                            },
                            border:"1px solid #ec6f41",
                            backgroundColor: "#ec6f41",
                            color: "#fff",
                            lineHeight:"23px",
                            fontSize: "14px",
                            textTransform: "none",
                            height:"38px",
                        }}
                        onClick={async () => {
                            this.setState({ creatingDevice: true });
                        }} 
                    >
                            Add RDC
                    </Button>
                </div>}
                {!isGateway && isDisplayScanRdcBtn && <div style={{display:"flex", alignItems:"center",position:"absolute",right:"20px"}}>
                    <Switch
                        checked={this.state.checked}
                        onChange={this.handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                    <p>Scan RDC</p>
                </div>}
            </div>
            <TableContainer>
                <Table>
                    <TableHead sx={{"& .MuiTableCell-root":{fontWeight:"bold",fontSize:"14px",background: "#f9ede7"}, "& .MuiTableCell-head":{background: "#f9ede7"}}}>
                    <TableRow>
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "location"} direction={order} onClick={() => this.onColumnClick("location")}>Location</TableSortLabel>
                        </TableCell>
                        { isShowScanRdc && <TableCell align="center">
                            <TableSortLabel active={sortBy === "rssi"} direction={order} onClick={() => this.onColumnClick("rssi")}>RSSI</TableSortLabel>
                        </TableCell>}
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "device"} direction={order} onClick={() => this.onColumnClick("device")}>Device</TableSortLabel>
                        </TableCell>
                        {(isGateway || this.props.areGatewaysEnabled) && <TableCell align="center">
                            <TableSortLabel active={sortBy === "gateway"} direction={order} onClick={() => this.onColumnClick("gateway")}>Gateway</TableSortLabel>
                        </TableCell>}
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell> 
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "lastExtracted"} direction={order} onClick={() => this.onColumnClick("lastExtracted")}>Last Extracted</TableSortLabel>
                        </TableCell>  
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "configuration"} direction={order} onClick={() => this.onColumnClick("configuration")}>Configuration</TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            Actions
                        </TableCell>          
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        { (dbDevices &&  dbDevices.length > 0) ? this.rdcRows(dbDevices):  
                        <TableRow>
                            <TableCell align="center" colSpan={8}>
                                <NoDataSvg />
                                <div>No RDC Device</div>
                            </TableCell>
                        </TableRow> 
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalPanel
                shouldBeShown={isUpdatingFirmware}
                title="Checking for firmware updates"
                content={<p>{progressText}</p>}
            /> 
            <ModalPanel
                shouldBeShown={isDownloadingData}
                title="Downloading data"
                content={<p>{progressText}</p>}
            />
            <ModalPanel
                shouldBeShown={isTakingAdHoc}
                title="Taking live readings"
                content={<p>{progressText}</p>}
            />
            <ModalPanel
                shouldBeShown={isUpdatingConfig}
                title="Updating config"
                content={<p>{progressText}</p>}
            />
            <ModalPanel
                content={<>
                    <p >The desktop version of iDART is required to use this feature. Click the link below to download it:</p>
                    <a
                        href="https://portal.inductosense.cloud/software-downloads"
                        style={{ height: 40, display: "block" }}
                    >
                        Download app
                    </a>
                </>}
                shouldBeShown={desktopMessageShouldBeShown}
                onClose={() => this.setState({ desktopMessageShouldBeShown: false })}
                showBranding
                floatingActionButton
            />
             {deploymentUpdatePrompt !== undefined ?
                    <ModalPanel
                        shouldBeShown={true}
                        title={"Update configuration?"}
                        onClose={async () => {
                            await PhysicalRDCDevice.updateDevice(false);
                            this.setState({ deploymentUpdatePrompt: undefined })
                        }}
                        content={
                            deploymentUpdatePrompt.deploymentUpdateAvailable ?
                                <>
                                    <p><strong>Current</strong></p>
                                    <p>{deploymentUpdatePrompt.currentDeployment}</p>

                                    <p><strong>New</strong></p>
                                    <p>{deploymentUpdatePrompt.newDeployment}</p>

                                    <ButtonsFormFooter
                                        buttons={[

                                            <ButtonPackage
                                                key="now"
                                                onClick={async () => {
                                                    this.setState({ deploymentUpdatePrompt: undefined });
                                                    await PhysicalRDCDevice.updateDevice(true);
                                                }}
                                                label="Update Now"
                                                precedence="primary"
                                            />,
                                            <ButtonPackage
                                                key="later"
                                                onClick={async () => {
                                                    await PhysicalRDCDevice.updateDevice(false);
                                                    this.setState({ deploymentUpdatePrompt: undefined });
                                                }}
                                                label="Update Later"
                                            />
                                        ]}
                                    />
                                </> :
                                <>
                                    <p>You have the latest configuration</p>
                                    <ButtonsFormFooter
                                        buttons={[
                                            <ButtonPackage
                                                key="later"
                                                onClick={async () => {
                                                    await PhysicalRDCDevice.updateDevice(false);
                                                    this.setState({ deploymentUpdatePrompt: undefined });
                                                }}
                                                label="OK"
                                                precedence="primary"
                                            />
                                        ]}
                                    />
                                </>
                        }
                    />
                    : null}
            {
                    operationErrorMessage !== undefined ?
                        <ModalPanel
                            shouldBeShown={true}
                            title="Operation Error"
                            content={<p className="operationErrorMessage">{operationErrorMessage}</p>}
                            onClose={async() => {
                                this.setState({ operationErrorMessage: undefined })
                                if( isUpdatingFirmware){
                                    await PhysicalRDCDevice.updateDevice(false)
                                    this.setState({ isUpdatingFirmware: false })
                                }
                              }
                            }
                        />
                    : null
                }         
            {
                creatingDevice ? <EditRDCModalPanel
                    shouldBeShown={true}
                    onCancel={() => this.setState({ creatingDevice: false })}
                    onSave={async () => {
                        this.setState({ creatingDevice: false });
                        await this.loadDeployments();
                        await MainCGI.uploadDbAuto();
                    }}
                /> : null
            }
            {
                (selectedDbDevice !== undefined) ?
                    <CreateDeploymentModalPanel
                        initialDeployment={selectedDbDevice.latestDeployment || null}
                        deviceId={selectedDbDevice.device.id}
                        shouldBeShown={true}
                        onCreateClick={this.onCreateRdcDeploymentClick.bind(this)}
                        onClose={() => this.setState({ createDeploymentModalDeviceIndex: undefined })}
                        rootSensorGroup={rootSensorGroup}
                        deviceDeployments={dbDevices}
                    />
                    : null
            }
            {
                (showLogsForDeployment !== undefined) ?
                    <ShowLogsForDeploymentModalPanel
                        iotdeployment={showLogsForDeployment}
                        shouldBeShown={true}
                        onClose={() => this.setState({ showLogsForDeployment: undefined })}
                    />
                    : null
            }
            {
                (showDataExtractionLogsForDevice !== undefined) ?
                    <DeviceDataExtractionLogsModalPanel
                        device={showDataExtractionLogsForDevice}
                        shouldBeShown={true}
                        uiSettings={this.props.uiSettings}
                        onClose={() => this.setState({ showDataExtractionLogsForDevice: undefined })}
                    />
                    : null
            }
            {
                (showErrorLogsForDevice !== undefined) ?
                    <DeviceErrorLogsModalPanel
                        device={showErrorLogsForDevice}
                        shouldBeShown={true}
                        onClose={() => this.setState({ showErrorLogsForDevice: undefined })}
                    />
                    : null
            }
            {
                (showBatteryForDevice !== undefined) ?
                    <BatteryModalPanel
                        device={showBatteryForDevice}
                        shouldBeShown={true}
                        onClose={() => this.setState({ showBatteryForDevice: undefined })}
                    />
                    : null
            }
            {
                (showTemperatureForDevice !== undefined) ?
                    <TemperatureModalPanel
                        device={showTemperatureForDevice}
                        shouldBeShown={true}
                        onClose={() => this.setState({ showTemperatureForDevice: undefined })}
                        temperatureUnits={this.props.uiSettings.temperatureUnits}
                    />
                    : null
            }
            { rdcIndex !== undefined && showMoreRdcOperationModal && 
                <ModalPanel
                    title={`${dbDevices[rdcIndex].latestSuccessfulDeployment !== undefined ?  dbDevices[rdcIndex].latestSuccessfulDeployment?.locationTag : null} Operations`}
                    shouldBeShown={true}
                    onClose={() => this.setState({ showMoreRdcOperationModal: false })}
                    content={rdcIndex !== undefined && this.contentTable(dbDevices[rdcIndex])}
                />
            }
            {firmwareUpdatePrompt !== undefined ?
                    <ModalPanel
                        shouldBeShown={firmwareUpdatePrompt !== undefined}
                        title={`${firmwareUpdatePrompt.firmwareType} firmware`}
                        onClose={async () => {
                            await PhysicalRDCDevice.updateDevice(false);
                            this.setState({ firmwareUpdatePrompt: undefined })
                        }}
                        content={
                            firmwareUpdatePrompt.currentVersion !== firmwareUpdatePrompt.newVersion ?
                                <>
                                    <p>{firmwareUpdatePrompt.currentVersion} to {firmwareUpdatePrompt.newVersion} {firmwareUpdatePrompt.firmwareType}</p>
                                    <ButtonsFormFooter
                                        buttons={[
                                            <CustomizeButton
                                                key="now"
                                                onClick={async () => {
                                                    this.setState({ firmwareUpdatePrompt: undefined });
                                                    checked && this.stopScanning();
                                                    await PhysicalRDCDevice.updateDevice(true);
                                                    checked && this.startScanning();
                                                }}
                                                label="Update Now"
                                                precedence="primary"
                                            />,
                                            <CustomizeButton
                                                key="later"
                                                onClick={async () => {
                                                    await PhysicalRDCDevice.updateDevice(false);
                                                    this.setState({ firmwareUpdatePrompt: undefined });
                                                }}
                                                label="Update Later"
                                            />
                                        ]}
                                    />
                                </> :
                                <>
                                    <p>You have the latest version: {firmwareUpdatePrompt.currentVersion}</p>
                                    <ButtonsFormFooter
                                        buttons={[
                                            <CustomizeButton
                                                key="later"
                                                onClick={async () => {
                                                    await PhysicalRDCDevice.updateDevice(false);
                                                    this.setState({ firmwareUpdatePrompt: undefined });
                                                }}
                                                label="OK"
                                                precedence="primary"
                                            />,
                                            <CustomizeButton
                                                key="now"
                                                onClick={async () => {
                                                    this.setState({ firmwareUpdatePrompt: undefined });
                                                    checked && this.stopScanning();
                                                    await PhysicalRDCDevice.updateDevice(true);
                                                    checked && this.startScanning();
                                                }}
                                                label="Reinstall"
                                            />
                                        ]}
                                    />
                                </>
                        }
                    />
                    : null}
             {extractionOfferPrompt !== undefined ?
                    <ModalPanel
                        shouldBeShown={extractionOfferPrompt !== undefined}
                        title={"Extraction Readings"}
                        onClose={async () => {
                            this.setState({ extractionOfferPrompt: undefined });
                            await PhysicalRDCDevice.updateDevice(false);
                        }}
                        content={
                            <>
                                <p>
                                    Readings have been found on the device which should be extracted to avoid problems with the
                                    firmware upgrade
                                </p>
                                <ButtonsFormFooter
                                    buttons={[
                                        <CustomizeButton
                                            key="now"
                                            onClick={async () => {
                                                this.setState({ extractionOfferPrompt: undefined });
                                                await PhysicalRDCDevice.updateDevice(true);
                                            }}
                                            label="Extract readings"
                                            precedence="primary"
                                        />,
                                        <CustomizeButton
                                            key="later"
                                            onClick={async () => {
                                                this.setState({ extractionOfferPrompt: undefined });
                                                await PhysicalRDCDevice.updateDevice(false);
                                            }}
                                            label="Don't extract"
                                        />
                                    ]}
                                />
                            </>
                        }
                    />
                    : null}
                <ReadingsTakenModalPanel
                    onClose={() => this.setState({ readingsTaken: undefined })}
                    showReadingsTaken={readingsTaken}
                    unitsMode={this.props.uiSettings.unitsMode}
                />
        </>;
    }

    private deploymentLogIcon(logs: Deploymentattemptlog[]) {
        if (logs.length === 0) return null;
        
        return <DeploymentLogIcon withLabel={false} codes={logs.map(l => l.code)} />;
    }

    private async onCreateRdcDeploymentClick(
        iotDeployment: Iotdeploymentpost) {
        await Services.IoTDeployments.iotDeploymentsPost(iotDeployment);

        this.setState({ createDeploymentModalDeviceIndex: undefined });
        await this.loadDeployments();

        const syncResult = await MainCGI.uploadDbAuto();
        console.log("syncResult", syncResult);
    }

    private createDeployment(deviceIndex: number) {
        this.setState({ createDeploymentModalDeviceIndex: deviceIndex });
    }

    private getIntervalSeconds(iotDeployment: Iotdeployment | null) {
        if (iotDeployment === null) return null;
        if (iotDeployment.schedules === undefined) return null;

        return iotDeployment.schedules.length > 0 ? iotDeployment.schedules[0].readingIntervalInSeconds : null;
    }

    private getEarliestScheduleActivationTime(iotDeployment: Iotdeployment | null) {
        if (iotDeployment === null) return Date.now();
        if (iotDeployment.schedules === undefined) return Date.now();

        return Date.now() - (this.getIntervalSeconds(iotDeployment) ?? 0);
    }
}
