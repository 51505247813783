import * as D3 from "d3";
import * as React from "react";
import { FunctionComponent, CSSProperties } from "react";
import { MarginComponents } from "Types/Styling";
import { inductosenseBoldOrange, inductosenseDeepTeal, inductosenseDuckEggTeal, inductosenseSmokeGrey } from "Styling/Palette/BrandColours";
import EnvelopeLine from "Components/Graphs/Line/EnvelopeLine";
import GraphAxis from "Components/Graphs/GraphAxis";
import PathLine from "Components/Graphs/Line/PathLine";

const yZeroLineStyle: CSSProperties = {
    strokeWidth: 1,
    stroke: inductosenseSmokeGrey,
    strokeDasharray: "5, 5"
};

const pathStyle: CSSProperties = {
    fill: "none",
    strokeWidth: 1,
    stroke: inductosenseDuckEggTeal,
    overflow: "hidden"/*,
    clipPath: "inset(10px 5px)"*/
};

const envelopeStyle: CSSProperties = {
    fill: "none",
    strokeWidth: 3,
    stroke: inductosenseDeepTeal
};

const thresholdStyle: CSSProperties = {
    strokeWidth: 2,
    stroke: inductosenseBoldOrange
};

const graphMargins: MarginComponents = {
    top: 20,
    right: 10,
    bottom: 45,
    left: 70
};

interface RRCGraphProps {
    points: { t: number; y: number }[];
    xMinimum: number;
    xMaximum: number;
    yMinimum: number;
    yMaximum: number;
    velocityInMetresPerSecond: number;
}

const RRCGraph: FunctionComponent<RRCGraphProps> = props => {
    const { xMinimum, xMaximum, yMinimum, yMaximum, velocityInMetresPerSecond } = props;
    const velocityInMilimetresPerSecond = velocityInMetresPerSecond / 1000;

    const points = props.points.map(coords => {
        return {
            x: 0.5 * velocityInMilimetresPerSecond * coords.t,
            y: coords.y
        }
    });
    
    const styles = {
        yZeroLine: yZeroLineStyle,
        path: pathStyle,
        envelope: envelopeStyle,
        threshold: thresholdStyle
    };

    const graphTranslation = `translate(${graphMargins.left}, ${graphMargins.top})`;

    const xAxisLabel = "Displacement (mm)";
    const yAxisLabel = "Amplitude (-)";
    const size = { width: 350 * (8 / 5), height: 350 };

    const width = size.width - graphMargins.left - graphMargins.right;
    const height = size.height - graphMargins.top - graphMargins.bottom;

    const xScale = D3
        .scaleLinear()
        .domain([xMinimum, xMaximum])
        .range([0, width]);

    const yScale = D3
        .scaleLinear()
        .domain([yMinimum, yMaximum])
        .range([height, 0]);

    const xAxisGenerator = D3.axisBottom(xScale);
    const yAxisGenerator = D3.axisLeft(yScale);

    return (
        <svg width={size.width} height={size.height}>
            <g width={width} height={height} transform={graphTranslation}>
                <EnvelopeLine points={points} xScale={xScale} yScale={yScale} style={styles.envelope} />
                <PathLine points={points} xScale={xScale} yScale={yScale} style={styles.path} />

                <rect
                    x={0}
                    y={-graphMargins.top}
                    width={size.width}
                    height={graphMargins.top}
                    fill="white"
                />
                <rect
                    x={-graphMargins.left}
                    y={0}
                    width={graphMargins.left}
                    height={size.height}
                    fill="white"
                />
                <rect
                    x={0}
                    y={size.height - graphMargins.bottom - graphMargins.top}
                    width={size.width}
                    height={graphMargins.bottom}
                    fill="white"
                />
                <rect
                    x={size.width - graphMargins.right - graphMargins.left}
                    y={0}
                    width={graphMargins.right}
                    height={size.height}
                    fill="white"
                />

                <GraphAxis axis="x" axisGenerator={xAxisGenerator} containerSize={size} graphMargin={graphMargins} label={xAxisLabel} />
                <GraphAxis axis="y" axisGenerator={yAxisGenerator} containerSize={size} graphMargin={graphMargins} label={yAxisLabel} />
            </g>
        </svg>
    );
}

export default RRCGraph;
