import Validator from "Validation/Validator";

export default class NumberIsNegativeValidator implements Validator<number> {
    errorMessage = "Must be negative";

    isValid(value: number | null | undefined) {
        if (value === null || value === undefined) return false;
        return value < 0;
    }
}
