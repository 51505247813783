export type Axis = "x" | "y";

export type XUnit = number | Date;
export type YUnit = number;

export function getNumericValue(x: XUnit) {
    if (typeof x === "number") return x;
    return x.getTime();
}

export function compare(a: XUnit, b: XUnit) {
    if (a > b) return 1;
    if (a < b) return -1;

    return 0;
}

export type CartesianCoordinate = {
    x: XUnit;
    y: YUnit;
};

export function coordsEqual(a: CartesianCoordinate, b: CartesianCoordinate) {
    return a.x === b.x && a.y === b.y;
}

export type PointsInCartesianSpace = CartesianCoordinate[];

export type ScaleMinimum = "zero" | "minimumValue";
