import * as MaterialUi from "@mui/material";
import * as BrandColours from "Styling/Palette/BrandColours";
import * as React from "react";
import { Component, CSSProperties } from "react";
import { TextAlignment, textAlignmentToCssTextAlignValue } from "Types/TextAlignment";
import InformationIcon from "Components/Graphics/Icons/InformationIcon";
import TableColumn from "Types/TableColumn";
import NoDataSvg from "Graphics/Icons/NoDataSvg.svg";

const defaultTitleAlignment: TextAlignment = "centre";

const tableContainerStyle: CSSProperties = {
    height: "calc(100vh - 200px)",
    overflowY: "auto"
};

const highlightedRowStyle: CSSProperties = {
    backgroundColor: "rgba(0, 0, 0, 0.05)"
};

const noRowsMessageTableStyle: CSSProperties = {
    height: "100%"
};

const noRowsMessageTableBodyStyle: CSSProperties = {
    backgroundColor: "#c9c9c9"
};

const noRowsMessageContainerStyle: CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "13pt",
    fontWeight: "bold",
    color: BrandColours.inductosenseMediumGrey
};

const noRowsMessageTextStyle: CSSProperties = {
    marginLeft: 7
};

interface TableProps<T> {
    isScanRDc?: boolean;
    columns: TableColumn<T>[];
    rows: T[] | [];
    getKey(row: T): string | number | undefined;
    noRowsMessage?: string | React.ReactNode;
    highlightedRow?: T | null;
    minimumHeaderHeight?: number;
    shouldHaveStickyHeader?: boolean;
    shouldReduceRowHeight?: boolean;
    onHoveredRowChange?(row: T | null): void;
    isModalTable?: boolean;
}

export default class Table<T> extends Component<TableProps<T>> {
    static readonly defaultProps = { shouldHaveStickyHeader: true };

    render() {
        const { rows, shouldHaveStickyHeader, shouldReduceRowHeight, isModalTable } = this.props;

        return (
            <div  style={ !isModalTable && rows && rows.length > 0 ? tableContainerStyle : undefined }>
                <MaterialUi.Table
                    style={rows.length === 0 ? noRowsMessageTableStyle : undefined}
                    stickyHeader={shouldHaveStickyHeader}
                    size={shouldReduceRowHeight ? "small" : undefined}
                >
                    {this.head()}
                    {this.body()}
                </MaterialUi.Table>
            </div>
        );
    }

    private head() {
        return (
            <MaterialUi.TableHead sx={{"& .MuiTableCell-root":{fontWeight:"bold",fontSize:"14px",background: "#f9ede7"}, "& .MuiTableCell-head":{background: "#f9ede7"}}}>
                <MaterialUi.TableRow>
                    {this.props.columns.map(column => this.title(column))}
                </MaterialUi.TableRow>
            </MaterialUi.TableHead>
        );
    }

    private body() {
        const { noRowsMessage, rows, isScanRDc } = this.props;

        if ( isScanRDc && rows.length === 0 && noRowsMessage !== undefined) return this.noRowsMessage();

        return (
            <MaterialUi.TableBody>
                {(rows && rows.length > 0) ? rows.map((row,index) => this.row(row,index)) : 
                <MaterialUi.TableRow>
                    <MaterialUi.TableCell align="center" colSpan={8}>
                        <NoDataSvg />
                        <div>No Data</div>
                    </MaterialUi.TableCell>
                </MaterialUi.TableRow>
                }
            </MaterialUi.TableBody>
        );
    }

    private noRowsMessage() {
        return (
            <tbody style={noRowsMessageTableBodyStyle}>
                <tr>
                    <td colSpan={this.props.columns.length}>
                        <div style={noRowsMessageContainerStyle}>
                            <InformationIcon shouldApplyDefaultColour={false} />
                            <div style={noRowsMessageTextStyle}>
                                {this.props.noRowsMessage}
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    private title(column: TableColumn<T>) {
        const { minimumHeaderHeight } = this.props;
        const { id, minimumWidth, titleAlignment, titleRenderer } = column;

        return (
            <MaterialUi.TableCell
                key={id}
                align={textAlignmentToCssTextAlignValue(titleAlignment ?? defaultTitleAlignment)}
                style={{
                    minWidth: minimumWidth,
                    minHeight: minimumHeaderHeight
                }}
            >
                {titleRenderer && titleRenderer()}
            </MaterialUi.TableCell>
        );
    }

    private row(row: T,index:number) {
        const { getKey, highlightedRow } = this.props;

        const isHighlighted = highlightedRow ? getKey(highlightedRow) === getKey(row) : false;

        return (
            // TODO: fix highlighting
            <MaterialUi.TableRow
                sx={{"&:hover":{background: "#f9ede7"}, background: (index !== undefined && index % 2 !== 0) ? "#fafafa" : ""}}
                key={getKey(row)}
                style={isHighlighted ? highlightedRowStyle : undefined}
                onMouseEnter={() => this.onRowMouseEnter(row)}
                onMouseLeave={() => this.onRowMouseLeave()}
                hover
            >
                {this.props.columns.map(column => this.cell(row, column))}
            </MaterialUi.TableRow>
        );
    }

    private cell(row: T, column: TableColumn<T>) {
        return (
            <MaterialUi.TableCell key={column.id} align="center">
                {column.cellRenderer(row)}
            </MaterialUi.TableCell>
        )
    }

    private onRowMouseEnter(row: T) {
        const { onHoveredRowChange } = this.props;
        if (onHoveredRowChange) onHoveredRowChange(row);
    }

    private onRowMouseLeave() {
        const { onHoveredRowChange } = this.props;
        if (onHoveredRowChange) onHoveredRowChange(null);
    }
}
