import { GatewaysApi, Gateway, Gatewaypost, Gatewaypatch } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class GatewaysService extends InductosenseService<GatewaysApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(GatewaysApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getAllGateways() {
        await this.ensureLatestConfig();
        const result: Gateway[] = await this.serverApi!.gatewaysGet();
        return result;
    }

    @wrapWithErrorHandling()
    async createGateway(gateway: Gatewaypost) {
        await this.ensureLatestConfig();
        await this.serverApi!.gatewaysPost({
            gatewaypost: gateway
        });
        return;
    }

    @wrapWithErrorHandling()
    async getGatewayEnabled() {
        await this.ensureLatestConfig();
        return this.serverApi.getGatewayEnabled();
    }

    async rebootGateway(id: string) {
        await this.ensureLatestConfig();
        await this.serverApi!.postGatewaysIdReboot({
            id
        });
        return;
    }

    @wrapWithErrorHandling()
    async updateGateway(id: string, gateway: Gatewaypatch) {
        await this.ensureLatestConfig();
        await this.serverApi!.gatewaysIdPatch({
            id,
            gatewaypatch: gateway
        });
        return;
    }

    @wrapWithErrorHandling()
    async deleteGateway(id: string) {
        await this.ensureLatestConfig();
        await this.serverApi!.gatewaysIdDelete({
            id
        });
        return;
    }
}
