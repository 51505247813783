import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from "@mui/material";
import * as React from "react";
import { Component } from "react";
import Services from "Services/Platform/Services";
import { Device, Dataextractionattemptlog } from "@inductosense/typescript-fetch";
import FirstIfNotEmpty from "Utilities/FirstIfNotEmpty";
import BatteryLevelIndicator from "../Components/Graphics/StatusIndicators/BatteryLevelIndicator";
import { CheckCircleOutline, ErrorOutlined } from "@mui/icons-material";
import TemperatureText from "../Components/Text/TemperatureText";
import UiSettings from "../Model/UiSettings";
import ModalPanel from "../Panels/ModalPanel";

interface RDCDataExtractionLogsProps {
    uiSettings: UiSettings;
}

interface RDCDataExtractionLogsState {
    dataExtractionAttemptLogs?: Dataextractionattemptlog[];
    devices?: Device[];
    showingLogTextFor?: Dataextractionattemptlog;
}

export default class RDCDataExtractionLogs extends Component<RDCDataExtractionLogsProps, RDCDataExtractionLogsState> {
    constructor(props: RDCDataExtractionLogsProps) {
        super(props);

        this.state = {
        };
    }

    async componentDidMount() {
        const dataExtractionAttemptLogs = await Services.IoTDeployments.dataExtractionAttemptLogs();
        const devices = await Services.Devices.getAllDevices();
        this.setState({
            dataExtractionAttemptLogs,
            devices
        });
    }

    deviceDescription(deviceSerialNumber: string) {
        const { devices } = this.state;
        if (devices === undefined) return null;

        const device = FirstIfNotEmpty(devices.filter(d => d.serialNumber === deviceSerialNumber));
        return device?.description || null;
    }

    render() {
        const { dataExtractionAttemptLogs, showingLogTextFor } = this.state;
        if (dataExtractionAttemptLogs === undefined) return null;

        return <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Location Tag</TableCell>
                            <TableCell>Battery</TableCell>
                            <TableCell>Temperature</TableCell>
                            <TableCell>Readings Extracted</TableCell>
                            <TableCell>Error Logs Extracted</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataExtractionAttemptLogs.map((log, index) =>
                            <TableRow
                                key={index}
                            >
                                <TableCell style={{ width: 120 }} title={log.dateTime.toString()}>
                                    {log.dateTime.toDateString()}
                                </TableCell>
                                <TableCell style={{ width: 120 }}>
                                    {log.locationTag || "?"}
                                </TableCell>
                                <TableCell>
                                    {
                                        (log.batteryPercentage !== null && log.batteryPercentage !== undefined) ?
                                            <>
                                                <BatteryLevelIndicator levelInPercent={log.batteryPercentage} />
                                                {log.batteryPercentage}%
                                    </>
                                            : null
                                    }
                                </TableCell>
                                <TableCell>
                                    <TemperatureText temperatureInCelsius={log.deviceTemperatureInDegreesCelsius} temperatureUnits={this.props.uiSettings.temperatureUnits} />
                                </TableCell>
                                <TableCell>
                                    {
                                        log.allNewReadingsExtracted ?
                                            <CheckCircleOutline style={{ verticalAlign: "middle" }} />
                                            : <ErrorOutlined style={{ verticalAlign: "middle" }} />
                                    } &nbsp;
                                {log.numberOfReadingsExtracted}
                                </TableCell>
                                <TableCell>
                                    {
                                        log.allErrorLogsExtracted ?
                                            <CheckCircleOutline style={{ verticalAlign: "middle" }} />
                                            : <ErrorOutlined style={{ verticalAlign: "middle" }} />
                                    } &nbsp;
                                {log.numberOfErrorLogsExtracted}
                                </TableCell>
                                <TableCell>
                                    {Services.userHasPolicy("ViewLogTextUi") ? <Link href="#" onClick={() => this.setState({ showingLogTextFor: log })}>Show logs</Link> : null}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                showingLogTextFor !== undefined ?
                    <ModalPanel
                        content={
                            <div style={{ overflowY: "auto", whiteSpace: "pre-line", width: "100%", height: "80vh" }}>
                                {showingLogTextFor.logText}
                            </div>
                        }
                        shouldBeShown={true}
                        title={`Log text ${showingLogTextFor.dateTime.toDateString()} ${showingLogTextFor.dateTime.toLocaleTimeString()}`}
                        onClose={() => this.setState({ showingLogTextFor: undefined })}
                    />
                    : null
            }
        </>
    }
}
