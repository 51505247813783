import * as React from "react";
import { CSSProperties, useEffect, FunctionComponent, useRef } from "react";
import * as d3 from "d3";
import { LinearOrTimeScale } from "../../../Types/D3Extensions";
import { ScaleLinear } from "d3";
import { DateCoordinateForReadingTrend } from "Types/DateCoordinateForReadingTrend";
import Tooltip from "@mui/material/Tooltip";

interface ScatterPointForReadingTrendProps {
    coordinates: DateCoordinateForReadingTrend;
    radius: number;
    style: CSSProperties | undefined;
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    onPointMouseEnter: (point: DateCoordinateForReadingTrend) => void;
    onPointMouseLeave: () => void;
    onPointDoubleClicked?(point: DateCoordinateForReadingTrend): void;
    onPointRightClicked?(point: DateCoordinateForReadingTrend): void;
}

const ScatterPointForReadingTrend: FunctionComponent<ScatterPointForReadingTrendProps> = (props: ScatterPointForReadingTrendProps) => {
    const cx = props.xScale(props.coordinates.x);
    const cy = props.yScale(props.coordinates.y);
    

    let peakDetectionSelectionModeName;
    switch (props.coordinates.peakWindowSelectionMode) {
        case (1):
            peakDetectionSelectionModeName = "\u2B24 Automatic";
            break;
        case (2):
            peakDetectionSelectionModeName = "\u2B1C Manual";
            break;
        default:
            peakDetectionSelectionModeName = "\u2B24 Unknown";
    }

    const circleRef = useRef<SVGCircleElement>(null);
    const squareRef = useRef<SVGRectElement>(null);

    useEffect(() => {
        const circleElement = d3.select(circleRef.current);
        const squareElement = d3.select(squareRef.current);

        const handleDoubleClick = (ev: MouseEvent) => {
            ev.stopImmediatePropagation();
            if (props.onPointDoubleClicked) {
                props.onPointDoubleClicked(props.coordinates);
            }
        };

        const handleTouchStart = (ev: MouseEvent) => {
            ev.stopImmediatePropagation();
            // Stopping the default touch behavior is enough to allow a double-click to be registered
        };

        circleElement.on("dblclick", handleDoubleClick);
        squareElement.on("dblclick", handleDoubleClick);

        circleElement.on("touchstart", handleTouchStart);
        squareElement.on("touchstart", handleTouchStart);

        return () => {
            circleElement.on("dblclick", null);
            squareElement.on("dblclick", null);
            circleElement.on("touchstart", null);
            squareElement.on("touchstart", null);
        };
    }, [circleRef, squareRef, props.onPointDoubleClicked]);

    const tooltipContent = (
        //<div style={{ backgroundColor: "white", color: "black" }}>
        <div style={{ color: "white" }}>
            <p>Time: {props.coordinates.displayedTime}</p>
            <p>Thickness: {props.coordinates.displayedThickness}</p>
            <p>Temperature: {props.coordinates.displayedTemperature}</p>
            <p>Peak Selection Mode: {peakDetectionSelectionModeName} </p>
        </div>
    );

    if (props.coordinates.peakWindowSelectionMode != 2) {
        return (
            <Tooltip title={tooltipContent} arrow>
                <circle
                    ref={circleRef}
                    onMouseEnter={() => props.onPointMouseEnter(props.coordinates)}
                    onMouseLeave={() => props.onPointMouseLeave()}
                    onContextMenu={ev => {
                        if (props.onPointRightClicked) {
                            ev.preventDefault();
                            props.onPointRightClicked(props.coordinates);
                        }
                    }}
                    cx={cx}
                    cy={cy}
                    r={props.radius}
                    style={props.style}
                />
            </Tooltip>
        );
    }
    else {
        return (
            <Tooltip title={tooltipContent} arrow>
                <rect
                    ref={squareRef}
                    onMouseEnter={() => props.onPointMouseEnter(props.coordinates)}
                    onMouseLeave={() => props.onPointMouseLeave()}
                    onContextMenu={(ev) => {
                        if (props.onPointRightClicked) {
                            ev.preventDefault();
                            props.onPointRightClicked(props.coordinates);
                        }
                    }}
                    x={cx - props.radius} // x of rect is on the rect left margin
                    y={cy - props.radius} // y of rect is on the rect top margin
                    width={2 * props.radius}
                    height={2 * props.radius}
                    style={props.style}
                />
            </Tooltip>
        );
    }
}

export default ScatterPointForReadingTrend;
