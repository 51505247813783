import NumberIsPositiveValidator from "Validation/Numeric/NumberIsPositiveValidator";
import StringRepresentsARealNumberOrNothingValidator from "Validation/Strings/StringRepresentsARealNumberOrNothingValidator";
import Validator from "Validation/Validator";

export default class StringRepresentsAPositiveNumberOrNothingValidator implements Validator<string> {
    errorMessage = "Must be a positive number";

    isValid(value: string | null | undefined) {
        const isANumberOrNothingValidator = new StringRepresentsARealNumberOrNothingValidator();
        const isAPositiveNumberValidator = new NumberIsPositiveValidator();

        if (value === null || value === undefined || value === "") return true;
        if (!isANumberOrNothingValidator.isValid(value)) return false;

        return isAPositiveNumberValidator.isValid(+value);
    }
}
