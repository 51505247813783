import * as React from "react";
import { Component } from "react";
import { Material } from "@inductosense/typescript-fetch";
import ConfigureSensorForm from "Forms/Composite/ConfigureSensorForm";
import ModalPanel from "Panels/ModalPanel";
import Sensor from "Model/Sensor";
import SensorType from "Model/SensorType";
import UiSettings from "../../../Model/UiSettings";
import SensorGroup from "Model/SensorGroup";

interface ConfigureSensorModalPanelProps {
    sensor: Sensor | null;
    sensorTypes: SensorType[];
    materials: Material[];
    shouldBeShown: boolean;
    onClose(): void;
    onSensorUpdated(): void;
    uiSettings: UiSettings;
    rootSensorGroup: SensorGroup;
}

interface ConfigureSensorModalPanelState {
    isSaving: boolean;
}

export default class ConfigureSensorModalPanel extends Component<ConfigureSensorModalPanelProps, ConfigureSensorModalPanelState> {
    constructor(props: ConfigureSensorModalPanelProps) {
        super(props);

        this.state = {
            isSaving: false
        };
    }

    render() {
        const { sensor, onClose, shouldBeShown } = this.props;

        return (
            <ModalPanel
                title={sensor !== null ? "Configure sensor" : "Create sensor"}
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        const { materials, onClose, sensor, sensorTypes, uiSettings, rootSensorGroup } = this.props;

        if (!sensorTypes || !materials) return null;

        return (
            <ConfigureSensorForm
                sensor={sensor}
                sensorTypes={sensorTypes}
                materials={materials}
                warningParameters={sensor?.warningParameters || null}
                onSave={() => this.onSave()}
                onCancel={() => onClose()}
                uiSettings={uiSettings}
                rootSensorGroup={rootSensorGroup}
            />
        );
    }

    private onSave() {
        this.props.onClose();
        this.props.onSensorUpdated();

        this.setState({ isSaving: false });
    }
}
