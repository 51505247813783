import * as React from "react";
import { Component } from "react";
import ModalPanel from "../Panels/ModalPanel";
import Button from "../Components/Buttons/Button";

interface SpiderSensorSelectModalPanelProps {
    shouldBeShown: boolean;
    onClose(): void;
    onSensorSelected(rfid: string): void;
    sensors: { name: string; rfid: string }[];
}

export default class SpiderSensorSelectModalPanel extends Component<SpiderSensorSelectModalPanelProps> {
    render() {
        const { shouldBeShown, onClose, onSensorSelected, sensors } = this.props;

        return (
            <>
                <ModalPanel
                    content={<div>
                        {sensors.map((sensor, index) =>
                            <Button
                                key={index}
                                label={sensor.name}
                                onClick={() => onSensorSelected(sensor.rfid)}
                            />
                        )}
                    </div>}
                    shouldBeShown={shouldBeShown}
                    onClose={onClose}
                />
            </>
        );
    }
}
