import * as React from "react";
import { CSSProperties, useEffect, FunctionComponent, useRef } from "react";
import * as d3 from "d3";
import { LinearOrTimeScale } from "../../../Types/D3Extensions";
import { ScaleLinear } from "d3";
import { DateCoordinate } from "Types/DateCoordinate";

interface ScatterPointProps {
    coordinates: DateCoordinate;
    radius: number;
    style: CSSProperties | undefined;
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    onPointMouseEnter: (point: DateCoordinate) => void;
    onPointMouseLeave: () => void;
    onPointDoubleClicked?(point: DateCoordinate): void;
    onPointRightClicked?(point: DateCoordinate): void;
}

const ScatterPoint: FunctionComponent<ScatterPointProps> = (props: ScatterPointProps) => {
        const cx = props.xScale(props.coordinates.x);
        const cy = props.yScale(props.coordinates.y);

        const circleRef = useRef<SVGCircleElement>(null);

    useEffect(() => {
            d3.select(circleRef.current).on("dblclick", (ev: MouseEvent) => {
                ev.stopImmediatePropagation();
                if (props.onPointDoubleClicked) {
                    props.onPointDoubleClicked(props.coordinates);
                }
            });
            d3.select(circleRef.current).on("touchstart", (ev: MouseEvent) => {
                ev.stopImmediatePropagation();
                // Stopping the default touch behaviour is enough to allow a double-click to be registered
            });

            return () => {
                d3.select(circleRef.current)
                    .on("dblclick", null)
                    .on("touchstart", null);
            };
        });

        return (
            <circle
                ref={circleRef}
                onMouseEnter={() => props.onPointMouseEnter(props.coordinates)}
                onMouseLeave={() => props.onPointMouseLeave()}
                onContextMenu={ev => {
                    if (props.onPointRightClicked) {
                        ev.preventDefault();
                        props.onPointRightClicked(props.coordinates);
                    }
                }}
                cx={cx}
                cy={cy}
                r={props.radius}
                style={props.style}
            />
        );
}

export default ScatterPoint;
