// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner-container-appear { opacity: 0; }
.loading-spinner-container-appear-active { opacity: 1; transition: opacity var(--splash-screen-content-panel-loading-spinner-fade-in-duration); }
.loading-spinner-container-appear-done { opacity: 1; }
`, "",{"version":3,"sources":["webpack://./Styling/Animations/LoadingSpinner/LoadingSpinnerContainer.css"],"names":[],"mappings":"AAAA,oCAAoC,UAAU,EAAE;AAChD,2CAA2C,UAAU,EAAE,uFAAuF,EAAE;AAChJ,yCAAyC,UAAU,EAAE","sourcesContent":[".loading-spinner-container-appear { opacity: 0; }\n.loading-spinner-container-appear-active { opacity: 1; transition: opacity var(--splash-screen-content-panel-loading-spinner-fade-in-duration); }\n.loading-spinner-container-appear-done { opacity: 1; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
