import * as AlertColours from "Styling/Palette/AlertColours";
import * as React from "react";
import { CSSProperties, FunctionComponent, ReactNode } from "react";
import { paddingInsidePanels } from "Styling/Spacing/Padding";
import AlertHeading from "Components/Text/AlertHeading";
import AlertType from "Types/AlertType";
import ErrorIcon from "Components/Graphics/Icons/ErrorIcon";
import InformationIcon from "Components/Graphics/Icons/InformationIcon";
import merge from "Utilities/Merge";
import SuccessIcon from "Components/Graphics/Icons/SuccessIcon";
import WarningIcon from "Components/Graphics/Icons/WarningIcon";

const commonContainerStyle: CSSProperties = {
    borderRadius: 5,
    padding: paddingInsidePanels,
    height: "100%",
    display: "flex"
};

const informationContainerStyle: CSSProperties = {
    backgroundColor: AlertColours.alertInformationBackgroundBlue,
    color: AlertColours.alertInformationTextBlue
};

const warningContainerStyle: CSSProperties = {
    backgroundColor: AlertColours.alertWarningBackgroundAmber,
    color: AlertColours.alertWarningTextAmber
};

const errorContainerStyle: CSSProperties = {
    backgroundColor: AlertColours.alertErrorBackgroundRed,
    color: AlertColours.alertErrorTextRed
};

const successContainerStyle: CSSProperties = {
    backgroundColor: AlertColours.alertSuccessBackgroundGreen,
    color: AlertColours.alertSuccessTextGreen
};

const bodyStyle: CSSProperties = {
    marginLeft: 10
};

interface AlertPanelProps {
    type: AlertType;
    title?: string;
    content: ReactNode;
}

const AlertPanel: FunctionComponent<AlertPanelProps> = ({ content, title, type }) => {
    let containerStyle: CSSProperties;
    let icon: ReactNode;

    switch (type) {
        case "information":
            containerStyle = merge(commonContainerStyle, informationContainerStyle);
            icon = <InformationIcon />;
            break;
        case "warning":
            containerStyle = merge(commonContainerStyle, warningContainerStyle);
            icon = <WarningIcon />;
            break;
        case "error":
            containerStyle = merge(commonContainerStyle, errorContainerStyle);
            icon = <ErrorIcon />;
            break;
        case "success":
            containerStyle = merge(commonContainerStyle, successContainerStyle);
            icon = <SuccessIcon />;
            break;
        default:
            throw new Error("Unexpected alert type encountered.");
    }

    return (
        <div style={containerStyle}>
            {icon}
            <div style={bodyStyle}>
                {title && <AlertHeading text={title} />}
                {content}
            </div>
        </div>
    );
};

export default AlertPanel;
