export type ServerOption = {
    "label": string,
    "name": string,
    "serverUrl": string,
    "channels": string[]
}

export type ManifestType = {
    "loginScreenMessage": string | null,
    "showPortalLink": boolean,
    "disableUsersScreen": boolean,
    "showMicrosoftLogin": boolean,
    "slides": {
        "imageUrl": string,
        "altText": string
    }[],
    "deviceHomeImgs":{
        "imageUrl": string,
        "altText": string
    }[],
    "serverOptions": ServerOption[]
}

export const manifest: ManifestType = {
    "loginScreenMessage": null,
    "showPortalLink": false,
    "disableUsersScreen": false,
    "showMicrosoftLogin": false,
    "slides": [
        {
            "imageUrl": "Inductosense-HDC-Sensor.png",
            "altText": "WAND Handheld Data Collector"
        },
        {
            "imageUrl": "Inductosense-Sensor.png",
            "altText": "WAND ultrasonic sensors"
        },
        {
            "imageUrl": "Inductosense-RDC.png",
            "altText": "WAND Remote Data Collector (WAND-RDC)"
        }
    ],
    "deviceHomeImgs": [
        {
            "imageUrl": "homeImg1.jpg",
            "altText": "device application scenarios 1"
        },
        {
            "imageUrl": "homeImg2.jpg",
            "altText": "device application scenarios 2"
        },
        {
            "imageUrl": "homeImg3.jpg",
            "altText": "device application scenarios 3"
        }
    ],
    "serverOptions": [
        {
            "label": "localhost",
            "name": "localhost",
            "serverUrl": "http://localhost:3200/v1",
            "channels": ["none"]
        },
        {
            "label": "Staging UK South",
            "name": "staginguksouth",
            "serverUrl": "https://api-staging.inductosenseplatform.com/v1",
            "channels": ["none", "staging"]
        },
        {
            "label": "UK South",
            "name": "uksouth",
            "serverUrl": "https://api-production.inductosenseplatform.com/v1",
            "channels": ["none", "staging", "production"]
        },
        {
            "label": "West Europe",
            "name": "westeurope",
            "serverUrl": "https://api-production-westeurope.inductosenseplatform.com/v1",
            "channels": ["none", "staging", "production"]
        }
    ]
}
