import * as D3 from "d3";
import * as React from "react";
import { Axis } from "Types/CartesianSpace";
import { Axis as D3Axis, AxisDomain as D3AxisDomain } from "d3";
import { Component } from "react";
import { Dimensions } from "Types/Sizing";
import { MarginComponents } from "Types/Styling";

interface GraphAxisProps<Domain extends D3AxisDomain> {
    axis: Axis;
    axisGenerator: D3Axis<Domain>;
    containerSize: Dimensions;
    graphMargin: MarginComponents;
    label?: string;
    gapBetweenXAxisAndLabel?: number;
    gapBetweenYAxisAndLabel?: number;
}

export default class GraphAxis<Domain extends D3AxisDomain> extends Component<GraphAxisProps<Domain>> {
    private element: SVGGElement | null = null;

    componentDidMount() { this.generateAxis(); }
    componentDidUpdate() { this.generateAxis(); }

    render() {
        const {
            axis,
            label,
            containerSize,
            graphMargin,
            gapBetweenXAxisAndLabel = 23,
            gapBetweenYAxisAndLabel = 15
        } = this.props;

        const horizontalMargins = graphMargin.left + graphMargin.right;
        const verticalMargins = graphMargin.top + graphMargin.bottom;

        const graphWidth = containerSize.width - horizontalMargins;
        const graphHeight = containerSize.height - verticalMargins;

        const axisTranslation = `translate(0, ${axis === "x" ? graphHeight : 0})`;
        const textRotation = `rotate(${axis === "x" ? 0 : -90})`;
        const textBaseline = {
            x: axis === "x" ? graphWidth - 10 : 0,
            y: axis === "x"
                ? graphHeight + graphMargin.top + gapBetweenXAxisAndLabel
                : gapBetweenYAxisAndLabel - graphMargin.left
        };

        return (
            <g>
                <g ref={e => this.element = e} transform={axisTranslation} style={{ fontSize: 13 }} />
                {label !== undefined ? <text textAnchor="end" x={textBaseline.x} y={textBaseline.y} transform={textRotation}>
                    {label}
                </text> : null}
            </g>
        );
    }

    private generateAxis() {
        const axisGenerator = this.props.axisGenerator as (selection: D3.Selection<SVGGElement | null, unknown, null, undefined>) => void;
        D3.select(this.element).call(axisGenerator);
    }
}
