import * as React from "react";
import { ChangeEvent, Component, ReactNode } from "react";
import { FormFieldChange } from "Types/Forms";
import { TextAlignment } from "Types/TextAlignment";
import Validator from "Validation/Validator";
import TextFieldControlled from "./TextFieldControlled";

interface TextFieldProps {
    textAlignment?: TextAlignment;
    label?: string;
    suffix?: string;
    icon?: ReactNode;
    width?: number;
    initialValue?: string | null;
    isDisabled?: boolean;
    validators?: Validator<string | null>[];
    formValidationError?: string | null;
    onNewValue?(newValue: string | null): void;
    onValueChanged?(change: FormFieldChange): void;
    onNewValidValue?(newValidValue: string | null): void;
    type?: string;
    autoComplete?: string;
    autoFocus?: boolean;
}

interface TextFieldState {
    validationError: string | null;
}

export default class TextField extends Component<TextFieldProps, TextFieldState> {
    private element: HTMLInputElement | undefined = undefined;

    constructor(props: TextFieldProps) {
        super(props);

        const { initialValue, validators } = props;

        let validationError: string | null = null;

        if (validators) {
            let errorMessage: string | null = null;

            for (const validator of validators) {
                if (!validator.isValid(initialValue)) {
                    errorMessage = validator.errorMessage;
                    break;
                }
            }

            if (errorMessage) validationError = errorMessage;
        }

        this.state = { validationError };
    }

    componentDidMount() {
        const { initialValue } = this.props;
        if (!initialValue || !this.element) return;
        this.element.value = initialValue;
    }

    render() {
        const { formValidationError, isDisabled, label, type, autoComplete, icon, suffix, textAlignment, width, autoFocus } = this.props;
        const { validationError } = this.state;

        return (
            <TextFieldControlled
                label={label}
                onChange={event => this.onChange(event)}
                validationError={validationError}
                formValidationError={formValidationError}
                disabled={isDisabled}
                type={type}
                icon={icon}
                width={width}
                suffix={suffix}
                textAlignment={textAlignment}
                InputProps={{
                    inputRef: element => this.element = element,
                    autoFocus
                }}
                autoComplete={autoComplete}
            />
        );
    }

    private onChange(event: ChangeEvent) {
        const { onNewValidValue, onNewValue, onValueChanged, validators } = this.props;
        const { validationError } = this.state;

        const target = event.target as HTMLInputElement;
        const value = target.value !== "" ? target.value : null;

        if (onNewValue) onNewValue(value);

        if (validators) {
            let errorMessage: string | null = null;

            for (const validator of validators) {
                if (!validator.isValid(value)) {
                    errorMessage = validator.errorMessage;
                    break;
                }
            }

            if (errorMessage) {
                this.setState({ validationError: errorMessage });
                if (onValueChanged) {
                    onValueChanged({
                        newValue: value,
                        isValid: false,
                        isDirty: this.props.initialValue !== value
                    });
                }
                return;
            }
        }

        if (validationError) this.setState({ validationError: null });
        if (onNewValidValue) onNewValidValue(value);
        if (onValueChanged) {
            onValueChanged({
                newValue: value,
                isValid: true,
                isDirty: this.props.initialValue !== value
            });
        }
    }
}
