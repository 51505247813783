import * as React from "react";
import { FunctionComponent } from "react";
import InductosenseLogotypeGraphic from "Graphics/Logos/InductosenseLogotypeGraphic.svg";

interface InductosenseLogotypeProps {
    width?: number;
    height?: number;
}

const InductosenseLogotype: FunctionComponent<InductosenseLogotypeProps> = ({ height, width }) => (
    <InductosenseLogotypeGraphic
        width={width ?? "100%"}
        height={height ?? "100%"}
        alt="Inductosense Logotype"
    />
);

export default InductosenseLogotype;
