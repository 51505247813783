import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import SystemDelayEntryForm from "./SystemDelayEntryForm";

interface SystemDelayEntryModalPanelProps {
    shouldBeShown: boolean;
    onClose(): void;
    onSubmit(newValue: number): void;
    currentSystemDelay: number;
}

export default class SystemDelayEntryModalPanel extends Component<SystemDelayEntryModalPanelProps> {
    constructor(props: SystemDelayEntryModalPanelProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { shouldBeShown, onClose } = this.props;

        return (
            <ModalPanel
                title="Update System Delay"
                shouldBeShown={shouldBeShown}
                onClose={onClose}
                content={this.form()}
            />
        );
    }

    form() {
        const { onSubmit, currentSystemDelay } = this.props;

        return <SystemDelayEntryForm
            onSubmit={onSubmit}
            currentSystemDelay={currentSystemDelay}
        />;
    }
}
