import * as React from "react";
import { FunctionComponent } from "react";

const noDataMessageStyle: React.CSSProperties = {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20pt",
    fontWeight: 300,
    fontStyle: "italic",
    textAlign: "center",
    color: "darkgrey"
};

const NoDataMessage: FunctionComponent<{ children: React.ReactNode  }> = (props) => {
    return <div style={noDataMessageStyle}>{props.children}</div>
}

export default NoDataMessage;
