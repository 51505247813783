import Sensor from "../Model/Sensor";
import SensorGroup from "../Model/SensorGroup";

export function FlattenSensors(rootSensorGroup: SensorGroup, sortOrder: "asc" | "desc") {
    const allSensors = new Map<string, Sensor>();
    const allSensorGroups = new Map<string, SensorGroup>();

    const sortMultiplier = sortOrder === "asc" ? 1 : -1;

    function addSensorsToLookup(sensorGroup: SensorGroup) {
        allSensorGroups.set(sensorGroup.id, sensorGroup);
        sensorGroup.sensors?.sort((a, b) => (a.description || "").localeCompare((b.description || "")) * sortMultiplier).forEach(sensor => allSensors.set(sensor.id || "", sensor));
        sensorGroup.children?.sort((a, b) => (a.name || "").localeCompare((b.name || "")) * sortMultiplier).forEach(group => addSensorsToLookup(group));
    }

    addSensorsToLookup(rootSensorGroup);

    return {
        "sensors": allSensors,
        "groups": allSensorGroups
    }
}
