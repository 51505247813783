import * as MaterialUiIcons from "@mui/icons-material";
import * as React from "react";
import { FunctionComponent } from "react";

interface DescriptionIconProps {
    fontSize?: "inherit" | "large" | "medium" | "small";
    color?:  "inherit" | "primary";
}

const LogsGatewayIcon: FunctionComponent<DescriptionIconProps> = ({ fontSize,color }) => <MaterialUiIcons.Description fontSize={fontSize} color={color}/>;

export default  LogsGatewayIcon;