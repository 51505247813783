import * as React from "react";
import { Component } from "react";
import { Material } from "@inductosense/typescript-fetch";
import MaterialsListPanel from "Panels/Composite/MaterialsListPanel";
import Services from "Services/Platform/Services";
import UiSettings from "../../Model/UiSettings";

interface MaterialsViewProps {
    onClose(): void;
    uiSettings: UiSettings;
}

interface MaterialsViewState {
    isReadyToRender: boolean;
    materials?: Material[];
}

export default class MaterialsView extends Component<MaterialsViewProps, MaterialsViewState> {
    constructor(props: MaterialsViewProps) {
        super(props);
        this.state = { isReadyToRender: false };
    }

    async componentDidMount() {
        await this.fetchAllMaterials();
    }

    async fetchAllMaterials() {
        const materials = await Services.Materials.getAllMaterials();

        this.setState({
            isReadyToRender: true,
            materials
        });
    }

    render() {
        const { onClose, uiSettings } = this.props;
        const { isReadyToRender, materials } = this.state;

        if (!isReadyToRender) return null;

        return (
            <MaterialsListPanel
                materials={materials!}
                uiSettings={uiSettings}
                onClose={() => onClose()}
                onMaterialsChange={async () => this.fetchAllMaterials()}
            />
        );
    }
}
