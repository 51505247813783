import * as MaterialUiIcons from "@mui/icons-material";
import * as React from "react";
import { alertWarningForegroundAmber } from "Styling/Palette/AlertColours";
import { Component } from "react";

interface WarningIconProps {
    shouldApplyDefaultColour?: boolean;
    fontSize?: "inherit" | "small" | "large";
}

export default class WarningIcon extends Component<WarningIconProps> {
    static readonly defaultProps: Partial<WarningIconProps> = { shouldApplyDefaultColour: true };

    render() {
        return (
            <MaterialUiIcons.Warning
                htmlColor={this.props.shouldApplyDefaultColour ? alertWarningForegroundAmber : undefined}
                fontSize={this.props.fontSize}
            />
        );
    }
}
