import * as React from "react";
import { Component, CSSProperties } from "react";
import WandConnectionService from "Services/Electron/WandConnectionService";
import CalculationService from "RRC/Services/CalculationService";
import ButtonsFormFooter from "Forms/Shared/ButtonsFormFooter";
import IntervalButton from "./IntervalButton";
import Button from "Components/Buttons/Button";
import AxisLimitForm from "./AxisLimitForm";
import AxisLimits from "./AxisLimits";
import RRCGraph from "./RRCGraph";
import RRCSensor from "./RRCSensor";
import RRCConfig from "./RRCConfig";
import sleep from "Utilities/Sleep";
import SubSeaUISettings from "./RRCUISettings";
import { NumberOfPresamplesBeforeTimeZero } from "Constants/DateAndTime";
import SerialConnection from "./SerialConnection";
import SocketConnection from "./SocketConnection";
import SystemDelayEntryModalPanel from "./SystemDelayEntryModalPanel";
import { Link } from "@mui/material";
import { Alert } from "@mui/lab";

enum RRCSaveState {
    NothingToSave,
    ReadyToSave,
    Saving,
    Saved
}

interface RRCSensorReadingPanelProps {
    uiSettings: SubSeaUISettings;
    connectionDetails: SerialConnection | SocketConnection;
    config: RRCConfig;
    sensor: RRCSensor;
}

interface RRCSensorReadingPanelState {
    payload?: number[];
    deviceSerialNumber?: number;
    isConnecting: boolean;
    isCalculatingThickness: boolean;
    isGettingMeasurement: boolean;
    saveState: RRCSaveState;
    thicknessMm?: number | null;
    minThicknessMm?: number;
    averageCount?: number;
    axisLimits: AxisLimits;
    systemDelay?: number;
    isSystemDelayEntryFormShown: boolean;
    errorMessage?: string;
}

const footerStyle: CSSProperties = {
    marginTop: 20,
    marginBottom: 20,
    display: "flex"
};

export default class RRCSensorReadingsPanel extends Component<RRCSensorReadingPanelProps, RRCSensorReadingPanelState> {
    wandConnectionService = new WandConnectionService();
    calculationService = new CalculationService();
    samplesPerMicrosecond = 56.25;

    constructor(props: RRCSensorReadingPanelProps) {
        super(props);

        this.state = {
            isCalculatingThickness: false,
            isGettingMeasurement: false,
            axisLimits: {
                xLimitMicroseconds: 70,
                yLimitMV: 1_000
            },
            saveState: RRCSaveState.NothingToSave,
            isSystemDelayEntryFormShown: false,
            isConnecting: false
        };
    }

    getLimits() {
        const { axisLimits } = this.state;

        return {
            xLimitMicroseconds: axisLimits.xLimitMicroseconds || 70,
            yLimitMV: axisLimits.yLimitMV || 50
        };
    }

    async componentDidMount() {
        const { connectionDetails } = this.props;

        this.setState({
            isConnecting: true
        });

        console.log("connect");
        if (await this.wandConnectionService.connect(connectionDetails)) {
            console.log("connectionDetails", this.props.connectionDetails);
            await this.wandConnectionService.RRCCreateDirectory();

            const systemDelay = await this.wandConnectionService.getSystemDelayInSeconds();
            console.log("got systemDelay", systemDelay);
            const deviceInfo = await this.wandConnectionService.getDeviceInfo();
            console.log("deviceInfo", deviceInfo);

            this.setState({
                systemDelay,
                deviceSerialNumber: deviceInfo.serialNumber,
                isConnecting: false
            });
        } else {
            this.setState({
                deviceSerialNumber: undefined,
                systemDelay: undefined,
                isConnecting: false
            });
        }
    }

    async componentDidUpdate(prevProps: RRCSensorReadingPanelProps) {
        if (this.props.sensor.rfid !== prevProps.sensor.rfid) {
            this.setState({
                payload: undefined,
                thicknessMm: undefined,
                averageCount: undefined
            });
        }

        if (this.props.connectionDetails.description !== prevProps.connectionDetails.description) {
            try {
                this.setState({
                    isConnecting: true
                });
                if (await this.wandConnectionService.connect(this.props.connectionDetails)) {
                    console.log("connectionDetails", this.props.connectionDetails);
                    await this.wandConnectionService.RRCCreateDirectory();

                    const systemDelay = await this.wandConnectionService.getSystemDelayInSeconds();
                    console.log("got systemDelay", systemDelay);
                    const deviceInfo = await this.wandConnectionService.getDeviceInfoRRC();
                    console.log("deviceInfo", deviceInfo);

                    this.setState({
                        systemDelay,
                        deviceSerialNumber: deviceInfo.serialNumber,
                        isConnecting: false
                    });
                } else {
                    this.setState({
                        deviceSerialNumber: undefined,
                        systemDelay: undefined,
                        isConnecting: false
                    });
                }
            }
            catch {
                console.log("caught");
            }
        }
    }
    

    private round(input: number) {
        return Math.round((input + Number.EPSILON) * 100) / 100;
    }

    private indexToTime(index: number, samplesPerMicrosecond: number) {
        return (index - NumberOfPresamplesBeforeTimeZero) / samplesPerMicrosecond;
    }

    render() {
        const { deviceSerialNumber, systemDelay, axisLimits, payload, isGettingMeasurement,
            averageCount, isSystemDelayEntryFormShown, errorMessage, isConnecting } = this.state;
        const { sensor } = this.props;

        if (isConnecting) {
            return <p>Connecting...</p>;
        }

        if (deviceSerialNumber === undefined) {
            return <Alert severity="error" style={{ marginTop: 15 }}>Could not reach device</Alert>
        }

        return (
            <>
                {errorMessage ?
                    <Alert severity="error" style={{ marginTop: 15 }}>{errorMessage}</Alert>
                    : <Alert severity="success" style={{ marginTop: 15 }}>Connected</Alert>
                }

                <div style={{ marginTop: 15 }}>
                    <AxisLimitForm
                        limits={axisLimits}
                        onLimitChange={axisLimits => this.setState({ axisLimits })}
                    />
                </div>

                <RRCGraph
                    points={(payload || []).map((sample, index) => {
                        return { t: this.indexToTime(index, this.samplesPerMicrosecond), y: sample }
                    })}
                    velocityInMetresPerSecond={sensor.material.longitudinalVelocityAtRoomTemperatureInMetresPerSecond}
                    xMinimum={-7}
                    xMaximum={this.getLimits().xLimitMicroseconds}
                    yMinimum={-this.getLimits().yLimitMV}
                    yMaximum={this.getLimits().yLimitMV}
                />

                <p>Hold down scan button to do a scan</p>

                <div style={footerStyle}>
                    <ButtonsFormFooter
                        buttonsPosition="left"
                        buttons={[
                            <IntervalButton
                                key="scan"
                                label="Scan"
                                interval={198}
                                onIntervalsStart={this.onIntervalsStart.bind(this)}
                                onIntervalsFinished={this.onIntervalsFinished.bind(this)}
                                onIntervalTriggered={() => this.getMeasurementForScreen()}
                                blockNewIntervals={isGettingMeasurement}
                            />,
                            this.saveButton(deviceSerialNumber)
                        ]}
                    />
                </div>

                <SystemDelayEntryModalPanel
                    shouldBeShown={isSystemDelayEntryFormShown}
                    onClose={() => this.setState({ isSystemDelayEntryFormShown: false })}
                    onSubmit={sub => this.onUpdateSystemDelaySubmit(sub)}
                    currentSystemDelay={systemDelay || 0}
                />

                <p>
                    Thickness: {this.renderThickness()}
                </p>

                <p>
                    Average count: {averageCount || ""}
                </p>

                <p>
                    <span>System delay: {systemDelay !== undefined ? `${systemDelay}s` : ""} </span>
                    <Link
                        onClick={() => this.setState({ isSystemDelayEntryFormShown: true })}
                        style={{ cursor: "pointer" }}>
                        update
                    </Link>
                </p>
            </>
        );
    }

    renderThickness() {
        const { thicknessMm } = this.state;

        if (thicknessMm === undefined) return null;
        if (thicknessMm === null) {
            return <span style={{ color: "red" }}>error</span>
        }

        return `${this.round(thicknessMm)}mm`;
    }

    saveButton(deviceSerialNumber: number) {
        const { payload, thicknessMm, minThicknessMm, averageCount, saveState, systemDelay } = this.state;

        if (saveState === RRCSaveState.Saving) {
            return <Button
                key="save"
                isDisabled={true}
                label="Saving..."
                onClick={() => null}
            />;
        }

        if (saveState === RRCSaveState.Saved) {
            return <Button
                key="save"
                isDisabled={true}
                label="✓ Saved"
                onClick={() => null}
            />;
        }

        return (payload && thicknessMm && minThicknessMm && averageCount && systemDelay !== undefined
            && saveState === RRCSaveState.ReadyToSave) ?
            <Button
                key="save"
                isDisabled={false}
                label="Save"
                onClick={() => this.onSaveClick(payload, thicknessMm, deviceSerialNumber, minThicknessMm, averageCount, systemDelay)}
            /> :
            <Button
                key="save"
                isDisabled={true}
                label="Save"
                onClick={() => null}
            />;
    }

    async onUpdateSystemDelaySubmit(newValue: number) {
        this.setState({ isSystemDelayEntryFormShown: false });

        const newSysDelay = parseFloat(newValue.toString());

        await this.wandConnectionService.setSystemDelayInSeconds(newSysDelay);

        this.setState({
            systemDelay: await this.wandConnectionService.getSystemDelayInSeconds()
        });
    }

    async getData(noSamples: number) {
        if (this.state.isGettingMeasurement) return;
        this.setState({ isGettingMeasurement: true });

        const measurement = await this.wandConnectionService.RRCGetMeasurement(noSamples);
        console.log("measurement", measurement);
        this.setState({
            averageCount: measurement.cumulativeAverages
        });
        
        if (measurement.payload) {
            const payload: number[] = measurement.payload;
            this.setState({
                payload,
                isGettingMeasurement: false,
                errorMessage: undefined
            });
        } else if (measurement.error) {
            console.error(measurement.error);
            this.setState({
                errorMessage: "Unable to get measurement",
                payload: undefined,
                isGettingMeasurement: false
            });
        }
    }

    async getMeasurement(noSamples: number) {
        if (this.state.isGettingMeasurement) return;
        this.setState({ isGettingMeasurement: true });

        const scans = this.props.uiSettings.scansAtATime;
        for (let i = 0; i < scans; i++) {
            await this.wandConnectionService.RRCDoScan();
        }

        this.setState({ isGettingMeasurement: false });

        await this.getData(noSamples);
    } 

    async getMeasurementForScreen() {
        if (this.state.isGettingMeasurement) return;

        const noSamples = NumberOfPresamplesBeforeTimeZero + this.samplesPerMicrosecond * 1000 * 2 * this.getLimits().xLimitMicroseconds / this.props.sensor.material.longitudinalVelocityAtRoomTemperatureInMetresPerSecond;
        await this.getMeasurement(Math.max(Math.ceil(noSamples), 500));

        if (!this.state.isCalculatingThickness && this.state.payload !== undefined) {
            this.calculateThickness(this.state.payload);
        }
    }

    calculateThickness(payload: number[]) {
        const { sensor, uiSettings } = this.props;
        const { systemDelay } = this.state;

        if (systemDelay === undefined) {
            throw new Error("System delay not defined");
        }
        
        this.setState({ isCalculatingThickness: true });

        this.calculationService.calculateThickness({
            payload,
            sensor,
            thicknessAlgorithm: uiSettings.thicknessAlgorithm,
            temperature: uiSettings.temperatureDegreesCelsius,
            systemDelay,
            ...uiSettings.isAutomatic ? { automatic: true } : {
                automatic: false,
                minThicknessMm: uiSettings.minThicknessMm,
                amplitudeThreshold: uiSettings.amplitudeThreshold
            },
            numberOfPresamplesBeforeTimeZero: NumberOfPresamplesBeforeTimeZero
        }).then(thicknessResult => {
            console.log("thicknessResult", thicknessResult);
            this.setState({
                isCalculatingThickness: false,
                thicknessMm: thicknessResult?.thicknessMm,
                minThicknessMm: thicknessResult?.minThicknessMm
            });
        });
    }

    async onIntervalsStart() {
        this.setState({
            saveState: RRCSaveState.NothingToSave
        });

        await this.wandConnectionService.RRCStartAveraging();

        this.setState({ isGettingMeasurement: false, averageCount: 0 });
    }

    async onIntervalsFinished() {
        while (this.state.isGettingMeasurement) {
            await sleep(20);
        }

        // TODO: Handle false response
        await this.wandConnectionService.RRCStopAveraging();

        await this.getData(9_999);

        if (!this.state.isCalculatingThickness && this.state.payload !== undefined) {
            this.calculateThickness(this.state.payload);
        }

        this.setState({
            saveState: RRCSaveState.ReadyToSave
        });

        const { payload } = this.state;
        if (payload === undefined) {
            console.error("failed to get final measurement");
        }
    }

    // We pass these variables in rather than taking them from the state to ensure they're not undefined
    // Though on the other hand that does mean the save function only gets the most recent render *
    async onSaveClick(payload: number[], thicknessMm: number, deviceSerialNumber: number, minThicknessMm: number, averageCount: number, systemDelay: number) {
        this.setState({
            saveState: RRCSaveState.Saving
        });

        const payloadTime = payload.map((sample, index) => {
            return {
                t: this.indexToTime(index, this.samplesPerMicrosecond),
                y: sample
            };
        });

        const result = await this.wandConnectionService.RRCSaveReading(
            payload,
            payloadTime,
            thicknessMm,
            deviceSerialNumber,
            this.props.sensor,
            minThicknessMm,
            averageCount,
            systemDelay);
        this.setState({
            saveState: RRCSaveState.Saved
        });
        return result;
    }
}
