import * as MaterialUiIcons from "@mui/icons-material";
import * as React from "react";
import { FunctionComponent } from "react";

interface HealingIconProps {
    color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
}

const HealingIcon: FunctionComponent<HealingIconProps> = ({ color }) => <MaterialUiIcons.Healing color={color} />;

export default HealingIcon;
