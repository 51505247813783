import { UploadedReadingsApi } from "@inductosense/typescript-fetch/dist/apis/UploadedReadingsApi"
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class UploadedReadingsService extends InductosenseService<UploadedReadingsApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(UploadedReadingsApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getUploadedReadings() {
        await this.ensureLatestConfig();
        return this.serverApi.uploadedReadingsGet();
    }

    @wrapWithErrorHandling()
    async uploadedReadingDetail(id: string) {
        await this.ensureLatestConfig();
        return this.serverApi.uploadedReadingDetail({ id: id });
    }

    async saveUploadedReading(id: string) {
        await this.ensureLatestConfig();
        return this.serverApi.saveUploadedReading({ id: id });
    }

    @wrapWithErrorHandling()
    async deleteUploadedReading(id: string) {
        await this.ensureLatestConfig();
        return this.serverApi.deleteUploadedReading({ id: id });
    }
}
