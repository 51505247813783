
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface LoadingComponentProps {
    message?: string;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ message }) => (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <CircularProgress />
        <p style={{ marginTop: 20 }}>{message || "Loading..."}</p>
    </div>
);

export default LoadingComponent;
