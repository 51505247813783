import * as React from "react";
import { Component } from "react";
import * as PhysicalWANDDevice from "Services/Electron/PhysicalWANDDevice";
import ModalPanel from "../Panels/ModalPanel";
import UiSettings from "../Model/UiSettings";
import WandScanResponse from "./DcScanResponse";
import sleep from "../Utilities/Sleep";
import { Alert } from "@mui/lab";
import Button from "Components/Buttons/Button";

interface DcScanningOverviewFormProps {
    onClose(): void;
    onDbUploadRequired(): Promise<void>;
    uiSettings: UiSettings;
}

interface DcScanningOverviewFormState {
    scanResponse?: WandScanResponse;
    progress?: number;
    progressText?: string;
    showReplugMessage: boolean;
    showWakeMessage: boolean;
    showDeleteButton: boolean;
}

export default class DcScanningOverviewForm extends Component<DcScanningOverviewFormProps, DcScanningOverviewFormState> {
    currentFormIsClosed = true;
    constructor(props: DcScanningOverviewFormProps) {
        super(props);

        this.state = {
            progress: 0,
            showReplugMessage: false,
            showWakeMessage: false,
            showDeleteButton: false
        }
    }

    async componentDidMount() {
        this.currentFormIsClosed = false;
        console.log("initing");
        const initResult = await PhysicalWANDDevice.initWand();
        console.log("init WAND result", initResult);

        if (!initResult) {
            this.setState({ showReplugMessage: true });
            await PhysicalWANDDevice.findWand();
            this.setState({ showReplugMessage: false });
            await PhysicalWANDDevice.initWand();
        }

        let scanResponse = await PhysicalWANDDevice.getDeviceInfo();
        
        while (scanResponse == null && !this.currentFormIsClosed) {
            this.setState({ showWakeMessage: true });
            scanResponse = await PhysicalWANDDevice.getDeviceInfo();
            await sleep(200);
        }

        this.setState({ showWakeMessage: false });

        PhysicalWANDDevice.subscribeStatusUpdate(args => {
            console.log("status", args.status);
            this.setState({ progressText: args.status });
        });

        if (scanResponse != null)
            this.setState({ scanResponse });

        const syncResult: { result: boolean; displayError: string; error: string } = await PhysicalWANDDevice.sync(); // TODO: Move type def
        console.log("syncResult", syncResult);

        if (!syncResult.result) {
            alert(syncResult.error);
            await this.props.onDbUploadRequired();
            this.props.onClose();
        } else {
            await this.props.onDbUploadRequired();
            this.setState({ showDeleteButton: true });
            //this.props.onClose();
        }
    }

    async componentWillUnmount() {
        this.currentFormIsClosed = true;
        await PhysicalWANDDevice.disposeWand();
    }

    userMessage() {
        const { showReplugMessage, showWakeMessage, progressText } = this.state;

        if (showReplugMessage) {
            return <Alert severity="info">Please plug in device (or unplug and plug it back in)</Alert>;
        }

        if (showWakeMessage) {
            return <Alert severity="info">Please power on device</Alert>;
        }

        return <p>{progressText}</p>;
    }

    render() {
        const { showDeleteButton } = this.state;
        const canClose = this.state.showReplugMessage || this.state.showWakeMessage || this.state.showDeleteButton;

        return (
            <>
                <ModalPanel
                    showBranding
                    shouldBeShown={true}
                    title="Sync device data"
                    content={<>
                        <p>This updates the sensors and users on a handheld DataCollector and downloads the readings</p>
                        {this.userMessage()}
                        {showDeleteButton ?
                            <Button
                                label="Delete All Readings"
                                onClick={async () => {
                                    await PhysicalWANDDevice.deleteAllReadings();
                                    this.props.onClose(); // TODO: Close auto?
                                }}
                            />
                            : null
                        }
                    </>}
                    onClose={canClose ? this.props.onClose : undefined}
                />
            </>
        );
    }
}
