// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
}

.MuiButton-label {
    text-transform: none;
    font-family: "Helvetica", "Arial", sans-serif;
}

.MuiTab-wrapper {
    text-transform: none;
    font-family: "Helvetica", "Arial", sans-serif;
}

.MuiTreeItem-iconContainer {
    width: 0 !important;
}

.MuiTreeItem-group {
    margin-left: 5px !important;
}

.sensorListUl {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
`, "",{"version":3,"sources":["webpack://./Styling/MaterialUiOverrides.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;IACI,oBAAoB;IACpB,6CAA6C;AACjD;;AAEA;IACI,oBAAoB;IACpB,6CAA6C;AACjD;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;AACb","sourcesContent":["body {\n}\n\n.MuiButton-label {\n    text-transform: none;\n    font-family: \"Helvetica\", \"Arial\", sans-serif;\n}\n\n.MuiTab-wrapper {\n    text-transform: none;\n    font-family: \"Helvetica\", \"Arial\", sans-serif;\n}\n\n.MuiTreeItem-iconContainer {\n    width: 0 !important;\n}\n\n.MuiTreeItem-group {\n    margin-left: 5px !important;\n}\n\n.sensorListUl {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
