import * as React from "react";
import { Component } from "react";
import ArrayData from "./ArrayData";
import GridView from "./GridView";
import FlexibleAndFixedPanel from "Panels/FlexibleAndFixedPanel";
import ArrayDataItem from "./ArrayDataItem";

interface ArrayPanelProps {
    showGrid: boolean;
    onNodeClick(coords: { x: number; y: number }, node?: ArrayDataItem): void;
    arrayData?: ArrayData;
    aboveSegment?: React.ReactElement | string | number;
}

interface ArrayPanelState {
    showArray: boolean;
    thicknessGraph: boolean;
    showGrid: boolean;
    chooseThickness: boolean;
}

export default class ArrayPanel extends Component<ArrayPanelProps, ArrayPanelState> {
    constructor(props: ArrayPanelProps) {
        super(props);
        this.state = {
            showArray: true,
            thicknessGraph: true,
            showGrid: true,
            chooseThickness: true,
        };
    }

    render() {
        return <FlexibleAndFixedPanel
            topFixedContent={this.props.aboveSegment}
            flexibleContent={this.content()}
        />;
    }
    
    private changeView(showArray: boolean) {
        this.setState({ showArray });
    }


    private content() {
        const { arrayData } = this.props;
        if (arrayData === undefined) return null;


        const sortedArrayData = { readingAnalyses: arrayData.readingAnalyses.sort((a, b) => a.datetime.getTime() - b.datetime.getTime()) };

        const dates = sortedArrayData.readingAnalyses.map(function (reading) { return reading.datetime.getTime() })
            .filter(function (date, i, array) {
                return array.indexOf(date) === i;
            })
            .map(function (time) { return new Date(time); });

        const sensorNames = sortedArrayData.readingAnalyses.map(reading =>  reading.Name)
            .filter((name, i, array) =>  array.indexOf(name) === i);

        const numCols = sensorNames.map((sensor) => Math.max(...arrayData.readingAnalyses.filter((data) => data.Name === sensor).map(reading => Math.max(reading.TransmitElement.y + 1, reading.ReceiveElement.y + 1))));
        const numRows = sensorNames.map((sensor) => Math.max(...arrayData.readingAnalyses.filter((data) => data.Name === sensor).map(reading => Math.max(reading.TransmitElement.x + 1, reading.ReceiveElement.x + 1))));

         const findIndex = (reqDate: Date) => {
            return dates.findIndex((date) =>
            date.getTime() === reqDate.getTime());
        }

        const reqData = (date: Date) => sortedArrayData.readingAnalyses.filter((reading) =>
            reading.datetime.getTime() === date.getTime() && reading.ReceiveElement.x === reading.TransmitElement.x && reading.ReceiveElement.y === reading.TransmitElement.y && reading.Name === sensorNames[0]);

        const thicknessData = () => {
            const thicknessArray: ArrayDataItem[][][] = []
            for (const date of dates) {
                const array = new Array(numRows[0]).fill(null).map(() => new Array(numCols[0]).fill(null));
                for (let r = 0; r < numRows[0]; r++) {
                    for (let c = 0; c < numCols[0]; c++) {
                        for (const reading of reqData(date)) {
                            if (reading.TransmitElement.x === r && reading.TransmitElement.y === c) {
                                array[r][c] = reading;
                            }
                        }
                    }
                }
                thicknessArray.push(array);
            }
            return thicknessArray;
        }

        const reqInterData = (date: Date, sensor: string) => arrayData.readingAnalyses.filter((reading) =>
            reading.datetime.getTime() === date.getTime() && reading.Name === sensor);

        const interThicknessData = () => {
            const array: ArrayDataItem[][][][] = [];
            for (const i in sensorNames) {
                const thicknessArray: ArrayDataItem[][][] = [];
                for (const date of dates) {
                    const array = new Array(numRows[i] * 2 - 1).fill(null).map(() => new Array(numCols[i] * 2 - 1).fill(null));
                    for (let r = 0; r < numRows[i]; r++) {
                        for (let c = 0; c < numCols[i]; c++) {
                            for (const reading of reqInterData(date, sensorNames[i])) {
                                if (reading.TransmitElement.x === r && reading.TransmitElement.y === c) {
                                    if (reading.ReceiveElement.x === reading.TransmitElement.x && reading.ReceiveElement.y === reading.TransmitElement.y) {
                                        array[r * 2][c * 2] = reading;
                                    } else if (r * 2 + 1 < numRows[i] * 2 - 1 && reading.ReceiveElement.x === reading.TransmitElement.x + 1 && reading.ReceiveElement.y === reading.TransmitElement.y) {
                                        array[r * 2 + 1][c * 2] = reading;
                                    } else if (reading.ReceiveElement.x === reading.TransmitElement.x - 1 && reading.ReceiveElement.y === reading.TransmitElement.y) {
                                        array[r * 2 - 1][c * 2] = reading;
                                    } else if (c * 2 + 1 < numCols[i] *2 - 1 && reading.ReceiveElement.x === reading.TransmitElement.x && reading.ReceiveElement.y === reading.TransmitElement.y + 1) {
                                        array[r * 2][c * 2 + 1] = reading;
                                    } else if (reading.ReceiveElement.x === reading.TransmitElement.x && reading.ReceiveElement.y === reading.TransmitElement.y - 1) {
                                        array[r * 2][c * 2 - 1] = reading;
                                    } else if (r * 2 + 1 < numRows[i] * 2 - 1 && c * 2 + 1 < numCols[i] * 2 - 1 && reading.ReceiveElement.x === reading.TransmitElement.x + 1 && reading.ReceiveElement.y === reading.TransmitElement.y + 1) {
                                        array[r * 2 + 1][c * 2 + 1] = reading;
                                    } else if (reading.ReceiveElement.x === reading.TransmitElement.x - 1 && reading.ReceiveElement.y === reading.TransmitElement.y - 1) {
                                        array[r * 2 - 1][c * 2 - 1] = reading;
                                    }
                                }
                            }
                        }
                    }
                    thicknessArray.push(array);
                }
                array.push(thicknessArray);
            }
            return array;
        }

        const conversionFactor = 24 * 3600 * 365.25 * 1000;
        const rateOfChangeData = (width: number, height: number, thicknessData: ArrayDataItem[][][]) => {
            const rateData: number[][][] = [];
            for (const date of dates) {
                const data = new Array(height).fill(null).map(() => new Array(width).fill(null));
                const dateIndex = findIndex(date);
                const currentData = thicknessData[dateIndex];
                if (dateIndex >= 1) {
                    for (let r = 0; r < height; r++) {
                        for (let c = 0; c < width; c++) {
                            if (currentData[r][c] !== null) {
                                let prevIndex = dateIndex - 1;
                                let prevData = thicknessData[prevIndex];
                                while (prevIndex > 0 && prevData[r][c] === null) {
                                    prevIndex -= 1;
                                    prevData = thicknessData[prevIndex];
                                }
                                if (prevData[r][c] !== null) {
                                    const val = (prevData[r][c].thicknessMm - currentData[r][c].thicknessMm) * conversionFactor / (date.valueOf() - dates[prevIndex].valueOf());
                                    data[r][c] = val;
                                }
                            }
                        }
                    }
                }
                rateData.push(data);
            }
            return rateData;
        }


        switch (this.state.showArray) {
            case true: return <GridView
                arrayData={sortedArrayData}
                changeView={showArray => {
                    this.changeView(showArray);
                }}
                numCols={numCols[0]}
                numRows={numRows[0]}
                dates={dates}
                changeGraph={thicknessGraph => this.setState({ thicknessGraph: thicknessGraph })}
                changeData={chooseThickness => this.setState({ chooseThickness })}
                chooseThickness={this.state.chooseThickness}
                changeGrid={showGrid => this.setState({ showGrid })}
                showGrid={this.props.showGrid}
                thicknessData={thicknessData()}
                interThicknessData={interThicknessData()}
                rateData={rateOfChangeData}
                findIndex={findIndex}
                sensorNames={sensorNames}
                onNodeClick={this.props.onNodeClick}
            />
            default: return

            /*case false: return <GraphView
                arrayData={arrayData}
                changeView={showArray => {
                    this.changeView(showArray);
                }}
                thicknessGraph={this.state.thicknessGraph}
                interThicknessData={interThicknessData()[0]}
                interRateData={rateOfChangeData(numCols[0] * 2 - 1, numRows[0] * 2 - 1, interThicknessData()[0])}
                dates={dates}
                    
            />*/
            // not used at the moment
        }
    }


}
