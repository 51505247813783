// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-loading-spinner {
    display: flex;
}

.horizontal-loading-spinner > div {
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 100%;
    display: inline-block;
    animation: horizontal-loading-spinner-bounce-delay 1.4s infinite ease-in-out both;
}

.horizontal-loading-spinner .horizontal-loading-spinner-circle-1 { animation-delay: -0.32s; margin-right: 5px; }
.horizontal-loading-spinner .horizontal-loading-spinner-circle-2 { animation-delay: -0.16s; margin-right: 5px; }

@keyframes horizontal-loading-spinner-bounce-delay {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1.0); }
}
`, "",{"version":3,"sources":["webpack://./Styling/Animations/HorizontalLoadingSpinner/HorizontalLoadingSpinner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;IACrB,iFAAiF;AACrF;;AAEA,mEAAmE,uBAAuB,EAAE,iBAAiB,EAAE;AAC/G,mEAAmE,uBAAuB,EAAE,iBAAiB,EAAE;;AAE/G;IACI,gBAAgB,mBAAmB,EAAE;IACrC,MAAM,qBAAqB,EAAE;AACjC","sourcesContent":[".horizontal-loading-spinner {\n    display: flex;\n}\n\n.horizontal-loading-spinner > div {\n    width: 18px;\n    height: 18px;\n    background-color: white;\n    border-radius: 100%;\n    display: inline-block;\n    animation: horizontal-loading-spinner-bounce-delay 1.4s infinite ease-in-out both;\n}\n\n.horizontal-loading-spinner .horizontal-loading-spinner-circle-1 { animation-delay: -0.32s; margin-right: 5px; }\n.horizontal-loading-spinner .horizontal-loading-spinner-circle-2 { animation-delay: -0.16s; margin-right: 5px; }\n\n@keyframes horizontal-loading-spinner-bounce-delay {\n    0%, 80%, 100% { transform: scale(0); }\n    40% { transform: scale(1.0); }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
