import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import UploadSensorsPanel from "./UploadSensorsPanel";

interface UploadSensorsModalPanelProps {
    onClose(): void;
    shouldBeShown: boolean;
    parentGroupId: string;
}

export default class UploadSensorsModalPanel extends Component<UploadSensorsModalPanelProps> {
    constructor(props: UploadSensorsModalPanelProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { onClose, shouldBeShown, parentGroupId } = this.props;

        return (
            <ModalPanel
                title="Upload list of sensors"
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={<UploadSensorsPanel parentGroupId={parentGroupId} />}
            />
        );
    }
}
