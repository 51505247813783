import * as React from "react";
import { Component } from "react";
import { Gateway } from "@inductosense/typescript-fetch";
import GatewaysListPanel from "Panels/Composite/GatewaysListPanel";
import Services from "Services/Platform/Services";
import UiSettings from "../../Model/UiSettings";

interface GatewaysViewProps {
    onDbUploadRequired(): Promise<void>;
    onClose(): void;
    uiSettings: UiSettings;
}

interface GatewaysViewState {
    isReadyToRender: boolean;
    gateways?: Gateway[];
}

export default class GatewaysView extends Component<GatewaysViewProps, GatewaysViewState> {
    private refreshInterval: NodeJS.Timeout | null = null;
    constructor(props: GatewaysViewProps) {
        super(props);
        this.state = { isReadyToRender: false, gateways: []};
        this.refreshInterval = null;
    }

    async componentDidMount() {
        await this.fetchAllGateways();
        this.refreshInterval = setInterval( this.fetchAllGateways, 60000)
    }

    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
      }

    async fetchAllGateways() {
        const gateways = await Services.Gateways.getAllGateways();

        this.setState({
            isReadyToRender: true,
            gateways: gateways
        });
    }

    render() {
        const { onClose, uiSettings } = this.props;
        const { isReadyToRender, gateways: gateways } = this.state;

        if (!isReadyToRender) return null;

        return (
            <GatewaysListPanel
                onDbUploadRequired={this.props.onDbUploadRequired}
                gateways={gateways!}
                uiSettings={uiSettings}
                onClose={() => onClose()}
                onGatewaysChange={async () => this.fetchAllGateways()}
            />
        );
    }
}
