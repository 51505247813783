import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import UserSelectPanel from "./UserSelectPanel";
import { Dcuser } from "@inductosense/typescript-fetch";

interface UserSelectModalPanelProps {
    shouldBeShown: boolean;
    onClose(): void;
    onAddUsers(userIds: string[]): void;
    userList: Dcuser[];
}

export default class UserSelectModalPanel extends Component<UserSelectModalPanelProps> {
    constructor(props: UserSelectModalPanelProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { onClose, shouldBeShown } = this.props;

        return (
            <ModalPanel
                title="Add users"
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        return <UserSelectPanel
            onAddUsers={this.props.onAddUsers}
            userList={this.props.userList}
        />;
    }
}
