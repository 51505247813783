import { SensorReadingsApi } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class PayloadsService extends InductosenseService<SensorReadingsApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(SensorReadingsApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getPayloadForReading(readingId: string) {
        await this.ensureLatestConfig();
        const result = await this.serverApi.readingsIdPayloadGet({ id: readingId });
        if (!result) throw Error("No payload was found for the specified reading.");
        return result;
    }
}
