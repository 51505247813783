import Validator from "Validation/Validator";

export default class StringRepresentsARealNumberOrNothingValidator implements Validator<string> {
    constructor(readonly errorMessage = "Must be a number or left empty") { }

    isValid(value: string | null | undefined) {
        if (value === null || value === undefined || value === "") return true;
        return !isNaN(+value);
    }
}
