export const inductosenseBlack = "#000000";
export const inductosenseDenimBlue = "#003d59";
export const inductosenseSlateGrey = "#414a4c";
export const inductosenseMediumGrey = "#808080";
export const inductosenseSmokeGrey = "#f2f2f2";
export const inductosenseDawnOrange = "#fb9334";
export const inductosenseBoldOrange = "#e9500e";
export const inductosenseDuckEggTeal = "#44857d";
export const inductosenseDeepTeal = "#16706f";
export const inductosenseWhite = "#ffffff";
