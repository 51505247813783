import * as MaterialUi from "@mui/material";
import * as React from "react";
import { Component, ReactElement } from "react";
import OnOrOff from "Types/OnOrOff";

const defaultState: OnOrOff = "off";

interface SwitchProps {
    label?: string;
    initialState?: OnOrOff;
    isDisabled?: boolean;
    onChange(newValue: OnOrOff): void;
}

interface SwitchState {
    isOnOrOff: OnOrOff;
}

export default class Switch extends Component<SwitchProps, SwitchState> {
    constructor(props: SwitchProps) {
        super(props);
        this.state = { isOnOrOff: props.initialState || defaultState };
    }

    render() {
        return <div className="switch">{this.content()}</div>;
    }

    private content() {
        const switchElement = this.switch();
        if (this.props.label) return this.label(switchElement);
        return switchElement;
    }

    private switch() {
        return (
            <MaterialUi.Switch
                checked={this.state.isOnOrOff === "on"}
                disabled={this.props.isDisabled}
                onChange={(_, newValue) => this.onChange(newValue ? "on" : "off")}
            />
        );
    }

    private label(switchElement: ReactElement) {
        return (
            <MaterialUi.FormControlLabel
                label={this.props.label}
                disabled={this.props.isDisabled}
                control={switchElement}
            />
        );
    }

    private onChange(newValue: OnOrOff) {
        this.props.onChange(newValue);
        this.setState({ isOnOrOff: newValue });
    }
}
