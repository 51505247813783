import * as React from "react";
import { Component } from "react";
import { CloseRounded, DoneRounded, ManageAccounts, Settings,  Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Checkbox, Divider, FormControlLabel, IconButton, Link, Tooltip } from "@mui/material";
import Button from "Components/Buttons/Button";
import Services from "Services/Platform/Services";
import * as SettingsService from "Services/SettingsService";
import View from "Types/View";
import { jwtDecode } from "jwt-decode";
import Select from "react-select";
import InductosenseEmblem from "../../Components/Graphics/Logos/InductosenseEmblem";
import TextFieldControlled from "../../Components/Input/TextFieldControlled";
import ReleaseNotes from "../../Panels/Composite/Primary/ReleaseNotes";
import WorkOffline from "./WorkOffline";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./login.css";
import { ManifestType } from "Views/manifest";
import { Authenticationresult, ModelErrorFromJSONTyped } from "@inductosense/typescript-fetch";
import FirstIfNotEmpty from "../../Utilities/FirstIfNotEmpty";
import CircleText from "../../Components/Text/CircleText";

interface LoginFormProps {
    showWorkOffline?: boolean;
    onLoginClick(refreshToken: string, tenantName: string, availableTenants: string[], rememberMe: boolean): void;
    onRequestOfflineView(view: View): void;
    errorMessage?: string;
    allowLogin: boolean;
    onTenantChosen?(tenant: string): void;
    manifest?: ManifestType;
}
 
interface LoginFormState {
    username: string;
    password: string;
    rrcShortcut: boolean;
    rdcScanningShortcut: boolean;
    hdcScanningShortcut: boolean;
    spiderShortcut: boolean;
    rememberMe: boolean;
    showPassword: boolean;
    tenantName: string | null;
    chooseFromTenants?: string[];
    showWorkOfflineChoices: boolean;
    loginResult: null | boolean;
    loginErrorMessage: null | string;
    hasChangedSinceAuthenticating: boolean;
    refreshToken: null | string;
    externallyAuthenticated: boolean;
    isApiAvailable: null | boolean;
}
 
const passwordPlaceholder = "--------------------------------------------";
 
const prefixCls = "login";

export default class LoginForm extends Component<LoginFormProps, LoginFormState> {
    //private fieldsRequiredValidator: Validator<string>;
 
    constructor(props: LoginFormProps) {
        super(props);
 
        const settings = SettingsService.getSettings();
 
        this.state = {
            rrcShortcut: settings.rrcShortcut,
            rdcScanningShortcut: settings.rdcScanningShortcut,
            hdcScanningShortcut: settings.hdcScanningShortcut,
            spiderShortcut: settings.spiderShortcut,
            username: "",
            password: "",
            rememberMe: false,
            showPassword: false,
            tenantName: null,
            showWorkOfflineChoices: false,
            loginResult: null,
            loginErrorMessage: null,
            hasChangedSinceAuthenticating: false,
            refreshToken: null,
            externallyAuthenticated: false,
            isApiAvailable: null
        };
 
        //this.fieldsRequiredValidator = new FieldIsRequiredValidator("This field is required");
    }
 
    async componentDidMount() {

        await Services.initialiseAuthentication();

        try {
            const urlPrefix = await Services.getUrlPrefix();
            await fetch(urlPrefix + "/sessions/active");
            this.setState({ isApiAvailable: true });
        }
        catch {
            this.setState({ isApiAvailable: false });
        }

        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get("redirectToMicrosoft") === "1") {
            this.goToMicrosoftLogin();
        }

        const myParam = urlParams.get("code");

        if (myParam !== null && myParam !== "") {
            // We do history.replaceState to hide the query string from the url bar after the redirect from Microsoft Identity
            // don't do this for file urls (iDART desktop) because it won't work and the user won't see the url in iDART desktop anyway
            if (location.protocol !== "file:") {
                history.replaceState(null, "", "/");
            }

            try {
                const authResult = await Services.authenticationFederatedPost(myParam, this.getRedirectUri());
                await this.onGotAuthResult(authResult);

                if (authResult.tenants.length === 1) {
                    this.props.onLoginClick(authResult.refreshToken, authResult.tenants[0], authResult.tenants, false);
                }
            }
            catch (ex) {
                if (ex instanceof Response) {
                    if (ex.status === 403) {
                        const json: object[] = await ex.json();
                        const errors = json.map(object => ModelErrorFromJSONTyped(object, false));
                        const error = FirstIfNotEmpty(errors);
                        const channel = Services.getChannel();
                        // Microsoft account not associated with a user
                        if (error?.code === 40301) {
                            const queryString = new URLSearchParams({
                                "idart-redirect": "true",
                                "redirect-url": this.getRedirectUriWithRedirectQueryString()
                            }).toString();

                            if (channel == "production") {
                                // Redirect to the specified page on the software portal on login failure
                                window.location.href = "https://portal.inductosense.cloud/LandingPage?" + queryString;
                            } else if (channel == "staging") {
                                window.location.href = "https://portal.staging.inductosense.cloud/LandingPage?" + queryString;
                            } else {
                                alert(errors[0].message)
                            }
                        }
                    } else {
                        throw ex;
                    }
                } else {
                    throw ex;
                }
            }
        }

        const savedToken = Services.getSavedToken();
 
        if (savedToken) {
 
            const refreshResponse = await Services.refresh();
            console.log("refreshResponse", refreshResponse);
 
            const decodedToken = jwtDecode<{ "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string }>(refreshResponse.refreshToken);
 
            this.setState({
                username: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
                password: passwordPlaceholder,
                rememberMe: true,
                loginResult: true,
                chooseFromTenants: refreshResponse.tenants,
                tenantName: Services.getSavedTenant(),
                refreshToken: refreshResponse.refreshToken
            });
        }
    }
 
    async onEnterCredentials(username: string, password: string, rememberMe: boolean) {
        if (this.state.externallyAuthenticated) return null;
        if (password === passwordPlaceholder) return null;

        try {
            if (username !== "" && password !== "")
            {
                if (this.state.hasChangedSinceAuthenticating)
                {
                    const authResult = await Services.getRefreshTokenFromServer(username, password, rememberMe);

                    this.setState({
                        loginResult: true,
                        chooseFromTenants: authResult.tenants,
                        tenantName: authResult.tenants.length === 1 ? authResult.tenants[0] : null, // TODO: Check null doesn't cause problems
                        hasChangedSinceAuthenticating: false,
                        refreshToken: authResult.refreshToken
                    });

                    return authResult;
                }

            } else {
                this.setState({ loginResult: null, chooseFromTenants: undefined });
            }
        }
        catch (ex) {
            console.log("the exception", ex);

            if (ex instanceof Response) {
                this.setState({ loginResult: false, loginErrorMessage: "Incorrect username or password", chooseFromTenants: undefined });
            } else {
                this.setState({ loginResult: false, loginErrorMessage: "Network connection error", chooseFromTenants: undefined });
            }
        }

        return null;
    }

    async onGotAuthResult(authResult: Authenticationresult) {
        this.setState({
            loginResult: true,
            chooseFromTenants: authResult.tenants,
            tenantName: authResult.tenants.length === 1 ? authResult.tenants[0] : null, // TODO: Check null doesn't cause problems
            hasChangedSinceAuthenticating: false,
            refreshToken: authResult.refreshToken,
            externallyAuthenticated: true,
            rememberMe: true
        });
    }
 
    loginStatusIcon() {
        const { loginResult } = this.state;
 
        switch (loginResult) {
            case null: return null;
            case true: return <>&nbsp;<DoneRounded /></>;
            case false: return <>&nbsp;<CloseRounded /></>;
        }
    }

    getRedirectUri() {
        return location.origin === "file://" ? "http://localhost:5000/" : location.origin;
    }

    getRedirectUriWithRedirectQueryString() {
        const queryString = new URLSearchParams({
            redirectToMicrosoft: "1"
        }).toString();

        return this.getRedirectUri() + "?" + queryString; // TODO: What if first bit contains ?
    }

    goToMicrosoftLogin() {
        const queryString = new URLSearchParams({
            login_hint: "",
            response_type: "code",
            client_id: "b8f5a2b8-79d6-4d05-a766-3cd6141cfbe3",
            redirect_uri: this.getRedirectUri(),
            nonce: "",
            scope: "profile"
        }).toString();

        location.href = "https://login.microsoftonline.com/organizations/oauth2/authorize?" + queryString;
    }
 
    render() {
        const { onLoginClick, onRequestOfflineView, manifest } = this.props;
        const { username, password, rememberMe, showPassword, tenantName, showWorkOfflineChoices,
            rrcShortcut, rdcScanningShortcut, hdcScanningShortcut, spiderShortcut, chooseFromTenants,
            refreshToken, loginResult, loginErrorMessage, externallyAuthenticated, isApiAvailable } = this.state;

        const onKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {   
            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();

                const authResult = await this.onEnterCredentials(username, password, rememberMe);

                if (authResult !== null && authResult.tenants.length === 1) {
                    onLoginClick(authResult.refreshToken, authResult.tenants[0], authResult.tenants, rememberMe);
                }
            }
        };

        const loginControls = () => {
            return <div style={{ position: "relative" }}>
                <p style={{ textAlign: "center" }}><InductosenseEmblem width={50} height={50} /></p>
                <p className={`${prefixCls}-title`}>Welcome to iDART {Services.getVersion()}</p>

                {Services.getCustomServerUrl() ?
                    <Tooltip title={Services.getCustomServerUrl()} arrow placement="top">
                        <Alert severity="info" onClick={() => this.props.onRequestOfflineView("installConfig")} style={{ cursor: "pointer" }}>
                            Using custom server URL
                        </Alert>
                    </Tooltip>
                    : null
                }

                <ReleaseNotes />
                {Services.getChannel() === "staging" ?
                    <p className={`${prefixCls}-version`} style={{ textAlign: "center" }}>Staging Version</p>
                    : null
                }

                {manifest?.loginScreenMessage ?
                    <div style={{ marginBottom: "0.625rem" }}>
                        <Alert severity="info">{manifest?.loginScreenMessage}</Alert>
                    </div>
                    : null
                }

                {isApiAvailable === true ?
                    <>
                        {externallyAuthenticated ? <CircleText text="1" style={{ marginTop: "17px", position: "absolute", right: 0 }} /> : null}
                        <div style={{ opacity: externallyAuthenticated ? 0.5 : 1 }}>
                            {manifest?.showMicrosoftLogin || Services.getChannel() !== "production" ?
                                <div style={{ textAlign: "center" }}>
                                    <IconButton onClick={() => this.goToMicrosoftLogin()}>
                                        <img src="./ms-symbollockup_signin_light.svg" />
                                    </IconButton>
                                    <div className={`${prefixCls}-or`}>
                                        <Divider style={{ marginBottom: 10, color: "#999", width: 140 }}>or</Divider>
                                    </div>
                                </div>
                                : null
                            }

                            <div className={`${prefixCls}-username`}>
                                <TextFieldControlled
                                    isLogin={true}
                                    value={username}
                                    onClick={() => this.setState({ externallyAuthenticated: false })}
                                    onChange={ev => this.setState({ username: ev.target.value, hasChangedSinceAuthenticating: true })}
                                    onKeyDown={onKeyDown}
                                    disabled={this.state.externallyAuthenticated}
                                    label="Username"
                                    onBlur={() => this.onEnterCredentials(username, password, rememberMe)}
                                    sx={{ backgroundColor: "#fff", "& .MuiInputBase-fullWidth": { borderRadius: "0.5rem" } }}
                                />
                            </div>

                            <div className={`${prefixCls}-password`} style={{ marginBottom: "0.625rem" }}>
                                <TextFieldControlled
                                    isLogin={true}
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onClick={() => this.setState({ externallyAuthenticated: false })}
                                    onChange={ev => this.setState({ password: ev.target.value, hasChangedSinceAuthenticating: true })}
                                    onKeyDown={onKeyDown}
                                    disabled={this.state.externallyAuthenticated}
                                    suffix={
                                        <>
                                            <IconButton onClick={() => this.setState({ showPassword: !showPassword })}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            {this.loginStatusIcon()}
                                        </>
                                    }
                                    onBlur={() => this.onEnterCredentials(username, password, rememberMe)}
                                    label="Password"
                                    sx={{ backgroundColor: "#fff", "& .MuiInputBase-fullWidth": { borderRadius: "0.5rem" } }}
                                />
                            </div>

                            <div style={{ marginBottom: "0.625rem", fontSize: "0.75rem" }}>
                                <Link
                                    href={`mailto:software@inductosense.com?subject=Forgotten%20Password&body=${encodeURIComponent("My username is " + username)}`}
                                    sx={{ cursor: "pointer" }}
                                >
                                    Forgot your password?
                                </Link>
                            </div>

                            <div className={`${prefixCls}-select`}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={rememberMe} onChange={(_ev, newValue) => {
                                            this.setState({ rememberMe: newValue, hasChangedSinceAuthenticating: true }, () => {
                                                this.onEnterCredentials(username, password, newValue);
                                            });
                                        }}
                                            sx={{ "& .MuiSvgIcon-root": { fontSize: ".875rem" } }}
                                        />
                                    }
                                    label="Remember me"
                                    labelPlacement="end"
                                    sx={{ "& .MuiFormControlLabel-label": { fontSize: ".875rem", color: "#212843" }, }}
                                />
                            </div>
                        </div>

                        {loginResult === false ?
                            <Alert severity="error" sx={{ marginBottom: "10px" }}>{loginErrorMessage}</Alert> :
                            null
                        }

                        {externallyAuthenticated ? <CircleText text="2" style={{ marginTop: "8px", position: "absolute", right: 0 }} /> : null}
                        <div style={{ marginBottom: "0.625rem" }}>
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderColor: state.isFocused ? "#EC6F41" : "#555",
                                        borderRadius: "0.5rem",
                                        width: "250px",
                                        marginLeft: "auto",
                                        marginRight: "auto"
                                    }),
                                }}
                                options={chooseFromTenants?.map(t => ({ value: t, label: t })) || [{ value: "", label: "Enter username and password to see tenants" }]}
                                value={tenantName !== null ?
                                    { value: tenantName, label: tenantName } :
                                    { value: "", label: "Select a tenant" }}
                                isSearchable={true}
                                menuPlacement="top"
                                onChange={t => {
                                    if (t !== undefined && t !== null && t.value !== undefined && t.value !== null)
                                        this.setState({ tenantName: t.value });
                                }}
                            />
                        </div>

                        {externallyAuthenticated ? <CircleText text="3" style={{ marginTop: "5px", position: "absolute", right: 0 }} /> : null}
                        <div style={{ textAlign: "center" }}>
                            <Button
                                isLogin={true}
                                onClick={() => {
                                    tenantName && chooseFromTenants && refreshToken ?
                                        onLoginClick(refreshToken, tenantName, chooseFromTenants, rememberMe)
                                        : null
                                }}
                                label="Sign In"
                                isDisabled={((username === "" || password === "") && !externallyAuthenticated) || tenantName === null}
                            />
                        </div>
                    </>
                    : <p style={{ textAlign: "center" }}>Attempting to reach server...</p>
                }

                {this.props.showWorkOffline ?
                    <>
                        <div className={`${prefixCls}-or`}>
                            <Divider style={{ marginTop: 10, marginBottom: 10, color: "#999", width:140 }}>or</Divider>
                        </div> 
                        <div style={{ textAlign: "center" }}>
                            <Button
                                isLogin={ true }
                                onClick={() => this.setState({ showWorkOfflineChoices: true })}
                                label="Work Offline"
                            />
                        </div>
                    </>
                    : null
                }
                <div style={{ marginTop: "0.625rem" }}>
                    <Button
                        isLoginSetting={true}
                        icon={<Settings />}
                        variant="text"
                        label="Advanced Settings"
                        onClick={() => this.props.onRequestOfflineView("installConfig")}
                    />
                </div>
                {
                    manifest?.showPortalLink ?
                    <div style={{ marginBottom: "0.625rem" }}>
                        <Button
                            isLoginSetting={true}
                            icon={<ManageAccounts htmlColor="#EC6F41" />}
                            variant="text"
                            label="Customer Portal"
                            onClick={() => window.open("https://portal.inductosense.cloud")}
                            sx={{ color: "#EC6F41" }}
                        />
                    </div>
                    : null
                }
            </div>;
        };

        const workOfflineControls = () =>
            <WorkOffline
                hdcScanningShortcut={hdcScanningShortcut}
                rdcScanningShortcut={rdcScanningShortcut}
                rrcShortcut={rrcShortcut}
                spiderShortcut={spiderShortcut}
                onClose={() => this.setState({ showWorkOfflineChoices: false })}
                onRequestOfflineView={onRequestOfflineView}
            />;
 
        return (<div className={`${prefixCls}-modal`}>
            <div className={`${prefixCls}-carousel`}>
                <Swiper
                key={JSON.stringify(manifest)} // When the manifest changes reload the component so the autoplay isn't affected
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                lazyPreloadPrevNext={10}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 20000,
                    disableOnInteraction: false,
                  }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
            >
                    {
                        manifest?.slides.map((slide, i) =>
                            <SwiperSlide key={i} style={{ textAlign: "center" }}>
                                <div className={`${prefixCls}-swiper-slide`}>
                                    <img
                                        src={slide.imageUrl}
                                        alt={slide.altText}
                                    />
                                    <div style={{ padding: "0.9375rem", color: "#fff", fontSize: "1.75rem" }}>
                                        {slide.altText}
                                    </div>
                                </div>
                            </SwiperSlide>
                    )
                }
            </Swiper>
            </div>
            <div className={`${prefixCls}-grid`} >
                {showWorkOfflineChoices ? workOfflineControls() : loginControls()}
            </div>
        </div>
        );
    }
}
