import { DCDeploymentsApi, Dcdeploymentpost } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class DcDeploymentsService extends InductosenseService<DCDeploymentsApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(DCDeploymentsApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getDcDevicesDeployments(last?: number) {
        await this.ensureLatestConfig();
        return this.serverApi.getDcDevicesDeployments({ last });
    }

    @wrapWithErrorHandling()
    async postDcDeployment(dcdeploymentpost: Dcdeploymentpost) {
        await this.ensureLatestConfig();
        return this.serverApi.postDcDeployment({ dcdeploymentpost });
    }

    @wrapWithErrorHandling()
    async getDcDataExtractionAttemptLogs(deviceSerialNumber?: string) {
        await this.ensureLatestConfig();
        return this.serverApi.getDcDataExtractionAttemptLogs({ deviceSerialNumber });
    }
}
