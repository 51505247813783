import * as MaterialUiIcons from "@mui/icons-material";
import * as React from "react";
import { alertInformationForegroundBlue } from "Styling/Palette/AlertColours";
import { Component } from "react";

interface InformationIconProps {
    shouldApplyDefaultColour?: boolean;
}

export default class InformationIcon extends Component<InformationIconProps> {
    static readonly defaultProps: Partial<InformationIconProps> = { shouldApplyDefaultColour: true };

    render() {
        return (
            <MaterialUiIcons.Info
                htmlColor={this.props.shouldApplyDefaultColour ? alertInformationForegroundBlue : undefined}
            />
        );
    }
}
