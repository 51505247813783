import * as React from "react";
import { Component, CSSProperties } from "react";
import { paddingBetweenPanels } from "Styling/Spacing/Padding";
import { Button } from "@mui/material";
import OnOrOff from "Types/OnOrOff";
import ReadingsListPanel from "Panels/Composite/Primary/ReadingsListPanel";
import GatewayIcon from "Components/Graphics/Icons/GatewayIcon";
import Sensor from "Model/Sensor";
import SensorGroup from "Model/SensorGroup";
import SensorTreePanel from "Panels/Composite/SensorTreePanel";
import SensorType from "Model/SensorType";
import Services from "Services/Platform/Services";
import SettingsIcon from "Components/Graphics/Icons/SettingsIcon";
import TrendGraphPanel from "Panels/Composite/TrendGraphPanel";
import View from "Types/View";
import PeopleIcon from "../Components/Graphics/Icons/PeopleIcon";
import { Material } from "@inductosense/typescript-fetch/dist/models/Material";
import { Device } from "@inductosense/typescript-fetch/dist/models/Device";
import UiSettings from "../Model/UiSettings";
import { DeveloperMode, DeviceHub, CloudDownload, PersonOutline, DirectionsWalk, SettingsRemote } from "@mui/icons-material";
import { Menu, MenuItem, ListItemText, ListItemIcon, CircularProgress } from "@mui/material";
import SplitPanel from "../Panels/SplitPanel";
import AscanModalPanel from "../Panels/Composite/Modals/AscanModalPanel";
import EditReadingModalPanel from "../Panels/Composite/Modals/EditReadingModalPanel";
import GetAllSensorsFromGroup from "../Utilities/GetAllSensorsFromGroup";
import FirstIfNotEmpty from "../Utilities/FirstIfNotEmpty";
import { Trendanalysis } from "@inductosense/typescript-fetch";
import ConfigureSensorModalPanel from "../Panels/Composite/Modals/ConfigureSensorModalPanel";
import ExportIcon from "../Components/Graphics/Icons/ExportIcon";
import * as SettingsService from "Services/SettingsService";
import ModalPanel from "Panels/ModalPanel";
import * as MainCGI from "Services/Electron/MainCGI";
import * as GeneralCGI from "Services/Electron/GeneralCGI";
import ReadingTrendPoint from "../Model/ReadingTrendPoint";
import LoadingComponent from "../Components/Animations/LoadingComponent";
import IdartButtonSvg from "Graphics/Icons/IdartButtonSvg.svg";
import Tooltip from "@mui/material/Tooltip";
import StatusBar from "./StatusBar";

const containerStyle: CSSProperties = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
};

const mainViewStyle: CSSProperties = {  
    flex: 1,
    margin: 5,
};

const footerStyle: CSSProperties = {
    padding: paddingBetweenPanels,
    display: "flex",
    alignItems: "center",
    fontSize: "10pt",
    background: "#212843",
};

const footerButtonsStyle: CSSProperties = {
    margin: "0 auto",
    display: "flex"
};

const uiDevicesButtonStyle: CSSProperties = {
    marginLeft: 10
};

const productNameStyle: CSSProperties = {
    textAlign: "right",
    fontWeight: "bold",
};

interface MainDashboardInternalsProps {
    rootSensorGroup: SensorGroup;
    onSignOut(): void;
    devices: Device[] | null;
    materials: Material[] | null;
    sensorTypes: SensorType[] | null;
    latestSensorTrends?: Trendanalysis[];
    onSensorTreeChange(): Promise<void>;
    changeActiveView(newView: View,isHaveGateway?: boolean): void;
    uiSettings: UiSettings;
    tabletMode: boolean;
    isSyncingData: boolean;
    onRequestFullSync(): void;
    onAdvancedConfig(): void;
    availableTenants?: string[];
    tenantName?: string;
    onTenantChange(newTenant: string): void;
}

interface MainDashboardInternalsState {
    selectedSensors: Sensor[];
    selectedNodes: string[];
    plots: {
        sensor: Sensor;
        readingPoints: ReadingTrendPoint[];
    }[];
    ignoredReadings: ReadingTrendPoint[];
    hoveredReading: ReadingTrendPoint | null;
    userMenuAnchor: null | HTMLElement;
    menuAnchor: null | HTMLElement;
    showingReading: null | ReadingTrendPoint;
    editingReading: null | ReadingTrendPoint;
    editingSensor: null | "new" | Sensor;
    isLoading: boolean;
    desktopMessageShouldBeShown: boolean;
    recalculatingSensors: string[];
    areGatewaysEnabled: boolean;
}

const idartButtonSvgStyle = {
    "&:hover": {
        background: "#F9EDE7",
        color:"#ec6f41",
    },
    border: "1px solid #ec6f41",
    background: "#F9EDE7",
    color:"#ec6f41",
    padding: "3px 7px",
    lineHeight:"23px",
    fontSize: "12px",
}

const idartButtonStyle = {
    "&:hover": {
        background: "#F9EDE7",
        color:"#ec6f41",
    },
    backgroundColor: "#ec6f41",
    color: "#fff",
    border: "1px solid #ec6f41",
    padding: "3px 7px",
    lineHeight:"23px",
    fontSize: "12px",
}
class MainDashboardInternals extends Component<MainDashboardInternalsProps, MainDashboardInternalsState> {
    constructor(props: MainDashboardInternalsProps) {
        super(props);

        this.state = {
            selectedSensors: [],
            selectedNodes: [],
            plots: [],
            ignoredReadings: [],
            hoveredReading: null,
            menuAnchor: null,
            userMenuAnchor: null,
            showingReading: null,
            editingReading: null,
            editingSensor: null,
            isLoading: false,
            desktopMessageShouldBeShown: false,
            recalculatingSensors: [],
            areGatewaysEnabled: false
        };
    }

    async componentDidUpdate(_: MainDashboardInternalsProps, previousState: MainDashboardInternalsState) {
        const currentSelectedSensors = this.state.selectedSensors;
        const previousSelectedSensors = previousState.selectedSensors;

        const currentSensorIds = currentSelectedSensors.map(s => s.id);
        const previousSensorIds = previousSelectedSensors.map(s => s.id);

        if ("".concat(...currentSensorIds) !== "".concat(...previousSensorIds)) {
            this.fetchReadings(currentSelectedSensors);
        }
    }

    async componentDidMount() {
        const gatewayStatus = await Services.Gateways.getGatewayEnabled();

        this.setState({
            areGatewaysEnabled: gatewayStatus.gatewayEnabled
        });
    }

    onKeyDown(ev: React.KeyboardEvent<HTMLElement>) {
        const { plots, showingReading } = this.state;

        console.log("ev.key", ev.key);

        if (plots.length !== 1) return; // TODO: Handle multiple plots
        if (showingReading === null) return;

        if (ev.key === "ArrowLeft" || ev.key === "ArrowRight") { 
            const delta = ev.key === "ArrowRight" ? 1 : -1;

            const nextReadingList = plots[0].readingPoints.filter((_r, index) => plots[0].readingPoints[index - delta]?.id === showingReading.id);

            if (nextReadingList.length === 1) {
                this.setState({ showingReading: nextReadingList[0] });
            }
        } else if (ev.key === "ArrowUp") {
            console.log("up");
        } else if (ev.key === "ArrowDown") {
           console.log("down");
        }
    }

    async onReadingsDeleteRequested(readings: ReadingTrendPoint[]) {
        if (readings.length === 0) {
            alert("No readings to delete");
            return;
        }

        const promptMessage = ["Delete the following readings forever?", ...readings.map(r => r.id)].join("\n");

        if (confirm(promptMessage) && confirm("Are you absolutely sure?")) {
            const waitForGraphCreatedAfter = new Date();
            await Promise.all(readings.map(reading => Services.SensorReadingsService.readingsIdDelete(reading.id)));

            await this.onReadingParametersChange(waitForGraphCreatedAfter);
        }
    }

    private editModal() {
        const { materials, sensorTypes, uiSettings } = this.props;
        const { editingSensor } = this.state;

        if (!editingSensor || !sensorTypes || !materials) return null;

        return (
            <ConfigureSensorModalPanel
                rootSensorGroup={this.props.rootSensorGroup}
                sensor={editingSensor === "new" ? null : editingSensor}
                sensorTypes={sensorTypes}
                materials={materials}
                shouldBeShown={true}
                onClose={() => this.setState({ editingSensor: null })}
                onSensorUpdated={() => {
                    this.onSensorsUpdated();
                    this.onReadingParametersChange(new Date());
                }}
                uiSettings={uiSettings}
            />
        );
    }
    
    render() {
        const { changeActiveView, devices, rootSensorGroup, latestSensorTrends, onSensorTreeChange, tabletMode, isSyncingData } = this.props;
        const { hoveredReading, ignoredReadings, plots, selectedSensors, selectedNodes, showingReading, editingReading, isLoading, recalculatingSensors,
            areGatewaysEnabled } = this.state;

        return (
            <div style={containerStyle} onKeyDown={ev => this.onKeyDown(ev)}>
                <div style={mainViewStyle}>
                    <SplitPanel
                        firstContent={
                            <SplitPanel
                                firstContent={
                                    <SensorTreePanel
                                        onSensorTreeChange={onSensorTreeChange}
                                        rootSensorGroup={rootSensorGroup}
                                        latestSensorTrends={latestSensorTrends}
                                        selectedNodes={selectedNodes}
                                        onSensorsSelect={(sensors: Sensor[]) => {
                                            this.setState({
                                                selectedNodes: sensors.map(s => s.id),
                                                selectedSensors: sensors,
                                                ignoredReadings: []
                                            });
                                        }}
                                        onSensorGroupClick={(sensorGroup: SensorGroup) => {
                                            this.setState({
                                                selectedNodes: [sensorGroup.id],
                                                selectedSensors: [...GetAllSensorsFromGroup(sensorGroup).values()]
                                            });
                                        }}
                                        onSensorCreateClick={() => this.setState({ editingSensor: "new" })}
                                        onSensorEditClick={sensor => this.setState({ editingSensor: sensor })}
                                        tabletMode={tabletMode}
                                        unitsMode={this.props.uiSettings.unitsMode}
                                    />
                                }
                                secondContent={
                                    selectedSensors.length === 1 && plots.length > 0 ?
                                        <ReadingsListPanel
                                            sensor={selectedSensors[0]}
                                            devices={devices}
                                            readings={plots[0].readingPoints}
                                            onReadingIgnoredStateChange={(reading, newState) => this.onReadingIgnoredStateChange(reading, newState)}
                                            uiSettings={this.props.uiSettings}
                                            highlightedReading={hoveredReading}
                                            onHoveredReadingChange={reading => this.onHoveredReadingChange(reading)}
                                            onViewAscanButtonClick={reading => this.setState({ showingReading: reading })}
                                            onEditButtonClick={async (reading) => {
                                                this.setState({ editingReading: reading });
                                            }}
                                            tabletMode={tabletMode}
                                        />
                                        : undefined
                                }
                                splitRatio={0.6}
                                plane="vertical"
                                tabletMode={tabletMode}
                            />

                        }
                        secondContent={
                            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                                <StatusBar />
                                {isLoading ?
                                    <CircularProgress /> :
                                    <TrendGraphPanel
                                        plots={this.state.plots}
                                        highlightedReading={hoveredReading}
                                        ignoredReadings={ignoredReadings}
                                        onHoveredReadingChange={reading => this.onHoveredReadingChange(reading)}
                                        unitsMode={this.props.uiSettings.unitsMode}
                                        tabletMode={this.props.tabletMode}
                                        onReadingDoubleClicked={reading => this.setState({ showingReading: reading })}
                                        onReadingRightClicked={reading => this.setState({ editingReading: reading })}
                                        onReadingsDeleteRequested={readings => this.onReadingsDeleteRequested(readings)}
                                        showDeleteButton={Services.userHasPolicy("DeleteReadings")}
                                        showLegend
                                        onSensorClick={sensor => this.setState({ editingSensor: sensor })}
                                        showRecalculatingMessage={selectedSensors.filter(s => recalculatingSensors.includes(s.id)).length > 0}
                                    />
                                }
                            </div>
                            }
                            plane="horizontal"
                            splitRatio={tabletMode ? 0.4 : 0.3}
                            tabletMode={tabletMode}
                        />
                </div>
                <div style={footerStyle}>
                    <div style={footerButtonsStyle}>
                        <div style={uiDevicesButtonStyle} hidden={!Services.userHasPolicy("ViewMaterials")}>
                            <Button
                                onClick={() => changeActiveView("materials")}
                                variant="contained"
                                startIcon={<SettingsIcon />}
                                sx = {{
                                    "&:hover": {
                                        background: "#F9EDE7",
                                        color:"#ec6f41",
                                    },
                                    border:"1px solid #ec6f41",
                                    backgroundColor: "#ec6f41",
                                    color: "#fff",
                                    padding: "3px 7px",
                                    lineHeight:"23px",
                                    fontSize: "12px",
                                }}
                                style={{ marginLeft: "10px", marginRight: "10px"}}
                            >Materials</Button>
                        </div>
                        <> 
                            <div style={{ border: "none", ...uiDevicesButtonStyle }}>
                                <Button
                                    onClick={() => {
                                        if (MainCGI.isServiceAvailable()) {
                                            changeActiveView("rdcScanning");
                                        } else {
                                            this.setState({ desktopMessageShouldBeShown: true });
                                        }
                                    }}
                                    variant="contained"
                                    startIcon={<DeviceHub />}
                                    sx = {{
                                        "&:hover": {
                                            background: "#F9EDE7",
                                            color:"#ec6f41",
                                        },
                                        border:"1px solid #ec6f41",
                                        backgroundColor: "#ec6f41",
                                        color: "#fff",
                                        padding: "3px 7px",
                                        lineHeight:"23px",
                                        fontSize: "12px", 
                                    }}
                                    style={{ marginLeft: "10px", marginRight: "10px"}}
                                >Scan RDC</Button>
                            </div>
                            <div style={uiDevicesButtonStyle}>
                                <Button
                                        onClick={() => {
                                            if (MainCGI.isServiceAvailable()) {
                                                changeActiveView("wandScanning");
                                            } else {
                                                this.setState({ desktopMessageShouldBeShown: true });
                                            }
                                        }}
                                        variant="contained"
                                        startIcon={<DeviceHub />}
                                        sx = {{
                                            "&:hover": {
                                                background: "#F9EDE7",
                                                color:"#ec6f41",
                                            },
                                            border:"1px solid #ec6f41",
                                            backgroundColor: "#ec6f41",
                                            color: "#fff",
                                            padding: "3px 7px",
                                            lineHeight:"23px",
                                            fontSize: "12px",
                                        }}
                                        style={{ marginLeft: "10px", marginRight: "10px"}}
                                    >Sync HDC</Button>
                            </div>
                        </>
                        {areGatewaysEnabled ?
                            <div style={uiDevicesButtonStyle}>
                                <Button
                                    onClick={() => changeActiveView("gateways")}
                                    variant="contained"
                                    startIcon={<GatewayIcon />}
                                    sx = {{
                                        "&:hover": {
                                            background: "#F9EDE7",
                                            color:"#ec6f41",
                                        },
                                        border:"1px solid #ec6f41",
                                        backgroundColor: "#ec6f41",
                                        color: "#fff",
                                        padding: "3px 7px",
                                        lineHeight:"23px",
                                        fontSize: "12px",
                                    }}
                                    style={{ marginLeft: "10px", marginRight: "10px"}}
                                >Gateway manager</Button>
                            </div>
                            : null
                        }
                        <div style={uiDevicesButtonStyle}>
                                <Button
                                    onClick={() => changeActiveView("deployments")}
                                    variant="contained"
                                    startIcon={<SettingsIcon />}
                                    sx = {{
                                        "&:hover": {
                                            background: "#F9EDE7",
                                            color:"#ec6f41",
                                        },
                                        border:"1px solid #ec6f41",
                                        backgroundColor: "#ec6f41",
                                        color: "#fff",
                                        padding: "3px 7px",
                                        lineHeight:"23px",
                                        fontSize: "12px",
                                    }}
                                    style={{ marginLeft: "10px", marginRight: "10px"}}
                                >Device manager</Button>
                        </div>
                        <div style={uiDevicesButtonStyle} hidden={!SettingsService.getSettings().rrcShortcut}>
                                <Button
                                    onClick={() => changeActiveView("rrc")}
                                    variant="contained"
                                    startIcon={<SettingsRemote />}
                                    sx = {{
                                        "&:hover": {
                                            background: "#F9EDE7",
                                            color:"#ec6f41",
                                        },
                                        border:"1px solid #ec6f41",
                                        backgroundColor: "#ec6f41",
                                        color: "#fff",
                                        padding: "3px 7px",
                                        lineHeight:"23px",
                                        fontSize: "12px",
                                    }}
                                    style={{ marginLeft: "10px", marginRight: "10px"}}
                                >Robotic Remote Control</Button>
                        </div>
                        <div style={uiDevicesButtonStyle} hidden={!SettingsService.getSettings().spiderShortcut}>
                                <Button
                                    onClick={() => changeActiveView("spider")}
                                    variant="contained"
                                    startIcon={<DirectionsWalk />}
                                    sx = {{
                                        "&:hover": {
                                            background: "#F9EDE7",
                                            color:"#ec6f41",
                                        },
                                        border:"1px solid #ec6f41",
                                        backgroundColor: "#ec6f41",
                                        color: "#fff",
                                        padding: "3px 7px",
                                        lineHeight:"23px",
                                        fontSize: "12px",
                                    }}
                                    style={{ marginLeft: "10px", marginRight: "10px"}}
                                >Crawler</Button>
                        </div>
                    </div>
                    <div style={{ ...productNameStyle, marginRight: 10 }}>
                                <Button
                                    onClick={ev => this.setState({ userMenuAnchor: ev.currentTarget })}
                                    variant="contained"
                                    startIcon={<PersonOutline />}
                                    sx = {{
                                        "&:hover": {
                                            background: "#F9EDE7",
                                            color:"#ec6f41",
                                        },
                                        border:"1px solid #ec6f41",
                                        backgroundColor: "#ec6f41",
                                        color: "#fff",
                                        padding: "3px 7px",
                                        lineHeight:"23px",
                                        fontSize: "12px",
                                    }}
                                    style={{ marginLeft: "10px", marginRight: "10px"}}
                                >{Services.getUsername() || "Anonymous"}
                                </Button>
                        <Menu
                            open={this.state.userMenuAnchor !== null}
                            onClose={() => this.setState({ userMenuAnchor: null })}
                            anchorEl={this.state.userMenuAnchor}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            transformOrigin={{ vertical: "bottom", horizontal: "right" }}
                        >
                            {GeneralCGI.isServiceAvailable() ?
                                <MenuItem
                                    onClick={() => this.props.onAdvancedConfig()}
                                >
                                    <ListItemText>
                                        Advanced config
                                    </ListItemText>
                                </MenuItem>
                                : null
                            }

                            <MenuItem
                                onClick={() => null}
                            >
                                <ListItemText>
                                    {Services.getTenant()}
                                </ListItemText>
                            </MenuItem>

                            <MenuItem
                                onClick={() => this.props.onSignOut()}
                            >
                                <ListItemText>
                                    Sign out
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                    <div style={productNameStyle}>
                            {isSyncingData ? <Tooltip title="Data is syncing" arrow placement="right">
                                    <Button 
                                        onClick={ ev => this.setState({ menuAnchor: ev.currentTarget })}
                                        variant="contained"
                                        sx = { idartButtonSvgStyle }
                                        style={{ marginLeft: "10px", marginRight: "10px"}}
                                    >
                                    <IdartButtonSvg style={{ width: "22px", height: "22px" }}/>
                                    </Button>
                                </Tooltip>:
                                <Button 
                                    onClick={ ev => this.setState({ menuAnchor: ev.currentTarget })}
                                    variant="contained"
                                    sx = { idartButtonStyle }
                                    style={{ marginLeft: "10px", marginRight: "10px"}}
                                >
                                    iDART
                                </Button>
                            }                   
                        <Menu
                            open={this.state.menuAnchor !== null}
                            onClose={() => this.setState({ menuAnchor: null })}
                            anchorEl={this.state.menuAnchor}
                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                            transformOrigin={{ vertical: "bottom", horizontal: "right" }}
                        >
                            <MenuItem
                                onClick={() => {
                                    this.setState({ menuAnchor: null });
                                    this.props.onRequestFullSync();
                                }}
                            >
                                <ListItemIcon>
                                    <DeveloperMode />
                                </ListItemIcon>
                                <ListItemText>
                                    Full db sync
                                </ListItemText>
                            </MenuItem>
                            {
                                Services.userHasPolicy("ClearCache") ?
                                    <MenuItem
                                        onClick={async () => {
                                            this.setState({ menuAnchor: null });
                                            await Services.Cache.cacheClear();
                                            alert("Cache cleared");
                                        }}
                                    >
                                        <ListItemIcon>
                                            <DeveloperMode />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Clear cache
                                        </ListItemText>
                                    </MenuItem>
                                    : null
                            }
                            {
                                Services.userHasPolicy("ContinuousTestingUI") ?
                                    <MenuItem
                                        onClick={() => changeActiveView("rdcTest")}
                                    >
                                        <ListItemIcon>
                                            <DeveloperMode />
                                        </ListItemIcon>
                                        <ListItemText>
                                            RDC testing
                                        </ListItemText>
                                    </MenuItem>
                                    : null
                            }
                            {
                                Services.userHasPolicy("ManageUsers") ?
                                    <MenuItem
                                        onClick={() => changeActiveView("users")}
                                    >
                                        <ListItemIcon>
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            Users
                                        </ListItemText>
                                    </MenuItem>
                                    : null
                            }
                            {
                                Services.userHasPolicy("UploadDatReadings") ?
                                <MenuItem
                                    onClick={() => changeActiveView("import")}
                                >
                                    <ListItemIcon>
                                        <CloudDownload />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Import
                                    </ListItemText>
                                </MenuItem>
                                : null
                            }
                            <MenuItem
                                onClick={() => changeActiveView("export")}
                            >
                                <ListItemIcon>
                                    <ExportIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Export
                                </ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={() => changeActiveView("recalculation")} // TODO: Make recalculation wait in place *
                            >
                                <ListItemIcon>
                                    <CloudDownload />
                                </ListItemIcon>
                                <ListItemText>
                                    Recalculation
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                {showingReading ? // TODO: Include sensor id somehow
                    <AscanModalPanel
                        reading={showingReading}
                        sensor={FirstIfNotEmpty(selectedSensors.filter(s => s.id === showingReading.sensorId))}
                        sensorReadings={FirstIfNotEmpty(plots.filter(p => p.sensor.id === showingReading.sensorId))?.readingPoints || []}
                        shouldBeShown={showingReading !== null}
                        onSave={(waitForGraphCreatedAfter) => this.onReadingParametersChange(waitForGraphCreatedAfter)}
                        onClose={() => this.setState({ showingReading: null })}
                        uiSettings={this.props.uiSettings}
                    />
                    : null
                }
                {editingReading ?
                    <EditReadingModalPanel
                        reading={editingReading}
                        devices={devices}
                        shouldBeShown={editingReading !== null}
                        onSave={waitForGraphCreatedAfter => this.onReadingParametersChange(waitForGraphCreatedAfter)}
                        onClose={() => this.setState({ editingReading: null })}
                        uiSettings={this.props.uiSettings}
                    />
                    : null
                }
                <ModalPanel
                    content={<> {/* TODO: This shouldn't still be here*/}
                        <p >The desktop version of iDART is required to use this feature. Click the link below to download it:</p>
                        <a
                            href="https://portal.inductosense.cloud/software-downloads"
                            style={{ height: 40, display: "block" }}
                        >
                            Download app
                        </a>
                    </>}
                    shouldBeShown={this.state.desktopMessageShouldBeShown}
                    onClose={() => this.setState({ desktopMessageShouldBeShown: false })}
                    showBranding
                    floatingActionButton
                />
                {this.editModal()}
            </div>
        );
    }

    private async onSensorsUpdated() {
        const { selectedSensors } = this.state;

        const reloadedSensors = await Promise.all(selectedSensors.map(s => Services.Sensors.getSensor(s.id)));

        this.setState({ selectedSensors: reloadedSensors });

        this.props.onSensorTreeChange();
    }

    private onHoveredReadingChange(reading: ReadingTrendPoint | null) {
        this.setState({ hoveredReading: reading });
    }

    private onReadingIgnoredStateChange(reading: ReadingTrendPoint, newState: OnOrOff) {
        const { ignoredReadings } = this.state;
        if (newState === "on") this.setState({ ignoredReadings: [reading, ...ignoredReadings] });
        else this.setState({ ignoredReadings: ignoredReadings.filter(i => i.id !== reading.id) });
    }

    private async onReadingParametersChange(waitForGraphCreatedAfter: Date) {
        const { selectedSensors } = this.state;

        this.setState({ recalculatingSensors: selectedSensors.map(s => s.id) });

        for (const selectedSensor of selectedSensors) {
            await Services.SensorReadingsService.waitForNewTrendGraph(selectedSensor.id, waitForGraphCreatedAfter);
        }
        
        this.fetchReadings(selectedSensors);
        this.setState({ recalculatingSensors: [] });
    }

    private async fetchReadings(sensors: Sensor[]) {
        this.setState({ isLoading: true });

        const plots = await Promise.all(sensors.map(async sensor => {

            console.log("this sensor", sensor);

            const reloadedSensor = await Services.Sensors.getSensor(sensor.id);
            const cache = await Services.SensorReadingsService.getSensorsIdReadingsGraph(sensor.id);
            console.log("reading points", cache);

            return {
                sensor: reloadedSensor,
                readingPoints: cache.points
            };
        }));

        this.setState({ plots, isLoading: false });
    }
}


interface MainDashboardProps {
    changeActiveView(newView: View): void;
    uiSettings: UiSettings;
    tabletMode: boolean;
    isSyncingData: boolean;
    onSignOut(): void;
    onRequestFullSync(): void;
    onAdvancedConfig(): void;
    availableTenants?: string[];
    tenantName?: string;
    onTenantChange(newTenant: string): void;
}

interface MainDashboardState {
    isReadyToRender: boolean;
    rootSensorGroup: SensorGroup | null;
    devices: Device[] | null;
    materials: Material[] | null;
    sensorTypes: SensorType[] | null;
    latestSensorTrends?: Trendanalysis[];
}

export default class MainDashboard extends Component<MainDashboardProps, MainDashboardState> {
    constructor(props: MainDashboardProps) {
        super(props);

        this.state = {
            isReadyToRender: false,
            rootSensorGroup: null,
            devices: null,
            materials: null,
            sensorTypes: null
        };
    }

    async componentDidMount() {
        try {
            await Promise.all([
                this.fetchRootSensorGroup(),
                this.fetchDevices(),
                this.fetchMaterials(),
                this.fetchSensorTypes(),
                this.fetchLatestSensorTrends()
            ]);

            // Data is loaded, update the state
            this.setState({ isReadyToRender: true });
        } catch (error) {
            console.error("Error loading data:", error);
            // Handle any errors, such as by displaying an error message to the user
            this.props.onSignOut();
        }
    }

    async componentDidUpdate(previousProps: MainDashboardProps) {
        if (!this.props.isSyncingData && previousProps.isSyncingData) {
            await this.fetchRootSensorGroup();
        }
    }

    render() {

        const { changeActiveView, onRequestFullSync, availableTenants, tenantName } = this.props;
        const { devices, isReadyToRender, sensorTypes, rootSensorGroup, materials, latestSensorTrends } = this.state;

        if (!isReadyToRender) {
            return <LoadingComponent message="Preparing dashboard, please wait..." />;
        }


        if (!rootSensorGroup) throw new Error("The root sensor group should never be empty on render.");

        return (
            <MainDashboardInternals
                rootSensorGroup={rootSensorGroup}
                devices={devices}
                materials={materials}
                sensorTypes={sensorTypes}
                onRequestFullSync={onRequestFullSync}
                latestSensorTrends={latestSensorTrends}
                onSensorTreeChange={async () => this.onSensorTreeChange()}
                changeActiveView={newView => changeActiveView(newView)}
                uiSettings={this.props.uiSettings}
                tabletMode={this.props.tabletMode}
                isSyncingData={this.props.isSyncingData}
                onSignOut={this.props.onSignOut}
                onAdvancedConfig={this.props.onAdvancedConfig}
                availableTenants={availableTenants}
                tenantName={tenantName}
                onTenantChange={this.props.onTenantChange}
            />
        );
    }

    private async onSensorTreeChange() {
        await Promise.all([
            this.fetchRootSensorGroup(),
            this.fetchLatestSensorTrends()
        ]);
    }

    private async fetchRootSensorGroup() {
        this.setState({ rootSensorGroup: await Services.SensorGroups.getRootSensorGroup() });
    }

    private async fetchDevices() {
        this.setState({ devices: await Services.Devices.getAllDevices() });
    }

    private async fetchMaterials() {
        this.setState({ materials: await Services.Materials.getAllMaterials() });
    }

    private async fetchSensorTypes() {
        this.setState({ sensorTypes: await Services.Sensors.getAllSensorTypes() });
    }

    private async fetchLatestSensorTrends() {
        this.setState({ latestSensorTrends: await Services.Sensors.getMostRecentTrendAnalysisForAllSensors() });
    }
}
