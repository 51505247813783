import * as React from "react";
import { Component, CSSProperties } from "react";
import CreateFolderButton from "Components/Buttons/Composite/CreateFolderButton";
import TextField from "Components/Input/TextField";

const formStyle: CSSProperties = {
    display: "flex",
    alignItems: "flex-end"
};

const groupNameFieldStyle: CSSProperties = {
    flex: 1,
    marginRight: 13
};

interface CreateSensorGroupFormProps {
    isSaving: boolean;
    onClose(): void;
    onCreate(groupName: string): void;
}

interface CreateSensorGroupFormState {
    groupName: string | null;
}

export default class CreateSensorGroupForm extends Component<CreateSensorGroupFormProps, CreateSensorGroupFormState> {
    constructor(props: CreateSensorGroupFormProps) {
        super(props);
        this.state = { groupName: null };
    }

    render() {
        return (
            <div style={formStyle}>
                {this.groupNameField()}
                {this.createButton()}
            </div>
        );
    }

    private groupNameField() {
        return (
            <div style={groupNameFieldStyle}>
                <TextField
                    label="Group name"
                    isDisabled={this.props.isSaving}
                    onNewValidValue={newValidValue => this.onGroupNameFieldUpdated(newValidValue)}
                />
            </div>
        );
    }

    private createButton() {
        return (
            <CreateFolderButton
                isDisabled={!this.state.groupName}
                isSpinning={this.props.isSaving}
                onClick={() => this.onCreateButtonClick()}
            />
        );
    }

    private onGroupNameFieldUpdated(newValue: string | null) {
        this.setState({ groupName: newValue });
    }

    private onCreateButtonClick() {
        const { isSaving, onCreate } = this.props;
        const { groupName } = this.state;

        if (!groupName) throw new Error("It should not be possible for the group name to be falsy at this point.");
        if (!isSaving) onCreate(groupName);
    }
}
