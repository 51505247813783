import { SensorGroupsApi } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import merge from "Utilities/Merge";
import SensorGroup from "Model/SensorGroup";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";
import Sensor from "../../Model/Sensor";

export default class SensorGroupsService extends InductosenseService<SensorGroupsApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(SensorGroupsApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getRootSensorGroup() {
        await this.ensureLatestConfig();
        const result: SensorGroup = await this.serverApi!.sensorGroupsGet();
        if (!result) throw Error("Root sensor group was null or undefined.");
        return result;
    }

    @wrapWithErrorHandling()
    async getSensorList() {
        await this.ensureLatestConfig();
        const rootSensorGroup = await this.getRootSensorGroup();
        const addSensorGroup = (sensorGroup: SensorGroup): Sensor[] => {
            const childSensorArrays = sensorGroup.children?.map(csg => addSensorGroup(csg)) || [];
            const childSensors = ([] as Sensor[]).concat.apply([], childSensorArrays);

            return [...(sensorGroup.sensors || []), ...childSensors];
        };

        return addSensorGroup(rootSensorGroup);
    }

    @wrapWithErrorHandling()
    async addSensorGroup(name: string, parentGroupId: string, rfidIfMES?: string) {
        await this.ensureLatestConfig();

        const apiResponse = await this.serverApi!.sensorGroupsPostRaw({ sensorgrouppost: { parentGroupId, name, rfidIfMES } });
        const location = apiResponse.raw.headers.get("Location");
        if (location === null) {
            throw new Error("No location in API response");
        }

        const rxp = new RegExp("/v1/sensor-groups/(.*)").exec(location);
        const readingId = rxp![1];
        return readingId;
    }

    @wrapWithErrorHandling()
    async deleteSensorGroup(id: string) {
        await this.ensureLatestConfig();
        await this.serverApi.sensorGroupsIdDelete({ id });
    }

    @wrapWithErrorHandling()
    async updateSensorGroup(sensorGroup: SensorGroup, changes: Partial<SensorGroup>) {
        await this.ensureLatestConfig();
        if (!sensorGroup.id) throw new Error("Sensor group ID should never be undefined at this point.");

        await this.serverApi!.sensorGroupsIdPut({
            id: sensorGroup.id,
            sensorgrouppost: merge(sensorGroup, changes)
        });
    }

    @wrapWithErrorHandling()
    async moveSensorGroup(sensorGroup: SensorGroup, destinationId: string) {
        await this.ensureLatestConfig();
        const { id, ...otherProps } = sensorGroup;
        if (!id) throw new Error("Source sensor ID should never be undefined at this point.");

        if (sensorGroup.parentGroupId !== destinationId) {
            await this.serverApi!.sensorGroupsIdPut({
                id, sensorgrouppost: {
                    ...otherProps,
                    parentGroupId: destinationId
                }
            });
        }
    }
}
