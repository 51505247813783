import { serviceTimeout } from "Constants/Timings";
import InductosenseService from "Services/Platform/InductosenseService";
import ServiceGeneralError from "Services/Platform/ServiceGeneralError";
import ServiceTimeoutError from "Services/Platform/ServiceTimeoutError";
import { ModelErrorFromJSONTyped } from "@inductosense/typescript-fetch";

export default function wrapWithErrorHandling<T>(timeout = serviceTimeout) {
    return (_target: InductosenseService<T>, _propertyKey: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;
        
        descriptor.value = async function (...args: unknown[]) {
            const originalMethodPromise = originalMethod.apply(this, args);
            const timeoutPromise = new Promise(function (_resolve, reject) {
                setTimeout(() => reject(new ServiceTimeoutError()), timeout);
            });

            try {
                await _target.ensureStateAuthenticated.apply(this);
                await _target.ensureAccessConfiguration.apply(this);              
                const result = await Promise.race([originalMethodPromise, timeoutPromise]);
                return result;
            } catch (error) {
                if (error instanceof ServiceTimeoutError) {
                    throw error;
                }
                if (error instanceof Response) {
                    if (error.status === 401 || error.status === 403 /* TODO: Handle forbidden error when specific action restricted */) {
                        console.log("Request was not authenticated. Probably the latest token has expired. Will attempt automatic re-authentication now.");
                        await _target.reauthenticate.apply(this);
                        const result = await originalMethod.apply(this, args);
                        return result;
                    }

                    const json: object[] = await error.json();
                    const errors = json.map(object => ModelErrorFromJSONTyped(object, false));

                    console.log("putting errors in", errors);
                    throw new ServiceGeneralError(undefined, errors);
                    
                }
                // Some errors can be thrown by DevTools, etc.
                // E.g. " TypeError: "NetworkError when attempting to fetch resource." "
                // so just return our most general error if nothing else matches
                throw error;
            }
        };
    };
}
