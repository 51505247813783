import * as React from "react";
import { Component } from "react";
import DropDownList from "Components/Input/DropDownList";
import WandConnectionService from "Services/Electron/WandConnectionService";
import RRCSensorReadingsPanel from "RRC/RRCSensorReadingsPanel";
import RRCSensor from "./RRCSensor";
import RRCConfig from "./RRCConfig";
import SubSeaUISettings from "./RRCUISettings";
import SerialConnection from "./SerialConnection";
import SocketConnection from "./SocketConnection";
import Alert from "@mui/lab/Alert";

interface RRCReadingPanelProps {
    uiSettings: SubSeaUISettings;
}

interface RRCReadingPanelState {
    config?: RRCConfig;
    selectedSensor?: RRCSensor;
    selectedConnection?: SerialConnection | SocketConnection;
    fatalErrorMessage?: string;
}

export default class RRCReadingsPanel extends Component<RRCReadingPanelProps, RRCReadingPanelState> {
    wandConnectionService = new WandConnectionService();

    constructor(props: RRCReadingPanelProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        await this.wandConnectionService.RRCCreateDirectory();
        const config = await this.wandConnectionService.RRCGetConfig();
        console.log("config log", config);

        if (config === null) {
            this.setState({
                fatalErrorMessage: "Invalid config file, check it's valid JSON"
            });
        } else {
            this.setState({
                config,
                selectedSensor: config.sensors.length > 0 ? config.sensors[0] : undefined,
                selectedConnection: new Array<SerialConnection | SocketConnection>()
                    .concat(config.serialConnections)
                    .concat(config.socketConnections)[0]
            });
        }
    }

    async onSensorSelect(sensor: RRCSensor) {
        this.setState({
            selectedSensor: sensor
        });
    }

    render() {
        const { config, selectedSensor, selectedConnection, fatalErrorMessage } = this.state;
        const { uiSettings } = this.props;

        if (fatalErrorMessage) {
            return <Alert severity="error">{fatalErrorMessage}</Alert>;
        }

        if (config === undefined) return null;
        if (config.sensors.length === 0) {
            return <p>No sensors defined</p>;
        }

        return (
            <div style={{
                width: 710,
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <div style={{ width: 350, display: "inline-block" }}>
                    <DropDownList<RRCSensor>
                        options={config.sensors}
                        labelFor={sensor => `${sensor.description}, ${sensor.rfid}`}
                        initiallySelectedOption={selectedSensor}
                        onChange={sensor => this.onSensorSelect(sensor)}
                    />
                </div>&nbsp;

                <div style={{ width: 350, display: "inline-block" }}>
                    <DropDownList<SerialConnection | SocketConnection>
                        options={new Array<SerialConnection | SocketConnection>()
                            .concat(config.serialConnections)
                            .concat(config.socketConnections)}
                        labelFor={option => option.description}
                        initiallySelectedOption={selectedConnection}
                        onChange={option => this.setState({ selectedConnection: option })}
                    />
                </div>

                {selectedSensor !== undefined && selectedConnection !== undefined ?
                    <RRCSensorReadingsPanel
                        uiSettings={uiSettings}
                        sensor={selectedSensor}
                        config={config}
                        connectionDetails={selectedConnection}
                    />
                    : null
                }
            </div>
        );
    }
}
