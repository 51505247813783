// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-screen-exit { opacity: 1; }
.splash-screen-exit-active { opacity: 0; transition: opacity var(--splash-screen-view-content-fade-out-duration); }
.splash-screen-exit-done { opacity: 0; }
`, "",{"version":3,"sources":["webpack://./Styling/Animations/SplashScreenAnimation/SplashScreenExit.css"],"names":[],"mappings":"AAAA,sBAAsB,UAAU,EAAE;AAClC,6BAA6B,UAAU,EAAE,uEAAuE,EAAE;AAClH,2BAA2B,UAAU,EAAE","sourcesContent":[".splash-screen-exit { opacity: 1; }\n.splash-screen-exit-active { opacity: 0; transition: opacity var(--splash-screen-view-content-fade-out-duration); }\n.splash-screen-exit-done { opacity: 0; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
