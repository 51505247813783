import * as React from "react";
import * as Unicode from "Constants/UnicodeCharacters";
import { AbsoluteZeroInDegreesCelsius } from "Constants/Temperatures";
import { Component, CSSProperties } from "react";
import { FormSection } from "Types/Forms";
import { ReaderType, TemperatureSource } from "Model/Enumerations";
import FieldIsRequiredValidator from "Validation/Fields/FieldIsRequiredValidator";
import Form from "Forms/Form";
import SaveAndCancelButtonsFormFooter from "Forms/Shared/SaveAndCancelButtonsFormFooter";
import Services from "Services/Platform/Services";
import StringRepresentsARealNumberOrNothingValidator from "Validation/Strings/StringRepresentsARealNumberOrNothingValidator";
import StringRepresentsATemperatureInDegreesCelsiusAboveAbsoluteZeroOrNothingValidator from "Validation/Strings/StringRepresentsATemperatureInDegreesCelsiusAboveAbsoluteZeroOrNothingValidator";
import TemperatureIcon from "Components/Graphics/Icons/TemperatureIcon";
import TextField from "Components/Input/TextField";
import Validator from "Validation/Validator";
import { Device } from "@inductosense/typescript-fetch/dist/models/Device";
import UiSettings from "../../Model/UiSettings";
import { Readingdetailed, Readingtrendpoint } from "@inductosense/typescript-fetch";

const footerStyle: CSSProperties = {
    marginTop: 16
};

//const dateAndTimeStyle: CSSProperties = {
//    display: "flex"
//};

//const ancillaryProductsStyle: CSSProperties = {
//    display: "flex",
//    flexDirection: "column",
//    alignItems: "flex-end"
//};

interface EditReadingFormProps {
    reading: Readingtrendpoint;
    devices: Device[] | null;
    onSave(waitForGraphCreatedAfter: Date): void;
    onCancel(): void;
    uiSettings: UiSettings;
}

interface EditReadingFormState {
    //dateFieldValue: Date | null;
    //timeFieldValue: Date | null;
    temperatureFieldValue: string | null;
    readingDetailed: Readingdetailed | null;
    //echo45FieldValue: boolean;
    //echo100FieldValue: boolean;
    //reach400FieldValue: boolean;
    userTriedToSave: boolean;
    isSaving: boolean;
    device: Device | null;
    company: string | null;
}

export default class EditReadingForm extends Component<EditReadingFormProps, EditReadingFormState> {
    private temperatureFieldInitialValue: string | null = null;

    //private readonly timestampFieldsRequiredValidator: Validator<Date>;
    private readonly textFieldRequiredValidator: Validator<string>;
    private readonly realNumberValidator: Validator<string>;
    private readonly aboveAbsoluteZeroValidator: Validator<string>;

    constructor(props: EditReadingFormProps) {
        super(props);

        //this.timestampFieldsRequiredValidator = new FieldIsRequiredValidator<Date>("This field is required");
        this.textFieldRequiredValidator = new FieldIsRequiredValidator<string>();
        this.realNumberValidator = new StringRepresentsARealNumberOrNothingValidator("Must be a number");
        this.aboveAbsoluteZeroValidator = new StringRepresentsATemperatureInDegreesCelsiusAboveAbsoluteZeroOrNothingValidator(`Must be > ${AbsoluteZeroInDegreesCelsius}`);

        this.state = {
            //dateFieldValue: reading.timestamp ?? null,
            //timeFieldValue: reading.timestamp ?? null,
            temperatureFieldValue: null,
            readingDetailed: null,
            //echo45FieldValue: false,
            //echo100FieldValue: false,
            //reach400FieldValue: false,
            userTriedToSave: false,
            isSaving: false,
            device: null,
            company: null
        };
    }

    async componentDidMount() {
        const { devices } = this.props;

        const readingDetailed = await Services.Readings.readingsIdGet(this.props.reading.id);

        const temperatureCelsius = readingDetailed.parameters?.temperature ?? readingDetailed.temperature;

        if (this.props.uiSettings.temperatureUnits === "fahrenheit") {
            this.temperatureFieldInitialValue = ((temperatureCelsius * 9 / 5) + 32).toString();
        } else {
            this.temperatureFieldInitialValue = temperatureCelsius.toString();

            this.setState({ readingDetailed, temperatureFieldValue: this.temperatureFieldInitialValue });
        }

        const thisDevice = devices?.find(d => d.id === readingDetailed.deviceId);
        if (thisDevice) {
            this.setState({ device: thisDevice });
        }

        try {
            const dcUser = await Services.DcUsersService.getDcUserByUsername(readingDetailed.userName);
            this.setState({ company: dcUser.company ?? "Unknown" });
        } catch {
            this.setState({ company: "Unknown" });
        }
    }

    render() {
        if (this.state.readingDetailed === null) return <p>Loading...</p>;

        return (
            <Form
                sections={this.sections()}
                footer={this.footer()}
            />
        );
    }

    // TODO: Server-side support for ancillary products to be added in work item #429.
    // TODO: Server-side support for timestamp editing to be added in work item #430.
    private sections() {
        return [
            //this.dateAndTimeSection(),
            this.temperatureSection(),
            //this.ancillaryProductsSection(),
            this.timestampSection(),
            this.takenBySection(),
            this.readerTypeSection(),
            this.probeDescriptionSection(),
            this.probeSerialNumberSection(),
            this.probeFirmwareVersionSection(),
            this.probeSystemDelaySection()
        ];
    }

    private footer() {
        return (
            <div style={footerStyle}>
                <SaveAndCancelButtonsFormFooter
                    formValuesAreUnchanged={this.formValuesAreUnchanged()}
                    isSaving={this.state.isSaving}
                    onSaveButtonClick={() => this.onSaveButtonClick()}
                    onCancelButtonClick={() => this.onCancelButtonClick()}
                />
            </div>
        );
    }

    //private dateAndTimeSection(): FormSection {
    //    const { reading } = this.props;
    //    const { dateFieldValue, isSaving, timeFieldValue, userTriedToSave } = this.state;
    //    const { errorMessage } = this.timestampFieldsRequiredValidator;
    //    const { timestampFieldsRequiredValidator } = this;

    //    const dateIsInvalid = userTriedToSave && !timestampFieldsRequiredValidator.isValid(dateFieldValue);
    //    const timeIsInvalid = userTriedToSave && !timestampFieldsRequiredValidator.isValid(timeFieldValue);

    //    return {
    //        marginTop: 4,
    //        label: { text: "Taken", isRequired: true, marginTop: 21 },
    //        control: {
    //            isEditable: true,
    //            node: (
    //                <div style={dateAndTimeStyle}>
    //                    <DatePicker
    //                        label="Date"
    //                        initialDate={reading.timestamp}
    //                        width={139}
    //                        isClearable
    //                        isDisabled={isSaving}
    //                        formValidationError={dateIsInvalid ? errorMessage : null}
    //                        onChange={newDate => this.onDateFieldUpdated(newDate)}
    //                    />
    //                    <TimePicker
    //                        label="Time"
    //                        initialTime={reading.timestamp}
    //                        width={121}
    //                        isClearable
    //                        isDisabled={isSaving}
    //                        formValidationError={timeIsInvalid ? errorMessage : null}
    //                        onChange={newTime => this.onTimeFieldUpdated(newTime)}
    //                    />
    //                </div>
    //            )
    //        }
    //    };
    //}

    private temperatureSection(): FormSection {
        const { isSaving } = this.state;
        const { aboveAbsoluteZeroValidator, realNumberValidator, temperatureFieldInitialValue } = this;
        const { temperatureUnits } = this.props.uiSettings;

        return {
            label: { text: "Temperature", marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextField
                        textAlignment="right"
                        suffix={`${Unicode.Degrees}${temperatureUnits === "celsius" ? "C" : "F"}`}
                        icon={<TemperatureIcon />}
                        width={135}
                        initialValue={temperatureFieldInitialValue}
                        isDisabled={isSaving}
                        onNewValue={newValue => this.onTemperatureFieldUpdated(newValue)}
                        validators={[
                            realNumberValidator,
                            aboveAbsoluteZeroValidator
                        ]}
                    />
                )
            }
        };
    }

    //private ancillaryProductsSection(): FormSection {
    //    return AncillaryProductsFormSection({
    //        marginTop: 9,
    //        isSaving: this.state.isSaving,
    //        onEcho45ValueChanged: newValue => this.onEcho45FieldUpdated(newValue),
    //        onEcho100ValueChanged: newValue => this.onEcho100FieldUpdated(newValue),
    //        onReach400ValueChanged: newValue => this.onReach400FieldUpdated(newValue)
    //    });
    //}

    private timestampSection(): FormSection {
        const timestamp = this.state.readingDetailed?.timestamp;

        const date = timestamp?.toLocaleDateString();
        const time = timestamp?.toLocaleTimeString();

        return {
            marginTop: 6,
            label: { text: "Timestamp" },
            control: {
                isEditable: false,
                node: `${date} ${time}`
            }
        };
    }

    private takenBySection(): FormSection {
        return {
            marginTop: 14,
            label: { text: "Taken by" },
            control: {
                isEditable: false,
                node: this.takenByValue()
            }
        };
    }

    private takenByValue() {
        return this.state.readingDetailed?.userName + ", " + (this.state.company ?? "...");
    }

    private readerTypeSection(): FormSection {
        const { device } = this.state;

        let value = "";

        if (device) {
            switch (device.readerType) {
                case ReaderType.Unknown: value = "Unknown"; break;
                case ReaderType.DataCollector: value = "Handheld Data Collector"; break;
                case ReaderType.RemoteDataCollector: value = "Remote Data Collector"; break;
                default: throw new Error("Unexpected reader type encountered.");
            }
        }

        return {
            marginTop: 14,
            label: { text: "Reader type" },
            control: {
                isEditable: false,
                node: value
            }
        };
    }

    private probeDescriptionSection(): FormSection {
        return {
            marginTop: 14,
            label: { text: "Device description" },
            control: {
                isEditable: false,
                node: this.state.device?.description
            }
        };
    }

    private probeSerialNumberSection(): FormSection {
        const { device } = this.state;
        const serialNumberString = device ? `#${device.serialNumber}` : null;

        return {
            marginTop: 14,
            label: { text: "Device serial number" },
            control: {
                isEditable: false,
                node: serialNumberString
            }
        };
    }

    private probeFirmwareVersionSection(): FormSection {
        return {
            marginTop: 14,
            label: { text: "Device firmware version" },
            control: {
                isEditable: false,
                node: this.state.readingDetailed?.firmwareVersion
            }
        };
    }

    private probeSystemDelaySection(): FormSection {
        const { device } = this.state;

        const systemDelayTime = device?.systemDelayInSeconds;
        const delayTimeString = systemDelayTime !== undefined
            ? `${(systemDelayTime * 1e9).toFixed(2)} ns`
            : null;

        return {
            marginTop: 14,
            label: { text: "Device system delay" },
            control: {
                isEditable: false,
                node: delayTimeString
            }
        };
    }

    private async onSaveButtonClick() {
        if (!this.state.userTriedToSave) this.setState({ userTriedToSave: true });
        if (this.formValuesAreValid()) {
            this.setState({ isSaving: true });
            await this.saveForm();
        }
    }

    private onCancelButtonClick() {
        this.props.onCancel();
    }

    //private onDateFieldUpdated(newDate: Date | null) {
    //    this.setState({ dateFieldValue: newDate });
    //}

    //private onTimeFieldUpdated(newTime: Date | null) {
    //    this.setState({ timeFieldValue: newTime });
    //}

    private onTemperatureFieldUpdated(newValue: string | null) {
        this.setState({ temperatureFieldValue: newValue });
    }

    //private onEcho45FieldUpdated(newValue: boolean) {
    //    this.setState({ echo45FieldValue: newValue });
    //}

    //private onEcho100FieldUpdated(newValue: boolean) {
    //    this.setState({ echo100FieldValue: newValue });
    //}

    //private onReach400FieldUpdated(newValue: boolean) {
    //    this.setState({ reach400FieldValue: newValue });
    //}

    private formValuesAreValid() {
        const { temperatureFieldValue } = this.state;
        const { aboveAbsoluteZeroValidator, realNumberValidator, textFieldRequiredValidator } = this;

        //if (!timestampFieldsRequiredValidator.isValid(dateFieldValue)) return false;
        //if (!timestampFieldsRequiredValidator.isValid(timeFieldValue)) return false;
        if (!textFieldRequiredValidator.isValid(temperatureFieldValue)) return false;
        if (!realNumberValidator.isValid(temperatureFieldValue)) return false;
        if (!aboveAbsoluteZeroValidator.isValid(temperatureFieldValue)) return false;

        return true;
    }

    private formValuesAreUnchanged() {
        return this.state.temperatureFieldValue === this.temperatureFieldInitialValue;
    }

    private async saveForm() {
        const { onSave } = this.props;
        const { temperatureFieldValue, readingDetailed } = this.state;
        const { temperatureUnits } = this.props.uiSettings;

        if (readingDetailed === null) throw new Error("Need to have reading to save it"); // TODO: Handle this better

        let temperatureCelsius = undefined;
        if (temperatureFieldValue !== null) {
            temperatureCelsius = temperatureUnits === "fahrenheit" ?
                (+temperatureFieldValue - 32) * 5/9
                : +temperatureFieldValue;
        }

        const waitForGraphCreatedAfter = new Date();
        await Services.Readings.createNewParametersForReading(readingDetailed, {
            temperature: temperatureCelsius,
            temperatureSource: TemperatureSource.User
        });

        onSave(waitForGraphCreatedAfter);
    }
}
