import * as React from "react";
import { Component, CSSProperties } from "react";
import AlertPanel from "Panels/AlertPanel";
import ModalPanel from "Panels/ModalPanel";

const defaultTitle = "Server request failed";
const detailsLineStyle: CSSProperties = { marginTop: 5 };

interface FailedRequestModalPanelProps {
    title?: string;
    message: string;
    response: Response | null;
    shouldBeShown: boolean;
    onClose?(): void;
}

export default class FailedRequestModalPanel extends Component<FailedRequestModalPanelProps> {
    render() {
        const { onClose, shouldBeShown, title } = this.props;

        return (
            <ModalPanel
                title={title || defaultTitle}
                content={this.content()}
                shouldBeShown={shouldBeShown}
                onClose={onClose}
            />
        );
    }

    private content() {
        if (!this.props.response) return null;

        return (
            <AlertPanel
                type="error"
                title={this.props.message}
                content={this.details()}
            />
        );
    }

    private details() {
        const { response } = this.props;

        return (
            <div>
                <div style={detailsLineStyle}>
                    <b>Type:</b> <i>{response?.type}</i>
                </div>
                <div style={detailsLineStyle}>
                    <b>URL:</b> <i>{response?.url}</i>
                </div>
                <div style={detailsLineStyle}>
                    <b>Status:</b> <i>{response?.status}</i>
                </div>
            </div>
        );
    }
}
