import { SyncApi } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class SyncService extends InductosenseService<SyncApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(SyncApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getServerSnapshot(lastSync?: Date) {
        await this.ensureLatestConfig();
        return this.serverApi.getServerSnapshot({ lastSync })
    }
}
