import * as React from "react";
import { FunctionComponent } from "react";

interface AlertHeadingProps {
    text: string;
}

const AlertHeading: FunctionComponent<AlertHeadingProps> = props => <h3>{props.text}</h3>;

export default AlertHeading;
