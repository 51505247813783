import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import SelectComparisonSignalPanel from "../SelectComparisonSignalPanel";
import { Sensor } from "@inductosense/typescript-fetch/dist/models/Sensor";

interface SelectComparisonSignalModalPanelProps {
    shouldBeShown: boolean;
    onSelect(readingId: string, sensor: Sensor): void;
    onClose(): void;
    initialSensorId: string;
}

export default class SelectComparisonSignalModalPanel extends Component<SelectComparisonSignalModalPanelProps> {
    constructor(props: SelectComparisonSignalModalPanelProps) {
        super(props);
    }

    render() {
        const { onClose, shouldBeShown } = this.props;

        return (
            <ModalPanel
                title="Select comparison signal"
                titleAlignment="centre"
                shouldBeShown={shouldBeShown}
                onClose={onClose}
                content={this.content()}
            />
        );
    }

    content() {
        return <SelectComparisonSignalPanel
            onSelect={this.props.onSelect}
            initialSensorId={this.props.initialSensorId}
        />
    }
}
