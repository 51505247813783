export function getReleaseNotes() {
    return [
        {
            version: "1.0.80",
            notes: [
                "On a touchscreen, use two fingers to pan/zoom the trend and a-scan graphs."
            ]
        },
        {
            version: "1.0.89",
            notes: [
                "Corrosion rates now included in CSV export",
                "New layout with bigger trend graph",
                "User now has control over whether to upgrade firmware when syncing"
            ]
        },
        {
            version: "1.1.1001",
                notes: [
                    "Show intervals when showing existing deployments"
                ]
        },
        {
            version: "1.1.1003",
                notes: [
                    "Resolved issue with Probe with iDART",
                    "Create AzDo Repository"
                ]
        },
        {
            version: "1.1.1022",
                notes: [
                    "New CSV export format.",
                    "UI Improvements and changes",
                    "Fixed several known issues - RDC and HDC Scanning"
                ]
        },
        {
            version: "1.2.3",
            notes: [
                "AddNoTracking to improve performance",
                "Addition of new sensor types",
                "CSV Format changes",
                "Offline data extraction",
                "Security Updates",
                "Fixes several bugs and software crashes"
            ]
        },
        {
            version: "1.3.20",
            notes: [
                "Token Management - Software reboot required for transitioning to a new token format.",
                "Update iDART - Enhanced functions for specific Sensor Type Specifications.",
                "Auto Peak Detection - Implemented improvements for automatic peak detection.",
                "Reading-Analyser Performance - Enhanced to process readings in parallel.",
                "Peak Window Selection - Method adjustment to ensure traceability.",
                "Additional Sensor Support - Added compatibility for Sensor B5R-H2.",
                "Array Grid View - Resolved issues with missing cells in the grid view's rows and columns.",
                "Graph Display - Modified display parameters for Minimum Thickness and Warning Thickness on graphs.",
                "Graph Navigation - Enhanced panning and zooming performance within the sensor graphs.",
                "Temperature Compensation - Enabled B5R and B2R sensors to determine thickness readings at invalid temperatures when active.",
                "Trend Analyser Performance- Additional improvements in the processing of data"
            ]
        },
        {
            version: "1.4",
            notes: [
                "iDART login updates: Changed the way users log into the iDART application, making it easier to select a tenant and no longer requiring different addresses provided for each customer.",
                "Improve the UI design for the login page.",
                "Implement Tooltips and indicates peak window selection mode for readings",
                "Edit InductosenseSoftware Error page",
                "Fixed: Data Extraction Log wasn't showing up at all",
                "Fixed: No message to say that the HDC isn't connected appeared when trying to sync a HDC device"
            ]
        },
        {
            version: "1.5",
            notes: [
                "Single Sign - On with Microsoft for iDART login",
                "Fixed: iDART cannot import A - scan CSV files",
                "Fixed: Negative thickness value when invalid peak is selected",
                "Fixed: Package 'Azure.Identity' 1.8.2 has a known high severity vulnerability according to .NET / Visual Studio",
                "Fixed: Start- up fix to ensure we don't bring down the server as it starts due to missing keyvault values",
                "Fixed: Image on Inductosense Software web page doesn't adjust sized when user zooms in or out.",
                "Fixed: Improve errorcode for extreme temperature when temperature compensation is on"
            ]
        },
        {
            version: "1.6",
            notes: [
                "Chirp Parameters Options - 4.5MHz option for S5R-A2",
                "Hide dummy device in device manager",
                "Fixed: A-Scan image saved is blank",
                "Fixed: Login with comma-separated list of tenants doesn't work",
                "Fixed: iDART doesn't remember user after MS login",
                "Fixed: Production DB's have duplicate Sensor Types",
                "Fixed: Login screen doesn't show specific message when offline"
            ]
        },
        {
            version: "1.7",
            notes: [
                "Implemented data caching to enhance UI loading speed",
                "Improved UI aesthetics",
                "Introduced sensor and sensor group searching feature",
                "Update the BLE firmware version on iDART to 2.2.2 (for specified clients only)",
                "Provided firmware update option when downloading readings",
                "Adjusted Gateway RDC Schedule time limit",
                "Enabled the ability to create new tenants within the portal",
                "Added a button on iDART for Gateway Reset",
                "Fixed: Gateway manager not showing up for gateway enabled tenant",
                "Fixed: Alive Calling Message is missing and impacting software stability",
                "Fixed: iDART keeps firing material api in Add HDC page and cause burden to the api server",
                "Fixed: Failed SSO blocks the iDART credential input on iDART desktop app",
            ]
        },
        {
            version: "1.8",
            notes: [
                "The UI for the device manager screen has been redesigned, with separate screens to display RDCs and HDCs and more graphics and descriptions",
                "The list of RDCs is now searchable and the \"add RDC\" button is always visible",
                "The list of HDCs now distinguishes between V2 and V3 HDCs",
                "The gateway management page has been improved.It now tracks connection types used by gateways and automatically reports whether a gateway is online, and the reason a gateway is offline is now logged",
                "Internally we now have the ability to deploy new tenants automatically from the software portal",
                "The software portal now tracks the licensing of tenants",
                "New RDC firmware now available for some tenants",
                "New tenants have been added to the system",
                "We have streamlined our software update process to make it more efficient and reliable",
                "Improved logging to ease internal troubleshooting",
                "New icons to differentiate sensors from groups",
                "Improved the display of HDC users table",
                "Fixed: Caching of internal API requests has been improved so that if two requests are made simultaneously they only hit the database once",
                "Fixed: RDC device time reset to 2020-05 - 18 - 13-00-00 after firmware update",
                "Fixed: Unable to generate API tokens in software portal"
            ]
        },
        {
            version: "1.9",
            notes: [
                "Add Multi-Element Sensor (MES) Features",
                "Add Short-Delay-Line Sensor (DL1) Features",
                "Add Sensor icons to distinguish standard, MES and DL1 sensors",			
                "Dropdown to select region in advanced settings instead of having to enter URL",
                "Add version update prompt and server connect state",
                "Add Search Feature in iDART RDC Configuration Channel Setup to Sensors",
                "Migrate Scan RDC features to RDC Device Manager page",
                "Fixed: Sensors with an empty RFID can be added in",
                "Fixed: Sensor search breaks recalculation screen",
                "Fixed: Selecting a sensor in the new RDC sensor selection UI chooses the wrong sensor in a group",
                "Fixed: In Gateway Manager, the message that says \"No Data\" when there's no gateways doesn't appear",
                "Fixed: The Nominal thickness doesn't convert when changing from Metric to Imperial"
            ]
        },
        {
            version: "1.9.5",
            notes: [
                "Added reading ID to data extraction logs for improved traceability",
                "Fixed: RDC reading length calculation algorithm - RDC reading trim length calculation",
                "Fixed: Resolved RFID selection dropdown issues",
                "Fixed: Enhanced cache management with restore functionality and debug logging",
                "Fixed: Duplicated chirp parameters for B2R, B5R, DL1 and S5R",
                "Fixed: Implemented protection against stack overflow errors by checking for self referencing ServerGroups",
                "Fixed: Reading analysis randomly triggers out of memory error",
                "Fixed: Optimized device communication queue processing"
            ]
        }
    ];
}

export function getLatestReleaseNotes() {
    return getReleaseNotes().reverse()[0];
}
