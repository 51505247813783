import * as React from "react";
import { FunctionComponent, useState, useEffect } from "react";
import UpdateSvg from "Graphics/Icons/UpdateSvg.svg";
import NoNetworkSvg from "Graphics/Icons/NoNetworkSvg.svg";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Services from "../Services/Platform/Services";
import * as GeneralCGI from "Services/Electron/GeneralCGI";

const StatusBar: FunctionComponent = () => {
    const [isUpdate, setIsUpdate] = useState(false);
    const [serverState, setServerState] = useState(true);

    useEffect(() => {
        setInterval(async () => {
            setIsUpdate(await GeneralCGI.isUpdateDownloaded() || false);
            setServerState(Services.isServerState());
        }, 200);
    });

    const [isDisplayUpdateAndServer, setIsDisplayUpdateAndServer] = useState(true);

    if (!(isDisplayUpdateAndServer && (isUpdate || !serverState))) return null;

    return <div style={{
        background: "#F9EDE7",
        height: "35px",
        lineHeight: "35px",
        fontSize: "13px",
        padding: "0 10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "relative"
    }}
    >
        {isUpdate &&
            <div style={{ display: "flex", alignItems: "center" }}>
                <UpdateSvg style={{ width: "15px", height: "15px" }} />
                <div style={{ marginLeft: "10px" }}>
                    An updated version of iDART is available and will be installed at the next launch.
                </div>
            </div>
        }
        <div style={{ position: "absolute", right: "10px", display: "flex", alignItems: "center" }}>
                {!serverState &&
                    <>
                        <NoNetworkSvg style={{ width: "20px", height: "20px", marginRight: "5px" }} />
                        <Tooltip title="No connection to the Inductosense cloud services" arrow placement="bottom">
                            <div>Server Disconnected</div>
                        </Tooltip>
                    </>
                }
                <div>
                    <IconButton onClick={() => setIsDisplayUpdateAndServer(false)}>
                        <CloseIcon style={{ width: "20px", height: "20px", }} />
                    </IconButton>
                </div>
            </div>

        {/* <ConnectSvg style={{width:"20px",height:"20px",marginLeft:isUpdate?"20px":"10px",marginRight:"5px",}} /> 
                                        : <DisconnectSvg style={{width:"20px",height:"20px",marginLeft:isUpdate?"20px":"10px",marginRight:"5px",}} />                               */}
    </div>;
}

export default StatusBar;
