import * as React from "react";
import { FunctionComponent } from "react";

interface IntervalPickerProps {
    valueSeconds: number | null;
    prefix?: string;
}

const IntervalDisplay: FunctionComponent<IntervalPickerProps> = ({ prefix = "every ", valueSeconds }) => {
    if (valueSeconds === null) return null;

    const units = {
        "days": 60 * 60 * 24,
        "hours": 60 * 60,
        "minutes": 60,
        "seconds": 1
    };

    const unit = Object.entries(units).filter(unit => valueSeconds >= unit[1])[0];
    const text = `${prefix}${valueSeconds / unit[1]} ${unit[0]}`;

    return <span style={{ "verticalAlign": "middle" }}>{text}</span>;
}

export default IntervalDisplay;
