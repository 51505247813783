import * as React from "react";
import { FunctionComponent } from "react";
import ModalPanel from "../Panels/ModalPanel";
import CreateDC from "./CreateDC";
import { Device } from "@inductosense/typescript-fetch";

interface CreateDCModalPanelProps {
    shouldBeShown: boolean;
    onCancel(): void;
    onSave(): void;
    currentDevices: Device[];
    unitsMode: "metric" | "imperial";
}

const CreateDCModalPanel: FunctionComponent<CreateDCModalPanelProps> = ({ shouldBeShown, onCancel, onSave, currentDevices, unitsMode }) => {
    return (
        <ModalPanel
            shouldBeShown={shouldBeShown}
            title="Create HDC"
            content={<CreateDC onSave={onSave} onCancel={onCancel} currentDevices={currentDevices} unitsMode={unitsMode} />}
            onClose={onCancel}
            floatingActionButton
        />
    );
};

export default CreateDCModalPanel;
