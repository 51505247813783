import * as React from "react";
import { FunctionComponent } from "react";
import { formatNumberWithThousandsPlaceSeparators } from "../../Utilities/Formatting";

interface CorrosionRateTextProps {
    unitsMode: "imperial" | "metric";
    corrosionRateInMMY: number | null | undefined;
    tooltip?: string;
    noCorrosionText?: string;
}

const CorrosionRateText: FunctionComponent<CorrosionRateTextProps> = ({ corrosionRateInMMY, unitsMode, tooltip, noCorrosionText = "No corrosion" }) => {
    if (corrosionRateInMMY === null || corrosionRateInMMY === undefined) return null;
    if (corrosionRateInMMY >= 0) return <span>{noCorrosionText}</span>;

    if (unitsMode === "imperial") {
        const corrosionRateInInchesY = corrosionRateInMMY / 25.4;  //Unit [inch / year]
        const corrosionRateInMilY = corrosionRateInInchesY * 10e3; //Unit [mil / year]


        return <span title={tooltip}>
            {formatNumberWithThousandsPlaceSeparators(-corrosionRateInMilY.toFixed(2))}mil/y
        </span>;
    } else {
        return <span title={tooltip}>
            {formatNumberWithThousandsPlaceSeparators(-corrosionRateInMMY.toFixed(2))}mm/y
        </span>;
    }
}

export default CorrosionRateText;
