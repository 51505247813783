import { AbsoluteZeroInDegreesCelsius } from "Constants/Temperatures";
import NumberIsGreaterThanMinimumValidator from "Validation/Numeric/NumberIsGreaterThanMinimumValidator";
import StringRepresentsARealNumberOrNothingValidator from "Validation/Strings/StringRepresentsARealNumberOrNothingValidator";
import Validator from "Validation/Validator";

export default class StringRepresentsATemperatureInDegreesCelsiusAboveAbsoluteZeroOrNothingValidator implements Validator<string> {
    constructor(readonly errorMessage = `Must be above absolute zero (${AbsoluteZeroInDegreesCelsius})`) { }

    isValid(value: string | null | undefined) {
        const isANumberOrNothingValidator = new StringRepresentsARealNumberOrNothingValidator();
        const isGreaterThanAbsoluteZeroValidator = new NumberIsGreaterThanMinimumValidator(AbsoluteZeroInDegreesCelsius);

        if (value === null || value === undefined || value === "") return true;
        if (!isANumberOrNothingValidator.isValid(value)) return false;

        return isGreaterThanAbsoluteZeroValidator.isValid(+value);
    }
}
