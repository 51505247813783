import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import { Metadatasummary, Robotreading } from "@inductosense/typescript-fetch";
import Services from "Services/Platform/Services";
import { CircularProgress } from "@mui/material";
import Button from "../Components/Buttons/Button";

enum ActiveView {
    loading,
    error,
    details,
    saving,
    deleting,
}

interface RDCUploadDetailModalPanelProps {
    reading: Robotreading
    onClose(action?: { saved?: boolean, deleted?: boolean }): void;
}

interface RDCUploadDetailModalPanelState {
    view: ActiveView
    readings: Metadatasummary[]
    errorMessage: string
    loadError: boolean
}

export default class RDCUploadDetailModalPanel extends Component<RDCUploadDetailModalPanelProps, RDCUploadDetailModalPanelState> {
    constructor(props: RDCUploadDetailModalPanelProps) {
        super(props);

        this.state = {
            view: ActiveView.loading,
            readings: [],
            errorMessage: "An unknown error as occurred",
            loadError: false
        };
    }

    async componentDidMount() {
        await Services.UploadedReadings.uploadedReadingDetail(this.props.reading.id)
            .then(readings => {
                if (readings.length == 0) {
                    this.setState({ view: ActiveView.error, errorMessage: "Loaded file is empty", loadError: true })
                } else {
                    this.setState({ view: ActiveView.details, readings });
                }
            })
            .catch(() => {
                this.setState({ view: ActiveView.error, errorMessage: "Unable to load file", loadError: true })
            });
    }

    render() {
        return (
            <ModalPanel
                title={this.state.view == ActiveView.error ? "Error" : "RDC Upload Details"}
                shouldBeShown={true}
                onClose={() => this.props.onClose()}
                content={this.detail()}
            />
        );
    }

    private detail() {

        if (this.state.view == ActiveView.error) {
            return <>
                <div style={{ "textAlign": "center" }}>
                    {this.state.errorMessage}
                </div>
                {this.state.loadError ? this.buttons(true) : ""}
            </>
        }
        
        if (this.state.view == ActiveView.loading) {
            return this.loading("Loading file")
        }

        if (this.state.view == ActiveView.saving) {
            return this.loading("Saving readings from file")
        }

        if (this.state.view == ActiveView.deleting) {
            return this.loading("Deleting from file")
        }

        const sorted = this.state.readings.sort((a, b) => {
            return b.timestamp.getTime() - a.timestamp.getTime();
        })

        const serialNumbersInFile = [...new Set(this.state.readings.map(r => r.serialNumber))]
        const rfidsInFile = [...new Set(this.state.readings.map(r => r.rfid))]
        const firstTimestamp = sorted[0].timestamp
        const lastTimestamp = sorted[sorted.length - 1].timestamp

        return <>
            <div style={{ margin: "0.5rem 0" }}>
                <b style={{ display: "inline-block", width: "7.5rem" }}>File Name:</b>
                {this.props.reading.fileName}
            </div>
            <div style={{ margin: "0.5rem 0" }}>
                <b style={{ display: "inline-block", width: "7.5rem" }}>Count:</b>
                {this.state.readings.length} Reading{this.state.readings.length != 1 ? "s" : ""}
            </div>
            <div style={{ margin: "0.5rem 0" }}>
                <b style={{ display: "inline-block", width: "7.5rem" }}>Timestamp{firstTimestamp != lastTimestamp ? "s" : ""}:</b>
                {firstTimestamp.toLocaleString()}{firstTimestamp != lastTimestamp ? " – " + lastTimestamp.toLocaleString() : ""}
            </div>
            <div style={{ margin: "0.5rem 0" }}>
                {serialNumbersInFile.map(serialNumber => {
                    return <>
                        <div>
                            <b style={{ display: "inline-block", width: "7.5rem" }}>
                                {serialNumbersInFile[0] == serialNumber ? `Device${serialNumbersInFile.length != 1 ? "s" : ""}:` : ""}
                            </b>
                            {serialNumber}
                        </div>
                    </>
                })}
            </div>
            <div style={{ margin: "0.5rem 0" }}>
                {rfidsInFile.map(rfid => {
                    return <>
                        <div>
                            <b style={{ display: "inline-block", width: "7.5rem" }}>
                                {rfidsInFile[0] == rfid ? `Sensor Id${rfidsInFile.length != 1 ? "s" : ""}:` : ""}
                            </b>
                            {rfid}
                        </div>
                    </>
                })}
            </div>
            {this.buttons()}
        </>
    }

    private loading(message: string) {
        return <>
            <div style={{ "textAlign": "center" }}>
                <CircularProgress />
                <div style={{ marginTop: "1rem", display: "flex", flexDirection: "column", gap: "0.65rem" }}>
                    <div>{message}</div>
                    <div>{this.props.reading.fileName}</div>
                </div>
            </div>
        </>
    }

    private buttons(deleteOnly: boolean = false) {
        return <>
            <div style={{ display: "flex", gap: "1rem", marginTop: "2rem", justifyContent: "end" }}>
                {!deleteOnly ? this.saveFile() : ""}
                {Services.userHasPolicy("DeleteUploadedReadings") ? this.deleteFile() : ""}
            </div>
        </>
    }

    private saveFile() {
        return <>
            <Button
                label="Save Readings"
                precedence="primary"
                className="submitButton"
                onClick={() => {
                    if (confirm("Import all readings from this file?")) {
                        this.setState({ view: ActiveView.saving })
                        Services.UploadedReadings.saveUploadedReading(this.props.reading.id)
                            .then(() => {
                                this.props.onClose({ saved: true })
                            })
                            .catch(async (reason) => {
                                this.setState({ view: ActiveView.error, errorMessage: await reason.text() })
                            })
                    }
                }}
            />
        </>
    }

    private deleteFile() {
        return <>
            <Button
                label="Delete File"
                precedence="secondary"
                onClick={() => {
                    if (confirm("Permanently delete this file and all it's readings") && confirm("Are you absolutely sure?")) {
                        this.setState({ view: ActiveView.deleting })
                        Services.UploadedReadings.deleteUploadedReading(this.props.reading.id)
                            .then(() => {
                               this.props.onClose({ deleted: true })
                            })
                            .catch(() => {
                                this.setState({ view: ActiveView.error })
                            })
                    }
                }}
            />
        </>
    }
}
