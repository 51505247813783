import * as React from "react";
import { FunctionComponent } from "react";

interface TemperatureTextProps {
    temperatureUnits: "celsius" | "fahrenheit";
    temperatureInCelsius: number | null | undefined;
}

const TemperatureText: FunctionComponent<TemperatureTextProps> = ({ temperatureInCelsius, temperatureUnits }) => {
    if (temperatureInCelsius === null || temperatureInCelsius === undefined) return null;

    if (temperatureInCelsius === 32767) return <>N/A</>;
    if (temperatureInCelsius === -32767) return <>N/A</>;

    if (temperatureUnits === "fahrenheit") {
        return <>{(temperatureInCelsius * 9 / 5) + 32}°F</>;
    } else {
        return <>{temperatureInCelsius}°C</>;
    }
}

export default TemperatureText;
