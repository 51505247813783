import * as React from "react";
import { Component, CSSProperties } from "react";
import { FormControl, OutlinedInput } from "@mui/material";
import ElevatedPanel from "Panels/Composite/ElevatedPanel";
import SensorGroupComponent from "Components/Sensors/SensorGroup";
import { Sensorgroup, Sensor } from "@inductosense/typescript-fetch";
import SensorGroup from "Model/SensorGroup";
import SortButton from "Components/Buttons/SortButton";


const sensorsContainerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 550,
    height: 430
};

interface SensorSelectPanelProps {
    selectedIds: string[];
    onSensorSelect(ids: string[], sensors: Sensor[]): void;
    rootSensorGroup?: Sensorgroup;
    multiSelect?: true | undefined;
    allowedRfidPrefixes?: string[];
    fastMultiSelectButtonsEnabled?: true | undefined;
}

interface SensorSelectPanelState {
    filterArray?: SensorGroup;
    searchTerm?: string;
    openArray: boolean;
    intData?: SensorGroup;
    rankState: boolean;
}

export default class SensorSelectPanel extends Component<SensorSelectPanelProps, SensorSelectPanelState> {
    constructor(props: SensorSelectPanelProps) {
        super(props);
        this.state={
            openArray: false,
            rankState: false,
        }
    }

    componentDidMount(){
        this.setState({intData: this.props.rootSensorGroup})
    }

    filterData(data: SensorGroup, searchItem:string) {
        if (data !== null) {
             if (data.sensors && Array.isArray(data.sensors)) {
                for (let i = data.sensors.length - 1; i >= 0; i--) {
                    if (!data.sensors[i].description?.toLowerCase().includes(searchItem.toLowerCase()) && !(data.sensors[i].rfid?.toLowerCase().includes(searchItem.toLowerCase()))) {
                        data.sensors.splice(i, 1);
                    }
                }
            }
            if (data.children && Array.isArray(data.children)) {
                for (let i = data.children.length - 1; i >= 0; i--) {
                    if(!data.children[i].name?.toLowerCase().includes(searchItem.toLowerCase())){
                        this.filterData( data.children[i], searchItem );
                    }
                        if (!data.children[i].name?.toLowerCase().includes(searchItem.toLowerCase()) && 
                            data.children[i].children?.length === 0 && 
                            data.children[i].sensors?.length === 0) {
                            data.children.splice(i, 1);
                        }
                }
            }
        }
        this.setState({filterArray: data})
    }

    searchFunction (searchItem: string) {
        const copyData = JSON.parse(JSON.stringify(this.props.rootSensorGroup));
        copyData && this.filterData( copyData, searchItem);
    }

    addSensorsToLookup (sensorGroup: SensorGroup,rankState: boolean) {
        sensorGroup.sensors && sensorGroup.sensors?.sort((a, b) => (a.description || "").localeCompare((b.description || "")) * (rankState? -1 : 1));
        sensorGroup.children && sensorGroup.children?.sort((a, b) => (a.name || "").localeCompare((b.name || "")) * (rankState? -1 : 1)).forEach(group => this.addSensorsToLookup(group,rankState));
        return sensorGroup;
    }

    sensorSort(){
        const rankState = !this.state.rankState;
        if(this.state.openArray && this.state.filterArray){
            const sortData = this.addSensorsToLookup(this.state.filterArray,rankState)
            this.setState({filterArray:sortData})
        }else if(!this.state.openArray && this.state.intData){
            const sortData = this.addSensorsToLookup(this.state.intData,rankState)
            this.setState({intData:sortData})
        }
        this.setState({rankState:rankState});
    }

    render() {
        if (this.props.rootSensorGroup == undefined) {
            return null;
        }

        const { openArray, intData, rankState, searchTerm } = this.state;

        return (
            <div style={sensorsContainerStyle}>
                <ElevatedPanel
                    searchRow={<div style={{marginBottom:"10px"}}>
                        <FormControl style={{ width:"90%" }}>
                            <OutlinedInput 
                                placeholder="Search for Sensor Groups or Sensors" 
                                type={"search"}
                                color="primary" 
                                sx={{
                                    height:"34px", 
                                    marginRight:"20px",
                                    "& .MuiOutlinedInput-notchedOutline":{
                                        borderColor:" #ec6f41",
                                    }
                                }} 
                                onChange={(e) => {
                                    this.setState({ searchTerm: e.target.value })
                                    if (e.target.value == "" ) {
                                        this.setState({openArray: false})
                                    } else {
                                        this.searchFunction(e.target.value);
                                        this.setState({openArray: true})
                                    }
                                }}
                                style={{ fontSize:"14px", border:"1px solid #EC6F41" }}
                            />
                        </FormControl>
                        <SortButton sortOrder={rankState ? "desc":"asc"} onToggle={()=>this.sensorSort()}/>
                    </div>
                    }
                    content={(
                        !openArray &&  intData? 
                        <SensorGroupComponent
                            data={intData}
                            isExpanded={false}
                            multiSelect={this.props.multiSelect}
                            selectedIds={this.props.selectedIds}
                            onSelectedIdsChange={this.props.onSensorSelect.bind(this)}
                            allowedRfidPrefixes={this.props.allowedRfidPrefixes}
                            fastMultiSelectButtonsEnabled={this.props.fastMultiSelectButtonsEnabled}
                        /> : openArray && this.state.filterArray ? 
                        <SensorGroupComponent
                            searchItem={searchTerm}
                            isExpanded={true}
                            data={ this.state.filterArray }
                            multiSelect={this.props.multiSelect}
                            selectedIds={this.props.selectedIds}
                            onSelectedIdsChange={this.props.onSensorSelect.bind(this)}
                            allowedRfidPrefixes={this.props.allowedRfidPrefixes}
                            fastMultiSelectButtonsEnabled={this.props.fastMultiSelectButtonsEnabled}
                        /> : <div></div>
                    )}
                />
            </div>
        );
    }
}
