import * as React from "react";
import { CSSProperties, FunctionComponent, ReactNode } from "react";
import { TextAlignment } from "Types/TextAlignment";
import merge from "Utilities/Merge";

const defaultButtonsPosition: TextAlignment = "right";
const spaceBetweenButtons = 15;

const footerStyle: CSSProperties = { display: "flex" };

interface ButtonsFormFooterProps {
    buttons: ReactNode[];
    buttonsPosition?: TextAlignment;
}

const ButtonsFormFooter: FunctionComponent<ButtonsFormFooterProps> = ({ buttons, buttonsPosition }) => {
    let justifyContent: string;
    let buttonStyle: CSSProperties;

    switch (buttonsPosition ?? defaultButtonsPosition) {
        case "left": {
            justifyContent = "flex-start";
            buttonStyle = { marginRight: spaceBetweenButtons };

            break;
        }

        case "centre": {
            justifyContent = "center";
            buttonStyle = { margin: `0 ${spaceBetweenButtons / 2}px` };

            break;
        }

        case "right": {
            justifyContent = "flex-end";
            buttonStyle = { marginLeft: spaceBetweenButtons };

            break;
        }

        default: throw new Error("Unexpected text alignment encountered.");
    }

    return (
        <div style={merge(footerStyle, { justifyContent })}>
            {buttons.map((button, index) => (
                <div key={index} style={buttonStyle}>
                    {button}
                </div>
            ))}
        </div>
    );
};

export default ButtonsFormFooter;
