import { Readingdetailed } from "@inductosense/typescript-fetch";
import { deserialiseBase64PayloadData } from "./Deserialisation";
import { NumberOfPresamplesBeforeTimeZero } from "../Constants/DateAndTime";
import * as D3 from "d3";
import { getNumericValue } from "../Types/CartesianSpace";

const defaultSamplesPerMicrosecond = 56.25;

export function GetFirstPeakAmplitude(reading: Readingdetailed) {

    const peaksInWindow = reading.calculatedPeaks
        .filter(a => reading.parameters?.gateLower === undefined || a.timeInSeconds > reading.parameters.gateLower)
        .filter(a => reading.parameters?.gateUpper === undefined || a.timeInSeconds < reading.parameters.gateUpper);

    const topPeaksInWindow = peaksInWindow.sort((a, b) => b.amplitude - a.amplitude);

    if (topPeaksInWindow.length > 0) return topPeaksInWindow[0].amplitude;
    else return null;
}

export function GetReadingSamples(reading: Readingdetailed) {
    return deserialiseBase64PayloadData(reading.payload.data, reading.payload.bitDepth);
}

export function GetReadingSamplesPerMicrosecond(reading: Readingdetailed) {
    return reading.payload.samplingFrequencyInHertz !== null
        ? reading.payload.samplingFrequencyInHertz * (10 ** -6) : defaultSamplesPerMicrosecond;
}

export function GetReadingPoints(reading: Readingdetailed, normalisationFactor = 1) {
    return GetReadingSamples(reading).map((sample, index) => ({
        x: (index - NumberOfPresamplesBeforeTimeZero) / GetReadingSamplesPerMicrosecond(reading),
        y: sample * normalisationFactor
    }));
}

export function GetReadingClips(reading: Readingdetailed, clipXScaleAtValue?: number, clipYScaleAtValue?: number) {
    const points = GetReadingPoints(reading);

    const xMinimum = 0;
    const yMinimumWithoutClipping = D3.min(points, p => p.y) as number;
    const yMinimum = clipYScaleAtValue !== null && clipYScaleAtValue !== undefined
        ? Math.max(-clipYScaleAtValue, yMinimumWithoutClipping)
        : yMinimumWithoutClipping;

    const xMaximumWithoutClipping = D3.max(points, p => p.x) as number;
    const xMaximum = clipXScaleAtValue !== null && clipXScaleAtValue !== undefined
        ? Math.min(clipXScaleAtValue, getNumericValue(xMaximumWithoutClipping))
        : xMaximumWithoutClipping;

    const yMaximumWithoutClipping = D3.max(points, p => p.y) as number;
    const yMaximum = clipYScaleAtValue !== null && clipYScaleAtValue !== undefined
        ? Math.min(clipYScaleAtValue, yMaximumWithoutClipping)
        : yMaximumWithoutClipping;

    return {
        xMinimum,
        yMinimum,
        xMaximum,
        yMaximum
    };
}
