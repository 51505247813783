// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --alert-information-background-blue: #e8f4fd;
    --alert-information-foreground-blue: #2196f3;
    --alert-information-text-blue: #0d3c61;

    --alert-warning-background-amber: #fff4e5;
    --alert-warning-foreground-amber: #ff9800;
    --alert-warning-text-amber: #663c00;

    --alert-error-background-red: #fdecea;
    --alert-error-foreground-red: #f44336;
    --alert-error-text-red: #611a15;

    --alert-success-background-green: #edf7ed;
    --alert-success-foreground-green: #4caf50;
    --alert-success-text-green: #1e4620;
}
`, "",{"version":3,"sources":["webpack://./Styling/Palette/AlertColours.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,4CAA4C;IAC5C,sCAAsC;;IAEtC,yCAAyC;IACzC,yCAAyC;IACzC,mCAAmC;;IAEnC,qCAAqC;IACrC,qCAAqC;IACrC,+BAA+B;;IAE/B,yCAAyC;IACzC,yCAAyC;IACzC,mCAAmC;AACvC","sourcesContent":[":root {\n    --alert-information-background-blue: #e8f4fd;\n    --alert-information-foreground-blue: #2196f3;\n    --alert-information-text-blue: #0d3c61;\n\n    --alert-warning-background-amber: #fff4e5;\n    --alert-warning-foreground-amber: #ff9800;\n    --alert-warning-text-amber: #663c00;\n\n    --alert-error-background-red: #fdecea;\n    --alert-error-foreground-red: #f44336;\n    --alert-error-text-red: #611a15;\n\n    --alert-success-background-green: #edf7ed;\n    --alert-success-foreground-green: #4caf50;\n    --alert-success-text-green: #1e4620;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
