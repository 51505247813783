import * as React from "react";
import { Component } from "react";
import { FormSection } from "Types/Forms";
import Form from "Forms/Form";
import { CSSProperties } from "@mui/material/styles/createTypography";
import DropDownList from "Components/Input/DropDownList";
import Button from "Components/Buttons/Button";
import WandConnectionService from "Services/Electron/WandConnectionService";
import SubSeaUISettings from "./RRCUISettings";
import RRCThicknessAlgorithm from "./RRCThicknessAlgorithm";
import TextFieldControlled from "Components/Input/TextFieldControlled";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "Components/Buttons/RadioButton";

const footerStyle: CSSProperties = {
    marginTop: 20,
    display: "flex",
    justifyContent: "flex-end"
};

interface RRCSettingsProps {
    uiSettings: SubSeaUISettings;
    uiSettingsChange(newSettings: SubSeaUISettings): void;
}

interface RRCSettingsState {
    minThicknessEmpty: boolean;
    amplitudeThresholdEmpty: boolean;
    temperatureEmpty: boolean;
}

export default class RRCSettingsForm extends Component<RRCSettingsProps, RRCSettingsState> {
    wandConnectionService = new WandConnectionService();

    constructor(props: RRCSettingsProps) {
        super(props);

        this.state = {
            minThicknessEmpty: false,
            amplitudeThresholdEmpty: false,
            temperatureEmpty: false,
        };
    }

    render() {
        return <Form sections={this.sections()} footer={this.footer()} />;
    }

    footer() {
        return <div style={footerStyle}>
            <Button
                label="Open config directory"
                onClick={() => this.wandConnectionService.RRCOpenDirectory()}
            />
        </div>;
    }

    private sections() {
        return [
            this.peakDetSection(),
            this.tempSection(),
            this.scansAtATimeSection(),
            this.signalProcessingSection(),
            this.minThicknessSection(),
            this.amplitudeThresholdSection()
        ];
    }

    private peakDetSection(): FormSection {
        const { uiSettings, uiSettingsChange } = this.props;
        return {
            marginTop: 15,
            label: { text: "Peak Det", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <DropDownList<RRCThicknessAlgorithm>
                        options={[RRCThicknessAlgorithm.PeakToPeak, RRCThicknessAlgorithm.FirstArrival]}
                        labelFor={o => RRCThicknessAlgorithm[o]}
                        initiallySelectedOption={uiSettings.thicknessAlgorithm}
                        onChange={newSelectedOption => uiSettingsChange({
                            ...uiSettings,
                            ...{ thicknessAlgorithm: newSelectedOption }
                        })}
                        width={150}
                    />
                )
            }
        };
    }

    private signalProcessingSection(): FormSection {
        const { uiSettings, uiSettingsChange } = this.props;

        return {
            marginTop: 15,
            label: { text: "Signal Processing", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <RadioGroup
                        name="signlProcessing"
                        onChange={(_e, selected) => uiSettingsChange({
                            ...uiSettings,
                            ...selected === "automatic" ? { isAutomatic: true }
                                : { isAutomatic: false, amplitudeThreshold: 100, minThicknessMm: 5 }
                        })}
                        value={uiSettings.isAutomatic ? "automatic" : "manual"}
                    >
                        <RadioButton
                            option="automatic"
                            label="Automatic"
                            isDisabled={false}
                        />
                        <RadioButton
                            option="manual"
                            label="Manual"
                            isDisabled={false}
                        />
                    </RadioGroup>


                )
            }
        };
    }

    private minThicknessSection(): FormSection {
        const { uiSettings, uiSettingsChange } = this.props;
        const { minThicknessEmpty } = this.state;
        return {
            marginTop: 15,
            label: { text: "Min Thickness", isRequired: true, marginTop: 5 },
            isDisabled: uiSettings.isAutomatic,
            control: {
                isEditable: true,
                node: (
                    uiSettings.isAutomatic ?
                    <TextFieldControlled
                        type="number"
                        width={150}
                        value=""
                        disabled
                        suffix="mm"
                    /> :
                    <TextFieldControlled
                        type="number"
                        width={150}
                        value={minThicknessEmpty ? "" : uiSettings.minThicknessMm}
                        onChange={event => {
                            uiSettingsChange({
                                ...uiSettings,
                                ...{ minThicknessMm: parseFloat(event.target.value) || 1 }
                            });
                            this.setState({ minThicknessEmpty: event.target.value === "" });
                        }}
                        onBlur={() => this.setState({ minThicknessEmpty: false })}
                        suffix="mm"
                    />
                )
            }
        };
    }

    private tempSection(): FormSection {
        const { uiSettings, uiSettingsChange } = this.props;
        const { temperatureEmpty } = this.state;
        return {
            marginTop: 15,
            label: { text: "Temperature", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        type="number"
                        width={150}
                        value={temperatureEmpty ? "" : uiSettings.temperatureDegreesCelsius}
                        onChange={event => {
                            uiSettingsChange({
                                ...uiSettings,
                                ...{ temperatureDegreesCelsius: parseFloat(event.target.value) || 1 }
                            });
                            this.setState({ temperatureEmpty: event.target.value === "" });
                        }}
                        onBlur={() => this.setState({ temperatureEmpty: false })}
                        suffix="°C"
                    />
                )
            }
        };
    }

    private scansAtATimeSection(): FormSection {
        const { uiSettings, uiSettingsChange } = this.props;
        const { temperatureEmpty } = this.state;
        return {
            marginTop: 15,
            label: { text: "Scans at a time", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        type="number"
                        width={150}
                        value={temperatureEmpty ? "" : uiSettings.scansAtATime}
                        onChange={event => {
                            uiSettingsChange({
                                ...uiSettings,
                                ...{ scansAtATime: parseFloat(event.target.value) }
                            });
                        }}
                    />
                )
            }
        };
    }

    private amplitudeThresholdSection(): FormSection {
        const { uiSettings, uiSettingsChange } = this.props;
        const { amplitudeThresholdEmpty } = this.state;
        return {
            marginTop: 15,
            label: { text: "Amplitude Threshold", isRequired: true, marginTop: 5 },
            isDisabled: uiSettings.isAutomatic,
            control: {
                isEditable: true,
                node: (
                    uiSettings.isAutomatic ?
                    <TextFieldControlled
                        type="number"
                        width={150}
                        value=""
                        disabled
                    /> :
                    <TextFieldControlled
                        type="number"
                        width={150}
                        value={amplitudeThresholdEmpty ? "" : uiSettings.amplitudeThreshold.toString()}
                        onChange={event => {
                            uiSettingsChange({
                                ...uiSettings,
                                ...{ amplitudeThreshold: parseFloat(event.target.value) || 1 }
                            });
                            this.setState({ amplitudeThresholdEmpty: event.target.value === "" });
                        }}
                        onBlur={() => this.setState({ amplitudeThresholdEmpty: false })}
                    />
                )
            }
        };
    }
}
