import Validator from "Validation/Validator";

export default class StringContainsOnlyPrintableASCIICharsValidator implements Validator<string> {
    constructor(readonly errorMessage = "Must contain only printable ASCII characters") { }

    isValid(value: string | null | undefined) {
        if (value === null || value === undefined) return true;
        return /^[ -~]+$/.test(value);
    }
}
