import * as React from "react";
import { FunctionComponent, useState } from "react";
import { Sensorreadertype } from "@inductosense/typescript-fetch";
import Services from "Services/Platform/Services";
import SaveAndCancelButtonsFormFooter from "../Forms/Shared/SaveAndCancelButtonsFormFooter";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import { Buffer } from "buffer/";

const footerStyle: React.CSSProperties = {
    marginTop: 16
}

interface CreateRDCProps {
    onSave(): void;
    onCancel(): void;
}

interface RDCDetails {
    serialNumber: number;
    macAddress: number[];
    clientKeyHex: string;
    deviceKeyHex: string;
}

const CreateRDC: FunctionComponent<CreateRDCProps> = ({ onSave, onCancel }) => {
    const [rdcDetails, setRdcDetails] = useState<RDCDetails | null>(null);

    const [isSaving, setIsSaving] = useState(false);

    const parseReport = (report: string) => {
        const lines = report.split(/\r|\n/);
        const fields = new Map<string, string>();

        for (const line of lines) {
            const parts = line.split(": ");

            if (parts.length > 1) {
                fields.set(parts[0].toLowerCase(), parts[1]);
            }
        }

        return fields;
    };

    const toHex = (input: number[]) => Array.from(input, b => ("0" + (b & 0xFF).toString(16)).slice(-2)).join("-").toUpperCase();

    return (
        <div>
            <input type="file" accept=".txt" onChange={c => {
                const reader = new FileReader();
                reader.onload = () => {
                    const text = reader.result as string;
                    const data = parseReport(text);
                    console.log("data", data);

                    setRdcDetails({
                        serialNumber: parseInt(data.get("device serial number") || "0"),
                        macAddress: [...Buffer.from(data.get("mac address")?.replace(/-/g, "") || "", "hex")].reverse(),
                        clientKeyHex: data.get("client key") || "",
                        deviceKeyHex: data.get("device key") || ""
                    });
                };
                if (c.target.files !== null && c.target.files.length > 0) {
                    reader.readAsText(c.target.files[0]);
                }
            }} />

            {
                rdcDetails !== null ?
                    <>
                        <Table size="small" style={{ marginTop: 15 }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Serial number</TableCell>
                                        <TableCell>{rdcDetails.serialNumber}</TableCell>
                                </TableRow>
                                <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>{toHex(rdcDetails.macAddress)}</TableCell>
                                </TableRow>
                                <TableRow>
                                        <TableCell>MAC address</TableCell>
                                        <TableCell>{toHex(rdcDetails.macAddress)}</TableCell>
                                </TableRow>
                                <TableRow>
                                        <TableCell>Client key</TableCell>
                                        <TableCell>{rdcDetails.clientKeyHex}</TableCell>
                                </TableRow>
                                <TableRow>
                                        <TableCell>Device key</TableCell>
                                        <TableCell>{rdcDetails.deviceKeyHex}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <div style={footerStyle}>
                            <SaveAndCancelButtonsFormFooter
                                isSaving={isSaving}
                                onSaveButtonClick={async () => {
                                    setIsSaving(true);

                                    const rdcDefaultSystemDelay = -4.4064e-07;

                                    await Services.Devices.devicesPost({
                                        macAddress: rdcDetails.macAddress,
                                        readerType: Sensorreadertype.RemoteDataCollector,
                                        systemDelayInSeconds: rdcDefaultSystemDelay,
                                        clientKey: [...Buffer.from(rdcDetails.clientKeyHex.replace("0x", ""), "hex")],
                                        deviceKey: [...Buffer.from(rdcDetails.deviceKeyHex.replace("0x", ""), "hex")],
                                        description: toHex(rdcDetails.macAddress),
                                        serialNumber: rdcDetails.serialNumber.toString()
                                    });

                                    setIsSaving(false);
                                    onSave();
                                }}
                                onCancelButtonClick={() => onCancel()}
                            />
                        </div>
                    </>
                : null
            }
        </div>
    );
};

export default CreateRDC;
