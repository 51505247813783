import * as React from "react";
import { Component } from "react";
import { Gateway } from "@inductosense/typescript-fetch";
import ModalPanel from "Panels/ModalPanel";
import UiSettings from "../../../Model/UiSettings";
import RDCDeviceManagerForm from "RDC/RDCDeviceManagerForm";
import SensorGroup from "Model/SensorGroup";
import Services from "Services/Platform/Services";

interface GatewayDevicesModalPanelProps {
    onDbUploadRequired(): Promise<void>;
    gateway?: Gateway;
    shouldBeShown: boolean;
    onClose(): void;
    uiSettings: UiSettings;
}

interface GatewayDevicesModalPanelState {
    isSaving: boolean;
    rootSensorGroup?: SensorGroup;
}

export default class GatewayDevicesModalPanel
    extends Component<GatewayDevicesModalPanelProps, GatewayDevicesModalPanelState> {

    constructor(props: GatewayDevicesModalPanelProps) {
        super(props);

        this.state = {
            isSaving: false
        };
    }

    async componentDidMount() {
        this.setState({
            rootSensorGroup: await Services.SensorGroups.getRootSensorGroup()
        });
    }

    render() {
        const { onClose, shouldBeShown, gateway: gateway } = this.props;
        const { isSaving } = this.state;

        return (
            <ModalPanel
                title={`Gateway Name: ${gateway?.displayName}`}
                shouldBeShown={shouldBeShown}
                onClose={() => !isSaving && onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        const { gateway, uiSettings } = this.props;
        const { rootSensorGroup } = this.state;

        if (rootSensorGroup === undefined) return <p>Loading...</p>;

        return (
            <RDCDeviceManagerForm
                onDbUploadRequired={this.props.onDbUploadRequired}
                isGateway={true}
                deviceIds={gateway?.activeDeviceIds}
                uiSettings={uiSettings}
                rootSensorGroup={rootSensorGroup}
            />
        );
    }
}
