import * as MaterialUi from "@mui/material";
import * as React from "react";
import { Component } from "react";
import { RelativePlacement } from "Types/RelativePlacement";
import Plane from "Types/Plane";

interface RadioButtonGroupProps<T> {
    options: T[];
    initiallySelectedOption?: T;
    disabledOptions?: T[];
    orientation?: Plane;
    labelFor(option: T): string;
    labelPositions?: RelativePlacement;
    isDisabled?: boolean;
    onChange(newSelectedOption: T): void;
}

interface RadioButtonGroupState<T> {
    selectedOption: T | null;
}

export default class RadioButtonGroup<T> extends Component<RadioButtonGroupProps<T>, RadioButtonGroupState<T>> {
    static readonly defaultProps = { orientation: "vertical" };

    constructor(props: RadioButtonGroupProps<T>) {
        super(props);
        this.state = { selectedOption: null };
    }

    render() {
        const { initiallySelectedOption, options } = this.props;

        return (
            <MaterialUi.Select
                defaultValue={JSON.stringify(initiallySelectedOption)}
                onChange={e => { this.onChange(e.target.value),console.log(e.target.value,"newSelectedSerialisedOption") }}
                style={{ height:  "40px",width:"200px" }}
            >
                {options.map(option => this.item(option))}
            </MaterialUi.Select>
        );
    }

    private item(option: T) {
        return (
            <MaterialUi.MenuItem
                key={JSON.stringify(option)}
                value={JSON.stringify(option)}
            >
                {this.props.labelFor(option)}
            </MaterialUi.MenuItem>
        );
    }

    private onChange(newSelectedSerialisedOption: string) {
        const deserialisedOption: T = JSON.parse(newSelectedSerialisedOption);
        this.props.onChange(deserialisedOption);
        this.setState({ selectedOption: deserialisedOption });
    }
}
