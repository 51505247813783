import * as React from "react";
import { Component } from "react";
import Button from "../Components/Buttons/Button";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Link } from "@mui/material";
import { Sensor, Dcdeployment, Dcdeploymentpost, Dcuser, Dcdevicedeployments } from "@inductosense/typescript-fetch";
import DeleteIconButton from "../Components/Buttons/Composite/DeleteIconButton";
import SensorSelectModalPanel from "../Panels/Composite/Modals/SensorSelectModalPanel";
import SensorGroup from "../Model/SensorGroup";
import Services from "Services/Platform/Services";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import UserSelectModalPanel from "./UserSelectModalPanel";
import DropDownButton from "../Components/Buttons/DropDownButton";
import { HasPermittedPrefix } from "../Utilities/RfidUtils";

dayjs.extend(utc);

interface CreateDcDeploymentProps {
    rootSensorGroup: SensorGroup;
    initialDeployment: Dcdeployment | null;
    deviceId: string;
    onCreateClick(
        dcDeployment: Dcdeploymentpost
    ): void;
    dcDeviceDeployments: Dcdevicedeployments[];
}

interface CreateDcDeploymentState {
    sensorIds: string[];
    userIds: string[];
    sensorSelectModalPanelShown: boolean;
    userSelectModalPanelShown: boolean;
    allDcUsers: Dcuser[];
    menuAnchor: null | HTMLElement;
}

const allowedRfidPrefixes = ["EEE0", "EEE1"]; // EEE1 is high temp sensor

export default class CreateDcDeployment extends Component<CreateDcDeploymentProps, CreateDcDeploymentState> {
    constructor(props: CreateDcDeploymentProps) {
        super(props);

        this.state = {
            sensorIds: props.initialDeployment?.sensors.filter(s => HasPermittedPrefix(s.rfid, allowedRfidPrefixes)).map(s => s.id) || [],
            userIds: props.initialDeployment?.dcUsers.map(u => u.id) || [],
            sensorSelectModalPanelShown: false,
            userSelectModalPanelShown: false,
            allDcUsers: [],
            menuAnchor: null
        };
    }

    async componentDidMount() {
        const dcUsers = await Services.DcUsersService.getUsers();

        this.setState({
            allDcUsers: dcUsers
        });
    }

    render() {
        const { userIds } = this.state;

        return (
            <div style={{ width: 650 }}>
                <DropDownButton<Dcdevicedeployments>
                    options={this.props.dcDeviceDeployments}
                    label="Add sensors/users from different device"
                    labelFor={d => d.device.serialNumber || d.device.id}
                    optionSelected={option => {
                        this.setState({
                            sensorIds: option.latestDeployment?.sensors.map(s => s.id) || [],
                            userIds: option.latestDeployment?.dcUsers.map(u => u.id) || [],
                            menuAnchor: null
                        });
                    }}
                />

                {this.sensorsTable()}
                {this.usersTable()}

                <div style={{ marginTop: 10 }}>
                    {userIds.length === 0 ?
                        <>
                            <p style={{ color: "red" }}>Please add at least one user</p>
                            <Button
                                label="Create"
                                isDisabled={true}
                                onClick={() => null}
                            />
                        </> :
                        <Button
                            label="Create"
                            onClick={() => this.props.onCreateClick({
                                deviceId: this.props.deviceId,
                                sensorIds: this.state.sensorIds,
                                dcUserIds: this.state.userIds
                            })}
                        />
                    }
                </div>
                <SensorSelectModalPanel
                    multiSelect={true}
                    okLabel="Add"
                    hideNoSensorOption={true}
                    shouldBeShown={this.state.sensorSelectModalPanelShown}
                    onClose={() => this.setState({ sensorSelectModalPanelShown: false })}
                    onSensorSelect={sensors => this.sensorsSelected(sensors)}
                    rootSensorGroup={this.props.rootSensorGroup}
                    allowedRfidPrefixes={allowedRfidPrefixes}
                />
                <UserSelectModalPanel
                    shouldBeShown={this.state.userSelectModalPanelShown}
                    onClose={() => this.setState({ userSelectModalPanelShown: false })}
                    onAddUsers={newUserIds => {
                        this.setState({
                            userIds: [...this.state.userIds, ...newUserIds],
                            userSelectModalPanelShown: false
                        });
                    }}
                    userList={this.state.allDcUsers.filter(u => !this.state.userIds.includes(u.id))}
                />
            </div>
        );
    }

    private sensorsSelected(sensors: Sensor[]) {
        const { sensorIds } = this.state; // todo: fix error message selecting sensor for port

        for (const sensor of sensors) {
            sensorIds.push(sensor.id);
        }

        this.setState({ sensorIds: [...new Set(sensorIds)] });
    }

    private showSensor(sensorId: string, sensorGroup: SensorGroup): React.JSX.Element[] | null {
        // TODO: Separate finding sensor from rendering?
        for (const sensor of sensorGroup.sensors || []) {
            if (sensorId === sensor.id) {
                return [
                    <TableCell key="description" style={{ textOverflow: "ellipsis", width: 210 }}>{sensor.description}</TableCell>,
                    <TableCell key="rfid">{sensor.rfid || "No RFID"}</TableCell>
                ];
            }
        }

        for (const subGroup of sensorGroup.children || []) {
            const subGroupResult = this.showSensor(sensorId, subGroup);
            if (subGroupResult !== null) return subGroupResult;
        }

        return null;
    }

    private usersTable() {
        return <TableContainer style={{ maxHeight: 180, marginTop: 20 }}>
            <Table size="small" stickyHeader style={{ tableLayout: "fixed" }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ textOverflow: "ellipsis", width: 190 }}>User</TableCell>
                        <TableCell style={{ width: 190 }}>RFID</TableCell>
                        <TableCell style={{ width: 80 }}>
                            <Link href="#" onClick={() => this.setState({ userIds: [] })}>
                                Delete All
                            </Link>
                        </TableCell>
                        <TableCell>
                            <Link href="#" onClick={() => this.setState({ userSelectModalPanelShown: true })}>
                                Add
                            </Link>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.allDcUsers
                        .filter(u => this.state.userIds.includes(u.id))
                        .map(u => {
                            return <TableRow key={u.id}>
                                <TableCell style={{ textOverflow: "ellipsis", width: 190 }}>{u.username}</TableCell>
                                <TableCell>{u.rfid}</TableCell>
                                <TableCell>
                                    <DeleteIconButton
                                        onClick={() => this.removeUser(u.id)}
                                        size="small"
                                    />
                                </TableCell>
                                <TableCell></TableCell>
                        </TableRow>;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    }

    private sensorsTable() {
        return <TableContainer style={{ maxHeight: 180, marginTop: 20 }}>
            <Table size="small" stickyHeader style={{ tableLayout: "fixed" }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: 190 }}>Sensor</TableCell>
                        <TableCell style={{ width: 190 }}>RFID</TableCell>
                        <TableCell style={{ width: 80 }}>
                            <Link href="#" onClick={() => {
                                this.setState({
                                    sensorIds: []
                                });
                            }}>Delete All</Link>
                        </TableCell>
                        <TableCell>
                            <Link href="#" onClick={() => {
                                this.setState({
                                    sensorSelectModalPanelShown: true
                                });
                            }}>Add</Link>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.sensors()}
                </TableBody>
            </Table>
        </TableContainer>;
    }

    private sensors() {
        const { sensorIds } = this.state;

        return <>
            {sensorIds.map(sensorId => {
                const cells = this.showSensor(sensorId, this.props.rootSensorGroup);

                return cells != null ? <TableRow key={sensorId}>
                    {...cells}
                    <TableCell>
                        <DeleteIconButton
                            onClick={() => this.removeSensor(sensorId)}
                            size="small"
                        />
                    </TableCell>
                    <TableCell></TableCell>
                </TableRow> : null
            })}
        </>;
    }

    private removeSensor(sensorId: string) {
        const sensorIds = this.state.sensorIds.filter(i => sensorId != i);
        this.setState({ sensorIds });
    }

    private removeUser(userId: string) {
        const userIds = this.state.userIds.filter(i => userId != i);
        this.setState({ userIds });
    }
}
