import * as React from "react";
import { CSSProperties, FunctionComponent, ReactNode } from "react";
import { TextAlignment } from "Types/TextAlignment";
import merge from "Utilities/Merge";
import PanelHeading from "Components/Text/PanelHeading";

const actionButtonWidth = 48;
const spaceBetweenTitleAndActionButton = 11;

const containerStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    position: "relative",
};

const actionButtonContainerStyle: CSSProperties = {
    position: "absolute",
    right: 0,
    top: 0,
    background: "white",
}

const commonTitleStyle: CSSProperties = {
    flex: 1,
    marginLeft: spaceBetweenTitleAndActionButton
};

const titleStyleWhenActionButtonIsPresentAndTitleIsCentred: CSSProperties = {
    marginLeft: actionButtonWidth + spaceBetweenTitleAndActionButton,
    marginRight: spaceBetweenTitleAndActionButton
};

interface PanelTitleBarProps {
    isDevice?: boolean;
    isNewStyleTitle?: boolean;
    title?: string;
    titleAlignment?: TextAlignment;
    actionButton?: ReactNode;
    floatingActionButton?: boolean;
    size: "regular" | "small";
}

const PanelTitleBar: FunctionComponent<PanelTitleBarProps> = ({ actionButton, title, titleAlignment, floatingActionButton = false, size, isNewStyleTitle, isDevice }) => {
    const titleStyle = actionButton && titleAlignment === "centre"
        ? merge(commonTitleStyle, titleStyleWhenActionButtonIsPresentAndTitleIsCentred)
        : commonTitleStyle;

    const SensorContainerStyle: CSSProperties = {
        display: "flex",
        alignItems: "center",
        position:  "relative",
        width: "100%",
        height:"50px",
        paddingTop: "10px",
    };

    const topNavStyle: CSSProperties = {
        display: "flex",
        alignItems: "center",
        position: "fixed",
        top: "4px" ,
        left: "0px",
        zIndex: "6px",
        width: "100%",
        height: "80px",
        background:  "#212843",
    };

    console.log(SensorContainerStyle,"SensorContainerStyle", isDevice,"isDevice")


    return (
        <div style={ isDevice ?  topNavStyle : isNewStyleTitle ? SensorContainerStyle  : containerStyle}>
            {title && (
                <div style={titleStyle}>
                    <PanelHeading text={title} alignment={titleAlignment} isNewStyleTitle={isNewStyleTitle} size={size} />
                </div>
            )}
            <div style={floatingActionButton ? actionButtonContainerStyle : {display: "flex",alignItems: "center"}}>{actionButton}</div>
        </div>
    );
};

export default PanelTitleBar;
