export enum AuthenticationState {
    Started = "Started", //Started the service
    Initial = "Initial",    // Starting Authentication

    Authenticating = "Authenticating", // While Authenticating
    Authenticated = "Authenticated", // Should be now authenitcated

    Reauthenticating = "Reauthenticating",   // Reauthentation using Token Exchange
    Reauthenticated = "Reauthenticated",   // Reauthentation using Token Exchange



    Unauthenticated = "Unauthenticated", // Unauthenticated for some reason
}

export class AuthenticationStateMachine {
    private _state: AuthenticationState = AuthenticationState.Initial;
    private _observers: Array<(state: AuthenticationState) => void> = [];

    get getCurrentState() {
        return this._state;
    }

    transitionTo(newState: AuthenticationState) {
        this._state = newState;
        this._notify(newState);
    }

    private _notify(state: AuthenticationState) {
        this._observers.forEach(observer => observer(state));
    }

    subscribe(observer: (state: AuthenticationState) => void) {
        this._observers.push(observer);
        return () => { // returns an unsubscribe function
            const index = this._observers.indexOf(observer);
            if (index !== -1) {
                this._observers.splice(index, 1);
            }
        };
    }
}
