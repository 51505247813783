import * as React from "react";
import { Component } from "react";
import EditSensorGroupForm from "Forms/Composite/EditSensorGroupForm";
import ModalPanel from "Panels/ModalPanel";
import SensorGroup from "Model/SensorGroup";
import Services from "Services/Platform/Services";

interface EditSensorGroupModalPanelProps {
    sensorGroup: SensorGroup;
    shouldBeShown: boolean;
    onClose(): void;
    onSave(): void;
}

interface EditSensorGroupModalPanelState {
    isSaving: boolean;
}

export default class EditSensorGroupModalPanel extends Component<EditSensorGroupModalPanelProps, EditSensorGroupModalPanelState> {
    constructor(props: EditSensorGroupModalPanelProps) {
        super(props);
        this.state = { isSaving: false };
    }

    render() {
        const { sensorGroup } = this.props;

        return (
            <ModalPanel
                title={sensorGroup.rfidIfMES ? "Edit MES" : "Edit sensor group"}
                titleAlignment="centre"
                shouldBeShown={this.props.shouldBeShown}
                onClose={() => this.onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        const { name, rfidIfMES } = this.props.sensorGroup;
        const { isSaving } = this.state;

        if (!name) throw new Error("Sensor group name should never be undefined here.");

        return (
            <EditSensorGroupForm
                currentName={name}
                rfidIfMES={rfidIfMES}
                isSaving={isSaving}
                onClose={() => this.onClose()}
                onSave={newName => this.onSave(newName)}
            />
        );
    }

    private onClose() {
        if (!this.state.isSaving) this.props.onClose();
    }

    private async onSave(newName: string) {
        const { onSave, sensorGroup } = this.props;

        this.setState({ isSaving: true });

        await Services.SensorGroups.updateSensorGroup(sensorGroup, { name: newName });
        await onSave();

        this.setState({ isSaving: false });
    }
}
