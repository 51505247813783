import * as React from "react";
import AxisLimits from "./AxisLimits";
import { FormControlLabel, FormGroup } from "@mui/material";
import TextFieldControlled from "Components/Input/TextFieldControlled";

interface AxisLimitFormProps {
    limits: AxisLimits;
    onLimitChange(newLimits: AxisLimits): void;
}

export default function AxisLimitForm(props: AxisLimitFormProps) {
    const { limits, onLimitChange } = props;

    return (
        <FormGroup row>
            <FormControlLabel
                label="X limit:"
                labelPlacement="start"
                control={
                    <TextFieldControlled
                        type="number"
                        suffix="mm"
                        style={{ width: 80, marginLeft: 5, marginRight: 40 }}
                        value={limits.xLimitMicroseconds || ""}
                        onChange={change => onLimitChange({
                            xLimitMicroseconds: parseInt(change.currentTarget.value) || undefined,
                            yLimitMV: limits.yLimitMV
                        })}
                        inputProps={{ min: 0 }}
                    />
                }
            />
            <FormControlLabel
                label="Y limit:"
                labelPlacement="start"
                control={
                    <TextFieldControlled
                        type="number"
                        style={{ width: 80, marginLeft: 5, marginRight: 40 }}
                        value={limits.yLimitMV || ""}
                        onChange={change =>
                            onLimitChange({
                                xLimitMicroseconds: limits.xLimitMicroseconds,
                                yLimitMV: parseInt(change.target.value) || undefined
                            })
                        }
                        inputProps={{ min: 0 }}
                    />
                }
            />
        </FormGroup>
    );
}
