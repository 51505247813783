import * as MaterialUi from "@mui/material";
import * as React from "react";
import { Component, ReactElement } from "react";
import OnOrOff from "Types/OnOrOff";

const defaultState: OnOrOff = "off";

interface SwitchWithLabelProps {
    labelWhenOn?: string;
    labelWhenOff?: string;
    initialState?: OnOrOff;
    isDisabled?: boolean;
    onChange(newValue: OnOrOff): void;
}

interface SwitchWithLabelState {
    isOnOrOff: OnOrOff;
}

export default class SwitchWithLabel extends Component<SwitchWithLabelProps, SwitchWithLabelState> {
    constructor(props: SwitchWithLabelProps) {
        super(props);
        this.state = { isOnOrOff: props.initialState || defaultState };
    }

    render() {
        return <div className="switchWithLabel">{this.content()}</div>;
    }

    private content() {
        const switchElement = this.switch();
        if (this.props.labelWhenOn && this.props.labelWhenOff) return this.label(switchElement);
        return switchElement;
    }

    private switch() {
        return (
            <MaterialUi.Switch
                checked={this.state.isOnOrOff === "on"}
                disabled={this.props.isDisabled}
                onChange={(_, newValue) => this.onChange(newValue ? "on" : "off")}
            />
        );
    }

    private label(switchElement: ReactElement) {
        return (
            <MaterialUi.FormControlLabel
                label={this.state.isOnOrOff === "on" ? this.props.labelWhenOn : this.props.labelWhenOff}
                disabled={this.props.isDisabled}
                control={switchElement}
            />
        );
    }

    private onChange(newValue: OnOrOff) {
        this.props.onChange(newValue);
        this.setState({ isOnOrOff: newValue });
    }
}
