import * as React from "react";
import { FunctionComponent } from "react";
import { Deploymentlogcode } from "@inductosense/typescript-fetch";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";

interface DeploymentLogIconProps {
    codes: Deploymentlogcode[];
    withLabel?: boolean;
}

const DeploymentLogIcon: FunctionComponent<DeploymentLogIconProps> = ({ codes, withLabel }) => {
    if (codes.includes(Deploymentlogcode.Success)) {
        return <><CheckCircleOutline style={{ verticalAlign: "middle" }} /> {withLabel ? <span>Applied</span> : null}</>;
    }

    return <><ErrorOutline style={{ verticalAlign: "middle", color: "red" }} /> {withLabel ? <span>Error</span> : null}</>;
};

export default DeploymentLogIcon;
