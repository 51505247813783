import * as React from "react";
import * as Unicode from "Constants/UnicodeCharacters";
import { Component, CSSProperties } from "react";
import { Material } from "@inductosense/typescript-fetch";
import AddIcon from "Components/Graphics/Icons/AddIcon";
import Button from "Components/Buttons/Button";
import CloseButton from "Components/Buttons/Composite/CloseButton";
import IconButton from "Components/Buttons/IconButton";
import CreateOrEditMaterialModalPanel from "Views/Materials/Modals/CreateOrEditMaterialModalPanel";
import SimplePanel from "Panels/SimplePanel";
import Services from "Services/Platform/Services";
import Table from "Components/Tables/Table";
import TableColumn from "Types/TableColumn";
import EditIcon from "../../Components/Graphics/Icons/EditIcon";
import UiSettings from "../../Model/UiSettings";
import VelocityText from "../../Components/Text/VelocityText";
import ThermalCoefficientText from "../../Components/Text/ThermalCoefficientText";
import { Delete } from "@mui/icons-material";

const containerStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
};

const addButtonContainerStyle: CSSProperties = { margin: "20px" };

interface MaterialsListPanelProps {
    materials: Material[] | null;
    onClose(): void;
    onMaterialsChange(): void;
    uiSettings: UiSettings;
}

interface MaterialsListPanelState {
    selectedMaterial: Material | null;
    isCreateModalShown: boolean;
    isEditModalShown: boolean;
}

export default class MaterialsListPanel extends Component<MaterialsListPanelProps, MaterialsListPanelState> {
    private readonly tableColumns: TableColumn<Material>[];

    constructor(props: MaterialsListPanelProps) {
        super(props);

        this.tableColumns = [
            {
                id: "name",
                titleRenderer: () => "Material name",
                cellRenderer: material => this.nameCell(material)
            },
            {
                id: "longitudinalVelocity",
                titleRenderer: () => `Longitudinal velocity @${Unicode.NonBreakingSpace}${this.getTemperatureForVelocities()}`,
                cellRenderer: material => this.longitudinalVelocityCell(material)
            },
            {
                id: "shearVelocity",
                titleRenderer: () => `Shear velocity @${Unicode.NonBreakingSpace}${this.getTemperatureForVelocities()}`,
                cellRenderer: material => this.shearVelocityCell(material)
            },
            {
                id: "thermalCoefficientAlpha",
                titleRenderer: () => `Thermal coefficient (${Unicode.Alpha})`,
                cellRenderer: material => this.thermalCoefficientAlphaCell(material)
            },
            {
                id: "edit",
                titleRenderer: () => "Edit",
                cellRenderer: material => this.editCell(material)
            },
            {
                id: "delete",
                titleRenderer: () => "Delete",
                cellRenderer: material => this.deleteCell(material)
            }
        ];

        this.state = {
            selectedMaterial: null,
            isCreateModalShown: false,
            isEditModalShown: false
        };
    }

    render() {
        return (
            <div style={containerStyle}>
                <SimplePanel
                    title="Materials"
                    titleAlignment="centre"
                    actionButton={this.closeButton()}
                    content={this.table()}
                    shouldApplyPadding={false}
                />
                {this.addButton()}
                {this.createModal()}
                {this.editModal()}
            </div>
        );
    }

    private addButton() {
        return (
            <div style={addButtonContainerStyle}>
                <Button
                    label="Add custom material"
                    isProhibited={!Services.userHasPolicy("AddMaterials")}
                    precedence="primary"
                    icon={<AddIcon />}
                    onClick={() => this.setState({ isCreateModalShown: true })}
                />
            </div>
        );
    }

    private getTemperatureForVelocities() {
        if (this.props.uiSettings.unitsMode === "imperial") {
            return `77${Unicode.NonBreakingSpace}${Unicode.Degrees}F`;
        } else {
            return `25${Unicode.NonBreakingSpace}${Unicode.Degrees}C`;
        }
    }

    private closeButton() {
        return <CloseButton onClick={() => this.props.onClose()} />;
    }

    private createModal() {
        return (
            <CreateOrEditMaterialModalPanel
                shouldBeShown={this.state.isCreateModalShown}
                onClose={() => {
                    this.setState({
                        isCreateModalShown: false
                    });
                    this.props.onMaterialsChange();
                }}
                uiSettings={this.props.uiSettings}
            />
        );
    }

    private editModal() {
        return (
            <CreateOrEditMaterialModalPanel
                material={this.state.selectedMaterial || undefined}
                shouldBeShown={this.state.isEditModalShown}
                onClose={() => {
                    this.setState({
                        isEditModalShown: false
                    });
                    this.props.onMaterialsChange();
                }}
                uiSettings={this.props.uiSettings}
            />
        );
    }

    private table() {
        const { materials } = this.props;

        return (
            <Table
                columns={this.tableColumns}
                rows={ materials ? materials : []}
                minimumHeaderHeight={57}
                getKey={row => row.id}
            />
        );
    }

    private deleteCell(material: Material) {
        if (material.isPreset) return null;
        return <IconButton
            icon={<Delete />}
            onClick={() => this.onDeleteButtonClick(material)}
            isProhibited={!Services.userHasPolicy("DeleteMaterials")}
        />;
    }

    private editCell(material: Material) {
        if (material.isPreset) return null;
        return <IconButton
            icon={<EditIcon />}
            isProhibited={!Services.userHasPolicy("ChangeMaterials")}
            onClick={() => this.onEditButtonClick(material)}
        />;
    }

    private nameCell(material: Material) {
        return material.name;
    }

    private longitudinalVelocityCell(material: Material) {
        return <VelocityText unitsMode={this.props.uiSettings.unitsMode} velocityInMetresPerSecond={material.longitudinalVelocityAtRoomTemperatureInMetresPerSecond} />;
    }

    private shearVelocityCell(material: Material) {
        return <VelocityText unitsMode={this.props.uiSettings.unitsMode} velocityInMetresPerSecond={material.shearVelocityAtRoomTemperatureInMetresPerSecond} />;
    }

    private thermalCoefficientAlphaCell(material: Material) {
        const { temperatureUnits } = this.props.uiSettings;

        return <ThermalCoefficientText
            temperatureUnits={temperatureUnits}
            thermalExpansionCoefficientPerDegreeCelsius={material.thermalExpansionCoefficientPerDegreeCelsius}
        />;
    }

    private async onDeleteButtonClick(material: Material) {
        await Services.Materials.deleteMaterial(material.id);
        this.props.onMaterialsChange();
    }

    private onEditButtonClick(material: Material) {
        this.setState({
            selectedMaterial: material,
            isEditModalShown: true
        });
    }
}
