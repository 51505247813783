import * as MaterialUiIcons from "@mui/icons-material";
import * as React from "react";
import { FunctionComponent } from "react";
import { CSSProperties } from "@mui/material/styles/createTypography";

interface BatteryLevelIndicatorProps {
    levelInPercent: number;
}

const BatteryLevelIndicator: FunctionComponent<BatteryLevelIndicatorProps> = ({ levelInPercent }) => {
    if (levelInPercent < 0 || levelInPercent > 100) throw new Error("Level in percent must be between 0 and 100.");

    const iconStyle: CSSProperties = {
        verticalAlign: "middle"
    };

    if (levelInPercent <= 10) return <MaterialUiIcons.BatteryAlert style={iconStyle} />;
    if (levelInPercent <= 20) return <MaterialUiIcons.Battery20 style={iconStyle} />;
    if (levelInPercent <= 30) return <MaterialUiIcons.Battery30 style={iconStyle} />;
    if (levelInPercent <= 50) return <MaterialUiIcons.Battery50 style={iconStyle} />;
    if (levelInPercent <= 60) return <MaterialUiIcons.Battery60 style={iconStyle} />;
    if (levelInPercent <= 80) return <MaterialUiIcons.Battery80 style={iconStyle} />;
    if (levelInPercent <= 90) return <MaterialUiIcons.Battery90 style={iconStyle} />;
    if (levelInPercent <= 100) return <MaterialUiIcons.BatteryFull style={iconStyle} />;

    throw new Error("Unexpected level value.");
}

export default BatteryLevelIndicator;
