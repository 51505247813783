import * as React from "react";
import { Component } from "react";
import SimplePanel from "../Panels/SimplePanel";
import Services from "../Services/Platform/Services";
import CloseButton from "../Components/Buttons/Composite/CloseButton";
import Button from "../Components/Buttons/Button";
import SensorSelectPanel from "../Panels/Composite/Primary/SensorSelectPanel";
import { Sensor } from "@inductosense/typescript-fetch";
import SensorGroup from "../Model/SensorGroup";
import ButtonsFormFooter from "../Forms/Shared/ButtonsFormFooter";

interface RecalculationDashboardProps {
    onClose(): void;
}

interface RecalculationDashboardState {
    isRecalculating?: boolean;
    rootSensorGroup?: SensorGroup;
    selectedSensors: Sensor[];
    status?: string[];
}

export default class RecalculationDashboard extends Component<RecalculationDashboardProps, RecalculationDashboardState> {
    constructor(props: RecalculationDashboardProps) {
        super(props);

        this.state = {
            isRecalculating: false,
            selectedSensors: []
        };
    }

    async componentDidMount() {
        const rootSensorGroup = await Services.SensorGroups.getRootSensorGroup();
        this.setState({ rootSensorGroup });
    }

    render() {
        return <SimplePanel
            title="Recalculation Dashboard"
            titleAlignment="centre"
            actionButton={this.closeButton()}
            content={this.content()}
            shouldApplyPadding={false}
        />
    }

    private closeButton() {
        return <CloseButton onClick={() => this.props.onClose()} />;
    }

    content() {
        const { isRecalculating, status, rootSensorGroup, selectedSensors } = this.state;

        return <div style={{ marginLeft: 20 }}>
            {rootSensorGroup !== undefined ?
                <SensorSelectPanel
                    rootSensorGroup={rootSensorGroup}
                    onSensorSelect={(_ids: string[], selectedSensors: Sensor[]) => this.setState({ selectedSensors })}
                    selectedIds={this.state.selectedSensors.map(s => s.id)}
                    multiSelect={true}
                />
                : null
            }

            <div style={{ marginTop: 15 }}>
                <ButtonsFormFooter
                    buttons={[
                        <Button
                            key={1}
                            label="Recalculate Selected"
                            isDisabled={isRecalculating || selectedSensors.length === 0}
                            onClick={this.recalculate.bind(this, false)}
                        />,
                        <div key={2} style={{ display: "none" }}>
                            <Button
                                label="Recalculate All"
                                isDisabled={isRecalculating}
                                onClick={this.recalculate.bind(this, true)}
                            />
                        </div>
                    ]}
                    buttonsPosition="left"
                />
            </div>

            <ul>
                {status?.map((s, index) => <li key={index}>{s}</li>)}
            </ul>
        </div>;
    }

    log(text: string) {
        this.setState({ status: [text] });
    }

    async recalculate(all: boolean) {
        const { selectedSensors } = this.state;

        this.setState({ isRecalculating: true });

        const d = new Date();

        if (all) {
            await Services.SensorReadingsService.postTriggerRecalculation();
        } else {
            for (const [index, sensor] of selectedSensors.entries()) {
                Services.Readings.updateParametersForSensorReadings(sensor.id, [], {});
                this.log(`Created parameters ${index + 1}/${selectedSensors.length}`);
            }
        }

        this.log("Recalculating readings...");

        for (const [index, sensor] of selectedSensors.entries()) {

            try {
                await Services.SensorReadingsService.waitForNewTrendGraph(sensor.id, d);
                this.log(`Checked sensor ${index + 1}/${selectedSensors.length}`);
            }
            catch {
                console.log("caught", sensor); // default sensor
                /* {
                 * rfid: "000000000000000000000000", created: Thu Mar 17 2022 10:25:38 GMT+0000 (Greenwich Mean Time),
                 * description: "Default Sensor", parentGroupId: "1ca2f875-d24b-45dd-8bf0-26a10bd700d4",
                 * id: "8c271d31-e57b-40b0-745e-08da072d38a0", …}*/
            }
        }

        this.setState({ isRecalculating: false });
    }
}
