import * as React from "react";
import { FunctionComponent } from "react";

interface X2Y2RectProps extends React.SVGProps<SVGRectElement> {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

const X2Y2Rect: FunctionComponent<X2Y2RectProps> = ({ x1, y1, x2, y2, ...props }) => {
    return (
        <rect
            x={x1}
            y={y1}
            width={Math.abs(x2 - x1)}
            height={Math.abs(y2 - y1)}
            {...props}
        />
    );
}

export default X2Y2Rect;
