import { Thicknessalgorithm } from "@inductosense/typescript-fetch";

export function thicknessAlgorithmTooltip(input: Thicknessalgorithm | undefined) {
    if (input === Thicknessalgorithm.FirstArrival) {
        return "First arrival";
    } else if (input === Thicknessalgorithm.PeakToPeak) {
        return "Peak to peak";
    }

    return undefined;
}
