import * as React from "react";
import { Component } from "react";
import DisplacementText from "Components/Text/DisplacementText";
import { formatNumberWithThousandsPlaceSeparators } from "Utilities/Formatting";
import ArrayDataItem from "./ArrayDataItem";

interface TableProps {
    thicknessData: ArrayDataItem[][];
    background(value: number): string | undefined;
    chooseThickness: boolean;
    rateOfChangeData: number[][];
    numRows: number;
    numCols: number;
}

export default class ThicknessGrid extends Component<TableProps> {
    constructor(props: TableProps) {
        super(props);
    }


    render() {
        const { thicknessData, numCols, chooseThickness, rateOfChangeData } = this.props;

        return (

            <table style={{ backgroundColor: "#fff", fontSize: "16px", borderCollapse: "collapse", margin: "auto" }}>
                <tbody>
                    {thicknessData.map((row, r) =>
                        <tr key={r}>
                            {
                                row.map((reading, c) =>
                                    <td style={{
                                        border: "1px solid", textAlign: "center", width: `${100 / numCols}%`, padding: "5px 5px",
                                        backgroundColor: chooseThickness ? (reading !== null ? this.props.background(reading.thicknessMm) : "grey") : (rateOfChangeData[r][c] !== null ? this.props.background(rateOfChangeData[r][c]) : "grey")
                                    }}
                                        key={c}
                                    >

                                        {chooseThickness
                                            ? (reading !== null ? <DisplacementText displacementInMetres={reading.thicknessMm / 1000} unitsMode="metric" /> : "-")
                                            : (rateOfChangeData[r][c] !== null ? `${formatNumberWithThousandsPlaceSeparators(+rateOfChangeData[r][c].toFixed(2))}mm/yr`
                                                : "-")
                                        }

                                    </td>
                                )
                            }
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

}
