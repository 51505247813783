import * as React from "react";
import { Component } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab/Tab";
import TabPanel from "Panels/Shared/TabPanel";
import RRCSettingsForm from "./RRCSettingsForm";
import RRCReadingsPanel from "./RRCReadingsPanel";
import RRCUISettings from "./RRCUISettings";
import RRCThicknessAlgorithm from "./RRCThicknessAlgorithm";

interface RRCPanelState {
    value: number;
    uiSettings: RRCUISettings;
}

export default class RRCPanel extends Component<object, RRCPanelState> {
    constructor(props: object) {
        super(props);
        
        this.state = {
            value: 0,
            uiSettings: {
                thicknessAlgorithm: RRCThicknessAlgorithm.FirstArrival,
                temperatureDegreesCelsius: 25,
                scansAtATime: 1,
                isAutomatic: true
            }
        };
    }

    handleChange(_ev: React.ChangeEvent<object>, newValue: number) {
        this.setState({ value: newValue });
    }

    render() {
        const { value, uiSettings } = this.state;

        return (
            <div style={{ backgroundColor: "#fff" }}>
                <Tabs value={value} onChange={this.handleChange.bind(this)}>
                    <Tab label="Main" id="tab-main" />
                    <Tab label="Settings" id="tab-settings" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <RRCReadingsPanel uiSettings={uiSettings} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <RRCSettingsForm
                        uiSettings={uiSettings}
                        uiSettingsChange={newSettings => {
                            console.log("new settings", newSettings);
                            this.setState({ uiSettings: newSettings })
                        }}
                    />
                </TabPanel>
            </div>
        );
    }
}
