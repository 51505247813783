import * as React from "react";
import { FunctionComponent } from "react";
import { formatNumberWithThousandsPlaceSeparators } from "../../Utilities/Formatting";

interface VelocityTextProps {
    unitsMode: "imperial" | "metric";
    velocityInMetresPerSecond: number | null | undefined;
}

const VelocityText: FunctionComponent<VelocityTextProps> = ({ velocityInMetresPerSecond, unitsMode }) => {
    if (velocityInMetresPerSecond === null || velocityInMetresPerSecond === undefined) return null;

    if (unitsMode === "imperial") {
        return <span title={`${formatNumberWithThousandsPlaceSeparators(velocityInMetresPerSecond * 3.2808)} ft/s`}>
            {formatNumberWithThousandsPlaceSeparators(Math.round(velocityInMetresPerSecond * 3.2808))} ft/s
        </span>;
    } else {
        return <span title={`${formatNumberWithThousandsPlaceSeparators(velocityInMetresPerSecond)} m/s`}>
            {formatNumberWithThousandsPlaceSeparators(Math.round(velocityInMetresPerSecond))} m/s
        </span>;
    }
}

export default VelocityText;
