import * as React from "react";
import { Component } from "react";
import CreateSensorGroupForm from "Forms/Composite/CreateSensorGroupForm";
import ModalPanel from "Panels/ModalPanel";
import Services from "Services/Platform/Services";

interface CreateSensorGroupModalPanelProps {
    parentGroupId: string;
    shouldBeShown: boolean;
    onClose(): void;
    onCreate(): void;
}

interface CreateSensorGroupModalPanelState {
    isSaving: boolean;
}

export default class CreateSensorGroupModalPanel extends Component<CreateSensorGroupModalPanelProps, CreateSensorGroupModalPanelState> {
    constructor(props: CreateSensorGroupModalPanelProps) {
        super(props);
        this.state = { isSaving: false };
    }

    render() {
        return (
            <ModalPanel
                title="Create sensor group"
                titleAlignment="centre"
                shouldBeShown={this.props.shouldBeShown}
                onClose={() => this.onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        return (
            <CreateSensorGroupForm
                isSaving={this.state.isSaving}
                onClose={() => this.onClose()}
                onCreate={groupName => this.onCreate(groupName)}
            />
        );
    }

    private onClose() {
        if (!this.state.isSaving) this.props.onClose();
    }

    private async onCreate(groupName: string) {
        const { onCreate, parentGroupId } = this.props;

        this.setState({ isSaving: true });

        await Services.SensorGroups.addSensorGroup(groupName, parentGroupId);
        await onCreate();

        this.setState({ isSaving: false });
    }
}
