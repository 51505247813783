import * as React from "react";
import { Component, CSSProperties, ReactNode } from "react";
import { paddingInsidePanels } from "Styling/Spacing/Padding";
import { TextAlignment } from "Types/TextAlignment";
import merge from "Utilities/Merge";
import PanelTitleBar from "Panels/Shared/PanelTitleBar";

const commonContainerStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
};

const containerWithPaddingStyle: CSSProperties = {
    height: `calc(100% - ${paddingInsidePanels * 2}px)`,
    padding: paddingInsidePanels
};

const containerWithoutPaddingStyle: CSSProperties = {};

const commonContentStyle: CSSProperties = {
    flex: "1 1 0",
};

const contentWithOverflowStyle: CSSProperties = {
    overflow: "auto"
};

const deviceContentCenterStyle: CSSProperties = {
    display: "flex",
    justifyContent: "center",
    minWidth: "1424px",
    marginTop: "80px",
    overflowX: "hidden",
};

const contentWithoutOverflowStyle: CSSProperties = {
    overflow: "hidden"
};

const contentWithMarginAboveStyle: CSSProperties = {
    marginTop: 15
};

interface SimplePanelProps {
    isDevice?: boolean;
    isNewStyleTitle?: boolean;
    title?: string;
    titleAlignment?: TextAlignment;
    searchRow?: ReactNode;
    actionButton?: ReactNode;
    content: ReactNode;
    shouldApplyPadding?: boolean;
    shouldHideContentOverflow?: boolean;
    floatingActionButton?: boolean;
    size: "regular" | "small";
}

export default class SimplePanel extends Component<SimplePanelProps> {
    static readonly defaultProps: Partial<SimplePanelProps> = { shouldApplyPadding: true };

    render() {
        const mergedStyle = this.props.shouldApplyPadding
            ? merge(commonContainerStyle, containerWithPaddingStyle )
            : merge(commonContainerStyle, containerWithoutPaddingStyle);

        return (
            <div style={mergedStyle}>
                {this.titleBar()}
                {this.props.searchRow}
                {this.content()}
            </div>
        );
    }

    private titleBar() {
        const { floatingActionButton } = this.props;

        return (
            <PanelTitleBar
                isDevice={this.props.isDevice}
                isNewStyleTitle={this.props.isNewStyleTitle}
                floatingActionButton={floatingActionButton}
                title={this.props.title}
                titleAlignment={this.props.titleAlignment}
                actionButton={this.props.actionButton}
                size={this.props.size}
            />
        );
    }

    private content() {
        const { actionButton, content, shouldHideContentOverflow, size } = this.props;

        let mergedStyle = shouldHideContentOverflow
            ? merge(commonContentStyle, contentWithoutOverflowStyle)
            : merge(commonContentStyle, this.props.isDevice ? deviceContentCenterStyle: contentWithOverflowStyle);

        if (actionButton && size === "regular") mergedStyle = merge(mergedStyle, contentWithMarginAboveStyle);

        return <div style={mergedStyle}>{content}</div>;
    }
}
