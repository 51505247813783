import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as React from "react";
import { Component } from "react";
import Services from "Services/Platform/Services";
import { Deploymentattemptlog, Device } from "@inductosense/typescript-fetch";
import DeploymentLogIcon from "./DeploymentLogIcon";
import FirstIfNotEmpty from "Utilities/FirstIfNotEmpty";

interface RDCDeploymentLogsState {
    deploymentAttemptLogs?: Deploymentattemptlog[];
    devices?: Device[];
}

export default class RDCDeploymentLogs extends Component<object, RDCDeploymentLogsState> {
    constructor(props: object) {
        super(props);

        this.state = {
        };
    }

    async componentDidMount() {
        const deploymentAttemptLogs = await Services.IoTDeployments.deploymentAttemptLogs();
        const devices = await Services.Devices.getAllDevices();
        this.setState({
            deploymentAttemptLogs,
            devices
        });
    }

    deviceDescription(deviceSerialNumber: string) {
        const { devices } = this.state;
        if (devices === undefined) return null;

        const device = FirstIfNotEmpty(devices.filter(d => d.serialNumber === deviceSerialNumber));
        return device?.description || null;
    }

    render() {
        const { deploymentAttemptLogs } = this.state;
        if (deploymentAttemptLogs === undefined) return null;

        return <>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Device</TableCell>
                                {Services.userHasPolicy("ViewLogTextUi") ? <TableCell>Log Text</TableCell> : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {deploymentAttemptLogs.map((log, index) =>
                            <TableRow key={index}>
                                <TableCell><DeploymentLogIcon codes={[ log.code ]} withLabel={true} /></TableCell>
                                    <TableCell style={{ width: 120 }} title={log.dateTime.toString()}>
                                        {log.dateTime.toDateString()}
                                    </TableCell>
                                    <TableCell style={{ width: 120 }}>
                                        {this.deviceDescription(log.deviceSerialNumber)} ({log.deviceSerialNumber})
                                    </TableCell>
                                    {
                                    Services.userHasPolicy("ViewLogTextUi") ?
                                        <TableCell>
                                            <div style={{ whiteSpace: "pre-line" }}>
                                                {log.logText}
                                            </div>
                                        </TableCell>
                                        : null
                                    }
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
        </>
    }
}
