import * as React from "react";
import { Component, CSSProperties } from "react";
import { LinearOrTimeScale, ScaleOutput } from "Types/D3Extensions";
import { ScaleContinuousNumeric } from "d3";
import { XUnit } from "Types/CartesianSpace";

const boundaryMarkerLength = 15;

interface TimeBoundThresholdLineProps<Range> {
    threshold: number | null;
    startTime: number | null;
    endTime: number | null;
    xMinimum: XUnit;
    xMaximum: XUnit;
    yMinimum: XUnit;
    yMaximum: XUnit;
    xScale: LinearOrTimeScale;
    yScale: ScaleContinuousNumeric<Range, ScaleOutput>;
    style?: CSSProperties;
}

export default class TimeBoundThresholdLine<Range> extends Component<TimeBoundThresholdLineProps<Range>> {
    render() {
        const { endTime, startTime, threshold, xMaximum, xMinimum, xScale, yScale } = this.props;

        const startTimeXCoordinate = xScale(startTime !== null ? startTime : xMinimum);
        const endtimeXCoordinate = xScale(endTime !== null ? endTime : xMaximum);
        const thresholdYCoordinate = threshold !== null ? +yScale(threshold)! : null;

        return (
            <g>
                {this.thresholdLine(thresholdYCoordinate, startTimeXCoordinate, endtimeXCoordinate)}
                {this.startMarker(thresholdYCoordinate, startTimeXCoordinate)}
                {this.endMarker(thresholdYCoordinate, endtimeXCoordinate)}
            </g>
        );
    }

    private thresholdLine(thresholdYCoordinate: number | null, startTimeXCoordinate: number, endtimeXCoordinate: number) {
        if (thresholdYCoordinate === null) return null;

        return (
            <line
                x1={startTimeXCoordinate}
                x2={endtimeXCoordinate}
                y1={thresholdYCoordinate}
                y2={thresholdYCoordinate}
                style={this.props.style}
            />
        );
    }

    private startMarker(thresholdYCoordinate: number | null, startTimeXCoordinate: number) {
        const { startTime, style, yScale } = this.props;
        const yCoordinate = thresholdYCoordinate || +yScale(0)!;

        if (startTime === null) return null;

        return (
            <line
                x1={startTimeXCoordinate}
                x2={startTimeXCoordinate}
                y1={yCoordinate + (boundaryMarkerLength / 2)}
                y2={yCoordinate - (boundaryMarkerLength / 2)}
                style={style}
            />
        );
    }

    private endMarker(thresholdYCoordinate: number | null, endtimeXCoordinate: number) {
        const { endTime, style, yScale } = this.props;
        const yCoordinate = thresholdYCoordinate || +yScale(0)!;

        if (endTime === null) return null;

        return (
            <line
                x1={endtimeXCoordinate}
                x2={endtimeXCoordinate}
                y1={yCoordinate + (boundaryMarkerLength / 2)}
                y2={yCoordinate - (boundaryMarkerLength / 2)}
                style={style}
            />
        );
    }
}
