import { SensorsApi, Trendparameterspost, Sensorpost, Sensorwarningparameters, Trendparameters } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import Sensor from "Model/Sensor";
import SensorType from "Model/SensorType";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";
import sleep from "../../Utilities/Sleep";

export default class SensorsService extends InductosenseService<SensorsApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(SensorsApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async sensorsGet(rfid: string) {
        await this.ensureLatestConfig();
        return this.serverApi.sensorsGet({ rfid });
    }

    @wrapWithErrorHandling()
    async createSensor(sensorpost: Sensorpost) {
        await this.ensureLatestConfig();
        await this.serverApi.sensorsPostRaw({ sensorpost });
    }

    @wrapWithErrorHandling()
    async getSensor(id: string) {
        await this.ensureLatestConfig();
        return this.serverApi!.sensorsIdGet({ id });
    }

    @wrapWithErrorHandling()
    async updateSensor(id: string, sensorpost: Sensorpost) {
        await this.ensureLatestConfig();
        await this.serverApi!.sensorsIdPut({ id, sensorpost });
    }

    @wrapWithErrorHandling()
    async moveSensor(source: Sensor, destinationId: string) {
        await this.ensureLatestConfig();
        const { id, structureMaterial, warningParameters, sensorType, ...sourceProps } = source;
        if (!id) throw new Error("Source sensor ID should never be undefined at this point.");
        await this.serverApi!.sensorsIdPut({
            id, sensorpost: {
                ...sourceProps,
                parentGroupId: destinationId,
                warningParametersId: warningParameters.id,
                sensorTypeId: sensorType.id,
                structureMaterialId: structureMaterial.id
            }
        });
    }

    @wrapWithErrorHandling(30 * 60 * 1000)
    async getMostRecentTrendAnalysesForSensor(sensorId: string) {
        await this.ensureLatestConfig();
        for (let i = 0; i < 5; i++) {
            try {
                return await this.serverApi!.sensorsIdTrendsGet({ id: sensorId, last: 1 });
            }
            catch {
                await sleep(1000);
            }
        }

        throw new Error("req failed"); // todo handle better
    }

    @wrapWithErrorHandling()
    async getAllSensorTypes() {
        await this.ensureLatestConfig();
        const result: SensorType[] = await this.serverApi!.getSensorType();
        if (!result) throw new Error("No sensor types were found in the database.");
        return result;
    }

    @wrapWithErrorHandling(5 * 60 * 1000)
    async getMostRecentTrendAnalysisForAllSensors() {
        await this.ensureLatestConfig();
        return this.serverApi.trendsGet();
    }

    @wrapWithErrorHandling()
    async getAllWarningParameters() {
        await this.ensureLatestConfig();
        return this.serverApi.getSensorWarningParameters();
    }

    @wrapWithErrorHandling()
    async getWarningParameters(id: string) {
        await this.ensureLatestConfig();
        const result: Sensorwarningparameters = await this.serverApi!.getSensorWarningParametersId({ id });
        if (!result) throw new Error("No warning parameters were found for the specified ID.");
        return result;
    }

    @wrapWithErrorHandling()
    async getMostRecentTrendParametersForSensor(sensorId: string) {
        await this.ensureLatestConfig();
        for (let i = 0; i < 5; i++) {
            try {
                const result: Trendparameters[] = await this.serverApi!.sensorsIdTrendsParametersGet({ id: sensorId });
                if (result.length > 1) throw new Error("Only one (or zero) trend parameters object should be returned from the API at this point.");
                return result.length === 1 ? result[0] : null;
            }
            catch {
                await sleep(1000);
            }
        }

        throw new Error("TODO");
    }

    @wrapWithErrorHandling()
    async createNewWarningParametersForSensor(sensor: Sensor, parameters: Partial<Sensorwarningparameters>) {
        await this.ensureLatestConfig();
        if (!sensor.id) throw new Error("The sensor's ID should never be undefined at this point.");

        const response = await this.serverApi!.postSensorWarningParametersRaw({
            sensorwarningparameterspost: {
                description: `User-specified warning parameters for sensor "${sensor.description ?? sensor.id}"`,
                warningThicknessInMetres: parameters.warningThicknessInMetres,
                criticalThicknessInMetres: parameters.criticalThicknessInMetres,
                warningRateInMetresPerYear: parameters.warningRateInMetresPerYear,
                critialRateInMetresPerYear: parameters.critialRateInMetresPerYear
            }
        });

        const locationResponseHeader = response.raw.headers.get("Location");
        const newWarningParametersId = locationResponseHeader?.replace("/v1/sensor-warning-parameters/", "");

        if (!newWarningParametersId) throw new Error("New warning parameters ID should never be undefined at this point.");

        return newWarningParametersId;
    }

    @wrapWithErrorHandling()
    async createNewTrendParametersForSensor(sensor: Sensor, parameters?: Trendparameterspost) {
        if (!sensor.id) throw new Error("The sensor's ID should never be undefined at this point.");
        await this.ensureLatestConfig();
        await this.serverApi!.sensorsIdTrendsParametersPostRaw({
            id: sensor.id,
            trendparameterspost: parameters
        });
    }
}
