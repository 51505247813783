import * as React from "react";
import { Component } from "react";
import Button from "./Button";
import { Menu, MenuItem, ListItemText } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface DropDownButtonProps<T> {
    options: T[];
    optionSelected(option: T): void;
    label: string;
    labelFor(option: T): string;
}

interface DropDownButtonState {
    menuAnchor: null | HTMLElement;
}

export default class DropDownButton<T> extends Component<DropDownButtonProps<T>, DropDownButtonState> {
    constructor(props: DropDownButtonProps<T>) {
        super(props);

        this.state = {
            menuAnchor: null
        };
    }

    render() {
        const { options, optionSelected, label, labelFor } = this.props;

        return (
            <div>
                <Button
                    label={label}
                    onClick={ev => this.setState({ menuAnchor: ev.currentTarget })}
                    variant="outlined"
                />
                <Menu
                    open={this.state.menuAnchor !== null}
                    onClose={() => this.setState({ menuAnchor: null })}
                    anchorEl={this.state.menuAnchor}
                >
                    {options.map((d, index) =>
                        <MenuItem
                            key={index}
                            onClick={() => {
                                this.setState({
                                    menuAnchor: null
                                });

                                optionSelected(d);
                            }}
                        >
                            <ListItemText>{labelFor(d)}</ListItemText>
                        </MenuItem>
                    )}
                </Menu>
            </div>
        );
    }
}
