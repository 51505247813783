import * as React from "react";
import { Component, CSSProperties, ReactNode } from "react";
import { inductosenseWhite } from "Styling/Palette/BrandColours";
import { paddingInsideElevatedPanels } from "Styling/Spacing/Padding";
import { TextAlignment } from "Types/TextAlignment";
import merge from "Utilities/Merge";
import SimplePanel from "Panels/SimplePanel";

const commonContainerStyle: CSSProperties = {
    width: "100%",
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: 5,
    backgroundColor: inductosenseWhite
};

const containerWithPaddingStyle: CSSProperties = {
    padding: paddingInsideElevatedPanels,
    height: `calc(100% - ${paddingInsideElevatedPanels * 2}px)`
};

const containerWithoutPaddingStyle: CSSProperties = {
    height: "100%"
};

interface ElevatedPanelProps {
    title?: string;
    titleAlignment?: TextAlignment;
    actionButton?: ReactNode;
    content: ReactNode;
    searchRow?: ReactNode;
    shouldApplyPadding?: boolean;
    shouldHideContentOverflow?: boolean;
    floatingActionButton?: boolean;
}

export default class ElevatedPanel extends Component<ElevatedPanelProps> {
    static readonly defaultProps: Partial<ElevatedPanelProps> = { shouldApplyPadding: true };

    render() {
        const { actionButton, content, searchRow, shouldApplyPadding, shouldHideContentOverflow, title, titleAlignment,
            floatingActionButton } = this.props;

        const mergedStyle = shouldApplyPadding
            ? merge(commonContainerStyle, containerWithPaddingStyle)
            : merge(commonContainerStyle, containerWithoutPaddingStyle);

        return (
            <div style={mergedStyle}>
                <SimplePanel
                    title={title}
                    titleAlignment={titleAlignment}
                    actionButton={actionButton}
                    content={content}
                    searchRow={searchRow}
                    shouldApplyPadding={false}
                    shouldHideContentOverflow={shouldHideContentOverflow}
                    floatingActionButton={floatingActionButton}
                />
            </div>
        );
    }
}
