import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import { Iotdeployment } from "@inductosense/typescript-fetch";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeploymentLogIcon from "./DeploymentLogIcon";
import Services from "Services/Platform/Services";

interface ShowLogsForDeploymentModalPanelProps {
    iotdeployment: Iotdeployment;
    shouldBeShown: boolean;
    onClose(): void;
}

export default class ShowLogsForDeploymentModalPanel extends Component<ShowLogsForDeploymentModalPanelProps> {
    constructor(props: ShowLogsForDeploymentModalPanelProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { onClose, shouldBeShown } = this.props;

        return (
            <ModalPanel
                title="Deployment logs"
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        const { iotdeployment } = this.props;

        return (
            <div style={{ overflowY: "scroll", height: "80vh" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Date</TableCell>
                                {Services.userHasPolicy("ViewLogTextUi") ? <TableCell>Log Text</TableCell> : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {iotdeployment.logs.map((log, index) =>
                                <TableRow key={index}>
                                    <TableCell><DeploymentLogIcon codes={[ log.code ]} /></TableCell>
                                    <TableCell>
                                        <div style={{ width: 120 }} title={log.dateTime.toString()}>
                                            {log.dateTime.toDateString()}
                                        </div>
                                    </TableCell>
                                    {
                                        Services.userHasPolicy("ViewLogTextUi") ?
                                        <TableCell>
                                            <blockquote>{log.logText}</blockquote>
                                        </TableCell>
                                        : null
                                    }
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}
