import * as React from "react";
import { FunctionComponent } from "react";
import OnOrOff from "Types/OnOrOff";
import Sensor from "Model/Sensor";
//import { Reading } from "@inductosense/typescript-fetch/dist/models/Reading";
import { Device } from "@inductosense/typescript-fetch/dist/models/Device";
import UiSettings from "../../../Model/UiSettings";
import ReadingsListPanelInner from "./ReadingsListPanelInner";
import ReadingTrendPoint from "../../../Model/ReadingTrendPoint";

interface ReadingsListPanelProps {
    sensor: Sensor;
    devices: Device[] | null;
    readings: ReadingTrendPoint[];
    highlightedReading: ReadingTrendPoint | null;
    onHoveredReadingChange(reading: ReadingTrendPoint | null): void;
    onReadingIgnoredStateChange(reading: ReadingTrendPoint, newState: OnOrOff): void;
    onViewAscanButtonClick(reading: ReadingTrendPoint): void;
    onEditButtonClick(reading: ReadingTrendPoint): void;
    uiSettings: UiSettings;
    tabletMode: boolean;
}

const ReadingsListPanel: FunctionComponent<ReadingsListPanelProps> = (props) => {
    const content = () => {
        const { readings, ...otherProps } = props;

        return <ReadingsListPanelInner readings={readings} {...otherProps} />;
    };

    return content();
}

export default ReadingsListPanel;

