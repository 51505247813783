import * as React from "react";

interface CircleTextProps {
    text: string;
    style?: React.CSSProperties;
}

const CircleText: React.FunctionComponent<CircleTextProps> = ({ text, style }) => {
    
    return (
        <div style={{
            display: "inline-block",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "black",
            textAlign: "center",
            ...style
        }}>
            <span style={{ verticalAlign: "middle" }}>{text}</span>
        </div>
    );
}

export default CircleText;
