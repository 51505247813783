import * as D3 from "d3";
import * as React from "react";
import { CartesianCoordinate, PointsInCartesianSpace } from "Types/CartesianSpace";
import { CSSProperties, FunctionComponent } from "react";
import { LinearOrTimeScale } from "Types/D3Extensions";
import { ScaleLinear } from "d3";

interface EnvelopeLineProps {
    points: PointsInCartesianSpace;
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    style?: CSSProperties;
}

const EnvelopeLine: FunctionComponent<EnvelopeLineProps> = ({ points, style, xScale, yScale }) => {
    const { length } = points;

    const absoluteValues = points.map(value => ({
        x: value.x,
        y: Math.abs(value.y)
    }));

    const maximaOnly = absoluteValues.filter((value, index, array) => {
        if (index == length - 1) return false;
        if (value.y > array[index + 1].y) return true;
        return false;
    });

    const maximaOnlyLength = maximaOnly.length;

    const maximaOnlySecondPass = maximaOnly.filter((value, index, array) => {
        if (index == maximaOnlyLength - 1) return false;
        if (value.y > array[index + 1].y) return true;
        return false;
    });

    const maximaOnlySecondPassLength = maximaOnlySecondPass.length;

    const maximaOnlyThirdPass = maximaOnlySecondPass.filter((value, index, array) => {
        if (index == maximaOnlySecondPassLength - 1) return false;
        if (value.y > array[index + 1].y) return true;
        return false;
    });

    const line = D3
        .line<CartesianCoordinate>()
        .x(point => xScale(point.x))
        .y(point => yScale(point.y))
        .curve(D3.curveBasis);

    const pathData = line(maximaOnlyThirdPass) as string;

    return (
        <g>
            <path d={pathData} style={style} />
        </g>
    );
}

export default EnvelopeLine;
