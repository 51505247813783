import * as MaterialUiIcons from "@mui/icons-material";
import * as React from "react";
import * as ReactDnd from "react-dnd";
import { alertErrorForegroundRed, alertWarningForegroundAmber } from "Styling/Palette/AlertColours";
import { FunctionComponent } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import CreateSensorGroupModalPanel from "Panels/Composite/Modals/CreateSensorGroupModalPanel";
import DeleteIconButton from "Components/Buttons/Composite/DeleteIconButton";
import EditSensorGroupModalPanel from "Panels/Composite/Modals/EditSensorGroupModalPanel";
import Sensor from "Model/Sensor";
import SensorGroup from "Model/SensorGroup";
import Services from "Services/Platform/Services";
import UploadSensorsModalPanel from "./Modals/UploadSensorsModalPanel";
import CloudUploadButton from "../../Components/Buttons/Composite/CloudUploadButton";
import { FormControl, OutlinedInput, Button } from "@mui/material";
import FirstIfNotEmpty from "../../Utilities/FirstIfNotEmpty";
import CorrosionRateText from "../../Components/Text/CorrosionRateText";
import DisplacementText from "../../Components/Text/DisplacementText";
import { Sensorgroup, Trendanalysis, Trendperiodtype } from "@inductosense/typescript-fetch";
import DropDownListControlled from "../../Components/Input/DropDownListControlled";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { NodeModel, Tree, TreeMethods } from "@minoru/react-dnd-treeview";
import SimplePanel from "../SimplePanel";
import { CSSProperties } from "@mui/material/styles/createMixins";
import merge from "../../Utilities/Merge";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "../../Components/Buttons/IconButton";
import useDebounce from "../../Components/Input/UseDebounce";
import SortButton from "../../Components/Buttons/SortButton";
import MesSvg from "Graphics/Icons/MesSvg.svg";
import SdlSvg from "Graphics/Icons/SdlSvg.svg"
import SensorSvg from "Graphics/Icons/SensorSvg.svg"


const sensorLabelCompactStyle: CSSProperties = {
    paddingRight: 10,
    flexShrink: 0,
    fontSize: "9pt"
};

const sensorLabelStyle: CSSProperties = {
    ...sensorLabelCompactStyle,
    fontSize: "11pt"
};

interface SensorTreePanelProps {
    rootSensorGroup: SensorGroup;
    selectedNodes: string[];
    latestSensorTrends?: Trendanalysis[];
    onSensorCreateClick(): void;
    onSensorEditClick(sensor: Sensor): void;
    onSensorsSelect(sensors: Sensor[]): void;
    onSensorGroupClick(sensorGroup: SensorGroup): void;
    onSensorTreeChange(): Promise<void>;
    tabletMode: boolean;
    unitsMode: "metric" | "imperial";
}

const SensorTreePanel: FunctionComponent<SensorTreePanelProps> = ({
    onSensorsSelect, onSensorGroupClick, onSensorTreeChange, onSensorCreateClick, rootSensorGroup, latestSensorTrends, tabletMode,
    onSensorEditClick, selectedNodes, unitsMode }) => {

    const [isSensorUploadModalShown, setIsSensorUploadModalShown] = React.useState(false);
    const [isAddModalShown, setIsAddModalShown] = React.useState(false);
    const [isEditModalShown, setIsEditModalShown] = React.useState(false);
    const [flatViewMode, setFlatViewMode] = React.useState(false);
    const [sensorGroupToBeEdited, setSensorGroupToBeEdited] = React.useState<SensorGroup | null>(null);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("asc");

    const showHighestButton = () => {
        return <div style={{ width: 85, display: "inline-block" }} className="Hello"><DropDownListControlled
            isSensorGroups={true}
            options={["Groups", "Highest"]}
            labelFor={l => l}
            selectedOption={flatViewMode ? "Highest" : "Groups"}
            onChange={newOption => setFlatViewMode(newOption === "Highest")}
        /></div>;
    };

    const addGroupButton = () => {
        // using the native material ui button rather than our own because it displays inline

        return <Button
            onClick={() => setIsAddModalShown(true)}
            variant="contained"
            startIcon={<MaterialUiIcons.Add />}
            sx={{
                "&:hover": {
                    background: "#F9EDE7",
                    color: "#ec6f41",
                },
                backgroundColor: "#ec6f41",
                color: "#fff",
                border: "1px solid #ec6f41",
                padding: "0px 6px",
                lineHeight: "23px"
            }}
            style={{ textTransform: "none", marginLeft: "18px", marginRight: "10px" }}
        >group</Button>;
    };

    const addSensorButton = () => {
        // using the native material ui button rather than our own because it displays inline

        if (Services.userHasPolicy("CreateSensorUi")) {
            return <Button
                onClick={() => onSensorCreateClick()}
                variant="contained"
                startIcon={<MaterialUiIcons.Add />}
                sx={{
                    "&:hover": {
                        background: "#F9EDE7",
                        color: "#ec6f41",
                    },
                    backgroundColor: "#ec6f41",
                    color: "#fff",
                    border: "1px solid #ec6f41",
                    padding: "0px 6px",
                    lineHeight: "23px"
                }}
                style={{ textTransform: "none", marginLeft: "18px" }}
            >sensor</Button>;
        } else {
            return null;
        }
    };

    const uploadSensorsButton = () => {
        if (Services.userHasPolicy("CreateSensorUi")) {
            return <CloudUploadButton
                onClick={() => setIsSensorUploadModalShown(true)}
                title="Upload list of sensors"
                color="primary"
            />;
        } else {
            return null;
        }
    };

    const onSensorGroupEditButtonClicked = (sensorGroup: SensorGroup) => {
        setIsEditModalShown(true);
        setSensorGroupToBeEdited(sensorGroup);
    };

    const allSensors = new Map<string, Sensor>();
    const allSensorGroups = new Map<string, SensorGroup>();
    const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);
    const debouncedSearchTermSearches = debouncedSearchTerm.split(",");

    const sortMultiplier = sortOrder === "asc" ? 1 : -1;

    // todo: use utility
    function addSensorsToLookup(sensorGroup: SensorGroup) {
        allSensorGroups.set(sensorGroup.id, sensorGroup);
        sensorGroup.sensors?.sort((a, b) => (a.description || "").localeCompare((b.description || "")) * sortMultiplier).forEach(sensor => allSensors.set(sensor.id || "", sensor));
        sensorGroup.children?.sort((a, b) => (a.name || "").localeCompare((b.name || "")) * sortMultiplier).forEach(group => addSensorsToLookup(group));
    }

    addSensorsToLookup(rootSensorGroup);

    React.useEffect(() => {
        allSensorGroups.clear();
        allSensors.clear();
        addSensorsToLookup(rootSensorGroup);
    }, [rootSensorGroup]);

    const toggleSortOrder = () => {
        setSortOrder((currentSortOrder) => currentSortOrder === "asc" ? "desc" : "asc");
    };

    const getSensorGroupHierarchyText = (sensorGroup: Sensorgroup): string => {
        const parentGroupId = sensorGroup.parentGroupId;

        if (parentGroupId !== null && parentGroupId !== undefined) {
            return (sensorGroup.name || "") + "," + getSensorGroupHierarchyText(allSensorGroups.get(parentGroupId)!);
        } else {
            return sensorGroup.name || "";
        }
    }

    const getSensorHierarchyText = (sensor: Sensor): string => {
        return (sensor.description || "") + "," + (sensor.rfid || "") + "," + getSensorGroupHierarchyText(allSensorGroups.get(sensor.parentGroupId!)!); // TODO: Remove !s
    }

    // Gets the text to search for in a group, including the names of the child sensors and groups, so that when you search for a child the parent groups show up
    const getGroupText = (group: Sensorgroup) : string => {
        return getSensorGroupHierarchyText(group) + ","  + (group.children?.map(getGroupText).join(",") || "") + "," + (group.sensors?.map(s => s.description + "," + s.rfid).join(",") || "");
    }

    // Filter and sort logic
    const filteredGroups = React.useMemo(() => {
        const filtered = Array.from(allSensorGroups).filter(([, group]) =>
            group && typeof group.name === "string" && debouncedSearchTermSearches.filter(search => getGroupText(group).toLowerCase().includes(search.toLowerCase())).length > 0
        );

        return new Map(filtered);
    }, [allSensorGroups, debouncedSearchTerm, sortOrder]);

    const filteredSensors = React.useMemo(() => {
        const matches = [];
        const groupsToExpand = new Set(); // To keep track of sensor groups to expand

        for (const sensor of allSensors.values()) {
            // Use optional chaining and provide a fallback empty string for the description
            const description = getSensorHierarchyText(sensor).toLowerCase() || "";
            if (debouncedSearchTermSearches.filter(search => description.includes(search.toLowerCase())).length > 0) {
                matches.push(sensor);
                if (sensor.parentGroupId) {
                    groupsToExpand.add(sensor.parentGroupId); // Add the parent group ID to the set if it exists
                }
            }
        }

        return { matches, groupsToExpand };
    }, [allSensors, debouncedSearchTerm, searchTerm]);

    if (latestSensorTrends) {
        console.log("latestSensorTrends",
            latestSensorTrends
                .filter(t => t.parameters?.trendPeriodType === Trendperiodtype.ShortTerm)
                .sort((a, b) => (a.corrosionRate || 0) - (b.corrosionRate || 0))
                .filter(t => t.corrosionRate !== 0)
                .map(t => allSensors.get(t.sensorId!))
        );
    }

    const ref = React.useRef<TreeMethods>(null);

    const isMES = (node?: NodeModel) => {
        if (node === undefined) return false;

        // droppable means a sensor group
        if (node.droppable) {
            const sensorGroup = allSensorGroups.get(node.id as string);
            if (sensorGroup === undefined) return false;

            return sensorGroup.name?.includes("MES");
        } else {
            const sensor = allSensors.get(node.id as string);
            if (sensor === undefined) return false;

            const sensorGroup = allSensorGroups.get(sensor.parentGroupId);
            if (sensorGroup === undefined) return false;

            return sensorGroup.name?.includes("MES");
        }
    };

    const isMESNode = (node?: NodeModel) => {
        if (node === undefined) return false;

        // droppable means a sensor group
        if (node.droppable) {
            return false;
        } else {
            const sensor = allSensors.get(node.id as string);
            if (sensor === undefined) return false;

            const sensorGroup = allSensorGroups.get(sensor.parentGroupId);
            if (sensorGroup === undefined) return false;

            return sensorGroup.name?.includes("MES");
        }
    }

    return (
        <ReactDnd.DndProvider backend={HTML5Backend}>
            <div style={{ width: "100%", height: "100%", overflowY: "auto", backgroundColor: "white" }}>
                <SimplePanel
                    isNewStyleTitle={true}
                    shouldApplyPadding={false}
                    title="Sensor Groups"
                    titleAlignment="left"
                    size="small"
                    actionButton={
                        <>
                            {showHighestButton()}
                            {addSensorButton()}
                            {addGroupButton()}
                            {uploadSensorsButton()}
                        </>
                    }
                    searchRow={
                        <>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center",justifyContent: "center",padding: "6px 0px"  }}>
                                <FormControl style={{ width:"80%" }}>
                                    <OutlinedInput 
                                        placeholder="Search for Sensor Groups or Sensors" 
                                        type={"search"}
                                        color="primary" 
                                        sx={{
                                            height:"34px", 
                                            marginRight:"20px",
                                            "& .MuiOutlinedInput-notchedOutline":{
                                                borderColor:" #ec6f41",
                                            },
                                        }} 
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            if (ref.current) {
                                                if (e.target.value !== "") {
                                                    ref.current.openAll();
                                                } else {
                                                    ref.current.closeAll();
                                                }
                                            }
                                        }}
                                        title="Separate different search terms by comma"
                                        style={{ fontSize:"14px", border:"1px solid #EC6F41",marginRight:"5px" }}
                                    />
                                </FormControl>
                                <SortButton sortOrder={sortOrder} onToggle={toggleSortOrder}/>
                            </div>
                        </>
                    }
                    content={
                        <Tree
                            ref={ref}
                            tree={
                                flatViewMode ?
                                    [
                                        { "id": rootSensorGroup.id, parent: "", "text": "Root", droppable: true },
                                        ...(latestSensorTrends !== undefined ? latestSensorTrends
                                            .filter(t => t.parameters?.trendPeriodType === Trendperiodtype.ShortTerm)
                                            .sort((a, b) => (a.corrosionRate || 0) - (b.corrosionRate || 0))
                                            .filter(t => t.corrosionRate !== 0)
                                            .map(t => {
                                                const sensor = allSensors.get(t.sensorId!);
                                                return {
                                                    "id": sensor?.id || "",
                                                    "parent": rootSensorGroup.id,
                                                    "text": sensor?.description || "Unknown sensor",
                                                    "droppable": false
                                                };
                                            }) : [])
                                    ] :
                                    // This section is adjusted to conditionally render based on searchTerm
                                    searchTerm === "" ?
                                        [
                                            // Original behavior: display all groups and sensors
                                            ...Array.from(allSensorGroups).map(([groupId, group]) => ({
                                                "id": groupId,
                                                "parent": group.parentGroupId || "",
                                                "text": group.name || "Unknown group",
                                                "droppable": true
                                            })),
                                            ...Array.from(allSensors).map(([sensorId, sensor]) => ({
                                                "id": sensorId,
                                                "parent": sensor.parentGroupId,
                                                "text": sensor.description || "Unknown Sensor"
                                            }))
                                        ] :
                                        // When there is a searchTerm: filter
                                        [
                                            ...Array.from(filteredGroups).map(([groupId, group]) => ({
                                                id: groupId,
                                                parent: group.parentGroupId || "",
                                                text: group.name || "Unknown Group",
                                                droppable: true
                                            })),
                                            ...filteredSensors.matches.map(sensor => ({
                                                id: sensor.id,
                                                parent: sensor.parentGroupId,
                                                text: sensor.description || "Unknown Sensor",
                                                droppable: false
                                            }))
                                        ]
                            }
                            classes={{
                                "container": "sensorListUl"
                            }}
                            rootId={rootSensorGroup.id}
                            sort={false}
                            insertDroppableFirst={true}
                            onDrop={async (_newTree, { dragSourceId, dropTargetId, dragSource }) => {

                                if (dragSource?.droppable) {
                                    const source = allSensorGroups.get(dragSourceId as string);
                                    if (!source || source.parentGroupId === dropTargetId as string) return;
                                    await Services.batch(async () => {
                                        await Services.SensorGroups.moveSensorGroup(source, dropTargetId as string);
                                        await onSensorTreeChange();
                                    }, true);
                                } else {
                                    // If item being dragged is one of a group that is selected, move the whole group
                                    if (dragSourceId && selectedNodes.includes(dragSourceId.toString())) {
                                        await Services.batch(async () => {
                                            for (const node of selectedNodes) {
                                                const source = allSensors.get(node);
                                                if (!source || source.parentGroupId === dropTargetId) continue;

                                                await Services.Sensors.moveSensor(source, dropTargetId as string);
                                            }
                                            
                                            await onSensorTreeChange();
                                        }, true);
                                    } else {
                                        const source = allSensors.get(dragSourceId as string);
                                        if (!source || source.parentGroupId === dropTargetId) return;
                                        await Services.batch(async () => {
                                            await Services.Sensors.moveSensor(source, dropTargetId as string);
                                            await onSensorTreeChange();
                                        }, true);
                                    }
                                }
                            }}
                            canDrag={(node) => !isMESNode(node)}
                            canDrop={(_tree, { dropTarget }) => isMES(dropTarget) ? false : undefined}
                            render={(node, { depth, isOpen, onToggle }) => {

                                const longTermTrend = FirstIfNotEmpty(latestSensorTrends?.filter(t => t.sensorId === node.id && t.parameters?.trendPeriodType === Trendperiodtype.LongTerm)) || undefined;
                                const shortTermTrend = FirstIfNotEmpty(latestSensorTrends?.filter(t => t.sensorId === node.id && t.parameters?.trendPeriodType === Trendperiodtype.ShortTerm)) || undefined;
                                const statsLoaded = latestSensorTrends !== undefined;

                                if (node.droppable) {
                                    const sensorGroup = allSensorGroups.get(node.id as string)!;
                                    
                                    return <SensorNode
                                        label={node.text}
                                        depth={depth}
                                        droppable={true}
                                        isOpen={isOpen}
                                        onClick={() => onSensorGroupClick(sensorGroup)}
                                        onContextMenu={() => onSensorGroupEditButtonClicked(sensorGroup)}
                                        onToggle={onToggle}
                                        selected={selectedNodes.includes(sensorGroup.id)}
                                        tabletMode={tabletMode}
                                        status="normal"
                                        onDelete={(sensorGroup.children?.length === 0 && sensorGroup.sensors?.length === 0) ?
                                            async () => {
                                                await Services.batch(async () => {
                                                    await Services.SensorGroups.deleteSensorGroup(sensorGroup.id);
                                                    await onSensorTreeChange();
                                                }, true)
                                            }
                                            : undefined
                                        }
                                    />;
                                } else {
                                    const sensor = allSensors.get(node.id as string)!;
                                    const type = sensor.sensorType.productName;

                                    const getThicknessStatus = (thickness: number | undefined) => {
                                        if (thickness === undefined) return "normal";

                                        if (sensor.warningParameters.criticalThicknessInMetres && thickness < sensor.warningParameters.criticalThicknessInMetres) {
                                            return "error";
                                        } else if (sensor.warningParameters.warningThicknessInMetres && thickness < sensor.warningParameters.warningThicknessInMetres) {
                                            return "warning";
                                        } else {
                                            return "normal";
                                        }
                                    }

                                    return <SensorNode
                                        type={type}
                                        label={node.text}
                                        depth={depth}
                                        droppable={false}
                                        isOpen={isOpen}
                                        onClick={(ctrlKey, shiftKey) => {
                                            if (shiftKey) {
                                                // Algorithm for shift select:
                                                // Get the lowest and highest indices of the sensors in both the existing sensor selection and the additional sensor
                                                // and find all sensors between them

                                                // Using .get! is a necessary evil, the ids should all exist in allSensors so should be okay to do
                                                const allSensorValues = [...allSensors.values()];
                                                const allSensorIds = allSensorValues.map(s => s.id);

                                                const selectedIndices = selectedNodes.map(node => allSensorIds.findIndex(id => id === node));
                                                const sensorIndex = allSensorIds.findIndex(id => id === sensor.id);

                                                const combinedIndices = [...selectedIndices, sensorIndex];
                                                const minIndex = Math.min(...combinedIndices);
                                                const maxIndex = Math.max(...combinedIndices);

                                                const newSelectedSensorIds = allSensorIds.filter((_id, index) => index >= minIndex && index <= maxIndex);
                                                const newSelectedSensors = newSelectedSensorIds.map(id => allSensors.get(id)!);

                                                onSensorsSelect(newSelectedSensors);
                                            } else { 
                                                onSensorsSelect(ctrlKey ? [...selectedNodes.map(n => allSensors.get(n)!), sensor] : [sensor]);
                                            }
                                        }}
                                        onContextMenu={() => onSensorEditClick(sensor)}
                                        onToggle={onToggle}
                                        selected={selectedNodes.includes(sensor.id)}
                                        tabletMode={tabletMode}
                                        status={getThicknessStatus(longTermTrend?.latestThickness)}
                                        corrosionRates={
                                            (!node.droppable && statsLoaded) ?
                                                <>[
                                                    {
                                                        longTermTrend?.latestThickness ?
                                                            <DisplacementText
                                                                displacementInMetres={longTermTrend?.latestThickness}
                                                                unitsMode={unitsMode}
                                                            />
                                                            : "N/A"
                                                    },&nbsp;
                                                    {
                                                        shortTermTrend?.corrosionRate ?
                                                            <CorrosionRateText
                                                                corrosionRateInMMY={shortTermTrend?.corrosionRate * 1000}
                                                                unitsMode={unitsMode}
                                                                noCorrosionText="0"
                                                                tooltip="Short-term corrosion rate"
                                                            />
                                                            : "N/A"
                                                    },&nbsp;
                                                    {
                                                        longTermTrend?.corrosionRate ?
                                                            <CorrosionRateText
                                                                corrosionRateInMMY={longTermTrend?.corrosionRate * 1000}
                                                                unitsMode={unitsMode}
                                                                noCorrosionText="0"
                                                                tooltip="Long-term corrosion rate"
                                                            />
                                                            : "N/A"
                                                    }
                                                    ]</>
                                                : null
                                        }
                                    />;
                                }
                            }}
                        />
                    }
                />

            </div>
            <CreateSensorGroupModalPanel
                parentGroupId={rootSensorGroup.id}
                shouldBeShown={isAddModalShown}
                onClose={() => setIsAddModalShown(false)}
                onCreate={async () => {
                    await onSensorTreeChange();
                    setIsAddModalShown(false);
                }}
            />
            <UploadSensorsModalPanel
                parentGroupId={rootSensorGroup.id}
                shouldBeShown={isSensorUploadModalShown}
                onClose={async () => {
                    setIsSensorUploadModalShown(false);
                    await onSensorTreeChange();
                }}
            />
            {sensorGroupToBeEdited && <EditSensorGroupModalPanel
                sensorGroup={sensorGroupToBeEdited}
                shouldBeShown={isEditModalShown}
                onClose={() => setIsEditModalShown(false)}
                onSave={async () => {
                    await onSensorTreeChange();
                    setIsEditModalShown(false);
                }}
            />}
        </ReactDnd.DndProvider>
    );
}

interface SensorDeleteButtonProps {
    tabletMode: boolean;
    onClick(): void;
}

const SensorDeleteButton: FunctionComponent<SensorDeleteButtonProps> = ({ tabletMode, onClick }) => {
    if (tabletMode) {
        return <DeleteIconButton
            onClick={event => {
                event?.stopPropagation();
                onClick();
            }}
        />;
    } else {
        return <IconButton
            size="small"
            icon={
                <CloseIcon
                    fontSize="small"
                    htmlColor="black"
                />
            }
            onClick={ev => {
                if (ev) {
                    ev.stopPropagation();
                    ev.preventDefault();
                }
                onClick();
            }}
        />;
    }
}

export default SensorTreePanel;

interface SensorNodeProps {
    type?: string;
    label: string;
    tabletMode: boolean;
    selected: boolean;
    droppable: boolean;
    isOpen: boolean;
    depth: number;
    onClick: (ctrlKey: boolean, shiftKey: boolean) => void;
    onContextMenu: () => void;
    onToggle: () => void;
    onDelete?: () => void;
    corrosionRates?: React.ReactNode;
    status: "normal" | "warning" | "error";
}

const SensorNode: FunctionComponent<SensorNodeProps> = ({ type, label, depth, onClick, onContextMenu, onToggle, selected, droppable, isOpen, tabletMode, onDelete, corrosionRates, status }) => {

    let style = tabletMode ? sensorLabelStyle : sensorLabelCompactStyle;

    if (status === "error") {
        style = merge(style, { color: alertErrorForegroundRed });
    } else if (status === "warning") {
        style = merge(style, { color: alertWarningForegroundAmber });
    }

    return <div
        style={{
            marginLeft: depth * 25,
            fontSize: "9pt",
            padding: 4,
            backgroundColor: selected ? "lightblue" : undefined,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            ...style
        }}
        onClick={(ev) => {
            onClick(ev.ctrlKey, ev.shiftKey);
        }}
        onContextMenu={ev => {
            ev.preventDefault();
            onContextMenu();
        }}
        onDoubleClick={ev => {
            ev.stopPropagation();
            ev.preventDefault();
            onContextMenu();
        }}
    >
        <span onClick={ev => {
            ev.stopPropagation();
            onToggle();
        }}>
            {droppable && (
                <span style={{ width: 50 }}>
                    {
                        isOpen ?
                            <ArrowDropDownIcon fontSize="small" sx={{ alignItems: "center" }} /> :
                            <ArrowRightIcon fontSize="small" sx={{ alignItems: "center" }} />
                    }&nbsp;
                </span>
            )}
        </span>
        {droppable && label.includes("MES") && <MesSvg style={{ marginRight: 10 }} />}
        {!droppable && (type === "DL1" ? <SdlSvg style={{ marginRight: 5, height: "12px" }} /> : <SensorSvg style={{ marginRight: "5px" }} />)}
        <span>
            {label}&nbsp;
            {
                onDelete ? <SensorDeleteButton
                    onClick={onDelete}
                    tabletMode={tabletMode}
                /> : null
            }&nbsp;
            {corrosionRates}
        </span>
    </div>;
}
