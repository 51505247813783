import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import { Device } from "@inductosense/typescript-fetch";
import TemperaturePanel from "./TemperaturePanel";

interface TemperatureModalPanelProps {
    shouldBeShown: boolean;
    onClose(): void;
    device: Device;
    temperatureUnits: "celsius" | "fahrenheit";
}

export default class TemperatureModalPanel extends Component<TemperatureModalPanelProps> {
    constructor(props: TemperatureModalPanelProps) {
        super(props);
    }

    render() {
        const { onClose, shouldBeShown, device, temperatureUnits } = this.props;

        return (
            <ModalPanel
                title="Temperature"
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={<TemperaturePanel device={device} temperatureUnits={temperatureUnits} />}
            />
        );
    }
}
