import * as React from "react";
import { CSSProperties, FunctionComponent } from "react";
import { TextAlignment, textAlignmentToCssTextAlignValue } from "Types/TextAlignment";

interface PanelHeadingProps {
    isNewStyleTitle?: boolean;
    text: string;
    alignment?: TextAlignment;
    size: "regular" | "small";
}

const PanelHeading: FunctionComponent<PanelHeadingProps> = ({ alignment, text, size, isNewStyleTitle }) => {
    let style: CSSProperties | undefined;
    if (alignment) style = { textAlign: textAlignmentToCssTextAlignValue(alignment),
                             fontWeight: isNewStyleTitle ? "bolder" : "",
                             color: isNewStyleTitle ? "#EC6F41" : "",
                             minWidth: isNewStyleTitle ? "124px" : "",
                             fontSize:  isNewStyleTitle && size !== "small" ? "24px" : "",
                            };

    if(isNewStyleTitle){
        return <div style={style}>{text}</div>
    }else if (size === "small") {
        return <h3 style={style} >{text}</h3>;
    } else {
        return <h2 style={style}>{text}</h2>;
    }
};

export default PanelHeading;
