import * as BrandColours from "Styling/Palette/BrandColours";
import * as React from "react";
import { Component, CSSProperties, ReactNode } from "react";
import { FormControl, FormLabel, FormSection, FormTab } from "Types/Forms";
import merge from "Utilities/Merge";
import Tabs from "@mui/material/Tabs";
import TabPanel from "Panels/Shared/TabPanel";
import Tab from "@mui/material/Tab";

const sectionStyle: CSSProperties = {
    display: "flex"
};

const labelStyle: CSSProperties = {
    flex: 1,
    fontWeight: "bold",
    marginRight: 20
};

const requiredMarkerStyle: CSSProperties = {
    color: "red"
};

const nonEditableControlStyle: CSSProperties = {
    color: BrandColours.inductosenseMediumGrey,
    fontStyle: "italic"
};

interface FormProps {
    tabs?: FormTab[];
    sections?: FormSection[];
    customControls?: ReactNode;
    footer?: ReactNode;
    onSubmit?(): void;
}

interface FormState {
    currentTabValue: number;
}

export default class Form extends Component<FormProps, FormState> {
    constructor(props: FormProps) {
        super(props);
        this.state = { currentTabValue: 0 };
    }

    render() {
        const { tabs, customControls, footer, sections, onSubmit } = this.props;

        if (tabs?.length && sections?.length) {
            throw new Error("Configure either tabs or sections, not both.");
        }

        return (
            <div 
                onKeyPress={ev => {
                    if (ev.key === "Enter") {
                        if (onSubmit) onSubmit();
                    }
                }}
            >
                {this.allTabs(tabs)}
                {sections?.map((section, index) => this.section(section, index))}
                {customControls}
                {footer}
            </div>
        );
    }

    private allTabs(tabs: FormTab[] | undefined) {
        if (!tabs?.length) {
            return null;
        }

        const handleTabChange = (_event: React.SyntheticEvent, newTabValue: number) => {
            this.setState({ currentTabValue: newTabValue });
        };

        return (
            <>
                <Tabs value={this.state.currentTabValue} onChange={handleTabChange.bind(this)} variant="fullWidth">
                    {tabs?.map((tab, index) => this.tab(tab, index))}
                </Tabs>
                {tabs?.map((tab, index) => this.tabPanel(tab, this.state.currentTabValue, index))}
            </>
        );
    }

    private tab({ label, id }: FormTab, index: number) {
        return (
            <Tab label={label} id={id} key={index} />
        );
    }

    private tabPanel({ sections }: FormTab, currentTabValue: number, index: number) {
        return (
            <TabPanel key={index} value={currentTabValue} index={index}>
                {sections?.map((section, index) => this.section(section, index))}
            </TabPanel>
        );
    }

    private section({ control,label, marginTop, isDisabled, isHidden }: FormSection, index: number) {
        const opacity = isDisabled ? 0.5 : 1;
        if (isHidden === true) return null;
        return <div key={index} style={merge(sectionStyle, { marginTop, opacity })}>
            {this.label(label)}
            {this.control(control)}
        </div>;
    }

    private label({ isRequired, marginTop, text }: FormLabel) {
        return (
            <div style={merge(labelStyle, { marginTop })}>
                {text}:
                {isRequired && this.requiredMarker()}
            </div>
        );
    }

    private control({ isEditable, node }: FormControl) {
        return <div style={!isEditable ? nonEditableControlStyle : {}}>{node}</div>;
    }

    private requiredMarker() {
        return <span style={requiredMarkerStyle}> *</span>;
    }
}
