import * as React from "react";
import { Component } from "react";
import Button from "Components/Buttons/Button";
import SuccessIcon from "Components/Graphics/Icons/SuccessIcon";
import ErrorIcon from "Components/Graphics/Icons/ErrorIcon";
import Services from "Services/Platform/Services";
import { Thicknessalgorithm, Material, Sensorwarningparameters, Sensorpost } from "@inductosense/typescript-fetch";
import SensorType from "Model/SensorType";
import FirstIfNotEmpty from "Utilities/FirstIfNotEmpty";
import { Alert } from "@mui/lab";
import ServiceGeneralError from "../../../Services/Platform/ServiceGeneralError";
import * as Papa from "papaparse";

interface UploadSensorsPanelProps {
    parentGroupId: string;
}

interface UploadSensorsPanelState {
    sensorTypes?: SensorType[];
    materials?: Material[];
    warningParametersObjects?: Sensorwarningparameters[];
    infoMessage?: string;
    csvFileName?: string;
    csvText?: string;
}

export default class UploadSensorsPanel extends Component<UploadSensorsPanelProps, UploadSensorsPanelState> {
    constructor(props: UploadSensorsPanelProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        this.setState({
            sensorTypes: await Services.Sensors.getAllSensorTypes(),
            materials: await Services.Materials.getAllMaterials(),
            warningParametersObjects: await Services.Sensors.getAllWarningParameters()
        });
    }

    render() {
        return this.form();
    }

    private validateString(input: string) {
        return new RegExp(/^[a-fA-F0-9]{24}$/).test(input);
    }

    private convertCsv(input: string, sensorTypes: SensorType[], parentGroupId: string, structureMaterialId: string,
        warningParametersId: string): Sensorpost[] {

        if (input.startsWith("RFID,Sensor")) {
            // CSV has a header. We strip it off to simplify handling of omitted data.
            try {
                input = input.substring(input.indexOf("\n") + 1);
            } catch (e) {
                console.error("Invalid CSV file");
                return [];
            }
        } else {
            throw new Error("Missing header");
        } // TODO: Specific error message *

        const results = Papa.parse(input, {
            skipEmptyLines: "greedy"
        });
        if (results.errors?.length) {
            // There is more information in the errors array but the lack of CSV
            // specifications make it impossible to be certain exactly where in
            // the file any error is. Thus to avoid misleading users we just say
            // the entire file is invalid and it's up to them to fix or binary
            // search through it until they find the problem. Maybe we could
            // provide some caveated hints one day but that would require
            // specification and UI design first.
            throw ("Invalid CSV row(s)");
        }
        return (results.data as string[][]).map((row) => {
            const sensorTypeId = sensorTypes.find(t => t.productName === row[1])?.id;
            if (sensorTypeId === undefined) throw new Error("Invalid sensor type");
            if (!this.validateString(row[0])) throw new Error("Invalid RFID");
            return {
                rfid: row[0],
                description: undefinedIfEmpty(row[2]) ?? row[0],
                sensorTypeId: sensorTypeId,
                thicknessAlgorithm: Thicknessalgorithm.FirstArrival,
                calibrationValue: 0,
                parentGroupId,
                structureMaterialId,
                warningParametersId,
                structureNominalThicknessInMetres: undefined,
                shortTermCorrosionPeriodDays: 93,
                longTermCorrosionPeriodDays: 365,
                equipmentId: undefinedIfEmpty(row[3]),
                sapEquipmentNumber: undefinedIfEmpty(row[4]),
                measurementSetId: undefinedIfEmpty(row[5]),
                circuitId: undefinedIfEmpty(row[6]),
                subCircuitId: undefinedIfEmpty(row[7]),
                cmlId: undefinedIfEmpty(row[8]),
                measurementPosition: undefinedIfEmpty(row[9]),
                matrixLocation: undefinedIfEmpty(row[10]),
                useOverrideChirpParameters: false
            };
        });
    }

    private form() {
        const { parentGroupId } = this.props;
        const { sensorTypes, materials, warningParametersObjects, infoMessage, csvText, csvFileName } = this.state;
        const sensorType = FirstIfNotEmpty(sensorTypes);
        const material = materials?.find(m => m.id === "e7cd66d4-84ef-4320-a933-e8344d3fc73e");
        const warningParameters = FirstIfNotEmpty(warningParametersObjects);

        if (sensorTypes === undefined || sensorType === null || material === undefined || warningParameters === null) return <p>Loading...</p>;

        let sensorData: Sensorpost[] | null = null;
        let errorText: string | null = null;
        if (csvFileName?.toLocaleLowerCase().endsWith(".csv")) {
            try {
                sensorData = csvText !== undefined ? this.convertCsv(csvText, sensorTypes, parentGroupId, material.id,
                    warningParameters.id) : null;
            }
            catch (err: unknown) {
                if (err instanceof Error) {
                    errorText = err.toString();
                    console.error(err);
                }
            }
        }        

        return (
            <>
                { infoMessage !== undefined ? <Alert severity="info" style={{ marginBottom: 10 }}>{infoMessage}</Alert> : null}

                <div style={{ marginBottom: 10 }}>
                    <a
                        href={`data:text/csv,${encodeURIComponent("RFID,Sensor Type,Sensor Name,Equipment ID,SAP Equipment Number,Measurement Set ID,Circuit ID,Sub-Circuit ID,CML ID,Measurement Position,Matrix Location\r\nEEE0A0000000000000001234,S5R,,,,,,,,,\r\n")}`}
                        download="sensors.csv">
                        Download example CSV
                    </a>
                </div>

                <input
                    type="file"
                    accept=".csv"
                    onClick={ev => {
                        // Setting value to "" on click ensures change event is raised even when user selects
                        // the same file again
                        // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
                        const el = ev.currentTarget as HTMLInputElement;
                        el.files = null;
                        el.value = "";
                    }}
                    onChange={c => {
                        const reader = new FileReader();
                        reader.onload = (ev) => {
                            console.log(ev, reader);
                            const text = reader.result as string;
                            this.setState({ csvText: text, csvFileName: c.target.value });
                        };
                        if (c.target.files !== null && c.target.files.length > 0) {
                            reader.readAsText(c.target.files[0]);
                        }
                    }}
                />

                <p>
                    {
                        csvText !== undefined ?
                            <>
                                <span style={{ verticalAlign: "middle" }}>{sensorData !== null ? <SuccessIcon /> : <ErrorIcon />}</span>
                                &nbsp;{errorText || (sensorData !== null ? "Valid" : "Invalid")}
                            </>
                        : null
                    }
                </p>
                {
                    sensorData !== null ?
                    <Button
                        label="Create"
                        onClick={() => this.onCreateClick(sensorData!)}
                    /> :
                    <Button
                        label="Create"
                        onClick={() => null}
                        isDisabled={true}
                    />
                }
            </>
        );
    }

    private async onCreateClick(sensors: Sensorpost[]) {
        let successfulCount = 0;
        let duplicateCount = 0;

        for (const sensor of sensors) {
            try {
                await Services.Sensors.createSensor(sensor);
                successfulCount++;
            }
            catch (err) {
                if (err instanceof ServiceGeneralError) {
                    console.error("err", err, err.responseErrors);

                    if (err.responseErrors && err.responseErrors.length > 0 && err.responseErrors[0].code === 9) {
                        // Sensors have a single unique key so we know if this code
                        // is returned it must be due to a duplicated RFID
                        duplicateCount++;
                    }
                }
            }
        }

        this.setState({ infoMessage: `${successfulCount} created, ${duplicateCount} already exist` });
    }
}

function undefinedIfEmpty(s: string): string | null | undefined {
    if (s === "") {
        return undefined;
    }
    return s;
}
