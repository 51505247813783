import * as React from "react";
import { DefaultIconSize } from "Constants/Sizes";
import { FunctionComponent } from "react";
import TemperatureIconGraphic from "Graphics/Icons/TemperatureIconGraphic.svg";

interface TemperatureIconProps {
    width?: number;
    height?: number;
}

const TemperatureIcon: FunctionComponent<TemperatureIconProps> = ({ height, width }) => (
    <TemperatureIconGraphic
        width={width ?? DefaultIconSize.width}
        height={height ?? DefaultIconSize.height}
    />
);

export default TemperatureIcon;
