import { DataUploadApi } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class UsersService extends InductosenseService<DataUploadApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(DataUploadApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async postReadingDats(datFiles: ArrayBuffer[]): Promise<void> {
        await this.ensureLatestConfig();
        const datFileBase64s = datFiles.map(d => btoa(String.fromCharCode(...new Uint8Array(d))));

        return this.serverApi.postReadingsDats({ requestBody: datFileBase64s });
    }
}
