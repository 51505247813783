// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --inductosense-black: #000000;
    --inductosense-denim-blue: #003d59;
    --inductosense-slate-grey: #414a4c;
    --inductosense-medium-grey: #808080;
    --inductosense-smoke-grey: #f2f2f2;
    --inductosense-dawn-orange: #fb9334;
    --inductosense-bold-orange: #e9500e;
    --inductosense-duck-egg-teal: #44857d;
    --inductosense-deep-teal: #16706f;
    --inductosense-white: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./Styling/Palette/BrandColours.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kCAAkC;IAClC,kCAAkC;IAClC,mCAAmC;IACnC,kCAAkC;IAClC,mCAAmC;IACnC,mCAAmC;IACnC,qCAAqC;IACrC,iCAAiC;IACjC,6BAA6B;AACjC","sourcesContent":[":root {\n    --inductosense-black: #000000;\n    --inductosense-denim-blue: #003d59;\n    --inductosense-slate-grey: #414a4c;\n    --inductosense-medium-grey: #808080;\n    --inductosense-smoke-grey: #f2f2f2;\n    --inductosense-dawn-orange: #fb9334;\n    --inductosense-bold-orange: #e9500e;\n    --inductosense-duck-egg-teal: #44857d;\n    --inductosense-deep-teal: #16706f;\n    --inductosense-white: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
