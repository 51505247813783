import * as React from "react";
import { Component } from "react";
import { ListItem, List, Checkbox, ListItemText } from "@mui/material";
import { Dcuser } from "@inductosense/typescript-fetch";
import Button from "../Components/Buttons/Button";

interface UserSelectPanelProps {
    onAddUsers(userIds: string[]): void;
    userList: Dcuser[];
}

interface UserSelectPanelState {
    selectedUserIds: string[];
}

export default class UserSelectPanel extends Component<UserSelectPanelProps, UserSelectPanelState> {
    constructor(props: UserSelectPanelProps) {
        super(props);

        this.state = {
            selectedUserIds: []
        };
    }

    render() {
        const { userList } = this.props;

        return <>
            <List>
                {
                    userList.length > 0 ? userList.map(user =>
                        <ListItem button key={user.username} onClick={() => this.handleToggle(user.id)}>
                            <Checkbox checked={this.state.selectedUserIds.includes(user.id)} />
                            <ListItemText primary={user.username} />
                        </ListItem>
                    ) : <p>No users left to add</p>
                }
            </List>
            <Button label="OK" precedence="primary" onClick={() => this.props.onAddUsers(this.state.selectedUserIds)} />
        </>;
    }

    handleToggle(userId: string) {
        const userChecked = this.state.selectedUserIds.includes(userId);

        if (userChecked) {
            const selectedUserIds = this.state.selectedUserIds.filter(u => u !== userId);
            this.setState({ selectedUserIds });
        } else {
            const selectedUserIds = this.state.selectedUserIds.concat([userId]);
            this.setState({ selectedUserIds });
        }
    }
}
