import * as React from "react";
import { Component } from "react";
import User from "Model/User";
import { FormField } from "Forms/FormField";
import FieldIsRequiredValidator from "Validation/Fields/FieldIsRequiredValidator";
import Form from "Forms/Form";
import TextField from "Components/Input/TextField";
import { FormSection, FormFieldChange } from "../../Types/Forms";
import SaveAndCancelButtonsFormFooter from "../Shared/SaveAndCancelButtonsFormFooter";
import Validator from "Validation/Validator";
import Services from "Services/Platform/Services";
import ServiceGeneralError from "../../Services/Platform/ServiceGeneralError";
import { Alert } from "@mui/lab";
import DropDownListControlled from "../../Components/Input/DropDownListControlled";
import { Units, Temperatureunits } from "@inductosense/typescript-fetch";

const footerStyle: React.CSSProperties = {
    marginTop: 16
}

const fieldWidth = 375;

interface UserFormProps {
    initialUser?: User;
    onSave(): void;
    onSaveComplete(): void;
    onSaveFailed(): void;
    onCancel(): void;
}

interface UserFormState {
    fields: Map<string, FormField>;
    userTriedToSave: boolean;
    isSaving: boolean;
    errorMessages: string[];
}

export default class UserForm extends Component<UserFormProps, UserFormState> {
    private fieldsRequiredValidator: Validator<string>;

    constructor(props: UserFormProps) {
        super(props);

        const { initialUser } = this.props;

        this.fieldsRequiredValidator = new FieldIsRequiredValidator("This field is required");

        console.log("initialUser", initialUser);

        this.state = {
            fields: new Map([
                ["Username", new FormField(
                    initialUser?.username ?? null,
                    [this.fieldsRequiredValidator]
                )],
                ["Password", new FormField(
                    null,
                    initialUser ? [] : [this.fieldsRequiredValidator]
                )],
                ["Units", new FormField<Units>(
                    initialUser?.unitsMode !== undefined ? initialUser.unitsMode : Units.Metric,
                    []
                )],
                ["Temperature Units", new FormField<Temperatureunits>(
                    initialUser?.temperatureUnits !== undefined ? initialUser.temperatureUnits : Temperatureunits.Celsius,
                    []
                )]
            ]),
            userTriedToSave: false,
            isSaving: false,
            errorMessages: []
        };
    }

    render() {
        return (
            <>
                {this.state.errorMessages.map((error, index) =>
                    <Alert key={index} severity="error" style={{ marginBottom: 2 }}>{error}</Alert>
                )}
                <Form
                    sections={this.sections()}
                    footer={this.footer()}
                    onSubmit={() => this.onSaveButtonClick()}
                />
            </>
        );
    }

    private sections(): FormSection[] {
        return [
            this.usernameSection(),
            this.passwordSection(),
            this.unitsModeSection(),
            this.temperatureUnitsSection()
        ];
    }

    private footer() {
        return (
            <div style={footerStyle}>
                <p>Changes to user units will be reflected after login</p>
                <SaveAndCancelButtonsFormFooter
                    isSaving={this.state.isSaving}
                    isValid={this.formValuesAreValid()}
                    onSaveButtonClick={() => this.onSaveButtonClick()}
                    onCancelButtonClick={() => this.onCancelButtonClick()}
                />
            </div>
        );
    }

    private isEditMode() {
        return this.props.initialUser !== undefined;
    }

    private usernameSection(): FormSection {
        const fieldName = "Username";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 6,
            label: { text: fieldName },
            control: {
                isEditable: true,
                node: <TextField
                    width={fieldWidth}
                    autoComplete="username"
                    initialValue={field?.value}
                    isDisabled={this.state.isSaving || this.isEditMode()}
                    onValueChanged={change => this.onValueChanged(fieldName, change)}
                    validators={field?.validators} />
            }
        };
    }

    private passwordSection(): FormSection {
        const fieldName = "Password";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 6,
            label: { text: fieldName },
            control: {
                isEditable: true,
                node: <TextField
                    width={fieldWidth}
                    autoComplete="new-password"
                    type="password"
                    initialValue={field?.value}
                    isDisabled={this.state.isSaving}
                    validators={field?.validators}
                    onValueChanged={change => this.onValueChanged(fieldName, change)} />
            }
        };
    }

    private unitsModeSection(): FormSection {
        const fieldName = "Units";
        const field = this.state.fields.get(fieldName) as FormField<Units>;
        return {
            marginTop: 6,
            label: { text: fieldName },
            control: {
                isEditable: true,
                node: <DropDownListControlled<Units>
                    options={[Units.Metric, Units.Imperial]}
                    labelFor={o => o}
                    onChange={newValue => {
                        const newFields = this.state.fields;
                        const newField = newFields.get(fieldName);
                        newField!.value = newValue;
                        newFields.set(fieldName, newField!);
                        this.setState({ fields: newFields });
                    }}
                    selectedOption={field.value !== null ? field.value : Units.Metric}
                />
            }
        }
    }

    private temperatureUnitsSection(): FormSection {
        const fieldName = "Temperature Units";
        const field = this.state.fields.get(fieldName) as FormField<Temperatureunits>;
        return {
            marginTop: 6,
            label: { text: fieldName },
            control: {
                isEditable: true,
                node: <DropDownListControlled<Temperatureunits>
                    options={[Temperatureunits.Celsius, Temperatureunits.Fahrenheit]}
                    labelFor={o => o}
                    onChange={newValue => {
                        const newFields = this.state.fields;
                        const newField = newFields.get(fieldName);
                        newField!.value = newValue;
                        newFields.set(fieldName, newField!);
                        this.setState({ fields: newFields });
                    }}
                    selectedOption={field.value !== null ? field.value : Temperatureunits.Celsius}
                />
            }
        }
    }

    private async onSaveButtonClick() {
        if (!this.state.userTriedToSave) this.setState({ userTriedToSave: true });
        if (this.formValuesAreValid()) {
            this.props.onSave();
            this.setState({ isSaving: true });
            await this.saveForm();
            this.setState({ isSaving: false });
        }
    }

    private onCancelButtonClick() {
        this.props.onCancel();
    }

    private onValueChanged(name: string, change: FormFieldChange) {
        const newFields = this.state.fields;
        const newField = newFields.get(name);
        newField!.value = change.newValue;
        newFields.set(name, newField!);
        this.setState({ fields: newFields });
    }

    private formValuesAreValid() {
        for (const f of this.state.fields.values()) {
            if (!f.isValid()) return false;
        }
        return true;
    }

    private async saveForm() {
        const { fields } = this.state;

        // If form is valid this field must exist
        const username = fields.get("Username")?.value ?? "";

        const password = fields.get("Password")?.value || undefined;
        const unitsMode = (fields.get("Units") as FormField<Units>).value;
        const temperatureUnits = (fields.get("Temperature Units") as FormField<Temperatureunits>).value;

        if (unitsMode === null) throw new Error("Units are undefined");
        if (temperatureUnits === null) throw new Error("Temperature units are undefined");

        try {
            if (this.props.initialUser !== undefined) {
                await Services.UsersService.updateUser(username, {
                    password,
                    unitsMode,
                    temperatureUnits
                });
            } else {
                // If we're creating a new user, password has already been validated as existing
                await Services.UsersService.createUser({
                    username,
                    password: password!,
                    unitsMode,
                    temperatureUnits
                });
            }
            this.props.onSaveComplete();
        }
        catch (ex) {
            if (ex instanceof ServiceGeneralError) {
                this.setState({
                    errorMessages: ex.responseErrors?.map(error => error.message) || []
                });
                this.props.onSaveFailed();
            } else {
                console.log("not instance of");
            }
        }
    }
}
