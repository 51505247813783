import * as React from "react";
import { Component } from "react";
import { Device, Dataextractionattemptlog } from "@inductosense/typescript-fetch";
import ScatterPlotGraph from "../Components/Graphs/Scatter/ScatterPlotGraph";
import Services from "Services/Platform/Services";
import { DateCoordinate } from "../Types/DateCoordinate";

interface TemperaturePanelProps {
    device: Device;
    temperatureUnits: "celsius" | "fahrenheit";
}

interface TemperaturePanelState {
    highlightedPoint?: DateCoordinate;
    logs?: Dataextractionattemptlog[];
}

export default class TemperaturePanel extends Component<TemperaturePanelProps, TemperaturePanelState> {
    constructor(props: TemperaturePanelProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        const { device } = this.props;

        console.log("temperatureUnits", this.props.temperatureUnits);

        const dataLogs = await Services.IoTDeployments.dataExtractionAttemptLogs(device.id);
        this.setState({ logs: dataLogs });
    }

    render() {
        const { highlightedPoint, logs } = this.state;
        if (logs === undefined) return <p>Loading...</p>;

        const points = logs
            .filter(l => l.deviceTemperatureInDegreesCelsius !== undefined && l.deviceTemperatureInDegreesCelsius !== null)
            .map(l => {
                return { x: l.dateTime, y: this.convertTemperatureToUnits(l.deviceTemperatureInDegreesCelsius!) }
            });

        if (points.length === 0) return <p>No temperature history available</p>;

        return (<>
            <div style={{ width: 800, height: 440 }}>
                <ScatterPlotGraph
                    plots={[{ label: "Temperature", points, highlightedPoint: highlightedPoint }]}
                    pointsRadius={5}
                    startXAxisScaleFrom="minimumValue"
                    startYAxisScaleFrom="zero"
                    xAxisLabel="Date"
                    yAxisLabel={`Temperature (${this.getUnits()})`}
                    styles={{}}
                    onHoveredPointChange={point => this.setState({ highlightedPoint: point || undefined })}
                    showLocalTrendButton={false}
                />
            </div>
            { highlightedPoint !== undefined ?
                <p>{highlightedPoint.x.toString()}: {highlightedPoint.y}{this.getUnits()}</p>
                : <p>&nbsp;</p>
            }
        </>
        );
    }

    private convertTemperatureToUnits(temperatureInCelsius: number) {
        const { temperatureUnits } = this.props;

        if (temperatureUnits === "fahrenheit") {
            return (temperatureInCelsius * 9 / 5) + 32;
        } else {
            return temperatureInCelsius;
        }
    }

    private getUnits() {
        const { temperatureUnits } = this.props;

        if (temperatureUnits === "fahrenheit") {
            return "°F";
        } else {
            return "°C";
        }
    }
}
