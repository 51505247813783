import * as React from "react";

import { CSSProperties } from "react";
import { inductosenseSmokeGrey } from "Styling/Palette/BrandColours";
import { uiBlockingBackdropZIndex, uiBlockingInvisibleBackdropZIndex } from "Styling/Depth/ZIndices";

import AlertPanel from "Panels/AlertPanel";
import Backdrop from "@mui/material/Backdrop";
import LoadingSpinner from "Components/Animations/LoadingSpinner";
import ModalPanel from "Panels/ModalPanel";
import UiBlockingState from "Types/UiBlockingState";

const delayBeforeVisibleBlockingEffect = 333;

const backdropStyle: CSSProperties = {
    zIndex: uiBlockingBackdropZIndex,
    color: "#ffffff"
};
const invisibleBackdropStyle: CSSProperties = {
    zIndex: uiBlockingInvisibleBackdropZIndex,
    color: "#ffffff"
};

interface UiBlockingOverlayProps {
    uiBlockingState: UiBlockingState;
}

interface UiBlockingOverlayState {
    isVisibleBackdropOpen: boolean;
    isInvisibleBackdropOpen: boolean;
    visibleMessage: string;
}

export default class UiBlockingOverlay extends React.Component<UiBlockingOverlayProps, UiBlockingOverlayState> {
    private timer?: number;

    constructor(props: UiBlockingOverlayProps) {
        super(props);
        this.state = {
            isVisibleBackdropOpen: false,
            isInvisibleBackdropOpen: props.uiBlockingState === true,
            visibleMessage: ""
        };
    }

    render() {
        return (
            <>
                <Backdrop
                    transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
                    style={invisibleBackdropStyle}
                    open={this.state.isInvisibleBackdropOpen}
                    invisible={true}
                    onClick={() => this.handleCloseInvisible()}
                />
                <Backdrop
                    transitionDuration={{ enter: 333, exit: 200 }}
                    style={backdropStyle}
                    open={this.state.isVisibleBackdropOpen}
                >
                    <LoadingSpinner colour={inductosenseSmokeGrey} />
                </Backdrop>
                <ModalPanel
                    title="Error"
                    content={this.alertPanel()}
                    shouldBeShown={!!this.state.visibleMessage}
                    onClose={() => this.setState({ visibleMessage: "" })}
                />
            </>
        )
    }

    private alertPanel() {
        return (
            <AlertPanel
                type="error"
                content={<div><b>{this.state.visibleMessage}</b></div>}
            />
        );
    }

    private handleCloseInvisible() {
        console.log("Starting to open main overlay from click")
        clearTimeout(this.timer);
        this.timer = undefined;
        this.setState({ isVisibleBackdropOpen: true, isInvisibleBackdropOpen: false });
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.timer = undefined;
    }

    componentDidUpdate(prevProps: UiBlockingOverlayProps) {
        if (prevProps.uiBlockingState !== this.props.uiBlockingState) {
            clearTimeout(this.timer);
            if (this.props.uiBlockingState === true) {
                this.setState({ isVisibleBackdropOpen: false, isInvisibleBackdropOpen: true });
                this.timer = window.setTimeout(() => {
                    this.setState({ isVisibleBackdropOpen: true, isInvisibleBackdropOpen: false });
                }, delayBeforeVisibleBlockingEffect);
            } else if (this.props.uiBlockingState === false) {
                this.setState({ isVisibleBackdropOpen: false, isInvisibleBackdropOpen: false });
            } else {
                this.setState({ isVisibleBackdropOpen: false, isInvisibleBackdropOpen: false, visibleMessage: this.props.uiBlockingState });
            }
        }
    }
}
