import * as React from "react";
import { Component } from "react";
import SimplePanel from "../Panels/SimplePanel";
import Services from "../Services/Platform/Services";
import CloseButton from "../Components/Buttons/Composite/CloseButton";
import { Endianness } from "@inductosense/typescript-fetch";
import Button from "../Components/Buttons/Button";
import moment from "moment";
import ServiceGeneralError from "../Services/Platform/ServiceGeneralError";

interface ImportDashboardProps {
    onClose(): void;
}

interface ImportDashboardState {
    isImporting: boolean;
    importRound: number;
    files: FileList | null;
}

// Called ImportExport because future import functionality would be added here
export default class ImportDashboard extends Component<ImportDashboardProps, ImportDashboardState> {
    fileUpload: React.RefObject<HTMLInputElement>;

    constructor(props: ImportDashboardProps) {
        super(props);

        this.state = {
            isImporting: false,
            importRound: 0,
            files: null
        };

        this.fileUpload = React.createRef();
    }

    render() {
        return <SimplePanel
            title="Import"
            titleAlignment="centre"
            actionButton={this.closeButton()}
            content={this.content()}
            shouldApplyPadding={false}
        />
    }

    private closeButton() {
        return <CloseButton onClick={() => this.props.onClose()} />;
    }

    filterCsvFiles(files: File[]) {
        return files.filter(f => f.name.toLowerCase().endsWith(".csv"));
    }

    filterDatFiles(files: File[]) {
        return files.filter(f => f.name.toLowerCase().endsWith(".dat"));
    }

    content() {
        const { files, isImporting, importRound } = this.state;

        if (files) {
            const filesArr = Array.from(files);

            console.log("CSV files", this.filterCsvFiles(filesArr).length);
            console.log("DAT files", this.filterDatFiles(filesArr).length);
        }

        return <div style={{ marginLeft: 20 }}>
            <h2>Import CSV/DAT files</h2>

            <input key={`file${importRound}`} type="file" multiple onChange={this.handleFileSelect.bind(this)} accept=".csv,.dat" />

            <p>Files: {files?.length}</p>

            {files !== null && files.length > 0 ?
                <Button label="Import" isSpinning={isImporting} onClick={() => this.onImportClick(files)} /> :
                <Button label="Import" isSpinning={isImporting} onClick={() => null} isDisabled={true} />
            }
        </div>;
    }

    // Code for CSV import here: https://dev.azure.com/Inductosense/Software/_workitems/edit/993

    uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    parseStrUnits(value: string, expectedUnits: string) { // TODO: Error handlingp
        const newValue = value.replace(expectedUnits, "");
        return parseFloat(newValue);
    }

    async getSensorList() {
        return Services.SensorGroups.getSensorList();
    }

    handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ files: event.target.files });
    }

    async uploadCSVs(files: File[]) {
        const sensors = await this.getSensorList();
        const devices = await Services.Devices.getAllDevices();

        for (const file of files) {

            const text = await file?.text();
            const lines = text?.split(/\r\n|\r|\n/);

            const metaline = lines![0].split(",");

            const timestampStr = metaline[0].replace("  ", " ");
            const locationStr = metaline[1];
            //const materialStr = metaline[2];

            const thicknessMm = this.parseStrUnits(metaline[3], "mm");
            const velocity = this.parseStrUnits(metaline[4], "");
            const rfidStr = metaline[5];
            //const frequencyMHz = this.parseStrUnits(metaline[6], "MHz");
            //const calibrationValueMm = this.parseStrUnits(metaline[7], "mm");
            const sysDelaySeconds = this.parseStrUnits(metaline[8], "s");

            const temperature = this.parseStrUnits(metaline[9], "�C");
            const usernameStr = metaline[10];
            //const companyStr = metaline[11];
            //const siteStr = metaline[12];
            const serialNumberStr = metaline[13];
            const versionStr = metaline[14];
            const minThicknessMm = this.parseStrUnits(metaline[15], "mm");
            const averageCount = this.parseStrUnits(metaline[16], "");

            const dataRows = lines?.filter((_v, i) => i >= 2);
            if (dataRows === undefined) throw new Error("DataRows undefined");

            const times = dataRows.map(r => parseFloat(r.split(",")[0]));
            const sampleIntervalInSeconds = (times[1] - times[0]) * 10 ** -9;

            const samples = dataRows.map(r => parseFloat(r.split(",")[1])).filter(r => !isNaN(r));

            const arrayBuffer = new ArrayBuffer(samples.length * 4);
            const dataView = new DataView(arrayBuffer);

            for (const [index, sample] of samples.entries()) {
                dataView.setFloat32(index * 4, sample, true);
            }

            const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));

            const sensor = sensors.find(s => s.rfid === rfidStr);
            if (sensor === undefined) throw new Error(`Sensor with RFID ${rfidStr} does not currently exist`);

            const device = devices.find(d => Math.abs(sysDelaySeconds - d.systemDelayInSeconds) <= 10e-6
                && (d.serialNumber === serialNumberStr || serialNumberStr === "0"));
            if (device === undefined) throw new Error(`Device with serial number ${serialNumberStr} and sys delay ${sysDelaySeconds} does not currently exist`);
            
            await Services.SensorReadingsService.sensorsIdReadingsPost(sensor.id, {
                averageCount,
                created: new Date(),
                firmwareVersion: versionStr,
                locationTag: locationStr,
                materialId: sensor.structureMaterial.id,
                sampleIntervalInSeconds,
                payload: {
                    id: this.uuidv4(),
                    data: base64String,
                    bitDepth: 32,
                    endianness: Endianness.LittleEndian,
                    format: "Base64",
                    numberOfPresamples: 300,
                    samplingFrequencyInHertz: 1 / sampleIntervalInSeconds
                },
                temperature,
                timestamp: moment(timestampStr, "DD/MM/YYYY hh:mm:ss").toDate(),
                userName: usernameStr,
                minimumThicknessInMillimetres: minThicknessMm,
                thicknessFromDeviceInMetres: !isNaN(thicknessMm) ? (thicknessMm * 1000) : undefined,
                velocityFromDeviceInMetresPerSecond: velocity,
                sensorId: sensor.id,
                deviceId: device.id,
                id: this.uuidv4(),
                txChannel: 0, // TODO: Fill this field
                rxChannel: 0 // TODO: Fill this field
            });
        }
    }

    async uploadDATs(files: File[]) {
        const buffers = await Promise.all(files.map(f => f.arrayBuffer()));
        await Services.DataUploadService.postReadingDats(buffers)
    }

    async onImportClick(files: FileList) {

        this.setState({ isImporting: true });
        const filesArr = Array.from(files);

        try {
            const csvFiles = this.filterCsvFiles(filesArr);
            await this.uploadCSVs(csvFiles);

            const datFiles = this.filterDatFiles(filesArr);
            await this.uploadDATs(datFiles);

            alert("Imported successfully");
        }
        catch (ex) {
            if (ex instanceof ServiceGeneralError) {
                alert(ex.responseErrors?.map(e => e.message).join(", "));
            } else {
                console.error(ex);
                alert("Error importing readings");
            }
        }

        this.setState(state => ({ isImporting: false, files: null, importRound: state.importRound + 1 }));
    }
}
