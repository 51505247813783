import { DCUsersApi, Dcuserpost } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class DcUsersService extends InductosenseService<DCUsersApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(DCUsersApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getUsers() {
        await this.ensureLatestConfig();
        return this.serverApi.getDcUsers({});
    }

    @wrapWithErrorHandling()
    async getDcUserByUsername(username: string) {
        await this.ensureLatestConfig();
        const result = (await this.serverApi!.getDcUsers({username}))?.[0];
        if (!result) throw Error("DC User not found.");
        return result;
    }

    @wrapWithErrorHandling()
    async postDcUsers(dcuserpost: Dcuserpost) {
        await this.ensureLatestConfig();
        return this.serverApi.postDcUsers({ dcuserpost });
    }

    @wrapWithErrorHandling()
    async putDcUsersId(id: string, dcuserpost: Dcuserpost) {
        await this.ensureLatestConfig();
        return this.serverApi.putDcUsersId({ id, dcuserpost });
    }

    @wrapWithErrorHandling()
    async deleteDcUsersId(id: string) {
        await this.ensureLatestConfig();
        return this.serverApi.deleteDcUsersId({ id });
    }
}
