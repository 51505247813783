import * as React from "react";
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

interface ItemListProps {
    items: string[];
    itemsChecked: boolean[];
    onSelectionChanged?(itemsChecked: boolean[]): void;
    onEditClick?(index: number): void;
    onDeleteClick?(index: number): void;
    showEditIcons: boolean;
    showDeleteIcons?: boolean;
    showCheckboxes?: boolean;
}

const ItemList: React.FunctionComponent<ItemListProps> = ({ items, itemsChecked, onSelectionChanged, onEditClick,
    showEditIcons, showCheckboxes = true, showDeleteIcons = false, onDeleteClick }) => {
    const handleToggle = (index: number) => {
        itemsChecked[index] = !itemsChecked[index];

        if (onSelectionChanged) {
            onSelectionChanged(itemsChecked);
        }
    }

    return (
        <div>
            <List>
                {items.map((item, index) => {
                    const labelId = `checkbox-list-label-${index}`;

                    return (
                        <ListItem key={index} role={undefined} button onClick={() => handleToggle(index)} style={{ "width": "300px" }}>
                            { showCheckboxes ?
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={itemsChecked[index]}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ "aria-labelledby": labelId }}
                                    />
                                </ListItemIcon>
                                : null
                            }

                            <ListItemText
                                id={labelId}
                                primary={item}
                            />

                            <ListItemSecondaryAction>
                                {showEditIcons ?
                                    <IconButton
                                        edge="end"
                                        aria-label="edit"
                                        onClick={() => {
                                            if (onEditClick) {
                                                onEditClick(index);
                                            }
                                        }}>
                                        <Edit />
                                    </IconButton>
                                    : null
                                }
                                {showDeleteIcons ?
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            if (onDeleteClick) {
                                                onDeleteClick(index);
                                            }
                                        }}>
                                        <Delete />
                                    </IconButton>
                                    : null
                                }
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })
                }
            </List>
        </div>
    );
}

export default ItemList;
