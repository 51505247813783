import * as React from "react";
import { coordsEqual } from "Types/CartesianSpace";
import { Component } from "react";
import { LinearOrTimeScale } from "Types/D3Extensions";
import ErrorPoint from "./ErrorPoint";
import { DateCoordinate } from "../../../Types/DateCoordinate";
import { ScaleLinear } from "d3";

interface ErrorPointsProps {
    errorPoints?: DateCoordinate[];
    highlightedPoint?: DateCoordinate | null;
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    onHoveredPointChange?(point: DateCoordinate | null): void;
    onPointDoubleClicked?(point: DateCoordinate): void;
    onPointRightClicked?(point: DateCoordinate): void;
    colour: string;
    colourLight: string;
}

export default class ErrorPoints extends Component<ErrorPointsProps> {
    render() {
        const { colourLight } = this.props;

        return (
            <g>
                {this.props.errorPoints?.map((e, index) => this.errorPoint(e, index, colourLight))}

                {this.highlighted()}
            </g>
        );
    }

    private highlighted() {
        const { highlightedPoint, errorPoints, colour } = this.props;

        if (highlightedPoint && errorPoints && errorPoints?.filter(p => coordsEqual(highlightedPoint, p)).length > 0) {
            return this.errorPoint(highlightedPoint, "highlighted", colour);
        } else {
            return null;
        }
    }

    private errorPoint(x: DateCoordinate, key: string | number, colour: string) {
        return (
           <ErrorPoint
                key={key}
                colour={colour}
                xScale={this.props.xScale}
                yScale={this.props.yScale}
                onPointMouseEnter={() => this.onPointMouseEnter(x)}
                onPointMouseLeave={() => this.onPointMouseLeave()}
                onPointDoubleClicked={this.props.onPointDoubleClicked?.bind(this, x)}
                onPointRightClicked={this.props.onPointRightClicked?.bind(this, x)}
               x={x}
            />
        );
    }

    private onPointMouseEnter(point: DateCoordinate) {
        const { onHoveredPointChange } = this.props;
        if (onHoveredPointChange) onHoveredPointChange(point);
    }

    private onPointMouseLeave() {
        const { onHoveredPointChange } = this.props;
        if (onHoveredPointChange) onHoveredPointChange(null);
    }
}
