import { Material, MaterialsApi, Materialpost } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class MaterialsService extends InductosenseService<MaterialsApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(MaterialsApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getAllMaterials() {
        await this.ensureLatestConfig();
        const result: Material[] = await this.serverApi!.getMaterials();
        return result;
    }

    @wrapWithErrorHandling()
    async createMaterial(material: Materialpost) {
        await this.ensureLatestConfig();
        await this.serverApi!.postMaterials({
            materialpost: material
        });
        return;
    }

    @wrapWithErrorHandling()
    async updateMaterial(id: string, material: Materialpost) {
        await this.ensureLatestConfig();
        await this.serverApi!.putMaterial({
            id,
            materialpost: material
        });
        return;
    }

    @wrapWithErrorHandling()
    async deleteMaterial(id: string) {
        await this.ensureLatestConfig();
        await this.serverApi!.deleteMaterial({
            id
        });
        return;
    }
}
