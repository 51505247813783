// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logotype-mask { overflow: hidden; }

.logotype-mask-vh-appear { width: 0vh; }
.logotype-mask-vw-appear { width: 0vw; }

.logotype-mask-vh-appear-active { width: 85vh; transition: width var(--splash-screen-logotype-animation-duration); }
.logotype-mask-vw-appear-active { width: 58.5vw; transition: width var(--splash-screen-logotype-animation-duration); }

.logotype-mask-vh-appear-done { width: 85vh; }
.logotype-mask-vw-appear-done { width: 58.5vw; }
`, "",{"version":3,"sources":["webpack://./Styling/Animations/SplashScreenAnimation/LogotypeMask.css"],"names":[],"mappings":"AAAA,iBAAiB,gBAAgB,EAAE;;AAEnC,2BAA2B,UAAU,EAAE;AACvC,2BAA2B,UAAU,EAAE;;AAEvC,kCAAkC,WAAW,EAAE,kEAAkE,EAAE;AACnH,kCAAkC,aAAa,EAAE,kEAAkE,EAAE;;AAErH,gCAAgC,WAAW,EAAE;AAC7C,gCAAgC,aAAa,EAAE","sourcesContent":[".logotype-mask { overflow: hidden; }\n\n.logotype-mask-vh-appear { width: 0vh; }\n.logotype-mask-vw-appear { width: 0vw; }\n\n.logotype-mask-vh-appear-active { width: 85vh; transition: width var(--splash-screen-logotype-animation-duration); }\n.logotype-mask-vw-appear-active { width: 58.5vw; transition: width var(--splash-screen-logotype-animation-duration); }\n\n.logotype-mask-vh-appear-done { width: 85vh; }\n.logotype-mask-vw-appear-done { width: 58.5vw; }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
