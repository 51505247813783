import * as React from "react";
import { FunctionComponent } from "react";
import { Select, MenuItem, Typography } from "@mui/material";
import TextFieldControlled from "./TextFieldControlled";

interface IntervalPickerProps {
    valueSeconds: number;
    onChange(newValueSeconds: number): void;
}

const IntervalPicker: FunctionComponent<IntervalPickerProps> = ({ valueSeconds, onChange }) => {
    const [isEmpty, setIsEmpty] = React.useState(false);

    const options = [
        {
            "label": "Minutes",
            "multiplier": 60
        },
        {
            "label": "Hours",
            "multiplier": 60*60
        },
        {
            "label": "Days",
            "multiplier": 60 * 60 * 24
        },
        {
            "label": "Months (approx)",
            "multiplier": 30 * 60 * 60 * 24
        },
        {
            "label": "Years (approx)",
            "multiplier": 365 * 60 * 60 * 24
        }
    ];

    let value = 0, selectedOption = options[0];
    for (const option of options) {
        if (valueSeconds % option.multiplier === 0 && valueSeconds >= option.multiplier) {
            value = valueSeconds / option.multiplier;
            selectedOption = option;
        }
    }

    const numberFieldWidth = 75;
    const numberFieldMargin = 5;
    const dropdownWidth = 90;

    return (
        <div style={{
            width: (numberFieldWidth + numberFieldMargin + dropdownWidth + 100),
            display: "flex",
            flexDirection: "row"
        }}>
            <Typography variant="body1" component="div">
                <p>once every</p>
            </Typography>&nbsp;
            <div style={{ width: numberFieldWidth, display: "inline-block", marginRight: numberFieldMargin }}>
                <TextFieldControlled
                    type="number"
                    value={isEmpty ? "" : value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value === "") {
                            setIsEmpty(true);
                        } else {
                            setIsEmpty(false);
                            onChange(e.target.valueAsNumber * selectedOption.multiplier);
                        }
                    }}
                />
            </div>
            <div style={{ width: dropdownWidth, display: "inline-block" }}>
                <Select
                    value={selectedOption.multiplier}
                    onChange={e =>
                        onChange(valueSeconds * (e.target.value as number) / selectedOption.multiplier)
                    }
                >
                    {options.map(option =>
                        <MenuItem
                            key={option.multiplier}
                            value={option.multiplier}>
                            {option.label}
                        </MenuItem>
                    )}
                </Select>
            </div>
        </div>
    );
}

export default IntervalPicker;
