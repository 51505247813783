import SignalCellular2Bar from "@mui/icons-material/SignalCellular2Bar";
import SignalCellular3Bar from "@mui/icons-material/SignalCellular3Bar";
import SignalCellularConnectedNoInternet1Bar from "@mui/icons-material/SignalCellularConnectedNoInternet1Bar";
import * as React from "react";
import { FunctionComponent } from "react";
import CassiaSvg from "Graphics/Icons/CassiaSvg.svg";
interface SignalStrengthIconProps {
    rssi: number | null;
    isGateway?: boolean;
}

const SignalStrengthIcon: FunctionComponent<SignalStrengthIconProps> = ({ rssi, isGateway }) => {
    const COLOR_RED = "red";
    const COLOR_AMBER = "#e39014";
    const COLOR_GREEN = "#1c751f";

    let icon;
    let color;

    if(rssi === null)return null;
    if (rssi >= -85 && rssi < -70) {
        icon = <SignalCellular2Bar />;
        color = COLOR_AMBER;
    } else if (rssi >= -70 && rssi <= 0) {
        icon = <SignalCellular3Bar />;
        color = COLOR_GREEN;
    } else {
        //When RSSI is below -85, the connection RDC may or may not be reliable, therefore we mark it in red colour with the "!" mark.
        icon = <SignalCellularConnectedNoInternet1Bar />;
        color = COLOR_RED;
    }

    return (
        <div style={{display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center"}}>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",}}>
                {isGateway && <CassiaSvg style={{width:"18px"}}/>}
                <span style={{ color, verticalAlign: "middle" }}>{icon}</span>
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <span style={{ color, fontSize: "11pt", marginLeft: "3pt" }}>
                    {rssi}
                </span>
                <span style={{ color, fontSize: "9pt", marginLeft: "1pt" }}>
                    dBm
                </span>
            </div>
        </div>
    );
};

export default SignalStrengthIcon;
