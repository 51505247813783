import Validator from "Validation/Validator";

export default class StringLengthMaximumValidator implements Validator<string> {
    errorMessage: string;
    maximum: number;

    constructor(maximum: number) {
        this.errorMessage = `Must be no longer than ${maximum} characters`;
        this.maximum = maximum;
    }

    isValid(value: string | null | undefined) {
        if (value === null || value === undefined) return true;

        return value.length <= this.maximum;
    }
}
