import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import Button from "Components/Buttons/Button";
import { CreateNewFolder } from "@mui/icons-material";

interface CreateFolderButtonProps {
    isDisabled?: boolean;
    isSpinning?: boolean;
    onClick(event?: MouseEvent): void;
}

const CreateFolderButton: FunctionComponent<CreateFolderButtonProps> = ({ isDisabled, isSpinning, onClick }) => (
    <Button
        label="Create"
        width={110}
        precedence="primary"
        icon={<CreateNewFolder />}
        isDisabled={isDisabled}
        isSpinning={isSpinning}
        onClick={event => onClick(event)}
    />
);

export default CreateFolderButton;
