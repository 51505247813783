import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import Button from "Components/Buttons/Button";
import { CheckCircle } from "@mui/icons-material";

interface YesButtonProps {
    isDisabled?: boolean;
    isSpinning?: boolean;
    onClick(event?: MouseEvent): void;
}

const YesButton: FunctionComponent<YesButtonProps> = ({ isDisabled, isSpinning, onClick }) => (
    <Button
        label="Yes"
        width={80}
        precedence="primary"
        icon={<CheckCircle/>}
        isDisabled={isDisabled}
        isSpinning={isSpinning}
        onClick={event => onClick(event)}
    />
);

export default YesButton;
