import { SensorReadingsApi, Readingpost } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";
import sleep from "../../Utilities/Sleep";

export default class SensorReadingsService extends InductosenseService<SensorReadingsApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(SensorReadingsApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling(100*1000)
    async sensorsIdReadingsGet(id: string, datetimeStart?: string, datetimeEnd?: string, last?: number) {
        await this.ensureLatestConfig();
        return this.serverApi.sensorsIdReadingsGet({ id, datetimeStart, datetimeEnd, last });
    }

    @wrapWithErrorHandling(10*60*1000)
    async getSensorsIdReadingsGraph(id: string) {
        await this.ensureLatestConfig();
        console.log("api getting...");
        const readings = await this.serverApi.getSensorsIdReadingsGraph({ id });

        return { ...readings, points: readings.points.map(r => { return { ...r, sensorId: id } }) };
    }

    @wrapWithErrorHandling()
    async readingsIdDelete(id: string) {
        await this.ensureLatestConfig();
        return this.serverApi.readingsIdDelete({ id });
    }

    @wrapWithErrorHandling()
    async sensorsIdReadingsPost(id: string, readingpost: Readingpost): Promise<string> {
        await this.ensureLatestConfig();
        const apiResponse = await this.serverApi!.sensorsIdReadingsPostRaw({ id, readingpost });
        const location = apiResponse.raw.headers.get("Location");
        if (location === null) {
            throw new Error("No location in API response");
        }

        const rxp = new RegExp("/v1/readings/(.*)").exec(location);
        const readingId = rxp![1];
        return readingId;
    }

    async waitForNewTrendGraph(sensorId: string, minDate: Date) {
        await this.ensureLatestConfig();
        let graphDate = new Date(0);
        while (graphDate <= minDate) {
            graphDate = (await this.getSensorsIdReadingsGraph(sensorId)).cacheCreated;

            if (graphDate <= minDate) {
                await sleep(2000);
            }
        }
    }

    @wrapWithErrorHandling()
    async sensorsIdReadingsPostRaw(id: string, readingpost: Readingpost) {
        await this.ensureLatestConfig();
        return this.serverApi.sensorsIdReadingsPostRaw({ id, readingpost });
    }

    @wrapWithErrorHandling()
    async postTriggerRecalculation() {
        await this.ensureLatestConfig();
        return this.serverApi.postTriggerRecalculation();
    }
}
