import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import IconButton from "Components/Buttons/IconButton";
//import SyncIcon from "../../Graphics/Icons/SyncIcon";
import { Build } from "@mui/icons-material";

interface UpdateConfigButtonProps {
    color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
    isDisabled?: boolean;
    onClick(event?: MouseEvent): void;
    className?: string;
}

const UpdateConfigButton: FunctionComponent<UpdateConfigButtonProps> = ({ color, isDisabled, onClick, className }) => (
    <IconButton
        icon={<Build fontSize="small" color={color} />}
        isDisabled={isDisabled}
        onClick={event => onClick(event)}
        className={className}
    />
);

export default UpdateConfigButton;
