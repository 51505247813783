import Validator from "Validation/Validator";

export default class NumberIsGreaterThanMinimumValidator implements Validator<number> {
    errorMessage: string;
    minimum: number;

    constructor(minimum: number) {
        this.errorMessage = `Must be greater than minimum (${minimum})`;
        this.minimum = minimum;
    }

    isValid(value: number | null | undefined) {
        if (value === null || value === undefined) return false;
        return value > this.minimum;
    }
}
