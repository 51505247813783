import * as React from "react";
import { Component } from "react";
import { Gateway } from "@inductosense/typescript-fetch";
import GatewayForm from "Views/Gateways/Forms/CreateOrEditGatewayForm";
import ModalPanel from "Panels/ModalPanel";
import UiSettings from "../../../Model/UiSettings";

interface CreateOrEditGatewayModalPanelProps {
    gateway?: Gateway;
    shouldBeShown: boolean;
    onClose(): void;
    uiSettings: UiSettings;
}

interface CreateOrEditGatewayModalPanelState {
    isSaving: boolean;
}

export default class CreateOrEditGatewayModalPanel
    extends Component<CreateOrEditGatewayModalPanelProps, CreateOrEditGatewayModalPanelState> {

    constructor(props: CreateOrEditGatewayModalPanelProps) {
        super(props);

        this.state = {
            isSaving: false
        };
    }

    render() {
        const { onClose, shouldBeShown, gateway: gateway } = this.props;
        const { isSaving } = this.state;

        return (
            <ModalPanel
                title={`${gateway ? "Edit" : "Add"} gateway`}
                shouldBeShown={shouldBeShown}
                onClose={() => !isSaving && onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        const { onClose, gateway: gateway, uiSettings } = this.props;

        return (
            <GatewayForm
                gateway={gateway}
                onSave={() => this.onSave()}
                onSaveComplete={() => this.onSaveComplete()}
                onCancel={() => onClose()}
                uiSettings={uiSettings}
            />
        );
    }

    private onSave() {
        this.setState({ isSaving: true });
    }

    private onSaveComplete() {
        this.props.onClose();
        this.setState({
            isSaving: false
        });
    }
}
