export default class ServiceTimeoutError extends Error {
    constructor(message?: string) {
        super(message || "Network timeout.");

        this.name = "ServiceTimeoutError";

        // "Error" magically breaks the prototype chain so we must restore it
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
