import * as React from "react";
import { YUnit } from "Types/CartesianSpace";
import { FunctionComponent } from "react";
import { LinearOrTimeScale } from "Types/D3Extensions";
import { ScaleLinear } from "d3";
import X2Y2Rect from "./X2Y2Rect";

interface WarningCriticalAreasProps {
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    warningPosition?: YUnit;
    criticalPosition?: YUnit;
}

const WarningCriticalAreas: FunctionComponent<WarningCriticalAreasProps> = ({ xScale, yScale, warningPosition, criticalPosition }) => {
    return (
        <g>
            {warningPosition !== undefined ?
                // Shade the area between the warning threshold and the critical threshold (or the x-axis if there is no critical threshold)
                <X2Y2Rect
                    x1={xScale.range()[0]}
                    x2={xScale.range()[1]}
                    y1={yScale(warningPosition)}
                    y2={criticalPosition !== undefined ? Math.min(yScale(criticalPosition), yScale.range()[0]) : yScale.range()[0]}
                    fill="orange"
                    fillOpacity={0.3}
                />
                : null
            }

            {criticalPosition !== undefined ?
                // Shade the area between the critical threshold and the x-axis
                <X2Y2Rect
                    x1={xScale.range()[0]}
                    x2={xScale.range()[1]}
                    y1={yScale(criticalPosition)}
                    y2={yScale.range()[0]}
                    fill="red"
                    fillOpacity={0.3}
                />
                : null
            }
        </g>
    );
}

export default WarningCriticalAreas;
