import * as MaterialUiIcons from "@mui/icons-material";
import * as React from "react";
import { FunctionComponent } from "react";

interface CloudUploadIconProps {
    color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
}

const CloudUploadIcon: FunctionComponent<CloudUploadIconProps> = ({ color }) => <MaterialUiIcons.CloudUpload color={color}/>;

export default CloudUploadIcon;
