import * as React from "react";
import { FunctionComponent } from "react";
import * as Unicode from "Constants/UnicodeCharacters";

interface ThermalCoefficientUnitsTextProps {
    temperatureUnits: "celsius" | "fahrenheit";
}

const ThermalCoefficientUnitsText: FunctionComponent<ThermalCoefficientUnitsTextProps> = ({ temperatureUnits }) => {
    const unitsLetter = temperatureUnits === "celsius" ? "C" : "F";

    return <>
        <sup>&mu;m</sup>&frasl;<sub>m{Unicode.Degrees}{unitsLetter}</sub> &nbsp;
    </>;

    /*return <>
        &mu; &nbsp;
        <sup>m</sup>&frasl;<sub>m</sub> &nbsp;
        <sup>1</sup>&frasl;<sub>{Unicode.Degrees}{unitsLetter}</sub>
    </>;*/
}

export default ThermalCoefficientUnitsText;
