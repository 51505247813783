// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emblem-container-vh-appear {
    width: 0vh;
    height: 0vh;
}

.emblem-container-vw-appear {
    width: 0vw;
    height: 0vw;
}

.emblem-container-vh-appear-active {
    width: 25vh;
    height: 25vh;
    transition: width var(--splash-screen-emblem-animation-durationy);
    transition: height var(--splash-screen-emblem-animation-durationy);
}

.emblem-container-vw-appear-active {
    width: 20vw;
    height: 20vw;
    transition: width var(--splash-screen-emblem-animation-durationy);
    transition: height var(--splash-screen-emblem-animation-durationy);
}

.emblem-container-vh-appear-done {
    width: 25vh;
    height: 25vh;
}

.emblem-container-vw-appear-done {
    width: 20vw;
    height: 20vw;
}
`, "",{"version":3,"sources":["webpack://./Styling/Animations/SplashScreenAnimation/EmblemContainer.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iEAAiE;IACjE,kEAAkE;AACtE;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iEAAiE;IACjE,kEAAkE;AACtE;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".emblem-container-vh-appear {\n    width: 0vh;\n    height: 0vh;\n}\n\n.emblem-container-vw-appear {\n    width: 0vw;\n    height: 0vw;\n}\n\n.emblem-container-vh-appear-active {\n    width: 25vh;\n    height: 25vh;\n    transition: width var(--splash-screen-emblem-animation-durationy);\n    transition: height var(--splash-screen-emblem-animation-durationy);\n}\n\n.emblem-container-vw-appear-active {\n    width: 20vw;\n    height: 20vw;\n    transition: width var(--splash-screen-emblem-animation-durationy);\n    transition: height var(--splash-screen-emblem-animation-durationy);\n}\n\n.emblem-container-vh-appear-done {\n    width: 25vh;\n    height: 25vh;\n}\n\n.emblem-container-vw-appear-done {\n    width: 20vw;\n    height: 20vw;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
