import * as React from "react";
import { coordsEqual } from "Types/CartesianSpace";
import { CSSProperties, FunctionComponent } from "react";
import { LinearOrTimeScale } from "Types/D3Extensions";
import { ScaleLinear } from "d3";
import ScatterPoint from "./ScatterPoint";
import { DateCoordinate } from "../../../Types/DateCoordinate";

interface ScatterPointsProps {
    points: DateCoordinate[];
    outliers?: DateCoordinate[];
    highlightedPoint?: DateCoordinate | null;
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    onHoveredPointChange?(point: DateCoordinate | null): void;
    onPointDoubleClicked?(point: DateCoordinate): void;
    onPointRightClicked?(point: DateCoordinate): void;
    radius: number;
    colour: string;
    colourLight: string;
}

const ScatterPoints: FunctionComponent<ScatterPointsProps> = (props: ScatterPointsProps) => {

    const onPointMouseEnter = (point: DateCoordinate) => {
        const { onHoveredPointChange } = props;
        if (onHoveredPointChange) onHoveredPointChange(point);
    }

    const onPointMouseLeave = () => {
        const { onHoveredPointChange } = props;
        if (onHoveredPointChange) onHoveredPointChange(null);
    }

    const point = (coordinates: DateCoordinate, key: string | number, radius: number, style: CSSProperties | undefined) => {
        return (
            <ScatterPoint
                key={key}
                coordinates={coordinates}
                onPointMouseEnter={onPointMouseEnter}
                onPointMouseLeave={onPointMouseLeave}
                radius={radius}
                style={style}
                xScale={props.xScale}
                yScale={props.yScale}
                onPointDoubleClicked={props.onPointDoubleClicked}
                onPointRightClicked={props.onPointRightClicked}
            />
        );
    }

    const highlight = () => {
        const { points, outliers, highlightedPoint, radius, colour } = props;

        if (!highlightedPoint) return null;

        if ([...points, ...outliers || []].filter(p => coordsEqual(highlightedPoint, p)).length > 0) {
            return point(highlightedPoint, "highlight", radius, { stroke: colour, strokeWidth: 2, fill: colour });
        } else {
            return null;
        }
    }

    const points = () => {
        const { points, radius, colour } = props;
        return points.map((coordinates, index) => point(coordinates, index, radius, { stroke: colour, strokeWidth: 2, fill: "transparent" }));
    }

    const outliers = () => {
        const { outliers, radius, colourLight } = props;
        if (!outliers) return null;
        return outliers.map((coordinates, index) => point(coordinates, index, radius * 0.75, { stroke: colourLight, strokeWidth: 2, fill: "none" }));
    }

    return (
        <g>
            {points()}
            {outliers()}
            {highlight()}
        </g>
    );
}

export default ScatterPoints;
