import * as React from "react";
import { Component, FunctionComponent } from "react";
import { extendHex, defineGrid } from "honeycomb-grid";
//import * as d3 from "d3";
import { formatNumberWithThousandsPlaceSeparators } from "Utilities/Formatting";
import useResizeObserver from "use-resize-observer";
import ArrayDataItem from "./ArrayDataItem";
//import ToggleButtonGroup from "Components/Buttons/ToggleButtonGroup";

interface HexagonProps {
    thicknessData: ArrayDataItem[][][];
    background(value: number): string | undefined;
    chooseThickness: boolean;
    rateOfChangeData: number[][][];
    numRows: number;
    numCols: number;
    sensorNames: string[];
    onNodeClick(coords: { x: number; y: number }, node?: ArrayDataItem): void;
}

interface SvgHexGridProps {
    thicknessData?: ArrayDataItem[][];
    background?(value: number): string | undefined;
    chooseThickness?: boolean;
    rateOfChangeData?: number[][];
    draggable?: boolean;
    width: number;
    height: number;
    translateX: number;
    translateY: number;
    bTransX?: number;
    bTransY?: number;
    start: [number, number];
    rotate: boolean;
    onNodeClick(coords: { x: number; y: number }, node?: ArrayDataItem): void;
}

/*function makeDraggable(comp: Element, transX: number, transY: number, bTransX: number, bTransY: number) {
    let translateX = transX;
    let translateY = transY;

    if (d3 === undefined) {
        return null;
    }

    const Hex = extendHex({ size: 30, orientation: "flat" });
    const Grid = defineGrid(Hex);
    const hexRect = Grid.parallelogram({
        width: 10, height: 10, direction: 3
    }).map((hex) => hex.toPoint());


    const handleDrag = d3.drag()
        .subject(function () {
            return { x: translateX, y: translateY }
        })
        .on("drag", function () {
            const me = d3.select(this);
            translateX = d3.event.x;
            translateY = d3.event.y;
            const transform = `translate(${translateX}, ${translateY})`;
            me.attr("transform", transform);
        })
        .on("end", function () {
            const me = d3.select(this);


            let min = Math.abs(hexRect[0].x - translateX + bTransX) ** 2 + Math.abs(hexRect[0].y - translateY + bTransY) ** 2;
            let slot = { x: hexRect[0].x, y: hexRect[0].y };

            for (let i = 0; i < hexRect.length; i++) {
                const dist = Math.abs(hexRect[i].x - translateX + bTransX) ** 2 + Math.abs(hexRect[i].y - translateY + bTransY) ** 2;
                if (dist < min) {
                    min = dist;
                    slot = { x: hexRect[i].x, y: hexRect[i].y };
                }
            }


            translateX = bTransX + slot.x;
            translateY = bTransY + slot.y;
            const transform = `translate(${translateX}, ${translateY})`;
            me.attr("transform", transform);
        });
    handleDrag(d3.select(comp));
    return;
}*/

class SvgHexGrid extends Component<SvgHexGridProps> {
    gRef: React.RefObject<SVGGElement>;

    constructor(props: SvgHexGridProps) {
        super(props);
        this.gRef = React.createRef<SVGGElement>();
        this.state = {
        };
    }
    componentDidMount() {
        console.log("a");
        /*const node = this.gRef.current;
        const bTransX = this.props.bTransX ? this.props.bTransX : 0;
        const bTransY = this.props.bTransY ? this.props.bTransY : 0;

        if (node) {
            makeDraggable(node, this.props.translateX, this.props.translateY, bTransX, bTransY);
        }*/
    }


    render() {
        const { width, height, translateX, translateY, background, draggable, thicknessData, rateOfChangeData, chooseThickness, rotate, onNodeClick } = this.props;


        const Hex = extendHex({ size: 30, orientation: "flat" });
        const Grid = defineGrid(Hex);
        const corners = Hex().corners();

        const hexRect = Grid.parallelogram({
            width: width, height: height, direction: 3, start: this.props.start
        }).map((hex) => hex.toPoint());

        const cornersPath = corners.map(({ x, y }) => `${x},${y}`).join(" ");
        
        const text = (i: number) => {
            if (thicknessData === undefined) return null;
            if (rotate) {
                return thicknessData[i % height][width - 1 - Math.floor(i / height)];
            }
            return thicknessData[height - 1 - i % height][Math.floor(i / height)];
        }

        const rateText = (i: number) => {
            if (rateOfChangeData === undefined) return null;
            if (rotate) {
                return rateOfChangeData[i % height][width - 1 - Math.floor(i / height)];
            }
            return rateOfChangeData[height - 1 - i % height][Math.floor(i / height)];
        }

        /*const coords = (i: number) => {
            if (rotate) {
                return { y: i % height, x: width - 1 - Math.floor(i / height) };
            }
            return { y: height - 1 - i % height, x: Math.floor(i / height) };
        }*/
        console.log(onNodeClick);

        return (
            <g style={{ display: "block", margin: "auto", position: "absolute" }} transform={`translate(${ translateX }, ${ translateY })`} ref={draggable ? this.gRef : undefined}>

                {hexRect.map(({ x, y }, i) => { /*onNodeClick(coords(i), i)*/
                    return (
                        <g key={`${x}, ${y}`} onClick={() => {
                            onNodeClick({ x: height - 1 - i % height, y: Math.floor(i / height) }, text(i) || undefined);
                        }}>
                            <title>{text(i)?.Tooltip}</title>
                            <polygon
                                stroke="#000000"
                                fill={background
                                    ? chooseThickness && text(i) !== null
                                        ? background(text(i)!.thicknessMm)
                                        : !chooseThickness && rateText(i) !== null ? background(rateText(i)!) : "grey"
                                    : "none"
                                }
                                strokeWidth={background && !((height - 1 - i % height) % 2) && !(Math.floor(i / height) % 2) ? 2 : 0.5}
                                points={cornersPath}
                                transform={`translate(${x}, ${y})`}
                            />
                            {chooseThickness && text(i) !== null ?

                                <text
                                    transform={`translate(${x + 30}, ${y + 26})`}
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    style={{ cursor: "pointer" }}
                                >{formatNumberWithThousandsPlaceSeparators(+text(i)!.thicknessMm.toFixed(2))}</text>
                                : !chooseThickness && rateText(i) !== null ?

                                    <text
                                        transform={`translate(${x + 30}, ${y + 26})`}
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                        fontSize={10}
                                    >{formatNumberWithThousandsPlaceSeparators(+rateText(i)!.toFixed(2))}</text>
                                    : null
                            }
                        </g>
                    )
                })}
            </g>
        );
    }
}

const ThicknessHexagon: FunctionComponent<HexagonProps> = ({ thicknessData, numCols, numRows, chooseThickness, rateOfChangeData, background, onNodeClick }) => {


    const { ref, ...sizeU } = useResizeObserver<HTMLDivElement>({ "box": "content-box" });
    const size = { width: sizeU.width || 100, height: sizeU.height || 100 };

    return (
        <div
            style={{
                width: "100%", height: "100%" }} ref={ref}>
            <svg viewBox="0 0 800 600" style={{ width: size.width, height: size.height - 10 }}>
                <SvgHexGrid
                    width={5}
                    height={5}
                    translateX={1600 - 360 - 500}
                    translateY={400 - 20 - 150}
                    start={[0, 0]}
                    rotate={false}
                    onNodeClick={onNodeClick}
                />
                {<SvgHexGrid
                    background={background}
                    draggable={true}
                    width={numCols * 2 - 1}
                    height={numRows * 2 - 1}
                    translateX={1600 - 360 - 500}
                    translateY={400 - 20 - 150}
                    bTransX={1300}
                    bTransY={300}
                    start={[0, 0]}
                    chooseThickness={chooseThickness}
                    thicknessData={thicknessData[0]}
                    rateOfChangeData={rateOfChangeData[0]}
                    rotate={false}
                    onNodeClick={onNodeClick}
                />}
                {/*<SvgHexGrid
                        background={background}
                        draggable={true}
                        width={numCols * 2 - 1}
                        height={numRows * 2 - 1}
                        translateX={1030 - 360}
                        translateY={300 - 90 * Math.sqrt(3) - 20}
                        bTransX={1300}
                        bTransY={300}
                        start={[0, 0]}
                        chooseThickness={chooseThickness}
                        thicknessData={thicknessData[1]}
                        rateOfChangeData={rateOfChangeData[1]}
                        rotate={this.state.rotate[1]}
                        onNodeClick={onNodeClick}
                    />*/}
            </svg>
            {/*<div style={{ margin: "10px 20px 10px" }}>

                    {sensorNames.map((sensor, i) =>
                        <ToggleButtonGroup
                            options={[`Rotate ${sensor}`]}
                            initialSelected={""}
                            size={"small"}
                            exclusive={true}
                            onChange={(chosenValue) => {
                                const rotate = this.state.rotate;
                                rotate[i] = chosenValue === `Rotate ${sensor}`;
                                this.setState({ rotate: rotate });
                            }}
                            key={sensor}
                        />
                    )}

                </div>*/}
        </div>

    );
}

export default ThicknessHexagon;
