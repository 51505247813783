import { ModelError } from "@inductosense/typescript-fetch";

export default class ServiceGeneralError extends Error {
    public responseErrors?: ModelError[] = [];
    public responseErrorsLen?: number = 0;

    constructor(message?: string, responseErrors?: ModelError[]) {
        super(message || "Service error.");

        this.name = "ServiceGeneralError";

        // "Error" magically breaks the prototype chain so we must restore it
        Object.setPrototypeOf(this, new.target.prototype);

        if (responseErrors !== undefined) {
            this.responseErrors = responseErrors;
            this.responseErrorsLen = 12;
        }
    }
}
