import { SensorReadingsApi, Reading, Readingparameterspost, Readingdetailed } from "@inductosense/typescript-fetch";
import { UnixEpoch } from "Constants/DateAndTime";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class ReadingsService extends InductosenseService<SensorReadingsApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(SensorReadingsApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getLatestReadingWithoutPayloadForSensor(sensorId: string) {
        await this.ensureLatestConfig();
        const result: Reading[] = await this.serverApi!.sensorsIdReadingsGet({ id: sensorId });
        if (!result || result.length === 0) throw new Error("No readings were found for the specified sensor.");
        return result[0];
    }

    //@wrapWithErrorHandling() //todo deal with
    async getReadingsWithoutPayloadsForSensor(
        sensorId: string,
        last?: number,
        datetimeStart = UnixEpoch,
        datetimeEnd = new Date("2050-01-01T10:00:00")) {
        await this.ensureLatestConfig();
        const result: Reading[] = await this.serverApi!.sensorsIdReadingsGet({
            id: sensorId,
            datetimeStart: datetimeStart.toISOString(),
            datetimeEnd: datetimeEnd.toISOString(),
            last
        });

        if (!result) throw new Error("No readings were found for the specified sensor.");

        return result;
    }

    @wrapWithErrorHandling()
    async readingsIdParametersGet(id: string) {
        await this.ensureLatestConfig();
        return this.serverApi.readingsIdParametersGet({ id });
    }

    @wrapWithErrorHandling()
    async readingsIdGet(id: string) {
        await this.ensureLatestConfig();
        return this.serverApi.readingsIdGet({ id });
    }

    async updateParametersForSensorReadings(sensorId: string, _readings: Reading[], parameters: Readingparameterspost) {
        await this.ensureLatestConfig();
        await this.serverApi.postSensorsIdReadingsParameters({ id: sensorId, readingparameterspost: parameters });
    }

    @wrapWithErrorHandling()
    async createNewParametersForReading(reading: Readingdetailed, parameters: Partial<Readingparameterspost>) {
        await this.ensureLatestConfig();
        if (!reading.id) throw new Error("The reading's ID should never be undefined at this point.");

        return this.serverApi!.readingsIdParametersPost({
            id: reading.id,
            readingparameterspost: {
                gateLower: parameters.gateLower ?? reading.parameters?.gateLower,
                gateUpper: parameters.gateUpper ?? reading.parameters?.gateUpper,
                threshold: parameters.threshold ?? reading.parameters?.threshold,
                temperature: parameters.temperature ?? reading.parameters?.temperature ?? reading.temperature,
                temperatureSource: parameters.temperatureSource ?? reading.parameters?.temperatureSource,
                velocity: parameters.velocity ?? reading.parameters?.velocity ?? reading.velocityFromDeviceInMetresPerSecond,
                velocitySource: parameters.velocitySource ?? reading.parameters?.velocitySource,
                peakAlgorithm: parameters.peakAlgorithm ?? reading.parameters?.peakAlgorithm,
                thicknessAlgorithm: parameters.thicknessAlgorithm ?? reading.parameters?.thicknessAlgorithm,
                structureMaterialId: parameters.structureMaterialId ?? reading.parameters?.structureMaterialId,
            }
        });
    }
}
