import * as React from "react";
import { Component } from "react";
import Button from "Components/Buttons/Button";

interface IntervalButtonProps {
    label: string;
    interval: number;
    onIntervalTriggered: () => void;
    onIntervalsStart: () => void;
    onIntervalsFinished: () => void;
    blockNewIntervals?: boolean;
}

interface IntervalButtonState {
    intervalObject?: number;
}

export default class IntervalButton extends Component<IntervalButtonProps, IntervalButtonState> {
    constructor(props: IntervalButtonProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { label } = this.props;

        return <Button
            onMouseDown={this.onMouseDown.bind(this)}
            onMouseUp={this.onMouseUp.bind(this)}
            onTouchStart={this.onMouseDown.bind(this)}
            onTouchEnd={this.onMouseUp.bind(this)}
            onMouseLeave={this.onMouseUp.bind(this)}
            onClick={() => null}
            label={label}
        />
    }

    onMouseDown() {
        const { interval, onIntervalTriggered, onIntervalsStart, blockNewIntervals } = this.props;
        if (blockNewIntervals) return;

        onIntervalsStart();

        const intervalObject = window.setInterval(() => onIntervalTriggered(), interval);
        this.setState({ intervalObject });
    }

    onMouseUp() {
        const { intervalObject } = this.state;
        const { onIntervalsFinished } = this.props;

        if (intervalObject) {
            clearInterval(intervalObject);
            onIntervalsFinished();
            this.setState({ intervalObject: undefined });
        }
    }
}
