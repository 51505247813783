import * as React from "react";
import { Component, CSSProperties } from "react";
import { FormSection } from "Types/Forms";
import { FormField } from "Forms/FormField";
import { Gateway, Gatewayconnectiontype, Gatewaytype } from "@inductosense/typescript-fetch";
import FieldIsRequiredValidator from "Validation/Fields/FieldIsRequiredValidator";
import Form from "Forms/Form";
import SaveAndCancelButtonsFormFooter from "Forms/Shared/SaveAndCancelButtonsFormFooter";
import Services from "Services/Platform/Services";
import Validator from "Validation/Validator";
import UiSettings from "../../../Model/UiSettings";
import TextFieldControlled from "Components/Input/TextFieldControlled";
import StringLengthMaximumValidator from "Validation/Strings/StringLengthMaximumValidator";
import DropDownListControlled from "../../../Components/Input/DropDownListControlled";
import Switch from "Components/Buttons/Switch";

const footerStyle: CSSProperties = {
    marginTop: 20,
    display: "flex",
    justifyContent: "flex-end"
};

interface CreateOrEditGatewayFormProps {
    gateway?: Gateway;
    onSave(): void;
    onSaveComplete(): void;
    onCancel(): void;
    uiSettings: UiSettings;
}

interface CreateOrEditGatewayFormState {
    fields: Map<string, FormField>;
    userTriedToSave: boolean;
    isSaving: boolean;
}

export default class CreateOrEditGatewayForm extends Component<CreateOrEditGatewayFormProps, CreateOrEditGatewayFormState> {
    private fieldsRequiredValidator: Validator<string>;
    private lengthValidator: Validator<string>;

    constructor(props: CreateOrEditGatewayFormProps) {
        super(props);

        const { gateway: gateway } = props;

        this.fieldsRequiredValidator = new FieldIsRequiredValidator("This field is required");
        this.lengthValidator = new StringLengthMaximumValidator(255);

        this.state = {
            fields: new Map([
                ["name", new FormField(
                    gateway?.displayName ?? null, []
                )],
                ["address", new FormField(
                    gateway?.address ?? null,
                    [this.fieldsRequiredValidator]
                )],
                ["physicalLocation", new FormField(
                    gateway?.physicalLocation ?? null,
                    [this.lengthValidator]
                )],
                ["username", new FormField(
                    gateway?.username ?? null,
                    [this.fieldsRequiredValidator]
                )],
                ["password", new FormField(
                    null,
                    gateway ? [] : [this.fieldsRequiredValidator]
                )],
                ["encryptPassword", new FormField(
                    gateway?.encryptPassword ? "on" : "off",
                    [this.fieldsRequiredValidator]
                )],
                ["connectionType", new FormField(
                    gateway?.connectionType ?? null,
                    gateway ? [] : [this.fieldsRequiredValidator]
                )],
            ]),
            userTriedToSave: false,
            isSaving: false
        };
    }

    render() {
        return (
            <Form
                sections={this.sections()}
                footer={this.footer()}
            />
        );
    }

    private sections() {
        return [
            this.nameSection(),
            this.addressSection(),
            this.physicalLocationSection(),
            this.usernameSection(),
            this.passwordSection(),
            this.encryptPasswordSection(),
            this.connectionTypeSection()
        ];
    }

    private footer() {
        return (
            <div style={footerStyle}>
                <SaveAndCancelButtonsFormFooter
                    isSaving={this.state.isSaving}
                    onSaveButtonClick={() => this.onSaveButtonClick()}
                    onCancelButtonClick={() => this.onCancelButtonClick()}
                />
            </div>
        );
    }

    private nameSection(): FormSection {
        const fieldName = "name";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: "Name", isRequired: false, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        isGateway={true}
                        width={350}
                        value={field?.value ?? ""}
                        disabled={this.state.isSaving}
                        onChange={change => this.onValueChanged(fieldName, change.target.value)}
                        validationError={field?.validators.filter(v => !v.isValid(field?.value)).map(v => v.errorMessage).join(",")}
                    />
                )
            }
        };
    }

    private addressSection(): FormSection {
        const fieldName = "address";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: "Hostname/IP address", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        width={350}
                        value={field?.value ?? ""}
                        disabled={this.state.isSaving}
                        onChange={change => this.onValueChanged(fieldName, change.target.value)}
                        validationError={field?.validators.filter(v => !v.isValid(field?.value)).map(v => v.errorMessage).join(",")}
                    />
                )
            }
        };
    }

    private physicalLocationSection(): FormSection {
        const fieldName = "physicalLocation";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: "Location", isRequired: false, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        width={350}
                        value={field?.value ?? ""}
                        disabled={this.state.isSaving}
                        onChange={change => this.onValueChanged(fieldName, change.target.value)}
                        validationError={field?.validators.filter(v => !v.isValid(field?.value)).map(v => v.errorMessage).join(",")}
                    />
                )
            }
        };
    }

    private usernameSection(): FormSection {
        const fieldName = "username";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: "Username", isRequired: true, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        width={350}
                        value={field?.value ?? ""}
                        disabled={this.state.isSaving}
                        onChange={change => this.onValueChanged(fieldName, change.target.value)}
                        validationError={field?.validators.filter(v => !v.isValid(field?.value)).map(v => v.errorMessage).join(",")}
                    />
                )
            }
        };
    }

    private passwordSection(): FormSection {
        const fieldName = "password";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: "Password", isRequired: !this.props.gateway, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <TextFieldControlled
                        width={350}
                        value={field?.value ?? ""}
                        disabled={this.state.isSaving}
                        onChange={change => this.onValueChanged(fieldName, change.target.value)}
                        validationError={field?.validators.filter(v => !v.isValid(field?.value)).map(v => v.errorMessage).join(",")}
                    />
                )
            }
        };
    }

    private encryptPasswordSection(): FormSection {
        const fieldName = "encryptPassword";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: "Encrypt Password", isRequired: !this.props.gateway, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <div style={{ "width": "350px" }}>
                        <Switch
                            initialState={field?.value == "on" ? "on" : "off" }
                            onChange={change => this.onValueChanged(fieldName, change)}
                        />
                    </div>
                )
            }
        };
    }

    private connectionTypeSection(): FormSection {
        const fieldName = "connectionType";
        const field = this.state.fields.get(fieldName);
        return {
            marginTop: 15,
            label: { text: "Connection type", isRequired: !this.props.gateway, marginTop: 5 },
            control: {
                isEditable: true,
                node: (
                    <DropDownListControlled<Gatewayconnectiontype>
                        width={350}
                        isGateway={true}
                        labelFor={item => item.toString()}
                        options={[Gatewayconnectiontype.Unknown, Gatewayconnectiontype.Internet, Gatewayconnectiontype.Cellular]}
                        onChange={v => this.onValueChanged(fieldName, v)}
                        selectedOption={field!.value as Gatewayconnectiontype /*Gatewayconnectiontype.Unknown*/}
                    />
                )
            }
        };
    }

    private async onSaveButtonClick() {
        if (!this.state.userTriedToSave) this.setState({ userTriedToSave: true });
        if (this.formValuesAreValid()) {
            this.props.onSave();
            this.setState({ isSaving: true });
            await this.saveForm();
        }
    }

    private onCancelButtonClick() {
        this.props.onCancel();
    }

    private onValueChanged(name: string, newValue: string | null) {
        const newFields = this.state.fields;
        const newField = newFields.get(name);
        if(newValue == "Ethernet"){
            newField!.value = "Internet"
        }else{
            newField!.value = newValue;
        }
        newFields.set(name, newField!);
        this.setState({ fields: newFields });
    }

    private formValuesAreValid() {
        for (const f of this.state.fields.values()) {
            if (!f.isValid()) return false;
        }
        return true;
    }

    private async saveForm() {
        if (this.props.gateway) {
            const displayNameChanged = (this.props.gateway!.displayName ?? "") !== (this.state.fields.get("name")!.value ?? "");
            const addressChanged = (this.props.gateway!.address ?? "") !== (this.state.fields.get("address")!.value ?? "");
            const physicalLocationChanged = (this.props.gateway!.physicalLocation ?? "") !== (this.state.fields.get("physicalLocation")!.value ?? "");
            const usernameChanged = (this.props.gateway!.username ?? "") !== (this.state.fields.get("username")!.value ?? "");
            const passwordMayHaveChanged = !!this.state.fields.get("password")!.value;
            const encryptPasswordChanged = (this.props.gateway!.encryptPassword ?? false) !== ((this.state.fields.get("encryptPassword")!.value ?? "off") == "on");
            const connectionTypeChanged = (this.props.gateway!.connectionType ?? "") !== (this.state.fields.get("connectionType")!.value ?? "");

            if (displayNameChanged || addressChanged || physicalLocationChanged || usernameChanged || passwordMayHaveChanged || encryptPasswordChanged || connectionTypeChanged) {
                await Services.Gateways.updateGateway(this.props.gateway.id, {
                    displayName: !displayNameChanged ? undefined : this.state.fields.get("name")!.value || undefined,
                    address: !addressChanged ? undefined : this.state.fields.get("address")!.value || undefined,
                    physicalLocation: !physicalLocationChanged ? undefined : this.state.fields.get("physicalLocation")!.value || undefined,
                    username: !usernameChanged ? undefined : this.state.fields.get("username")!.value || undefined,
                    password: this.state.fields.get("password")!.value || undefined,
                    encryptPassword: this.state.fields.get("encryptPassword")!.value == "on",
                    connectionType: this.state.fields.get("connectionType")!.value! as Gatewayconnectiontype
                });
            }
        } else {
            await Services.Gateways.createGateway({
                gatewayType: Gatewaytype.CassiaX2000,
                displayName: this.state.fields.get("name")!.value || undefined,
                address: this.state.fields.get("address")!.value!,
                physicalLocation: this.state.fields.get("physicalLocation")!.value || undefined,
                username: this.state.fields.get("username")!.value!,
                password: this.state.fields.get("password")!.value!,
                encryptPassword: this.state.fields.get("encryptPassword")!.value! == "on",
                connectionType: this.state.fields.get("connectionType")!.value! as Gatewayconnectiontype
            });
        }

        this.props.onSaveComplete();
    }
}
