import { DevicesApi, Devicepost, Device } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class DevicesService extends InductosenseService<DevicesApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(DevicesApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getDevicesOverview() {
        await this.ensureLatestConfig();
        return this.serverApi.getDevicesOverview();
    }

    @wrapWithErrorHandling()
    async getAllDevices() {
        await this.ensureLatestConfig();
        const result: Device[] = await this.serverApi!.devicesGet({});
        if (!result) throw Error("No devices were found in the devices table.");
        return result;
    }

    @wrapWithErrorHandling()
    async getDeviceBySerialNumber(serial: string) {
        await this.ensureLatestConfig();
        const result: Device = (await this.serverApi!.devicesGet({ serial }))?.[0];
        if (!result) throw Error("Device not found in the devices table.");
        return result;
    }

    @wrapWithErrorHandling()
    async getDevices(deviceIds: string[]) {
        await this.ensureLatestConfig();
        const devices: Device[] = [];

        for (const id of deviceIds) {
            const result: Device = await this.serverApi!.devicesIdGet({ id });
            if (!result) throw new Error(`No device was found with ID "${id}."`);
            devices.push(result);
        }

        return devices;
    }

    @wrapWithErrorHandling()
    async devicesPost(devicepost: Devicepost) {
        await this.ensureLatestConfig();
        await this.serverApi.devicesPost({ devicepost });
    }

    @wrapWithErrorHandling()
    async devicesIdPut(id: string, devicepost: Devicepost) {
        await this.ensureLatestConfig();
        await this.serverApi.devicesIdPut({ id, devicepost });
    }

    @wrapWithErrorHandling()
    async getDevice(deviceId: string) {
        await this.ensureLatestConfig();
        const result: Device = await this.serverApi!.devicesIdGet({ id: deviceId });
        if (!result) throw new Error(`No device was found with ID "${deviceId}."`);
        return result;
    }

    @wrapWithErrorHandling()
    async deviceErrorsGet(deviceId: string) {
        await this.ensureLatestConfig();
        return this.serverApi.deviceErrorsGet({ deviceId, last: 100 });
    }
}
