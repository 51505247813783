import * as React from "react";
import { Component } from "react";
import Services from "Services/Platform/Services";
import { Bluetoothadvertisingchannel, Bluetoothsecuritylevel, Bluetoothmodulationmode, Schedulepost, Portparameterspost, Iotdeploymentpost, Rdcfirmwareversion, Rdcfirmwaretype } from "@inductosense/typescript-fetch";
import * as PhysicalRDCDevice from "Services/Electron/PhysicalRDCDevice";
import * as MainCGI from "Services/Electron/MainCGI";
import Button from "../Components/Buttons/Button";
import FirstIfNotEmpty from "../Utilities/FirstIfNotEmpty";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import DeviceDbStatus from "./DeviceDbStatus";
import SimplePanel from "../Panels/SimplePanel";
import CloseButton from "../Components/Buttons/Composite/CloseButton";
import SplitPanel from "../Panels/SplitPanel";
import ItemList from "../Components/ItemList";

interface RDCTestPanelProps {
    onClose(): void;
}

interface RDCTestPanelState {
    devices?: DeviceDbStatus[];
    results?: { iteration: number; test: string; result: boolean | null }[];
    firmwareVersions: Rdcfirmwareversion[];
    selectedBluetoothVersions: string[];
    selectedDspVersions: string[];
    done: boolean;
}

export default class RDCTestPanel extends Component<RDCTestPanelProps, RDCTestPanelState> {
    constructor(props: RDCTestPanelProps) {
        super(props);

        this.state = {
            done: false,
            firmwareVersions: [],
            selectedBluetoothVersions: [],
            selectedDspVersions: []
        };
    }

    async componentDidMount() {
        MainCGI.subscribeStatusUpdate(args => console.log(`${new Date().toLocaleTimeString()}: ${args.status}`));

        const devices = await PhysicalRDCDevice.allDevices();

        console.log("devices", devices);
        this.setState({
            devices,
            firmwareVersions: await Services.IoTDeployments.getRdcFirmwareVersions()
        });
    }

    async componentWillUnmount() {
        await MainCGI.unsubscribeStatusUpdate();
        await PhysicalRDCDevice.disposeRdc();
    }

    generateDeployment(deviceId: string, sensorId: string): Iotdeploymentpost {
        const readingInterval = (Math.round(Math.random() * 100) + 1) * 24 * 60 * 60;

        const schedules: Schedulepost[] = [];
        const portParameters: Portparameterspost[] = [];
        for (let port = 0; port < 8; port++) {
            if (Math.random() > 0.5) {
                schedules.push({
                    "deviceId": deviceId,
                    "acquisitionParameters": {
                        "sensorId": sensorId,
                        "sendPort": port,
                        "receivePort": port,
                        "chirpParameters": {
                            "centreFrequencyInKHz": 5000,
                            "numberOfSamples": 262144,
                            "samplingFrequencyInKHz": 25000,
                            "numberOfCycles": 6,
                            "stretchFactor": 8738.1,
                            "amplitudeScalar": 1
                        },
                        "thermocoupleEnabled": Math.random() > 0.5,
                        "dataCompressionBitDepth": 24,
                        "dataCompressionTrimLength": 2000,
                        "dataCompressionSubsampleRatio": 1,
                        "physicalMemoryIndex": port
                    },
                    "startTime": new Date("2021-06-24T00:00:00.000Z"),
                    "endTime": new Date("2050-01-01T10:00:00.000Z"),
                    "readingIntervalInSeconds": readingInterval,
                    "physicalMemoryIndex": port
                });

                portParameters.push({
                    "portNumber": port,
                    "coilCode": 1,
                    "calibrationDelayInPicoseconds": 0,
                    "deviceId": deviceId,
                    "physicalMemoryIndex": port
                });
            }
        }

        return {
            "deployedFrom": new Date("2021-06-24T00:00:00.000Z"),
            "deployedUntil": new Date("2050-01-01T00:00:00.000Z"),
            "notes": "No notes",
            "deployedBy": "SB",
            "deviceId": deviceId,
            "schedules": schedules,
            "portParameters": portParameters,
            "wirelessSettings": {
                "advertisingChannel": Bluetoothadvertisingchannel.AllChannels,
                "connectionIntervalInMilliseconds": 8,
                "connectionTimeoutInMilliseconds": 500,
                "payloadSizeInBytes": 244,
                "securityLevel": Bluetoothsecuritylevel.NoSecurity,
                "transmitPowerInDecibelMilliwatts": 10,
                "advertisingIntervalInMilliseconds": 1500,
                "modulationMode": Bluetoothmodulationmode.LE_125k_codedS8
            },
            "locationTag": "fremføres-5"
        };
    }

    async runTests(testCount: number, deviceId: string, macAddressHex: string) {
        const initResult = await PhysicalRDCDevice.initRdc();
        if (!initResult) {
            console.log("Please plug in dongle...");
            await PhysicalRDCDevice.findDongle();
            await PhysicalRDCDevice.initRdc();
        }

        const results = this.state.results || [];
        this.setState({ results });

        for (let i = 1; i <= testCount; i++) {
            const rsg = await Services.SensorGroups.getRootSensorGroup();

            const deployment = this.generateDeployment(deviceId, rsg.sensors![0].id);
            console.log(`${i} Generated deployment`, deployment);
            await Services.IoTDeployments.iotDeploymentsPost(deployment);

            results.push({ iteration: i, test: "Deployment posted", result: true });
            this.setState({ results });

            await MainCGI.uploadDbAuto();
            results.push({ iteration: i, test: "DB sync 1", result: true });
            this.setState({ results });

            const scanResponse = await PhysicalRDCDevice.scanForDevices();

            const foundDevice = FirstIfNotEmpty(scanResponse.results.filter(d => d.macAddressHex === macAddressHex));
            results.push({ iteration: i, test: "Device found when scanning", result: foundDevice !== null });
            this.setState({ results });

            console.log("foundDevice", foundDevice);

            // TODO: Check foundDevice.dbDeviceId == deviceId

            if (foundDevice !== undefined && foundDevice !== null) {
                const deviceSyncResult = await PhysicalRDCDevice.downloadDeviceData(foundDevice.macAddressHex, deviceId, foundDevice.isLongRangeMode, foundDevice.rssi, true);

                results.push({ iteration: i, test: "Connection", result: !deviceSyncResult.connectionError });
                results.push({ iteration: i, test: "Sync result", result: deviceSyncResult.result });

                console.log(`${i} Sync result`, deviceSyncResult);
                this.setState({ results });
            }

            await MainCGI.uploadDbAuto();
            results.push({ iteration: i, test: "Db sync 2", result: true });
        }
    }

    renderMark(input? : boolean | null) {
        switch (input) {
            case true: return "✓";
            case false: return "✘";
            case undefined: return "-";
            case null: return "?";
        }
    }

    render() {
        return <SimplePanel
            title="RDC Testing"
            titleAlignment="centre"
            actionButton={this.closeButton()}
            content={this.content()}
            shouldApplyPadding={false}
        />
    }

    results() {
        const { results } = this.state;
        if (results === undefined) return null;

        const tests = [...new Set(results.map(r => r.test))];
        return <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Test</TableCell>
                        <TableCell>True count</TableCell>
                        <TableCell>False count</TableCell>
                        <TableCell>N/A count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tests.map(t =>
                        <TableRow key={t}>
                            <TableCell>{t}</TableCell>
                            <TableCell>
                                {results.filter(r => r.test === t && r.result === true).length}
                            </TableCell>
                            <TableCell>
                                {results.filter(r => r.test === t && r.result === false).length}
                            </TableCell>
                            <TableCell>
                                {results.filter(r => r.test === t && r.result === null).length}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {Array.from(results.map(({ iteration, test, result }, i) =>
                <p key={i}>{this.renderMark(result)}: ({iteration}) {test}</p>
            ))}
        </>;
    }

    private closeButton() {
        return <CloseButton onClick={() => this.props.onClose()} />;
    }

    content() {
        const { done, devices, firmwareVersions, selectedBluetoothVersions, selectedDspVersions } = this.state;

        const bluetoothFirmwareVersions = firmwareVersions.filter(v => v.type === Rdcfirmwaretype.Bluetooth);
        const dspFirmwareVersions = firmwareVersions.filter(v => v.type === Rdcfirmwaretype.DSP);

        return (
            <div style={{ padding: 12 }}>
                <SplitPanel
                    firstContent={<ItemList
                        items={bluetoothFirmwareVersions.map(v => v.version)}
                        itemsChecked={bluetoothFirmwareVersions.map(v => selectedBluetoothVersions.includes(v.version))}
                        showEditIcons={false}
                        onSelectionChanged={selectedStatus => this.setState({
                            selectedBluetoothVersions: bluetoothFirmwareVersions.filter((_b, i) => selectedStatus[i]).map(v => v.version)
                        })}
                    />}
                    secondContent={<ItemList
                        items={dspFirmwareVersions.map(v => v.version)}
                        itemsChecked={dspFirmwareVersions.map(v => selectedDspVersions.includes(v.version))}
                        showEditIcons={false}
                        onSelectionChanged={selectedStatus => this.setState({
                            selectedDspVersions: dspFirmwareVersions.filter((_b, i) => selectedStatus[i]).map(v => v.version)
                        })}
                    />}
                    splitRatio={0.5}
                />

                { devices !== undefined ?
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Device</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devices.map(d =>
                                <TableRow key={d.dbDeviceId}>
                                    <TableCell>{d.macAddressHex}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={this.runTests.bind(this, 1, d.dbDeviceId, d.macAddressHex)}
                                            label="Single Test"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={this.runTests.bind(this, 100*1000, d.dbDeviceId, d.macAddressHex)}
                                            label="Continuous Tests"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    : null
                }

                {this.results()}

                {done ? <p>Done</p> : null}
            </div>
        );
    }
}
