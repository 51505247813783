import * as React from "react";
import { Component, CSSProperties } from "react";
import AlertPanel from "Panels/AlertPanel";
import ModalPanel from "Panels/ModalPanel";
import YesAndNoButtonsFormFooter from "Forms/Shared/YesAndNoButtonsFormFooter";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";

const contentStyle: CSSProperties = {
    margin: "9px 3px 3px",
    maxWidth: 438
};

const alertPanelStyle: CSSProperties = {
    lineHeight: "22px"
};

const footerStyle: CSSProperties = {
    marginTop: "16px"
};

const progressStyle: CSSProperties = {
    marginTop: "16px"
}

interface ConfirmActionModalPanelProps {
    title: string;
    warningMessage: string;
    questionToUser: string;
    shouldBeShown: boolean;
    depthIndex: 1 | 2;
    onYes(): void;
    onNoOrClose(): void;
    isCommittingAction: boolean;
    progress?: number;
    progressText?: string;
}

export default class ConfirmActionModalPanel extends Component<ConfirmActionModalPanelProps> {
    static readonly defaultProps: Partial<ConfirmActionModalPanelProps> = {
        title: "Confirm action",
        depthIndex: 1
    };

    render() {
        const { depthIndex, shouldBeShown, onNoOrClose, title } = this.props;

        return (
            <ModalPanel
                title={title}
                shouldBeShown={shouldBeShown}
                depthIndex={depthIndex}
                onClose={() => onNoOrClose()}
                content={this.content()}
            />
        );
    }

    private content() {
        return (
            <div style={contentStyle}>
                {this.alertPanel()}
                {this.footer()}
            </div>
        );
    }

    private alertPanel() {
        return (
            <div style={alertPanelStyle}>
                <AlertPanel
                    type="warning"
                    title={this.props.warningMessage}
                    content={this.props.questionToUser}
                />
            </div>
        );
    }

    private footer() {
        return (
            <>
                <div style={footerStyle}>
                    <YesAndNoButtonsFormFooter
                        isCommittingAction={this.props.isCommittingAction}
                        onYesButtonClick={() => this.onYesButtonClick()}
                        onNoButtonClick={() => this.onNoButtonClick()}
                    />
                </div>
                {this.progressBar()}
                {this.progressText()}
            </>
        );
    }

    private progressBar() {
        const { progress } = this.props;

        if (progress !== undefined) {
            return (
                <LinearProgress variant="determinate" value={progress} style={progressStyle} />
            );
        } else {
            return (null);
        }
    }

    private progressText() {
        const { progressText } = this.props;

        if (progressText) {
            return (<p>{progressText}</p>);
        } else {
            return (null);
        }
    }

    private onYesButtonClick() {
        this.props.onYes();
    }

    private onNoButtonClick() {
        this.props.onNoOrClose();
    }
}
