import { CacheApi } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class CacheService extends InductosenseService<CacheApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(CacheApi, authenticationService);
    }

    @wrapWithErrorHandling()
    async cacheClear() {
        await this.serverApi.cacheClearPost();
    }
}
