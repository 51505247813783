import { Robotreading} from "@inductosense/typescript-fetch";
import { Table, TableBody, TableContainer, TableHead, TableRow,TableCell, TableSortLabel, CircularProgress } from "@mui/material";
import * as React from "react";
import { Component } from "react";
import Services from "Services/Platform/Services";
import SensorGroup from "../Model/SensorGroup";
import UiSettings from "../Model/UiSettings";
import NoDataSvg from "Graphics/Icons/NoDataSvg.svg";
import RDCUploadDetailModalPanel from "./RDCUploadDetailModalPanel";

type SortOptions = "received" | "filename" | "username" | "readingsSaved";

interface RDCUploadsProps {
    uiSettings: UiSettings;
    rootSensorGroup: SensorGroup | undefined;
}

interface RDCUploadsState {
    uploadedReadings?: Robotreading[];
    detailReading?: Robotreading;
    sortBy?: SortOptions;
    order: "asc" | "desc";
}

export default class RDCUploads extends Component<RDCUploadsProps, RDCUploadsState> {
    constructor(props: RDCUploadsProps) {
        super(props);

        this.state = {
            uploadedReadings: undefined,
            detailReading: undefined,
            order: "asc"
        };
    }

    async componentDidMount() {
        const uploadedReadings = await Services.UploadedReadings.getUploadedReadings();
        this.setState({ uploadedReadings });
    }

    compareReadings(a: Robotreading, b: Robotreading) {
        const { sortBy } = this.state;

        if (sortBy === "filename") {
            return a.fileName.localeCompare(b.fileName)
        } else if (sortBy === "username") {
            return a.userName.localeCompare(b.userName)
        } else if (sortBy === "readingsSaved") {
            return (a.readingsSaved?.valueOf() ?? 0) - (b.readingsSaved?.valueOf() ?? 0);
        } else {
            return a.received.valueOf() - b.received.valueOf();
        }
    }

    compareDbDevicesWithOrder(a: Robotreading, b: Robotreading) {
        const { order } = this.state;

        return this.compareReadings(a, b) * ((order === "asc") ? 1 : -1);
    }

    readingRow(reading: Robotreading, index: number) {
        return <TableRow key={reading.id} sx={{"&:hover":{background: "#f9ede7"}, background: (index !== undefined && index % 2 !== 0) ? "#fafafa" : "", cursor: "pointer"}} onClick={() => this.onReadingRowClick(index)}>
            <TableCell align="center">
                <span style={{ "verticalAlign": "middle", marginRight:"5px", paddingRight: "18px"}}>{reading.received.toLocaleString()}</span>
            </TableCell>
            <TableCell align="center">
                <span style={{ "verticalAlign": "middle", marginRight:"5px", paddingRight: "18px"}}>{reading.fileName}</span>
            </TableCell>
            <TableCell align="center">
                <span style={{ "verticalAlign": "middle", marginRight:"5px", paddingRight: "18px"}}>{reading.userName}</span>
            </TableCell>
            <TableCell align="center">
                <span style={{ "verticalAlign": "middle", marginRight:"5px", paddingRight: "18px"}}>
                    {reading.readingsSaved != null ?
                        reading.readingsSaved.toLocaleString()
                        : "Unsaved"
                        }
                </span>
            </TableCell>
        </TableRow>
    }

    table() {
        const { uploadedReadings, sortBy, order } = this.state;

        return <>
            <TableContainer>
                <Table>
                <TableHead sx={{"& .MuiTableCell-root":{fontWeight:"bold",fontSize:"14px",background: "#f9ede7"}, "& .MuiTableCell-head":{background: "#f9ede7"}}}>
                    <TableRow>
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "received"} direction={order} onClick={() => this.onColumnClick("received")}>Uploaded</TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "filename"} direction={order} onClick={() => this.onColumnClick("filename")}>File</TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "username"} direction={order} onClick={() => this.onColumnClick("username")}>Uploaded by</TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "readingsSaved"} direction={order} onClick={() => this.onColumnClick("readingsSaved")}>Readings Saved</TableSortLabel>
                        </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {(uploadedReadings &&  uploadedReadings.length > 0) ? uploadedReadings
                            .sort((a, b) => this.compareDbDevicesWithOrder(a, b))
                            .map((dbDevice, index) => this.readingRow(dbDevice, index))
                        : 
                        <TableRow>
                            <TableCell align="center" colSpan={8}>
                                <NoDataSvg />
                                <div>No Uploaded Readings</div>
                            </TableCell>
                        </TableRow> 
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    }

    render() {
        return <>
            <h2 style={{margin: "20px 0"}}>RDC Uploads</h2>
            {
            this.state.uploadedReadings === undefined
                ? <CircularProgress />
                : this.table()}
            {
                this.state.detailReading !== undefined ?
                    <RDCUploadDetailModalPanel
                        reading={this.state.detailReading}
                        onClose={action => {

                            if (this.state.uploadedReadings && (action?.saved === true || action?.deleted === true)) {
                            
                                const readingIndex = this.state.uploadedReadings?.findIndex(r => r.id == this.state.detailReading?.id)
                                if (readingIndex != null && readingIndex >= 0) {

                                    const readings = this.state.uploadedReadings
                                    if (action.saved === true) {
                                        readings[readingIndex].readingsSaved = new Date()
                                    }
                                    if (action.deleted === true) {
                                        readings.splice(readingIndex, 1)
                                    }

                                    this.setState({ uploadedReadings: readings })
                                }
                            }
                            this.setState({ detailReading: undefined })
                        }}
                    />
                    : null
            }
        </>        
    }

    onColumnClick(newSortBy: SortOptions) {
        const { sortBy, order } = this.state;

        if (newSortBy === sortBy) {
            this.setState({ "order": order === "asc" ? "desc" : "asc" });
        } else {
            this.setState({ "sortBy": newSortBy });
        }
    }    

    private async onReadingRowClick(index: number) {
        if (this.state.uploadedReadings) {
            this.setState({ detailReading: this.state.uploadedReadings[index] })
        }
    }
}
