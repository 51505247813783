import * as React from "react";
import { Component } from "react";
import { NumberOfPresamplesBeforeTimeZero } from "Constants/DateAndTime";
import SocketConnection from "RRC/SocketConnection";
import RRCGraph from "RRC/RRCGraph";
import Button from "Components/Buttons/Button";
import { Alert } from "@mui/lab";
import AxisLimitForm from "RRC/AxisLimitForm";
import AxisLimits from "RRC/AxisLimits";
import SpiderSensorSelectModalPanel from "./SpiderSensorSelectModalPanel";
import { Switch, FormControlLabel } from "@mui/material";
import sleep from "../Utilities/Sleep";
import SpiderVoltages from "./SpiderVoltages";
import WarningIcon from "../Components/Graphics/Icons/WarningIcon";
import * as MainCGI from "Services/Electron/MainCGI";
import SplitPanel from "../Panels/SplitPanel";
import StatusPanel from "./StatusPanel";
import * as SpiderConnectionService from "Services/Electron/SpiderConnectionService";

interface SpiderReadingResult {
    payload: number[];
    thicknessMm: number | null;
    minThicknessMm: number;
    systemDelay: number;
    rfid: string;
    deviceSerialNumber: number;
}

interface SpiderPanelState {
    selectSensorManuallyMode: boolean;
    readingResult?: SpiderReadingResult;
    errorMessage?: string;
    isConnecting: boolean;
    isCommunicating: boolean;
    axisLimits: AxisLimits;
    showSensorModal: boolean;
    showSensorModalCallback: (rfid: string) => void;
    voltages?: SpiderVoltages;
    statusMessages: string[];
}

export default class SpiderPanel extends Component<object, SpiderPanelState> {
    samplesPerMicrosecond = 56.25;

    connectionDetails: SocketConnection = {
        description: "Socket",
        socketIp: "192.168.4.1",
        socketPort: 57198
    };

    robotConnectionDetails: SocketConnection = {
        description: "Serial",
        socketIp: "192.168.4.1",
        socketPort: 51908
    };

    constructor(props: object) {
        super(props);

        this.state = {
            selectSensorManuallyMode: false,
            isCommunicating: false,
            isConnecting: true,
            axisLimits: {
                xLimitMicroseconds: 70,
                yLimitMV: 1_000
            },
            showSensorModal: false,
            showSensorModalCallback: () => null,
            statusMessages: []
        };
    }

    async componentDidMount() {
        await SpiderConnectionService.SpiderCreateDirectory();

        MainCGI.subscribeStatusUpdate(arg => {
            console.log(arg.status);
            this.setState(prevState => {
                return { statusMessages: [...prevState.statusMessages, arg.status?.substring(0, 60) /*TODO FIX*/] };
            });
        });

        this.setState({ isConnecting: true });

        // Don't need to connect to HDC port because only test function is being used currently
        const connectResult = true; //await SpiderConnectionService.connect(this.connectionDetails);

        if (connectResult) {
            this.setState({
                isConnecting: false
            });
        } else {
            this.setState({
                isConnecting: false,
                errorMessage: "Could not connect"
            });
        }

        setInterval(async () => {
            const voltages = await SpiderConnectionService.SpiderGetVoltages(this.robotConnectionDetails);
            console.log("voltages", voltages);

            this.setState({
                voltages
            });
        }, 10 * 1000);
    }

    private indexToTime(index: number, samplesPerMicrosecond: number) {
        return (index - NumberOfPresamplesBeforeTimeZero) / samplesPerMicrosecond;
    }

    getLimits() {
        const { axisLimits } = this.state;

        return {
            xLimitMicroseconds: axisLimits.xLimitMicroseconds || 70,
            yLimitMV: axisLimits.yLimitMV || 50
        };
    }

    displayReadingResult(readingResult: SpiderReadingResult) {
        const { axisLimits } = this.state;
        const { payload, thicknessMm, rfid, deviceSerialNumber, minThicknessMm, systemDelay } = readingResult;

        const payloadTime = (payload || []).map((sample, index) => {
            return { t: this.indexToTime(index, this.samplesPerMicrosecond), y: sample }
        });

        return <>
            <div style={{ marginTop: 15 }}>
                <AxisLimitForm
                    limits={axisLimits}
                    onLimitChange={axisLimits => this.setState({ axisLimits })}
                />
            </div>

            <RRCGraph
                points={payloadTime}
                velocityInMetresPerSecond={this.getSensor("").material.longitudinalVelocityAtRoomTemperatureInMetresPerSecond}
                xMinimum={-7}
                xMaximum={this.getLimits().xLimitMicroseconds}
                yMinimum={-this.getLimits().yLimitMV}
                yMaximum={this.getLimits().yLimitMV}
            />

            <p>Thickness: {thicknessMm}mm</p>
            <p>RFID: {rfid}</p>

            { thicknessMm !== null ?
                <div style={{ display: "inline-block" }}>
                    <Button label="Save" onClick={() =>
                        this.onSaveClick(payload, payloadTime, thicknessMm, deviceSerialNumber, minThicknessMm, systemDelay, rfid)} />
                </div>
                : null
            }
        </>;
    }

    getSensor(rfid: string) {
        return {
            calibrationValue: 0,
            description: rfid.slice(-10),
            material: {
                "longitudinalTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin": -1,
                "longitudinalVelocityAtRoomTemperatureInMetresPerSecond": 5915,
                "shearTemperatureVelocityChangeInMetresPerSecondPerDegreeKelvin": -1,
                "shearVelocityAtRoomTemperatureInMetresPerSecond": 3300,
                "thermalExpansionCoefficientInPercentPerDegreeKelvin": 1
            },
            rfid
        };
    }

    async onSaveClick(
        payload: number[],
        payloadTime: { t: number; y: number }[],
        thicknessMm: number,
        deviceSerialNumber: number,
        minThicknessMm: number,
        systemDelay: number,
        rfid: string) {

        const result = await SpiderConnectionService.SpiderSaveReading(
            payload,
            payloadTime,
            thicknessMm,
            deviceSerialNumber,
            this.getSensor(rfid),
            minThicknessMm,
            1,
            systemDelay);
        console.log("result", result);
    }

    mainPanel() {
        const { readingResult, isConnecting, errorMessage, isCommunicating, selectSensorManuallyMode } = this.state;

        if (isConnecting) return <p>Connecting...</p>;
        if (isCommunicating) return <p>Getting reading...</p>;

        return <>
            { errorMessage !== undefined ? <Alert severity="error">{errorMessage}</Alert> : null }

            { readingResult === undefined ? <p>No reading yet</p> : this.displayReadingResult(readingResult)}

            <div style={{ display: "inline-block", marginRight: 10 }}>
                <Button label="Scan" isDisabled={true} onClick={this.onScanClick.bind(this)} />
            </div>
            <div style={{ display: "inline-block" }}>
                <Button label="Test" onClick={this.onTestClick.bind(this)} />
            </div>

            <div style={{ marginTop: 10 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={selectSensorManuallyMode}
                            onChange={(_element, newValue) => this.setState({ selectSensorManuallyMode: newValue })}
                        />
                    }
                    label="Select sensor manually"
                />
            </div>
        </>;
    }

    showVoltage(voltage?: number) {
        if (voltage === undefined) {
            return <>?</>;
        }

        if (voltage < 17) {
            return <><span style={{ verticalAlign: "middle" }}><WarningIcon fontSize="small" /></span> <span style={{ color: "red" }}>{voltage}</span></>;
        }

        return <>{voltage}</>;
    }

    leftPanel() {
        const { showSensorModal, showSensorModalCallback, voltages } = this.state;

        return (
            <>
                <div style={{ backgroundColor: "#fff", width: "100%", height: "100%" }}>
                    <div style={{ paddingLeft: 20, paddingTop: 10 }}>
                        <p>{voltages !== undefined ? <>V1 = {this.showVoltage(voltages.v1)}, &nbsp; V2 = {this.showVoltage(voltages.v2)}</> : null}</p>

                        {this.mainPanel()}
                    </div>
                </div>

                <SpiderSensorSelectModalPanel
                    shouldBeShown={showSensorModal}
                    onClose={() => null}
                    onSensorSelected={(rfid: string) => showSensorModalCallback(rfid)}
                    sensors={[
                        { name: "Sensor B3", rfid: "EEE000000000000000001DB3" },
                        { name: "Sensor B4", rfid: "EEE000000000000000001DB4" },
                        { name: "Sensor B5", rfid: "EEE000000000000000001DB5" },
                        { name: "Sensor B6", rfid: "EEE000000000000000001DB6" },
                        { name: "Sensor B7", rfid: "EEE000000000000000001DB7" },
                        { name: "Sensor B8", rfid: "EEE000000000000000001DB8" },
                        { name: "Sensor B9", rfid: "EEE000000000000000001DB9" },
                        { name: "Sensor BA", rfid: "EEE000000000000000001DBA" },
                        { name: "Sensor BB", rfid: "EEE000000000000000001DBB" },
                        { name: "Sensor BC", rfid: "EEE000000000000000001DBC" }
                    ]}
                />
            </>
        );
    }

    render() {
        const { statusMessages } = this.state;

        return <SplitPanel
            firstContent={this.leftPanel()}
            secondContent={<StatusPanel statusMessages={statusMessages} />}
            splitRatio={1 / 2}
            plane="horizontal"
        />;
    }

    async getSensorFromUser(): Promise<string> {
        return new Promise(resolve => {
            this.setState({
                showSensorModal: true,
                showSensorModalCallback: (rfid: string) => {
                    this.setState({ showSensorModal: false });
                    resolve(rfid);
                }
            });
        });
    }

    async onScanClick() {
        const { selectSensorManuallyMode } = this.state;

        this.setState({ readingResult: undefined, isCommunicating: true });

        let rfid = "";
        if (selectSensorManuallyMode) {
            rfid = await this.getSensorFromUser();
        } else {
            rfid = await SpiderConnectionService.SpiderScanRfid(this.robotConnectionDetails);

            if (rfid === null || rfid === "000000000000000000000000") {
                rfid = await this.getSensorFromUser();
            }
        }

        await sleep(1000);

        const reading = await SpiderConnectionService.SpiderTakeGetReading(this.robotConnectionDetails);
        console.log("reading result", reading);

        console.log("rfid result", rfid);

        const payload = reading.payload;

        if (payload === undefined) {
            console.log("no payload");
            this.setState({ errorMessage: "Could not get reading", isCommunicating: false });
            return;
        }

        this.setState({
            readingResult: {
                rfid: rfid,
                payload: payload,
                systemDelay: reading.systemDelayInSeconds,
                thicknessMm: reading.thicknessMm,
                minThicknessMm: reading.minThicknessMm,
                deviceSerialNumber: reading.deviceSerialNumber
            },
            isCommunicating: false,
            errorMessage: undefined
        });
    }

    async onTestClick() {
        this.setState({ readingResult: undefined, isCommunicating: true });

        await SpiderConnectionService.SpiderTest(this.robotConnectionDetails);

        this.setState({ isCommunicating: false });
    }
}

