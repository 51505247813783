import * as MaterialUi from "@mui/material";
import * as React from "react";
import { Component } from "react";
import { TextAlignment, textAlignmentToCssTextAlignValue } from "Types/TextAlignment";
import { Clear } from "@mui/icons-material";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import FourgSvg from "Graphics/Icons/FourgSvg.svg";
import PortSvg from "Graphics/Icons/PortSvg.svg";

interface DropDownListControlledProps<T> {
    isGateway?: boolean;
    isSensorGroups?: boolean;
    textAlignment?: TextAlignment;
    options: T[];
    labelFor(option: T): string | React.ReactNode;
    selectedOption?: T;
    unsetOption?: T;
    onChange(newSelectedOption: T): void;
    width?: number;
    minimumWidth?: number;
    isDisabled?: boolean;
}

const InputGroup = styled(InputBase)(() => ({
    "& .MuiSvgIcon-root":{
        color: "#EC6F41",
    },
    "& .MuiInputBase-input":{
        color: "#EC6F41",
        borderRadius: 4,
        position: "relative",
        background: "#f9ede7",
        border: "1px solid #EC6F41",
        padding: "0 0 0 5px",
        fontSize: 14,
      },
}) )

export default class DropDownListControlled<T> extends Component<DropDownListControlledProps<T>> {
    private options: T[];

    constructor(props: DropDownListControlledProps<T>) {
        super(props);

        const { options, unsetOption } = props;

        if (unsetOption && !options.some(option => option === unsetOption)) {
            this.options = [unsetOption, ...options];
        } else {
            this.options = options;
            console.log(options,"options",this.props.selectedOption,"selectedOption")
        }
    }

    private gatewayOptions = ["Ethernet", "Cellular"]

    render() {
        const { selectedOption, onChange, unsetOption, width, minimumWidth, isDisabled, isSensorGroups, isGateway } = this.props;
        console.log(isGateway && selectedOption == null,"(isGateway && selectedOption == null")
        return (
            <div className="drop-down-list" style={{ width, minWidth: minimumWidth, display: "inlineBlock" }}>
                <MaterialUi.FormControl disabled={isDisabled} fullWidth>
                    <div style={{ display: "inlineBlock" }}>
                        {
                            isSensorGroups?<MaterialUi.Select
                            value={JSON.stringify(selectedOption || unsetOption || "")}
                            onChange={event => onChange(JSON.parse(event.target.value as string))}
                            style={{ height: 25 }}
                            input = {<InputGroup />}
                        >
                            {...this.options.map(option => this.item(option))}
                        </MaterialUi.Select>:
                        isGateway ? <MaterialUi.Select
                            value={JSON.stringify((isGateway && selectedOption=="Internet" ) ? "Ethernet" : selectedOption)}
                            onChange={event => onChange(JSON.parse(event.target.value as string))}
                            style={{ height:  "40px",width:"200px" }}
                            renderValue={(value) => {
                                if (!selectedOption) {
                                    return <em style={{fontSize: "14px",color: "grey"}}>-- please choose--</em>;
                                }
                                return  <div style={{
                                    width: "100%",
                                    display:"flex",
                                    alignItems:"center",
                                    height:"30px",
                                    textAlign: "center",
                                    }}
                                >
                                    {JSON.parse(value) == "Cellular" ? 
                                        <FourgSvg style={{width:"18px",marginRight:"10px",height:"18px"}}/> 
                                        : <PortSvg style={{width:"18px",marginRight:"10px",height:"18px"}}/>
                                    }
                                    {JSON.parse(value)}
                                </div>
                            }}
                        >
                            {this.gatewayOptions.map(option => this.gatewayItem(option))}   
                        </MaterialUi.Select> :
                        <MaterialUi.Select
                            value={JSON.stringify( selectedOption || unsetOption || "")}
                            onChange={event => onChange(JSON.parse(event.target.value as string))}
                            style={{ height:  "40px",width:"200px" }}
                        >
                            {this.options.map(option => this.item(option))}
                        </MaterialUi.Select>
                        }
                        {unsetOption !== undefined && selectedOption !== unsetOption ?
                            <MaterialUi.IconButton onClick={() => onChange(unsetOption)} size="small">
                                <Clear />
                            </MaterialUi.IconButton>
                            : null
                        }
                    </div>
                </MaterialUi.FormControl>
            </div>
        );
    }

    private gatewayItem(option: string) {
        return <MaterialUi.MenuItem
                key={JSON.stringify(option)}
                value={JSON.stringify(option)}
            >
                {this.gatewayLabelFor(option)}
            </MaterialUi.MenuItem>
    }

    private gatewayLabelFor(option: string) {
        const { textAlignment } = this.props;

        return (
            <div style={{
                width: "100%",
                display:"flex",
                alignItems:"center",
                height:"30px",
                textAlign: textAlignmentToCssTextAlignValue(textAlignment)
            }}>
                {option == "Cellular" ? 
                    <FourgSvg style={{width:"18px",marginRight:"10px",height:"18px"}}/> 
                    : <PortSvg style={{width:"18px",marginRight:"10px",height:"18px"}}/>
                }{option}
            </div>
        );
    }

    private item(option: T) {
        return (
            <MaterialUi.MenuItem
                key={JSON.stringify(option)}
                value={JSON.stringify(option)}
            >
                {this.labelFor(option)}
            </MaterialUi.MenuItem>
        );
    }

    private labelFor(option: T) {
        const { labelFor, textAlignment, unsetOption } = this.props;

        const labelText = labelFor(option);
        const labelNode = option === unsetOption ? <i>{labelText}</i> : labelText;

        return (
            <div style={{
                width: "100%",
                textAlign: textAlignmentToCssTextAlignValue(textAlignment)
            }}>
                {labelNode}
            </div>
        );
    }
}
