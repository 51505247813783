import Validator from "Validation/Validator";

export default class StringRepresentsAnIntegerOrNothingValidator implements Validator<string> {
    constructor(readonly errorMessage = "Must be a whole number or left empty") { }

    isValid(value: string | null | undefined) {
        if (value === null || value === undefined || value === "") return true;
        return parseFloat(value) % 1 === 0;
    }
}
