import * as React from "react";
import { FunctionComponent, useState, useCallback, useEffect } from "react";
import { Device, Readinganalysisstatus } from "@inductosense/typescript-fetch";
import { CSSProperties } from "@mui/material/styles/createTypography";
import DisplacementText from "../../../Components/Text/DisplacementText";
import TemperatureText from "../../../Components/Text/TemperatureText";
import UiSettings from "../../../Model/UiSettings";
import Sensor from "../../../Model/Sensor";
import OnOrOff from "../../../Types/OnOrOff";
import useResizeObserver from "use-resize-observer";
import ReadingTrendPoint from "../../../Model/ReadingTrendPoint";
//import { thicknessAlgorithmTooltip } from "../../../Utilities/EnumDescriptionUtil";

const readingsTableHeaderTitleStyle: CSSProperties = {
    marginTop: 3,
    fontFamily: "lato",
    fontWeight: "bold"
};

const readingsTableHighlightedStyle: CSSProperties = {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderColor: "rgba(0, 0, 0, 0.05)"
};

interface ReadingsListPanelInnerProps {
    sensor: Sensor | null;
    devices: Device[] | null;
    readings: ReadingTrendPoint[] | null;
    highlightedReading: ReadingTrendPoint | null;
    onHoveredReadingChange(reading: ReadingTrendPoint | null): void;
    onReadingIgnoredStateChange(reading: ReadingTrendPoint, newState: OnOrOff): void;
    onViewAscanButtonClick(reading: ReadingTrendPoint): void;
    onEditButtonClick(reading: ReadingTrendPoint): void;
    uiSettings: UiSettings;
    tabletMode: boolean;
}

const ReadingsListPanelInner: FunctionComponent<ReadingsListPanelInnerProps> = ({ readings, highlightedReading, uiSettings,
    sensor, onHoveredReadingChange, onReadingIgnoredStateChange, onViewAscanButtonClick, onEditButtonClick, tabletMode }) => {

    const { ref, ...sizeU } = useResizeObserver<HTMLDivElement>({ "box": "content-box" });
    const size = { width: sizeU.width || 1, height: sizeU.height || 1 };

    const tableHeaderTitle = (label: string) => {
        return <div style={readingsTableHeaderTitleStyle}>{label}</div>;
    }

    const editCell = (reading: ReadingTrendPoint) => {
        return <button onClick={() => onEditButtonClick(reading)} style={tabletMode ? { fontSize: 24 } : undefined}>&#x270E;</button>;
    }

    const timestampCell = (reading: ReadingTrendPoint) => {
        const { timestamp } = reading;

        const date = timestamp.toLocaleDateString();
        const time = timestamp.toLocaleTimeString();

        return `${date} ${time}`;
    }

    const thicknessCell = (reading: ReadingTrendPoint) => {
        const thickness = reading.latestThicknessM;
        //const thicknessAlgorithm = reading.parameters?.thicknessAlgorithm;

        return <DisplacementText
            displacementInMetres={thickness}
            unitsMode={uiSettings.unitsMode}
            // TODO tooltip={thicknessAlgorithmTooltip(thicknessAlgorithm)}
        />;
    }

    const thicknessOrErrorCell = (reading: ReadingTrendPoint) => {
        switch (reading.analysisStatus) {
            case undefined:
                return <span>Pending</span>;
            case Readinganalysisstatus.Successful:
                return thicknessCell(reading);
            default:
                return <span>{reading.analysisStatus || "Unknown error"}</span>;
        }
    }

    const temperatureCell = (reading: ReadingTrendPoint) => {
        return <TemperatureText
            temperatureInCelsius={reading.temperatureInDegreesC}
            temperatureUnits={uiSettings.temperatureUnits}
        />;
    }

    const ignoredCell = (reading: ReadingTrendPoint) => {
        //if (reading.analysis?.status === Readinganalysisstatus.Successful) {
            return <input type="checkbox" style={tabletMode ? { width: 25, height: 25 } : undefined} onChangeCapture={ev => onReadingIgnoredStateChange(reading, ev.currentTarget.checked ? "on" : "off")} defaultChecked={false} />;
        /*} else {
            return null;
        } TODO need condition here? */
    }

    // https://stackoverflow.com/a/37285344
    const checkInView = (container: Element, element: HTMLElement, partial: boolean) => {
        //Get container properties
        const cTop = container.scrollTop;
        const cBottom = cTop + container.clientHeight;

        //Get element properties
        const eTop = element.offsetTop;
        const eBottom = eTop + element.clientHeight;

        //Check if in view    
        const isTotal = (eTop >= cTop && eBottom <= cBottom);
        const isPartial = partial && (
            (eTop < cTop && eBottom > cTop) ||
            (eBottom > cBottom && eTop < cBottom)
        );

        //Return outcome
        return (isTotal || isPartial);
    }

    const [readingsToShow, setReadingsToShow] = useState(0);

    const highlightedReadingRef = useCallback((node: HTMLElement | null) => {
        if (node !== null && !checkInView(node.closest(".scrollcont")!, node, false)) {
            node.scrollIntoView(); // TODO: Fix closest
        }
    }, [readings, highlightedReading, readingsToShow]);

    const readingsInReverseChronologicalOrder = [...readings || []].sort((a, b) => {
        return b.timestamp.getTime() - a.timestamp.getTime();
    });

    useEffect(() => {
        const readingPos = readingsInReverseChronologicalOrder.findIndex(r => r.id === highlightedReading?.id);
        if (readingPos !== undefined && readingsToShow < readingPos) {
            setReadingsToShow(Math.ceil((readingPos + 1) / 5) * 5);
        }
    }, [highlightedReading]);

    useEffect(() => {
        setReadingsToShow(0);
    }, [sensor]);

    if (!readings) return null;

    const tds: CSSProperties = tabletMode ? { padding: 10 } : { padding: 4, fontSize: "9pt" };

    return (
        <div style={{ width: "100%", height: "100%" }} ref={ref}>
            <div className="scrollcont" style={{ overflow: "auto", width: "100%", height: size.height, backgroundColor: "white" }}>
                <table>
                    <thead>
                        <tr>
                            <td style={tds}>{tableHeaderTitle("Timestamp")}</td>
                            <td style={tds}>{tableHeaderTitle("Thickness")}</td>
                            <td style={tds}>{tableHeaderTitle("Temp")}</td>
                            <td style={tds}>{tableHeaderTitle("Ignore")}</td>
                            {tabletMode ? <td style={tds}></td> : null}
                        </tr>
                    </thead>
                    <tbody style={{ userSelect: "none" }}>
                        {readingsInReverseChronologicalOrder.map(r =>
                            <tr
                                ref={r.id === highlightedReading?.id ? highlightedReadingRef : null}
                                key={r.id}
                                onMouseEnter={() => onHoveredReadingChange(r)}
                                onMouseLeave={() => onHoveredReadingChange(null)}
                                onDoubleClick={ev => {
                                    ev.preventDefault();
                                    onViewAscanButtonClick(r)
                                }}
                                onContextMenu={ev => {
                                    onEditButtonClick(r);
                                    ev.preventDefault()
                                }}
                                style={r.id === highlightedReading?.id ? readingsTableHighlightedStyle : {}}
                            >
                                <td style={tds}>{timestampCell(r)}</td>
                                <td style={tds}>{thicknessOrErrorCell(r)}</td>
                                <td style={tds}>{temperatureCell(r)}</td>
                                <td style={tds}>{ignoredCell(r)}</td>
                                {tabletMode ? <td style={tds}>{editCell(r)}</td> : null}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ReadingsListPanelInner;
