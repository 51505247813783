import * as React from "react";
import { FunctionComponent, useEffect, useRef } from "react";

interface StatusPanelProps {
    statusMessages: string[];
}

const StatusPanel: FunctionComponent<StatusPanelProps> = ({ statusMessages }) => {
    const messagesEndRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView();
    }, [statusMessages]);

    return <div style={{ width: "100%", height: "100%", overflowY: "auto" }}>
        <div style={{ padding: 14 }}>
            {statusMessages.map((m, index) =>
                <div key={index} style={{ fontFamily: "monospace" }}>
                    {m.substring(0, 60)}
                </div>
            )}
            <div ref={messagesEndRef} />
        </div>
    </div>;
}

export default StatusPanel;
