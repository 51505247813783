import { getNumericValue, PointsInCartesianSpace, XUnit } from "Types/CartesianSpace";

export function getLinearRegressionFunction(points: PointsInCartesianSpace) {
    const pointsCount = points.length;

    let sigmaX = 0;
    let sigmaY = 0;
    let sigmaXX = 0;
    let sigmaXY = 0;

    for (let i = 0; i < pointsCount; i++) {
        const x = getNumericValue(points[i].x);
        const { y } = points[i];

        sigmaX += x;
        sigmaY += y;
        sigmaXX += x * x;
        sigmaXY += x * y;
    }

    const gradient =
        ((pointsCount * sigmaXY) - (sigmaX * sigmaY)) /
        ((pointsCount * sigmaXX) - (sigmaX * sigmaX));

    const intercept = (sigmaY - (gradient * sigmaX)) / pointsCount;

    return (xCoordinate: XUnit) => (gradient * getNumericValue(xCoordinate)) + intercept;
}
