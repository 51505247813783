import * as React from "react";
import { Component } from "react";
import EditReadingForm from "Forms/Composite/EditReadingForm";
import ModalPanel from "Panels/ModalPanel";
import { Device } from "@inductosense/typescript-fetch/dist/models/Device";
import UiSettings from "../../../Model/UiSettings";
import { Readingtrendpoint } from "@inductosense/typescript-fetch";

interface EditReadingModalPanelProps {
    reading: Readingtrendpoint | null;
    devices: Device[] | null;
    shouldBeShown: boolean;
    onSave(waitForGraphCreatedAfter: Date): void;
    onClose(): void;
    uiSettings: UiSettings;
}

interface EditReadingModalPanelState {
    isSaving: boolean;
}

export default class EditReadingModalPanel extends Component<EditReadingModalPanelProps, EditReadingModalPanelState> {
    constructor(props: EditReadingModalPanelProps) {
        super(props);
        this.state = { isSaving: false };
    }

    render() {
        const { onClose, shouldBeShown } = this.props;
        const { isSaving } = this.state;

        return (
            <ModalPanel
                title="Edit reading"
                shouldBeShown={shouldBeShown}
                onClose={() => !isSaving && onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        const { devices, onClose, reading } = this.props;

        if (!reading) return null;

        return (
            <EditReadingForm
                reading={reading}
                devices={devices}
                onSave={waitForGraphCreatedAfter => this.onSave(waitForGraphCreatedAfter)}
                onCancel={() => onClose()}
                uiSettings={this.props.uiSettings}
            />
        );
    }

    private onSave(waitForGraphCreatedAfter: Date) {
        this.props.onSave(waitForGraphCreatedAfter);
        this.props.onClose();

        this.setState({ isSaving: false });
    }
}
