// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --splash-screen-animation-initial-delay: 500ms;
    --splash-screen-emblem-animation-durationy: 300ms;
    --splash-screen-delay-after-emblem-animation: 200ms;
    --splash-screen-logotype-animation-duration: 400ms;
    --splash-screen-delay-after-logotype-animation: 1.5s;

    --splash-screen-content-panel-delay-before-loading-spinner-fade-in: 1s;
    --splash-screen-content-panel-loading-spinner-fade-in-duration: 750ms;

    --splash-screen-view-content-fade-out-duration: 1s;
}
`, "",{"version":3,"sources":["webpack://./Styling/Animations/Timings.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,iDAAiD;IACjD,mDAAmD;IACnD,kDAAkD;IAClD,oDAAoD;;IAEpD,sEAAsE;IACtE,qEAAqE;;IAErE,kDAAkD;AACtD","sourcesContent":[":root {\n    --splash-screen-animation-initial-delay: 500ms;\n    --splash-screen-emblem-animation-durationy: 300ms;\n    --splash-screen-delay-after-emblem-animation: 200ms;\n    --splash-screen-logotype-animation-duration: 400ms;\n    --splash-screen-delay-after-logotype-animation: 1.5s;\n\n    --splash-screen-content-panel-delay-before-loading-spinner-fade-in: 1s;\n    --splash-screen-content-panel-loading-spinner-fade-in-duration: 750ms;\n\n    --splash-screen-view-content-fade-out-duration: 1s;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
