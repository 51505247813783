import { Device, Sensorreadertype, Dcdevicedeployments, Dcdeploymentpost, Dcdeploymentattemptlog, Dcdeployment} from "@inductosense/typescript-fetch";
import { Link, Table, TableBody, TableContainer, TableHead, TableRow,TableCell, CircularProgress, TableSortLabel, Tooltip, Button } from "@mui/material";
import * as React from "react";
import { Component } from "react";
import AddIcon from "@mui/icons-material/Add";
import Services from "Services/Platform/Services";
import IconButton from "../Components/Buttons/IconButton";
import EditIcon from "../Components/Graphics/Icons/EditIcon";
import RelativeDate from "../Components/Text/RelativeDate";
import SensorGroup from "../Model/SensorGroup";
import BatteryModalPanel from "../RDC/BatteryModalPanel";
import CreateDcDeploymentModalPanel from "../DC/CreateDcDeploymentModalPanel";
import DeviceErrorLogsModalPanel from "RDC/DeviceErrorLogsModalPanel"; // Does DC have error logs? *
import TemperatureModalPanel from "RDC/TemperatureModalPanel";
import UiSettings from "../Model/UiSettings";
import DeviceDataExtractionLogsModalPanel from "RDC/DeviceDataExtractionLogsModalPanel";
import DcDeviceDataExtractionLogsModalPanel from "DC/DcDeviceDataExtractionLogsModalPanel";
import * as MainCGI from "Services/Electron/MainCGI";
import { CSSProperties } from "@mui/material/styles/createTypography";
import ShowLogsForDcDeploymentModalPanel from "./ShowLogsForDcDeploymentModalPanel";
import DeploymentLogIcon from "../RDC/DeploymentLogIcon";
import CreateDCModalPanel from "./CreateDCModalPanel";
import NoDataSvg from "Graphics/Icons/NoDataSvg.svg";
import HDCV2Svg from "Graphics/Icons/HDCV2Svg.svg";

type SortOptions = "device" | "lastExtracted" | "configuration" ;

interface DcDeviceManagerFormProps {
    uiSettings: UiSettings;
    rootSensorGroup: SensorGroup | undefined;
}

interface DcDeviceManagerFormState {
    createDeploymentModalDeviceIndex: number | undefined;
    showLogsForDeployment?: Dcdeployment;
    dcDevices?: Dcdevicedeployments[];
    showBatteryForDevice?: Device;
    showTemperatureForDevice?: Device;
    showDataExtractionLogsForDevice?: Device;
    showErrorLogsForDevice?: Device;
    showCreateDcForm: boolean;
    sortBy?: SortOptions;
    order: "asc" | "desc";
}

// TODO: Remove duplication between controls
const unappliedChangesStyle: CSSProperties = {
    fontStyle: "italic",
    color: "brown"
}

export default class DcDeviceManagerForm extends Component<DcDeviceManagerFormProps, DcDeviceManagerFormState> {
    constructor(props: DcDeviceManagerFormProps) {
        super(props);

        this.state = {
            createDeploymentModalDeviceIndex: undefined,
            showCreateDcForm: false,
            order: "asc"
        };
    }

    async componentDidMount() {
        await this.loadDeployments();
    }

    async loadDeployments() {
        const dcDevices = await Services.DcDeployments.getDcDevicesDeployments();
        this.setState({ dcDevices });
    }

    isApplied(input: Dcdevicedeployments) {
        return input.latestSuccessfulDeployment?.id === input.latestDeployment?.id;
    }

    compareDbDevices(a: Dcdevicedeployments, b: Dcdevicedeployments) {
        const { sortBy } = this.state;

        if (sortBy === "device") {
            return (a.device.serialNumber || "").localeCompare(b.device.serialNumber || "");
        } else if (sortBy === "configuration") {
            return +this.isApplied(a) - +this.isApplied(b);
        } else if (sortBy === "lastExtracted") {
            return (a.latestSuccessfulDataExtractionAttemptLog?.dateTime.getTime() || 0) - (b.latestSuccessfulDataExtractionAttemptLog?.dateTime.getTime() || 0);
        } else {
            return a.device.updated.getTime() - b.device.updated.getTime();
        }
    }

    compareDbDevicesWithOrder(a: Dcdevicedeployments, b: Dcdevicedeployments) {
        const { order } = this.state;

        return this.compareDbDevices(a, b) * ((order === "asc") ? 1 : -1);
    }

    dcRow(dbDevice: Dcdevicedeployments, index: number) {
        const latestDeployment = dbDevice.latestDeployment;
        const appliedDeployment = dbDevice.latestSuccessfulDeployment;
        const unappliedDeployment = latestDeployment?.id !== appliedDeployment?.id ? latestDeployment : undefined;

        // We want to hide the default device until this can be removed from the databases. We use
        // display: none rather than excluding it because the edit device modal uses the index of the device in the list.
        const isHiddenDefaultDevice = dbDevice.device.serialNumber === "1";

        return <TableRow sx={{"&:hover":{background: "#f9ede7"}, background: (index !== undefined && index % 2 !== 0) ? "#fafafa" : "", display: isHiddenDefaultDevice ? "none" : undefined}}>
            <TableCell align="center">
                <span style={{ "verticalAlign": "middle", marginRight:"5px", paddingRight: "18px"}} title={`System delay ${dbDevice.device.systemDelayInSeconds}`}>
                    {dbDevice.device.serialNumber}
                </span>
            </TableCell>
            <TableCell align="center">
                <HDCV2Svg style={{width:"20px",height:"20px"}} />        
            </TableCell>
            <TableCell align="center">
                <Link
                    href="#"
                    onClick={() => this.setState({ showDataExtractionLogsForDevice: dbDevice.device })} // TODO: Show unsuccessful log status
                ><div style={{paddingRight: "18px"}}>
                    <RelativeDate date={dbDevice.latestSuccessfulDataExtractionAttemptLog?.dateTime || dbDevice.latestDataExtractionAttemptLog?.dateTime} />
                </div>
                </Link>
            </TableCell>
            <TableCell align="center">
                <div style={{paddingRight: "18px"}}>
                    {appliedDeployment !== undefined ?
                        <div title="Last configuration confirmed to be applied">
                            <span style={{ "verticalAlign": "middle" }}>
                                {appliedDeployment.sensors.length} sensors, {appliedDeployment.dcUsers.length} users
                        </span>&nbsp;
                        <Link onClick={() => this.setState({ showLogsForDeployment: appliedDeployment })}>
                                {this.deploymentLogIcon(appliedDeployment.logs)}
                            </Link><br />
                        </div>
                        : null
                    }

                    {unappliedDeployment !== undefined ?
                        <div title="New configuration - either not applied or not connected to internet since being applied">
                            <span style={{ "verticalAlign": "middle", ...unappliedChangesStyle }}>
                                {unappliedDeployment.sensors.length} sensors, {unappliedDeployment.dcUsers.length} users
                        </span>&nbsp;

                        <Link onClick={() => this.setState({ showLogsForDeployment: unappliedDeployment })}>
                                {this.deploymentLogIcon(unappliedDeployment.logs)}<br />
                            </Link>
                        </div>
                        : null
                    }
                </div>
            </TableCell>
            <TableCell align="center">
                <div style={{ display: "flex",justifyContent: "center" }} >
                    <Tooltip title="Edit Configuration" >
                        <div>
                            <IconButton
                                icon={<EditIcon color="primary" />}
                                isProhibited={!Services.userHasPolicy("AddIoTDeviceConfiguration")}
                                onClick={() => this.createDeployment(index)}
                            />
                        </div>
                    </Tooltip>
                </div>
            </TableCell>
        </TableRow>
    }

    private deploymentLogIcon(logs: Dcdeploymentattemptlog[]) {
        if (logs.length === 0) return null;

        return <DeploymentLogIcon withLabel={false} codes={logs.map(l => l.code)} />;
    }

    render() {
        const { dcDevices, createDeploymentModalDeviceIndex, showLogsForDeployment,
            showBatteryForDevice, showTemperatureForDevice, showDataExtractionLogsForDevice,
            showErrorLogsForDevice, showCreateDcForm, sortBy, order } = this.state;
        const { rootSensorGroup } = this.props;
        if (rootSensorGroup === undefined || dcDevices === undefined) return <CircularProgress />;
        const selectedDbDevice = createDeploymentModalDeviceIndex !== undefined ? dcDevices[createDeploymentModalDeviceIndex] : undefined;

        return <>
            <h2 style={{margin: "20px 0"}}>HDC Management</h2>
            <div style={{marginBottom:"20px"}} hidden={!Services.userHasPolicy("ChangeDevices")}>
            <Button 
                variant="contained"
                startIcon={<AddIcon />}
                sx = {{
                        padding:"3px 20px",
                        "&:hover": {
                            background: "#a54d2d",
                            border: "unset"
                        },
                        border:"1px solid #ec6f41",
                        backgroundColor: "#ec6f41",
                        color: "#fff",
                        lineHeight:"23px",
                        fontSize: "14px",
                        textTransform: "none",
                        height:"38px",
                    }}
                onClick={() => this.setState({ showCreateDcForm: true })}
            >
                Add HDC
            </Button>
            </div>
            <TableContainer>
                <Table>
                <TableHead sx={{"& .MuiTableCell-root":{fontWeight:"bold",fontSize:"14px",background: "#f9ede7"}, "& .MuiTableCell-head":{background: "#f9ede7"}}}>
                    <TableRow>
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "device"} direction={order} onClick={() => this.onColumnClick("device")}>Device</TableSortLabel>
                        </TableCell>
                        <TableCell align="center">Version</TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "lastExtracted"} direction={order} onClick={() => this.onColumnClick("lastExtracted")}>Last Extracted</TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={sortBy === "configuration"} direction={order} onClick={() => this.onColumnClick("configuration")}>Configuration</TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            Actions
                        </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {(dcDevices &&  dcDevices.length > 0) ? dcDevices
                            .sort((a, b) => this.compareDbDevicesWithOrder(a, b))
                            .map((dbDevice, index) => this.dcRow(dbDevice, index))
                        : 
                        <TableRow>
                            <TableCell align="center" colSpan={8}>
                                <NoDataSvg />
                                <div>No HDC Device</div>
                            </TableCell>
                        </TableRow> 
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                selectedDbDevice !== undefined ?
                    <CreateDcDeploymentModalPanel
                        initialDeployment={selectedDbDevice.latestDeployment || null}
                        deviceId={selectedDbDevice.device.id}
                        shouldBeShown={true}
                        onCreateClick={this.onCreateDcDeploymentClick.bind(this)}
                        onClose={() => this.setState({ createDeploymentModalDeviceIndex: undefined })}
                        rootSensorGroup={rootSensorGroup}
                        dcDeviceDeployments={dcDevices}
                    />
                    : null
            }
            {
                (showLogsForDeployment !== undefined) ?
                    <ShowLogsForDcDeploymentModalPanel
                        dcdeployment={showLogsForDeployment}
                        shouldBeShown={true}
                        onClose={() => this.setState({ showLogsForDeployment: undefined })}
                    />
                    : null
            }
            {
                (showDataExtractionLogsForDevice !== undefined && showDataExtractionLogsForDevice.readerType === Sensorreadertype.RemoteDataCollector) ?
                    <DeviceDataExtractionLogsModalPanel
                        device={showDataExtractionLogsForDevice}
                        shouldBeShown={true}
                        uiSettings={this.props.uiSettings}
                        onClose={() => this.setState({ showDataExtractionLogsForDevice: undefined })}
                    />
                    : null
            }
            {
                (showDataExtractionLogsForDevice !== undefined && showDataExtractionLogsForDevice.readerType === Sensorreadertype.DataCollector) ?
                    <DcDeviceDataExtractionLogsModalPanel
                        device={showDataExtractionLogsForDevice}
                        shouldBeShown={true}
                        uiSettings={this.props.uiSettings}
                        onClose={() => this.setState({ showDataExtractionLogsForDevice: undefined })}
                    />
                    : null
            }
            {
                (showErrorLogsForDevice !== undefined) ?
                    <DeviceErrorLogsModalPanel
                        device={showErrorLogsForDevice}
                        shouldBeShown={true}
                        onClose={() => this.setState({ showErrorLogsForDevice: undefined })}
                    />
                    : null
            }
            {
                (showBatteryForDevice !== undefined) ?
                    <BatteryModalPanel
                        device={showBatteryForDevice}
                        shouldBeShown={true}
                        onClose={() => this.setState({ showBatteryForDevice: undefined })}
                    />
                    : null
            }
            {
                (showTemperatureForDevice !== undefined) ?
                    <TemperatureModalPanel
                        device={showTemperatureForDevice}
                        shouldBeShown={true}
                        onClose={() => this.setState({ showTemperatureForDevice: undefined })}
                        temperatureUnits={this.props.uiSettings.temperatureUnits}
                    />
                    : null
            }
            {
                showCreateDcForm ?
                    <CreateDCModalPanel
                        shouldBeShown={true}
                        onCancel={() => this.setState({ showCreateDcForm: false })}
                        onSave={async () => {
                            this.setState({ showCreateDcForm: false });
                            await this.loadDeployments();
                        }}
                        currentDevices={dcDevices.map(d => d.device)}
                        unitsMode={this.props.uiSettings.unitsMode}
                    />
                    : null
            }
        </>
    }

    onColumnClick(newSortBy: SortOptions) {
        const { sortBy, order } = this.state;

        if (newSortBy === sortBy) {
            this.setState({ "order": order === "asc" ? "desc" : "asc" });
        } else {
            this.setState({ "sortBy": newSortBy });
        }
    }

    private async onCreateDcDeploymentClick(
        dcDeployment: Dcdeploymentpost) {

        await Services.DcDeployments.postDcDeployment(dcDeployment);

        this.setState({ createDeploymentModalDeviceIndex: undefined });
        await this.loadDeployments();

        const uploadResult = await MainCGI.uploadDbAuto();

        console.log("uploadResult", uploadResult);
    }

    private createDeployment(deviceIndex: number) {
        this.setState({ createDeploymentModalDeviceIndex: deviceIndex });
    }
}
