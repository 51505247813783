declare global {
    interface Window {
        generalApi?: GeneralApiResponse
    }
}
interface GeneralApiResponse {
    isUpdateDownloaded: () => boolean
    getArgs: () => Promise<string[]>
}
export function isServiceAvailable() {
    return !!window.generalApi;
}
export async function isUpdateDownloaded() {
    return window.generalApi?.isUpdateDownloaded();
}