import RRCThicknessAlgorithm from "../RRCThicknessAlgorithm";
import RRCSensor from "../RRCSensor";

declare global {
    interface Window {
        calculationCgiApi?: { send: (eventName: string, args?: object) => Promise<CalculationApiRespoonse> | undefined }
    }
}

interface CalculationApiRespoonse {
    thicknessMm?: number | null;
    minThicknessMm?: number;
}

const send = (eventName: string, args?: object): Promise<CalculationApiRespoonse> | undefined => {
    return window.calculationCgiApi?.send(eventName, args);
}

type ThicknessParams = {
    thicknessAlgorithm: RRCThicknessAlgorithm;
    payload: number[];
    sensor: RRCSensor;
    temperature: number;
    systemDelay: number;
    automatic: false;
    amplitudeThreshold: number;
    minThicknessMm: number;
    numberOfPresamplesBeforeTimeZero: number;
} | {
    thicknessAlgorithm: RRCThicknessAlgorithm;
    payload: number[];
    sensor: RRCSensor;
    temperature: number;
    systemDelay: number;
    automatic: true;
    numberOfPresamplesBeforeTimeZero: number;
}

export default class CalculationService {
    async calculateThickness(params: ThicknessParams): Promise<CalculationApiRespoonse | undefined> {
        console.log("sending calculateThickness", params);
        return send("calculateThickness", params);
    }
}
