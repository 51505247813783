import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import Button from "Components/Buttons/Button";
import { Cancel } from "@mui/icons-material";

interface CancelButtonProps {
    isDisabled?: boolean;
    onClick(event?: MouseEvent): void;
}

const CancelButton: FunctionComponent<CancelButtonProps> = ({ isDisabled, onClick }) => (
    <Button
        label="Cancel"
        width={111}
        icon={<Cancel />}
        isDisabled={isDisabled}
        onClick={event => onClick(event)}
    />
);

export default CancelButton;
