import * as React from "react";
import { Component, CSSProperties } from "react";
import Button from "Components/Buttons/Button";
import ModalPanel from "Panels/ModalPanel";
import SensorSelectPanel from "../Primary/SensorSelectPanel";
import ButtonsFormFooter from "../../../Forms/Shared/ButtonsFormFooter";
import CancelButton from "../../../Components/Buttons/Composite/CancelButton";
import { Sensor, Sensorgroup } from "@inductosense/typescript-fetch";
import AddIcon from "../../../Components/Graphics/Icons/AddIcon";

interface SensorSelectModalPanelProps {
    rootSensorGroup: Sensorgroup;
    shouldBeShown: boolean;
    onClose(): void;
    onSensorSelect(sensors: Sensor[]): void;
    multiSelect?: true | undefined;
    title?: string;
    okLabel?: string;
    hideNoSensorOption?: boolean;
    allowedRfidPrefixes?: string[];
}

interface SensorSelectModalPanelState {
    selectedSensors: Sensor[];
}

const containerStyle: CSSProperties = { display: "flex", margin: 10 };
const rightHandButtonsStyle: CSSProperties = { flex: 1 };

export default class SensorSelectModalPanel extends Component<SensorSelectModalPanelProps, SensorSelectModalPanelState> {
    constructor(props: SensorSelectModalPanelProps) {
        super(props);

        this.state = {
            selectedSensors: []
        };
    }

    render() {
        const { onClose, shouldBeShown } = this.props;

        return (
            <ModalPanel
                title={this.title()}
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={this.form()}
                shouldApplyPaddingToContent={false}
            />
        );
    }

    title() {
        if (this.props.title) {
            return this.props.title;
        } else if (this.props.multiSelect) {
            return "Select sensors";
        } else {
            return "Select sensor";
        }
    }

    private form() {
        return (
            <div>
                {this.sensorTreePanel()}
                {this.footer()}
            </div>
        );
    }

    private sensorTreePanel() {
        if(this.props.rootSensorGroup == undefined){
            return null;
        }

        return (
            <SensorSelectPanel
                rootSensorGroup={this.props.rootSensorGroup}
                onSensorSelect={(_ids: string[], sensors: Sensor[]) => this.setState({ selectedSensors: sensors })}
                multiSelect={this.props.multiSelect}
                selectedIds={this.state.selectedSensors.map(s => s.id)}
                allowedRfidPrefixes={this.props.allowedRfidPrefixes}
             />
        );
    }

    private footer() {
        return (
            <div style={containerStyle}>
                <div style={rightHandButtonsStyle}>
                    <ButtonsFormFooter
                        buttons={[
                            <Button
                                key="ok"
                                precedence="primary"
                                icon={<AddIcon />}
                                label={this.props.okLabel || "OK"}
                                onClick={() => this.onOKButtonClick()}
                                isDisabled={this.state.selectedSensors.length === 0}
                            />,
                            (
                                this.props.hideNoSensorOption ? null :
                                <Button
                                    key="no_sensor"
                                    label="No Sensor"
                                    onClick={() => this.onNoSensorButtonClick()}
                                />
                            ),
                            <CancelButton
                                key="cancel"
                                onClick={() => this.props.onClose()}
                            />
                        ]}
                    />
                </div>
            </div>
        );
    }

    private onOKButtonClick() {
        this.props.onSensorSelect(this.state.selectedSensors);
        this.props.onClose();
    }

    private onNoSensorButtonClick() {
        this.props.onSensorSelect([]);
        this.props.onClose();
    }
}
