import Validator from "Validation/Validator";

export default class NumberIsPositiveValidator implements Validator<number> {
    errorMessage = "Must be positive";

    isValid(value: number | null | undefined) {
        if (value === null || value === undefined) return false;
        return value > 0;
    }
}
