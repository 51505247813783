import * as React from "react";
import { FunctionComponent } from "react";
import { Readingdetailed } from "@inductosense/typescript-fetch";
import PathLine from "./PathLine";
import { LinearOrTimeScale } from "../../../Types/D3Extensions";
import { ScaleLinear } from "d3";
import { GetReadingPoints } from "../../../Utilities/ReadingUtils";
import { CSSProperties } from "@mui/material/styles/createTypography";

interface ReadingLinesProps {
    reading: Readingdetailed;
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    colour: string;
    showEnvelope: boolean;
    showRF: boolean;
    timeBoundThresholdValues?: {
        threshold: number | null;
        startTime: number | null;
        endTime: number | null;
    };
    normalisationFactor?: number;
}

const ReadingLines: FunctionComponent<ReadingLinesProps> = ({ reading,
    xScale, yScale, colour, showEnvelope, showRF, timeBoundThresholdValues, normalisationFactor = 1 }) => {

    const points = GetReadingPoints(reading, normalisationFactor);

    const { threshold, startTime, endTime } = timeBoundThresholdValues ||
    {
        threshold: reading.parameters?.threshold || null, // TODO: Remove truthy test
        startTime: reading.parameters?.gateLower !== undefined ? reading.parameters?.gateLower * 10 ** 6 : null,
        endTime: reading.parameters?.gateUpper !== undefined ? reading.parameters?.gateUpper * 10 ** 6 : null
    };

    console.log({ threshold, startTime, endTime });

    const peakPoints = reading.calculatedPeaks
        .filter(e => threshold === null || e.amplitude >= threshold)
        .filter(e => startTime === null || e.timeInSeconds * 10 ** 6 >= startTime)
        .filter(e => endTime === null || e.timeInSeconds * 10 ** 6 <= endTime)
        .map(e => {
            return {
                x: e.timeInSeconds * 10 ** 6,
                y: e.amplitude * normalisationFactor
            }
        });

    const envelopePoints = reading.calculatedEnvelopeSamples.map(e => {
        return {
            x: e.timeInSeconds * 10 ** 6,
            y: e.amplitude * normalisationFactor
        }
    });

    const style: CSSProperties = {
        pointerEvents: "none",
        fill: "none",
        strokeWidth: 1,
        stroke: colour
    };

    const envelopeStyle: CSSProperties = {
        ...style,
        strokeWidth: 2
    }

    const peakStyle: CSSProperties = {
        ...style,
        fill: colour
    }

    return (
        <g>
            {showRF ? <PathLine points={points} xScale={xScale} yScale={yScale} style={style} /> : null}
            {showEnvelope ? <PathLine points={envelopePoints} xScale={xScale} yScale={yScale} style={envelopeStyle} /> : null}
            
            {(peakPoints || []).map((p, i) =>
                <polygon
                    key={i}
                    points={`${xScale(p.x) - 5},${yScale(p.y) - 10} ${xScale(p.x) + 5},${yScale(p.y) - 10} ${xScale(p.x)},${yScale(p.y)}`}
                    style={peakStyle}
                />
            )}
        </g>
    );
}

export default ReadingLines;
