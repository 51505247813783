import * as React from "react";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { TreeItem, SimpleTreeView } from "@mui/x-tree-view";
import SensorComponent from "./Sensor";
import { FunctionComponent } from "react";
import Sensor from "Model/Sensor";
import SensorGroup from "Model/SensorGroup";
import { HasPermittedPrefix } from "../../Utilities/RfidUtils";
import Button from "Components/Buttons/Button";


 
const multiSelectButtonsContainerStyle: CSSProperties = {
    display: "flex",
    width: "100%",
    justifyContent: "start",
    gap: 10,
    paddingBottom: 10,
    paddingLeft: 30
};
 
const treeContainerStyle: CSSProperties = {
    flex: 1
};
 
interface SensorGroupProps {  
    searchItem?: string;
    isExpanded?: boolean;
    data: SensorGroup;
    multiSelect?: true | undefined;
    selectedIds: string[];
    onSelectedIdsChange(newIds: string[], sensors: Sensor[]): void;
    allowedRfidPrefixes?: string[];
    fastMultiSelectButtonsEnabled?: true | undefined;
}
 
const SensorGroupComponent: FunctionComponent<SensorGroupProps> = ({ isExpanded, searchItem, data, multiSelect, selectedIds, onSelectedIdsChange, allowedRfidPrefixes, fastMultiSelectButtonsEnabled }) => {
    const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

    const handleExpandedItemsChange = (
        event: React.SyntheticEvent,
        itemIds: string[],
      ) => {
        setExpandedItems(itemIds);
        console.log(event)
      };

    const expandedItemsFunc = (data: SensorGroup) => {
        let ids: string[] = [];
        expandedItems && data.children && data.children.map((item) => { 
            ids.push(item.id)
            if( item.children && item.children.length>0){
                ids = ids.concat(expandedItemsFunc(item))
            }
        })
        return ids
    }

    React.useEffect(()=>{ 
        if( isExpanded || searchItem ){
            setExpandedItems(expandedItemsFunc(data))
        } else {
            setExpandedItems([])
        } 
    },[ isExpanded, searchItem ]);

    const findAllSensors = (data: SensorGroup): Sensor[] => {
        const children = data.children || [];
        const childSensors = children.map(findAllSensors).reduce((prev, cur) => prev.concat(cur), []);
 
        return [
            ...(data.sensors || []),
            ...childSensors
        ];
    }
 
    const allSensors = findAllSensors(data);
    const allPermittedSensors = allSensors.filter(s => HasPermittedPrefix(s.rfid, allowedRfidPrefixes)); // TODO: RFID should be mandatory
    const allSensorIds = allPermittedSensors.map(s => s.id);
 
    const findSelectedSensors = (selectedNodeIds: string[], data: SensorGroup): Sensor[] => {
        if (selectedNodeIds.indexOf(data.id || "") !== -1) {
            return findAllSensors(data);
        }
 
        const sensors = data.sensors || [];
        const children = data.children || [];
        const childSensors = children.map(sensorGroup => findSelectedSensors(selectedNodeIds, sensorGroup))
            .reduce((prev, cur) => prev.concat(cur), []);
 
        return [
            ...sensors.filter(sensor => selectedNodeIds.indexOf(sensor.id || "") !== -1),
            ...childSensors
        ];
    }
 
    const sensors = data.sensors?.map(sensor => {
        const { description, id, rfid } = sensor;
 
        if (!id) throw new Error("ID should never be undefined at this point.");
        if (!rfid) throw new Error("RFID should never be undefined at this point.");
 
        if (!HasPermittedPrefix(rfid, allowedRfidPrefixes)) {
            return null;
        }
 
        return (
            <SensorComponent
                key={id}
                id={id}
                name={description ?? `[${rfid}]`}
                checked={selectedIds.includes(id)}
                onCheckedChanged={newState => {
                    const newNodeIds = [...selectedIds.filter(n => n !== id), ...(newState ? [id] : [])];
                    onSelectedIdsChange(newNodeIds, findSelectedSensors(newNodeIds, data).filter(s => s !== undefined));
                }}
                showCheckbox={multiSelect || false}
            />
        );
    });
 
    const subgroups = data.children?.map(group => {
        const groupSensorIds = findAllSensors(group).map(s => s.id);
 
        return <SensorGroupComponent
            key={group.id}
            data={group}
            multiSelect={multiSelect}
            selectedIds={selectedIds.filter(r => groupSensorIds.includes(r))}
            onSelectedIdsChange={newIds => {
                const updatedStateIds = [...selectedIds.filter(id => !groupSensorIds.includes(id)), ...newIds];
                onSelectedIdsChange(updatedStateIds, findSelectedSensors(updatedStateIds, data));
            }}
            allowedRfidPrefixes={allowedRfidPrefixes}
        />;
    });
 
    if (data.parentGroupId == null) {
 
        if (multiSelect) {
 
            const multiSelectButtons = fastMultiSelectButtonsEnabled ? (
                <div style={multiSelectButtonsContainerStyle}>
                    <Button
                        onClick={() => {
                            selectedIds = allSensorIds;
                            onSelectedIdsChange(allSensorIds, allPermittedSensors);
                        }}
                        label="Select all"
                        isDisabled={selectedIds.length === allSensorIds.length}
                    />
                    <Button
                        onClick={() => {
                            selectedIds = [];
                            onSelectedIdsChange([], []);
                        }}
                        label="Deselect all"
                        isDisabled={selectedIds.length === 0}
                    />
                </div>) : <></>;
 
            return (
                <>
                    {multiSelectButtons}
                    <SimpleTreeView
                        sx={{"& .MuiTreeItem-content":{width: "auto"}}}
                        expandedItems={expandedItems}
                        onExpandedItemsChange={handleExpandedItemsChange}
                        slots={{ collapseIcon: ExpandMore, expandIcon: ChevronRight }}
                        multiSelect
                        selectedItems={selectedIds}
                        // No event handler for onSelectedItemsChanged because this is taken care of in the handlers for the sensors/groups themselves
                    >
                        {sensors}
                        {subgroups}
                    </SimpleTreeView>
                </>
            );
        } else {
            return (
                <SimpleTreeView
                    sx={{"& .MuiTreeItem-content":{width: "auto"}}}
                    slots={{ collapseIcon: ExpandMore, expandIcon: ChevronRight }}
                    expandedItems={expandedItems}
                    onExpandedItemsChange={handleExpandedItemsChange}
                    selectedItems={selectedIds.length === 1 ? selectedIds[0] : ""}
                    onSelectedItemsChange={(_event, id) => {
                        if (id) {
                            onSelectedIdsChange([id], [findSelectedSensors([id], data)[0]].filter(s => s !== undefined))}
                        }
                    }
                >
                    {sensors}
                    {subgroups}
                </SimpleTreeView>
            );
        }
    }
    const isWholeGroupChecked = allSensorIds.filter(sid => !selectedIds.includes(sid)).length === 0;
 
    const checkedChange = () => isWholeGroupChecked ? onSelectedIdsChange([], []) : onSelectedIdsChange(allSensorIds, allSensors);
 
    const nameAndEditButton = (
        <div style={{ display: "flex", alignItems: "center" }} onClick={() => checkedChange()}>
            <span style={{ margin: "0 10px" }}>
                {
                    (multiSelect && allSensors.length > 0) ? <input
                        type="checkbox"
                        checked={isWholeGroupChecked}
                        onChange={ () => checkedChange() }
                    /> : null
                }
                {data.name}
            </span>
        </div>
    );
 
    return (
        <div style={treeContainerStyle}>
            <TreeItem itemId={data.id ?? ""} label={nameAndEditButton}>
                {sensors}
                {subgroups}
            </TreeItem>
        </div>
    );
};
 
export default SensorGroupComponent;
