import * as React from "react";
import { FunctionComponent } from "react";
import ButtonsFormFooter from "Forms/Shared/ButtonsFormFooter";
import NoButton from "Components/Buttons/Composite/NoButton";
import YesButton from "Components/Buttons/Composite/YesButton";

interface YesAndNoButtonsFormFooterProps {
    isCommittingAction: boolean;
    onYesButtonClick(): void;
    onNoButtonClick(): void;
}

const YesAndNoButtonsFormFooter: FunctionComponent<YesAndNoButtonsFormFooterProps> = ({ isCommittingAction, onYesButtonClick, onNoButtonClick }) => (
    <ButtonsFormFooter
        buttonsPosition="centre"
        buttons={[
            <YesButton
                key="yes"
                isSpinning={isCommittingAction}
                onClick={() => onYesButtonClick()}
            />,
            <NoButton
                key="no"
                isDisabled={isCommittingAction}
                onClick={() => onNoButtonClick()}
            />
        ]}
    />
);

export default YesAndNoButtonsFormFooter;
