import * as MaterialUi from "@mui/material";
import * as React from "react";
import { Component } from "react";
import { RelativePlacement, relativePlacementToMaterialUiLabelPlacementValue } from "Types/RelativePlacement";

interface RadioButtonProps<T> {
    option: T;
    label: string;
    labelPosition?: RelativePlacement;
    isDisabled: boolean;
}

export default class RadioButton<T> extends Component<RadioButtonProps<T>> {
    render() {
        const { isDisabled, label, labelPosition, option } = this.props;

        return (
            <MaterialUi.FormControlLabel
                className="radio-button"
                value={option}
                label={label}
                labelPlacement={relativePlacementToMaterialUiLabelPlacementValue(labelPosition)}
                disabled={isDisabled}
                control={<MaterialUi.Radio />}
            />
        );
    }
}
