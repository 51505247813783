import * as React from "react";
import { Component } from "react";
import User from "Model/User";
import Services from "Services/Platform/Services";
import Button from "Components/Buttons/Button";
import { Alert } from "@mui/lab";
import UserFormModalPanel from "../Panels/Composite/Modals/UserFormModalPanel";
import SimplePanel from "../Panels/SimplePanel";
import CloseButton from "../Components/Buttons/Composite/CloseButton";
import * as MainCGI from "Services/Electron/MainCGI";
import ItemList from "../Components/ItemList";
import { ManifestType } from "./manifest";

interface UserListPanelProps {
    onClose(): void;
    manifest?: ManifestType;
}

interface UserListPanelState {
    isUserFormShown: boolean;
    editingUser?: User;
    isReadyToRender: boolean;
    userList: User[] | null;
    itemsChecked: boolean[];
    errorMessage?: string;
}

export default class UserListView extends Component<UserListPanelProps, UserListPanelState> {
    constructor(props: UserListPanelProps) {
        super(props);

        this.state = {
            isUserFormShown: false,
            isReadyToRender: false,
            userList: null,
            itemsChecked: []
        };
    }

    async componentDidMount() {
        await this.fetchUsers();
    }

    async fetchUsers() {
        const userList = await Services.UsersService.getUsers();
        this.setState({
            userList,
            itemsChecked: new Array(userList.length).fill(false),
            isReadyToRender: true
        });
    }

    render() {
        return <SimplePanel
            title="Users"
            titleAlignment="centre"
            actionButton={this.closeButton()}
            content={this.content()}
            shouldApplyPadding={false}
        />
    }

    private closeButton() {
        return <CloseButton onClick={() => this.props.onClose()} />;
    }

    content() {
        const { userList, isUserFormShown, isReadyToRender, errorMessage, editingUser } = this.state;
        const { manifest } = this.props;

        console.log("manifest", manifest);

        if (manifest?.disableUsersScreen) {
            return <div><p>User management is now part of the <a
                href="https://portal.inductosense.cloud" target="_blank" rel="noreferrer">customer portal</a>.
            </p></div>;
        }

        if (!isReadyToRender) return null;

        return (
            <div>
                <p>{this.props.manifest?.disableUsersScreen}</p>
                {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
                <div style={{ display: "inline-block", marginLeft: "10px" }}>
                    <Button label="Add User" onClick={this.onCreateClick.bind(this)} />
                </div>
                <div style={{ display: "inline-block", marginLeft: "10px" }}>
                    <Button label="Select All" onClick={this.selectAll.bind(this)} />
                </div>
                <div style={{ width: 400 }}>
                    <ItemList
                        items={userList!.map(item => item.username)}
                        onSelectionChanged={(itemsChecked) => this.setState({ itemsChecked })}
                        itemsChecked={this.state.itemsChecked}
                        onEditClick={this.onEditClick.bind(this)}
                        showEditIcons={true}
                    />
                </div>
                <UserFormModalPanel
                    shouldBeShown={isUserFormShown}
                    initialUser={editingUser}
                    onClose={() => this.setState({ isUserFormShown: false })}
                    onUserUpdated={() => this.onUserUpdated()}
                />
            </div>
        );
    }

    async onUserUpdated() {
        await this.fetchUsers();
        await MainCGI.uploadDbAuto();
    }

    selectAll() {
        this.setState({
            itemsChecked: new Array(this.state.userList!.length).fill(true)
        });
    }

    onCreateClick() {
        this.setState({
            isUserFormShown: true,
            editingUser: undefined
        });
    }

    onEditClick(index: number) {
        this.setState({
            isUserFormShown: true,
            editingUser: this.state.userList![index]
        });
    }
}
