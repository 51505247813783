import Validator from "Validation/Validator";

export default class StringRepresentsAFourDigitPinOrNothingValidator implements Validator<string> {
    constructor(readonly errorMessage = "Must be a four digit pin or left empty") { }

    isValid(value: string | null | undefined) {
        if (value === null || value === undefined || value === "") return true;
        
        return /^\d{4}$/.test(value);

    }
}
