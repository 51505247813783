import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import Button from "Components/Buttons/Button";
import { Cancel } from "@mui/icons-material";

interface NoButtonProps {
    isDisabled?: boolean;
    onClick(event?: MouseEvent): void;
}

const NoButton: FunctionComponent<NoButtonProps> = ({ isDisabled, onClick }) => (
    <Button
        label="No"
        width={73}
        precedence="secondary"
        icon={<Cancel />}
        isDisabled={isDisabled}
        onClick={event => onClick(event)}
    />
);

export default NoButton;
