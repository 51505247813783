import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import { Device } from "@inductosense/typescript-fetch";
import BatteryPanel from "./BatteryPanel";

interface BatteryModalPanelProps {
    shouldBeShown: boolean;
    onClose(): void;
    device: Device;
}

export default class BatteryModalPanel extends Component<BatteryModalPanelProps> {
    constructor(props: BatteryModalPanelProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { onClose, shouldBeShown, device } = this.props;

        return (
            <ModalPanel
                title="Battery"
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={<BatteryPanel device={device} />}
            />
        );
    }
}
