import * as AlertColours from "Styling/Palette/AlertColours";
import * as BrandColours from "Styling/Palette/BrandColours";
import * as React from "react";
import { StandardTextFieldProps } from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { /*textAlignmentToCssTextAlignValue,*/ TextAlignment } from "../../Types/TextAlignment";
import { CSSProperties } from "@mui/material/styles/createTypography";
import merge from "../../Utilities/Merge";

const containerStyle: CSSProperties = {
    display: "flex"
};

const commonIconStyle: CSSProperties = {
    marginTop: 3,
    marginRight: 2,
    color: BrandColours.inductosenseDuckEggTeal,
    fill: BrandColours.inductosenseDuckEggTeal
};

const iconStyleWhenLabelIsSpecified: CSSProperties = {
    marginTop: 19
};

const iconStyleWhenDisabled: CSSProperties = {
    color: BrandColours.inductosenseMediumGrey,
    fill: BrandColours.inductosenseMediumGrey
};

const iconStyleWhenInvalid: CSSProperties = {
    color: AlertColours.alertErrorForegroundRed,
    fill: AlertColours.alertErrorForegroundRed
};

interface TextFieldControlledProps extends StandardTextFieldProps {
    isLogin?:boolean | null;
    suffix?: string | React.ReactNode;
    width?: number;
    textAlignment?: TextAlignment;
    validationError?: string | null;
    formValidationError?: string | null;
    icon?: React.ReactNode;
    isGateway?: boolean;
}

const TextFieldControlled = React.forwardRef<HTMLInputElement, TextFieldControlledProps>((props, ref) => {
    const {
        isLogin,
        width,
        icon,
        validationError,
        formValidationError,
        disabled,
        suffix,
        InputProps,
        ...otherProps
    } = props;

    const endAdornment = () => {
        if (!suffix) return null;
        return <InputAdornment position="end">{suffix}</InputAdornment>;
    }

    const renderIcon = () => {
        if (!icon) return null;

        let style: CSSProperties;

        const isInvalid = validationError || formValidationError;

        style = isInvalid
            ? merge(commonIconStyle, iconStyleWhenInvalid)
            : commonIconStyle;

        style = otherProps.label
            ? merge(style, iconStyleWhenLabelIsSpecified)
            : style;

        style = disabled
            ? merge(style, iconStyleWhenDisabled)
            : style;

        return <div style={style}>{icon}</div>;
    }

    const loginStyle = {
        borderRaduis: 10,
        padding: "0px !important", 
        margin: "0px !important",
    }
 
    const sxStyle = {
        padding: "0px !important", 
        margin: "0px !important",
    }

    const gatewayStyle = {
        "& .MuiOutlinedInput-root":{
            height:"40px",
            padding: "0px !important", 
            margin: "0px !important",
        }
    }
    
    const field = () => {
        const isValid = !validationError && !formValidationError;
        console.log(isValid);
        const validationErrorMessage = validationError || formValidationError;
        return <TextField
            size={isLogin ? "small" : "medium"}
            className="text-field"
            fullWidth
            error={!isValid}
            helperText={validationErrorMessage}
            InputProps={{
                ...InputProps,
                endAdornment: endAdornment()
            }}
            variant="outlined"
            ref={ref}
            sx={ isLogin ? loginStyle : gatewayStyle ? gatewayStyle : sxStyle }
            disabled={disabled}
            {...otherProps}
        />
    }

    const style = !width
        ? containerStyle
        : merge(containerStyle, { width: `${width}px` });

    return (
        <div style={style}>
            {renderIcon()}
            {field()}
        </div>
    );
});

TextFieldControlled.displayName = "TextFieldControlled";
export default TextFieldControlled;
