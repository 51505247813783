import * as React from "react";
import { Component } from "react";
import ArrayData from "./ArrayData";
import Slider from "./Slider";
import TextFieldControlled from "Components/Input/TextFieldControlled";
//import IconButton from "../Components/Buttons/IconButton";
//import ArrowRightIcon from "@mui/icons-material/ArrowRight";
//import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ToggleButtonGroup from "Components/Buttons/ToggleButtonGroup";
import ThicknessGrid from "./ThicknessGrid";
import ThicknessHexagon from "./ThicknessHexagon";
import FlexibleAndFixedPanel from "../../Panels/FlexibleAndFixedPanel";
import ArrayDataItem from "./ArrayDataItem";


interface GridViewProps {
    arrayData?: ArrayData;
    changeView(showArray: boolean): void;
    changeGraph(thicknessGraph: boolean): void;
    changeData(thicknessData: boolean): void;
    changeGrid(showGrid: boolean): void;
    showGrid: boolean;
    chooseThickness: boolean;
    numCols: number;
    numRows: number;
    dates: Date[];
    thicknessData: ArrayDataItem[][][];
    interThicknessData: ArrayDataItem[][][][];
    rateData(width: number, height: number, data: ArrayDataItem[][][]): number[][][];
    findIndex(reqDate: Date): number;
    sensorNames: string[];
    onNodeClick(coords: { x: number; y: number }, node?: ArrayDataItem): void;
}

interface GridViewState {
    date: Date;
    maxTR: number;
    maxTY: number;
    maxRR: number;
    maxRY: number;
}

export default class GridView extends Component<GridViewProps, GridViewState> {
    constructor(props: GridViewProps) {
        super(props);
        this.state = {
            date: this.props.chooseThickness ? this.props.dates[0] : this.props.dates[1],
            maxTR: 8.3,
            maxTY: 8.4,
            maxRR: 1,
            maxRY: 0.5,
        };
    }

    /*private dateBox(labels: number[]) {
        const onClickLeft = () => {
            const index = labels.findIndex((label) => label === this.state.date?.getTime());
            const newIndex = index === 0 || index === -1 ? index : index - 1;
            this.setState({ date: new Date(labels[newIndex]) });
        }

        const onClickRight = () => {
            const index = labels.findIndex((label) => label === this.state.date?.getTime());
            const newIndex = index === labels.length - 1 || index === -1 ? index : index + 1;
            this.setState({ date: new Date(labels[newIndex]) });
        }

        return (
            <div style={{ textAlign: "center" }}>
                <IconButton
                    icon={<ArrowLeftIcon />}
                    onClick={onClickLeft}
                />
                <span
                    style={{ textAlign: "center", backgroundColor: "#fff", width: "30%", margin: "10px auto", padding: "10px" }}>
                    {this.state.date?.toString()}

                </span>
                <IconButton
                    icon={<ArrowRightIcon />}
                    onClick={onClickRight}
                />
            </div>
        );
    }*/
   
    private dateSlider(labels: number[]) {
        const labelrange = labels[labels.length - 1] - labels[0];
        const marks = labels.map((label, i) => (
            {
                value: (label - labels[0]) * 100 / labelrange,
                label: (i == 0 || i == labels.length - 1) ? new Date(label).toDateString() : "",
                date: new Date(label)
            }
        ));

        const onChange = (_event: Event, value: number | number[]) => {
            const date = marks.find((mark) => mark.value === value)?.date;
            this.setState({ date : date || this.props.dates[0]});
        }
        const getVal = marks.find((mark) => mark.date.getTime() === this.state.date?.getTime())?.value;

        return <div style={{ marginLeft: 80, marginRight: 80 }}>
            <Slider
                currentVal={getVal}
                marks={marks}
                onChange={onChange}
            />
        </div>
    }


    private colourThreshold() {

        const { chooseThickness } = this.props;

        return <span style={{ width: "30%", float: "right", border: "solid 1px #ccc", padding: "5px"}}>
            Warning:
            <TextFieldControlled
                type="number"
                value={chooseThickness ? (isNaN(this.state.maxTY) ? "" : this.state.maxTY) : (isNaN(this.state.maxRY) ? "" : this.state.maxRY)}
                name="warningThreshold"
                InputProps={{
                    inputProps: {
                        min: chooseThickness ? `${this.state.maxTR}` : "0",
                        max: chooseThickness ? "none" : `${this.state.maxRR}`,
                        step: chooseThickness ? "1" : "0.1",
                    }
                    
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (chooseThickness) {
                        this.setState({ maxTY: e.target.valueAsNumber });
                    } else {
                        this.setState({ maxRY: e.target.valueAsNumber });

                    }
                }}
                
            />
             
            Critical:
            <TextFieldControlled
                type="number"
                value={chooseThickness ? (isNaN(this.state.maxTR) ? "" : this.state.maxTR) : (isNaN(this.state.maxRR) ? "" : this.state.maxRR)}
                InputProps={{
                    inputProps: {
                        min: chooseThickness ? "0" : `${this.state.maxRY}`,
                        max: chooseThickness ? `${this.state.maxTY}` : "none",
                        step: chooseThickness ? "1" : "0.1",
                    }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (chooseThickness) {
                        this.setState({ maxTR: e.target.valueAsNumber });
                    } else {
                        this.setState({ maxRR: e.target.valueAsNumber });
                    }
                  
                }}
            />
        </span>
    }
    
    private chooseData() {
        return (<div>
            <ToggleButtonGroup
                options={["Thickness", "Rate of Change"]}
                initialSelected={this.props.chooseThickness ? "Thickness" : "Rate of Change"}
                exclusive={true}
                size={"small"}
                onChange={(chosenValue) => {
                    if (chosenValue !== null) {
                        this.props.changeData(chosenValue === "Thickness");
                        if (chosenValue === "Rate of Change" && this.props.arrayData && this.state.date?.getTime() === this.props.dates[0].getTime()) {
                                this.setState({ date: new Date(this.props.dates[1]) });
                        } 
                    }
                }}
            />
        </div>
        )
    }
    /*private chooseGraph() {
        return (<div style={{ marginTop: "10px"}}>
            <ToggleButtonGroup
                    options={["Thickness", "Rate of Wall Loss"]}
                    initialSelected={""}
                    exclusive={true}
                    size={"small"}
                    onChange={(chosenValue) => {
                        if (chosenValue !== null) {
                            this.props.changeView(false);
                            this.props.changeGraph(chosenValue === "Thickness");
                        }

                    }}
                />
        </div>
        )}
   
    
    private chooseGrid() {
        return ( <div>
            <ToggleButtonGroup
            options={["Table", "Hexagon"]}
            initialSelected={this.props.showGrid ? "Table" : "Hexagon"}
            exclusive={true}
            size={"small"}
            onChange={(chosenValue) => {
                if (chosenValue !== null) {
                    this.props.changeGrid(chosenValue === "Table");
                }
            }}
            />
        </div>
        )
    }*/

    private getBackground = (value: number, upperThreshold: number) => {
        if (this.props.chooseThickness) {
            const colour = value < this.state.maxTR ? "red" : value < this.state.maxTY ? "yel" : "green";
            switch (colour) {
                case "red":
                    return `hsl(${value * 30 / (this.state.maxTR)}, 100%, 50%)`;
                case "yel":
                    return `hsl(${((value - this.state.maxTR) / (this.state.maxTY - this.state.maxTR)) * 20 + 30}, 100%, 50%) `;
                default:
                    return `hsl(${((value - this.state.maxTY) / (upperThreshold - this.state.maxTY)) * 50 + 70}, 100%, 50%)`;
            }
        } else {
            const colour = value < this.state.maxRY ? "green" : value < this.state.maxRR ? "yel" : "red";
            switch (colour) {
                case "red":
                    return `hsl(${(1 - (value - this.state.maxRR) / (upperThreshold - this.state.maxRR))*30}, 100%, 50%)`;
                case "yel":
                    return `hsl(${(1-(value - this.state.maxRY) / (this.state.maxRR - this.state.maxRY)) * 20 + 30}, 100%, 50%) `;
                default:
                    return `hsl(${Math.min((1 - value / (this.state.maxRY)) * 50 + 70, 120)}, 100%, 50%)`;
            }
        }
    }
    
   

    renderThickness() {
        const { arrayData, chooseThickness, numRows, numCols, thicknessData, interThicknessData, rateData, findIndex, sensorNames, onNodeClick } = this.props;
        if (arrayData === undefined) return null;

        
        
        
        const currentThickness = thicknessData[findIndex(this.state.date)];
        const currentInterThickness = interThicknessData.map(sensor => sensor[findIndex(this.state.date)]);

        const rateOfChangeData = interThicknessData.map(sensor => rateData(numCols * 2 - 1, numRows * 2 - 1, sensor)[findIndex(this.state.date)]);




        let maxT: number = this.state.maxTY;
        for (const reading of arrayData.readingAnalyses) {
            maxT = Math.max(maxT, reading.thicknessMm);
        }

        let maxR: number = this.state.maxRR;
        for (const sensor in sensorNames) {
            const data = rateData(numCols * 2 - 1, numRows * 2 - 1, interThicknessData[sensor]);
            for (const reading of data) {
                const currMax = Math.max(...reading.map(function (val) { return Math.max(...val); }));
                maxR = Math.max(maxR, currMax);
            }
        }

        const upperThreshold = chooseThickness ? maxT : maxR;

        switch (this.props.showGrid) {
            case true: return <ThicknessGrid
                thicknessData={currentThickness}
                rateOfChangeData={rateData(numCols, numRows,thicknessData)[findIndex(this.state.date)]}
                background={(value) => this.getBackground(value, upperThreshold)}
                chooseThickness={chooseThickness}
                numRows={numRows}
                numCols={numCols}
            />
            case false: return <ThicknessHexagon
                thicknessData={currentInterThickness}
                rateOfChangeData={rateOfChangeData}
                background={(value) => this.getBackground(value, upperThreshold)}
                chooseThickness={chooseThickness}
                numRows={numRows}
                numCols={numCols}
                sensorNames={sensorNames}
                onNodeClick={onNodeClick}
            />
        }
    }
    
    render() {
        const { arrayData, chooseThickness, dates } = this.props;
        if (arrayData === undefined) return null;

        const labels = dates.map((date) => date.getTime());

        return (
            <>
                <FlexibleAndFixedPanel
                    topFixedContent={this.chooseData()}
                    flexibleContent={this.renderThickness()}
                    bottomFixedContent={<>
                        {chooseThickness ? this.dateSlider(labels) : this.dateSlider(labels.slice(1))}
                        <div style={{
                            padding: "10px 20px 10px",
                            position: "absolute",
                            right: 10,
                            top: 10,
                        }}>
                            {this.colourThreshold()}
                        </div>
                    </>}
                />
            </>
        )
    }

}

