// This file is our short-term hack to enable configuration. While we have just one
// production deployment we can just keep the endpoint and credentials here.

// The app is pre-configured for debugging server interactions during development;
// if you change this on a feature branch during development, remember to revert
// the change at the end of the branch before submitting a pull request, so that
// the master branch remains configured for development purposes by default.

interface Configuration {
    skipSplashScreen: boolean;
}

interface Configurations {
    development: Configuration;
    staging: Configuration;
    production: Configuration;
}

const configurations: Configurations = {
    development: {
        skipSplashScreen: true
    },
    staging: {
        skipSplashScreen: false
    },
    production: {
        skipSplashScreen: false
    }
};

// Change this value to switch configuration:
const AppConfiguration = configurations.development;

export default AppConfiguration;
