import * as React from "react";
import { CSSProperties } from "react";
import Box from "@mui/material/Box";

interface TabPanelProps {
    isDevice?: boolean;
    children?: React.ReactNode;
    index: number;
    value: number;
}

const deviceHomeCss:CSSProperties = {
    width:"1200px",
    position: "relative",
    paddingLeft: "220px",
    background: "#fff",
    minHeight: "calc(100vh - 84px)",
}

export default function TabPanel(props: TabPanelProps) {
    const { isDevice, children, index, value, ...other } = props;

    return (
        <div
            style={ isDevice ? deviceHomeCss : undefined }
            role="tabpanel"
            hidden={value !== index}
            /*style={value !== index ? { display: "none" } : {}}*/
            id={`tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>
                {children}
            </Box>
        </div>
    );
}
