import * as React from "react";
import { FunctionComponent } from "react";
import ButtonsFormFooter from "Forms/Shared/ButtonsFormFooter";
import CancelButton from "Components/Buttons/Composite/CancelButton";
import SaveButton from "Components/Buttons/Composite/SaveButton";

interface SaveAndCancelButtonsFormFooterProps {
    formValuesAreUnchanged?: boolean;
    isValid?: boolean;
    isSaving: boolean;
    onSaveButtonClick(): void;
    onCancelButtonClick(): void;
}

const SaveAndCancelButtonsFormFooter: FunctionComponent<SaveAndCancelButtonsFormFooterProps> = ({ formValuesAreUnchanged, isValid = true, isSaving, onCancelButtonClick, onSaveButtonClick }) => (
    <ButtonsFormFooter
        buttons={[
            <SaveButton
                key="save"
                isDisabled={formValuesAreUnchanged || !isValid}
                isSpinning={isSaving}
                onClick={() => onSaveButtonClick()}
            />,
            <CancelButton
                key="cancel"
                isDisabled={isSaving}
                onClick={() => onCancelButtonClick()}
            />
        ]}
    />
);

export default SaveAndCancelButtonsFormFooter;
