import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import IconButton from "Components/Buttons/IconButton";
import HealingIcon from "../../Graphics/Icons/HealingIcon";

interface HealingButtonProps {
    color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
    isDisabled?: boolean;
    onClick(event?: MouseEvent): void;
}

const HealingButton: FunctionComponent<HealingButtonProps> = ({ color, isDisabled, onClick }) => (
    <IconButton
        icon={<HealingIcon color={color} />}
        isDisabled={isDisabled}
        onClick={event => onClick(event)}
    />
);

export default HealingButton;
