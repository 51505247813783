import * as React from "react";
import { Component } from "react";
import { Device, Deviceerror } from "@inductosense/typescript-fetch";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Services from "Services/Platform/Services";

interface DeviceErrorLogsPanelProps {
    device: Device;
}

interface DeviceErrorLogsPanelState {
    deviceErrors?: Deviceerror[];
    selectedRow?: number;
}

export default class DeviceErrorLogsPanel extends Component<DeviceErrorLogsPanelProps, DeviceErrorLogsPanelState> {
    constructor(props: DeviceErrorLogsPanelProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        const errors = await Services.Devices.deviceErrorsGet(this.props.device.id);

        this.setState({ deviceErrors: errors });
    }

    render() {
        const { deviceErrors } = this.state;

        if (deviceErrors === undefined) return null;

        return (
            <div style={{ overflowY: "auto", width: "100%", height: "80vh" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Logged At</TableCell>
                                <TableCell>Error Code</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deviceErrors.map((log, index) =>
                                <TableRow key={index}>
                                    <TableCell style={{ width: 200 }} title={log.loggedAt.toString()}>
                                        {log.loggedAt.toDateString()} {log.loggedAt.toLocaleTimeString()}
                                    </TableCell>
                                    <TableCell style={{ width: 200 }} title={log.loggedAt.toString()}>
                                        {log.code.toString()}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}
