import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import CreateDeployment from "./CreateDeployment";
import SensorGroup from "../Model/SensorGroup";
import { Iotdeploymentpost, Iotdeployment, Devicedeployments } from "@inductosense/typescript-fetch";

interface CreateDeploymentModalPanelProps {
    rootSensorGroup: SensorGroup;
    initialDeployment: Iotdeployment | null;
    deviceId: string;
    onCreateClick(
        iotDeployment: Iotdeploymentpost
    ): void;
    shouldBeShown: boolean;
    onClose(): void;
    deviceDeployments: Devicedeployments[];
}

export default class CreateDeploymentModalPanel extends Component<CreateDeploymentModalPanelProps> {
    constructor(props: CreateDeploymentModalPanelProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { onClose, shouldBeShown } = this.props;

        return (
            <ModalPanel
                title="Edit RDC configuration"
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        return (
            <>
                <CreateDeployment
                    initialDeployment={this.props.initialDeployment}
                    deviceId={this.props.deviceId}
                    onCreateClick={this.props.onCreateClick}
                    rootSensorGroup={this.props.rootSensorGroup}
                    deviceDeployments={this.props.deviceDeployments}
                />
            </>
        );
    }
}
