import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import IconButton from "Components/Buttons/IconButton";
import { CameraAlt } from "@mui/icons-material";

interface CameraButtonProps {
    color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
    isDisabled?: boolean;
    onClick(event?: MouseEvent): void;
    className?: string;
}

const CameraButton: FunctionComponent<CameraButtonProps> = ({ color, isDisabled, onClick, className }) => (
    <IconButton
        icon={<CameraAlt color={color} />}
        isDisabled={isDisabled}
        onClick={event => onClick(event)}
        className={className}
    />
);

export default CameraButton;
