/* eslint-disable quotes */
export const Alpha = '\u03B1';
export const Copyright = '\u00A9';
export const Degrees = '\u00B0';
export const Ellipsis = '\u2026';
export const EnDash = '\u2013';
export const Mu = '\u03BC';
export const NonBreakingSpace = '\u00A0';
export const NorthWestArrow = '\u2196';
export const LeftArrow = '\u2190';
export const UpArrow = '\u2191';
