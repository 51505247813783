import * as React from "react";
import { Component, CSSProperties } from "react";
import SaveButton from "Components/Buttons/Composite/SaveButton";
import TextField from "Components/Input/TextField";

const groupNameFieldStyle: CSSProperties = {
    flex: 1,
    marginRight: 13
};

interface EditSensorGroupFormProps {
    currentName: string;
    rfidIfMES?: string;
    isSaving: boolean;
    onClose(): void;
    onSave(newName: string): void;
}

interface EditSensorGroupFormState {
    name: string | null;
}

export default class EditSensorGroupForm extends Component<EditSensorGroupFormProps, EditSensorGroupFormState> {
    constructor(props: EditSensorGroupFormProps) {
        super(props);
        this.state = { name: props.currentName };
    }

    render() {
        return (
            <div>
                {this.groupNameField()}
                {this.rfidIfMESField()}
                {this.saveButton()}
            </div>
        );
    }

    private groupNameField() {
        return (
            <div style={groupNameFieldStyle}>
                <TextField
                    label="Group name"
                    initialValue={this.props.currentName}
                    isDisabled={this.props.isSaving}
                    onNewValidValue={newValidValue => this.onGroupNameFieldUpdated(newValidValue)}
                />
            </div>
        );
    }

    private rfidIfMESField() {
        const { rfidIfMES } = this.props;

        if (rfidIfMES !== undefined) {
            return <div style={{ display: "inlineBlock" }}>
                <p>MES RFID: {rfidIfMES}</p>
                <p style={{ width: 400 }}>
                    You can change other properties such as thickness algoritm by right-clicking on an individual MES node.
                </p>
            </div>;
        } else {
            return null;
        }
    }

    private saveButton() {
        const { currentName, isSaving } = this.props;
        const { name } = this.state;

        return (
            <div style={{ marginTop: 10 }}>
                <SaveButton
                    isDisabled={!name || name === currentName}
                    isSpinning={isSaving}
                    onClick={() => this.onSaveButtonClick()}
                />
            </div>
        );
    }

    private onGroupNameFieldUpdated(newValue: string | null) {
        this.setState({ name: newValue });
    }

    private onSaveButtonClick() {
        const { isSaving, onSave } = this.props;
        const { name } = this.state;

        if (!name) throw new Error("It should not be possible for the group name to be falsy at this point.");
        if (!isSaving) onSave(name);
    }
}
