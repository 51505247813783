import Validator from "Validation/Validator";

export default class FieldIsRequiredValidator<T> implements Validator<T> {
    constructor(readonly errorMessage = "This is a required field") { }

    isValid(value: T | "" | null | undefined) {
        if (value === null || value === undefined || value === "") return false;
        return true;
    }
}
