import * as React from "react";
import { FunctionComponent } from "react";
import ThermalCoefficientUnitsText from "./ThermalCoefficientUnitsText";
import { perDegreeCToPerDegreeF } from "../../Utilities/Conversion";

interface ThermalCoefficientTextProps {
    temperatureUnits: "celsius" | "fahrenheit";
    thermalExpansionCoefficientPerDegreeCelsius: number;
}

const ThermalCoefficientText: FunctionComponent<ThermalCoefficientTextProps> = ({ temperatureUnits, thermalExpansionCoefficientPerDegreeCelsius }) => {
    if (temperatureUnits === "celsius") {
        return <>{(thermalExpansionCoefficientPerDegreeCelsius * 10**6).toFixed(2)} <ThermalCoefficientUnitsText temperatureUnits="celsius" /></>;
    } else {
        const thermalExpansionCoefficientPerDegreeFahrenheit = perDegreeCToPerDegreeF(thermalExpansionCoefficientPerDegreeCelsius);
        return <>{(thermalExpansionCoefficientPerDegreeFahrenheit * 10 ** 6).toFixed(2)} <ThermalCoefficientUnitsText temperatureUnits="fahrenheit" /></>;
    }
}

export default ThermalCoefficientText;
