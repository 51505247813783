import * as React from "react";
import { Component } from "react";
import * as MainCGI from "Services/Electron/MainCGI";
import Button from "Components/Buttons/Button";
import View from "Types/View";
import { Alert, Divider } from "@mui/material";
import { ArrowBackIosNewRounded, DeviceHub, DirectionsWalk, SettingsRemote } from "@mui/icons-material";
import Select from "react-select";
import FirstIfNotEmpty from "../../Utilities/FirstIfNotEmpty";
import Services from "../../Services/Platform/Services";

interface WorkOfflineProps {
    rrcShortcut: boolean;
    rdcScanningShortcut: boolean;
    hdcScanningShortcut: boolean;
    spiderShortcut: boolean;
    onClose(): void;
    onRequestOfflineView(view: View): void;
}

interface WorkOfflineState {
    availableDatabases?: { fileName: string, displayName: string }[];
    selectedDatabase: { fileName: string, displayName: string } | null;
}

export default class WorkOffline extends Component<WorkOfflineProps, WorkOfflineState> {
    constructor(props: WorkOfflineProps) {
        super(props);

        this.state = {
            selectedDatabase: null
        };
    }

    async componentDidMount() {
        const availableDatabases = await MainCGI.listSavedDatabases();

        this.setState({
            availableDatabases,
            selectedDatabase: FirstIfNotEmpty(availableDatabases)
        });
    }

    mainUI() {
        const { rrcShortcut, rdcScanningShortcut, hdcScanningShortcut, spiderShortcut, onRequestOfflineView } = this.props;
        const { availableDatabases, selectedDatabase } = this.state;

        if (availableDatabases === undefined) return <p>Loading databases...</p>;
        if (availableDatabases.length === 0) return <Alert severity="error">Unable to work offline until you log in while online first</Alert>; // TODO: Good message?

        const getSelectOption = (option: { fileName: string, displayName: string }) => ({ label: option.displayName, value: option.fileName });

        return <>
            <div style={{ marginBottom: "10px" }}>
                <Select
                    options={availableDatabases?.map(getSelectOption)}
                    value={selectedDatabase !== null ? getSelectOption(selectedDatabase) : null}
                    onChange={a => {
                        this.setState({ selectedDatabase: FirstIfNotEmpty(availableDatabases?.filter(d => d.fileName === a?.value)) })
                    }}
                />
            </div>

            {selectedDatabase !== null ?
                <>
                    {rdcScanningShortcut ?
                        <Button
                            icon={<DeviceHub />}
                            variant="text"
                            label="RDC scanning"
                            onClick={() => {
                                Services.LocalDbName = selectedDatabase.fileName;
                                onRequestOfflineView("rdcScanning");
                            }}
                        />
                        : null
                    }
                    {hdcScanningShortcut ?
                        <div>
                            <Button
                                icon={<DeviceHub />}
                                variant="text"
                                label="HDC scanning"
                                onClick={() => {
                                    Services.LocalDbName = selectedDatabase.fileName;
                                    onRequestOfflineView("wandScanningOffline")
                                }}
                            />
                        </div>
                        : null
                    }
                    {rrcShortcut ?
                        <div>
                            <Button
                                icon={<SettingsRemote />}
                                variant="text"
                                label="Robotic Remote Control"
                                onClick={() => onRequestOfflineView("rrc")}
                            />
                        </div>
                        : null
                    }
                    {spiderShortcut ?
                        <div>
                            <Button
                                icon={<DirectionsWalk />}
                                variant="text"
                                label="Crawler"
                                onClick={() => onRequestOfflineView("spider")}
                            />
                        </div>
                        : null
                    }
                </>
                : null
            }
        </>;
    }

    render() {
        const { onClose } = this.props;

        return <>
            <Button
                icon={<ArrowBackIosNewRounded />}
                variant="text"
                label="Back"
                onClick={onClose}
            />

            <Divider style={{ marginBottom: 20, color: "#999" }}>Work Offline</Divider>

            {this.mainUI()}
        </>;
    }
}
