// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page{
    position: relative;
}
.login-logo{
    position: absolute;
    left: 1.25rem;
    width: 15.625rem;
    height: 5.0625rem;
    max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./Panels/Composite/Modals/loginFormModalPanel.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".login-page{\n    position: relative;\n}\n.login-logo{\n    position: absolute;\n    left: 1.25rem;\n    width: 15.625rem;\n    height: 5.0625rem;\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
