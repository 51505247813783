import * as React from "react";
import SplitPanel from "../SplitPanel";
import SensorGroup from "../../Model/SensorGroup";
import Services from "../../Services/Platform/Services";
import SensorSelectPanel from "./Primary/SensorSelectPanel";
import { Reading, Sensor } from "@inductosense/typescript-fetch/dist/models";
import ItemList from "../../Components/ItemList";
import { CircularProgress } from "@mui/material";
import GetAllSensorsFromGroup from "Utilities/GetAllSensorsFromGroup";

interface SelectComparisonSignalPanelProps {
    onSelect(readingId: string, sensor: Sensor): void;
    initialSensorId: string;
}

interface SelectComparisonSignalPanelState {
    rootSensorGroup?: SensorGroup;
    sensor?: Sensor;
    sensorReadings?: Reading[];
    isLoadingReadings: boolean;
    selectedId?: string;
}

export default class SelectComparisonSignalPanel extends React.Component<SelectComparisonSignalPanelProps, SelectComparisonSignalPanelState> {
    constructor(props: SelectComparisonSignalPanelProps) {
        super(props);

        this.state = {
            isLoadingReadings: false
        };
    }

    async componentDidMount() {
        const rootSensorGroup = await Services.SensorGroups.getRootSensorGroup();
        const allSensors = GetAllSensorsFromGroup(rootSensorGroup);
        const theSensor = [...allSensors.values()].find(s => s.id === this.props.initialSensorId);

        this.setState({
            rootSensorGroup
        });

        if (theSensor) {
            await this.onSensorSelect([theSensor.id], [theSensor]);
        }
    }

    render() {
        return <div style={{ width: 800, height: 400 }}>
            {this.content()}
        </div>;
    }

    content() {
        const { rootSensorGroup, sensor, isLoadingReadings, sensorReadings } = this.state;
        if (rootSensorGroup === undefined) return <p>Loading sensors...</p>;

        return (
            <SplitPanel
                firstContent={<SensorSelectPanel
                    rootSensorGroup={rootSensorGroup}
                    onSensorSelect={this.onSensorSelect.bind(this)}
                    selectedIds={sensor ? [sensor.id] : []}
                />}
                secondContent={
                    isLoadingReadings ?
                        <CircularProgress /> :
                            sensorReadings && sensor ?
                            <div style={{ height: 350, overflowY: "auto" }}>
                                <ItemList
                                    items={this.state.sensorReadings?.map(r => r.timestamp.toLocaleString()) || []}
                                    showEditIcons={false}
                                    showCheckboxes={false}
                                    onSelectionChanged={itemsChecked => {
                                        const selectedIndex = itemsChecked.map((checked, index) => checked ? index : -1).filter(i => i !== -1)[0];

                                        this.props.onSelect(sensorReadings[selectedIndex].id, sensor);
                                    }}
                                    itemsChecked={[]}
                                />
                            </div>
                            : null
                }
                splitRatio={0.42}
                plane="horizontal"
            />
        );
    }

    async onSensorSelect(_sensorIds: string[], sensors: Sensor[]) {
        // Todo: Check arrays aren't empty
        this.setState({ isLoadingReadings: true });
        const sensorReadings = await Services.SensorReadingsService.sensorsIdReadingsGet(sensors[0].id, undefined, undefined, 100);
        console.log("the sensor", sensors[0]);
        this.setState({ sensorReadings, sensor: sensors[0], isLoadingReadings: false });
    }
}
