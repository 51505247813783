import * as React from "react";
import { Component } from "react";
import TextFieldControlled from "../Components/Input/TextFieldControlled";
import Button from "../Components/Buttons/Button";
import ButtonsFormFooter from "../Forms/Shared/ButtonsFormFooter";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import ModalPanel from "../Panels/ModalPanel";
import Services from "../Services/Platform/Services";
import { ManifestType,/*, ServerOption*/ 
ServerOption} from "./manifest";
import Select from "react-select";

interface PostInstallConfigScreenProps {
    onClose(): void;
    hideServerUrl?: boolean;
    manifest?: ManifestType;
}

interface PostInstallConfigScreenState {
    customServerUrl: string;
    productionOverride: boolean;
    rrcShortcut: boolean;
    rdcScanningShortcut: boolean;
    spiderShortcut: boolean;
    channel?: "none" | "staging" | "production";
}

export default class PostInstallConfigScreen extends Component<PostInstallConfigScreenProps, PostInstallConfigScreenState> {
    constructor(props: PostInstallConfigScreenProps) {
        super(props);

        this.state = {
            customServerUrl: localStorage["customServerUrl"] || "",
            productionOverride: localStorage["productionOverride"] === "true",
            rrcShortcut: localStorage["rrcShortcut"] === "true",
            rdcScanningShortcut: localStorage["rdcScanningShortcut"] !== "false",
            spiderShortcut: localStorage["spiderShortcut"] === "true"
        };
    }

    async componentDidMount() {
        this.setState({ channel: await Services.getChannel() });
    }

    getDefaultServerOption() {
        return {
            "label": "Select a custom server",
            "value": ""
        };
    }

    getDropdownOption(input: ServerOption) {
        return {
            label: input.label,
            value: input.serverUrl
        }
    }

    getServerOptions() {
        return [
            this.getDefaultServerOption(),
            ...this.props.manifest?.serverOptions?.filter(o => o.channels.includes(Services.getChannel())).map(this.getDropdownOption) || []
        ];
    }

    // TODO: Check continuous testing!

    content() {
         return (
             <div style={{ padding: 5 }}>
                 <div style={{ marginBottom: 10 }}>
                     <Select
                         styles={{
                             control: (baseStyles, state) => ({
                                 ...baseStyles,
                                 borderColor: state.isFocused ? "#EC6F41" : "#555",
                                 borderRadius: "0.5rem",
                                 width: "500px"
                             }),
                             menu: (baseStyles) => ({
                                 ...baseStyles,
                                 zIndex: 50000
                             })
                         }}
                         maxMenuHeight={155}
                         className="basic-single"
                         classNamePrefix="select"
                         options={this.getServerOptions()}
                         value={this.getServerOptions().find(o => o.value === this.state.customServerUrl) || this.getDefaultServerOption()}
                         onChange={newOption => this.setState({ customServerUrl: newOption?.value || "" })}
                     />
                 </div>

                 {!this.props.hideServerUrl ?
                     <div style={{ marginBottom: 10 }}>
                        <TextFieldControlled
                            onChange={ev => this.setState({ customServerUrl: ev.target.value })}
                            label="Custom server URL"
                            value={this.state.customServerUrl}
                            sx={{ backgroundColor: "#fff", "& .MuiInputBase-fullWidth": { borderRadius: "0.5rem" } }}
                         />
                     </div>
                     : null
                 }
                 <FormControlLabel
                     label="Robotic Remote Control"
                     control={
                         <Checkbox
                             onChange={(_ev, checked) => this.setState({ rrcShortcut: checked })}
                             checked={this.state.rrcShortcut}
                         />
                     }
                 />
                 <FormControlLabel
                     label="RDC scanning"
                     control={
                         <Checkbox
                             onChange={(_ev, checked) => this.setState({ rdcScanningShortcut: checked })}
                             checked={this.state.rdcScanningShortcut}
                         />
                     }
                 />
                 <FormControlLabel
                     label="Crawler"
                     control={
                         <Checkbox
                             onChange={(_ev, checked) => this.setState({ spiderShortcut: checked })}
                             checked={this.state.spiderShortcut}
                         />
                     }
                 />
                
                <ButtonsFormFooter
                    buttons={[
                         <Button
                            key="OK"
                            onClick={this.onClick.bind(this)}
                            label="OK"
                        />
                    ]}
                    buttonsPosition="left"
                />
            </div>
        );
    }

    render() {
        return <ModalPanel
            shouldBeShown={true}
            content={this.content()}
            onClose={() => this.props.onClose()}
            floatingActionButton
            showBranding
        />;
    }

    async onClick() {
        const { rrcShortcut, rdcScanningShortcut, spiderShortcut, customServerUrl, productionOverride } = this.state;

        localStorage["rrcShortcut"] = rrcShortcut;
        localStorage["rdcScanningShortcut"] = rdcScanningShortcut;
        localStorage["spiderShortcut"] = spiderShortcut;
        localStorage["productionOverride"] = productionOverride;

        if (!this.props.hideServerUrl) {
            localStorage["customServerUrl"] = customServerUrl;
        }

        localStorage.removeItem("latestToken");

        this.props.onClose();
    }
}
