import { Configuration } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import { AuthenticationState } from "./AuthenticationStateMachine";

interface ApiServiceConstructor<T> {
    new (c: Configuration): T;
}

export default class InductosenseService<T> {
    protected serverApi: T;

    constructor(private apiServiceConstructor: ApiServiceConstructor<T>, protected authenticationService: AuthenticationService) {
        const configuration = this.authenticationService.getAuthenticatingConfiguration(true);
        this.serverApi = new this.apiServiceConstructor(configuration);
    }

    public async refreshToken() {
        await this.authenticationService.getAuthenticationTokenRefresh();
        const configuration = this.authenticationService.getAuthenticatingConfiguration(true);
        this.serverApi = new this.apiServiceConstructor(configuration);
    }

    public async ensureStateAuthenticated() {

        const state = await this.authenticationService.getCurrentAuthState();
        if (state !== AuthenticationState.Authenticated) {            
            this.authenticationService.handleUnauthorizedResponse();
        }               
        console.log("Ensured the service is authenticated!");
    }

    public async ensureAccessConfiguration() {
        const configuration = this.authenticationService.getAuthenticatingConfiguration(true);
        this.serverApi = new this.apiServiceConstructor(configuration);
        this.authenticationService.setConfiguration();

        console.log("Apply Access Configuration!");
    }

    public async reauthenticate() {
        try {
            this.authenticationService.handleUnauthorizedResponse();
            await this.authenticationService.waitForState(AuthenticationState.Authenticated, 10000);
            console.log("The service is authenticated!");
            // Now we're sure that the service is authenticated, so continue reset to use Access token in Authentication header

            const configuration = this.authenticationService.getAuthenticatingConfiguration(true);
            this.serverApi = new this.apiServiceConstructor(configuration);

        } catch (err) {
            console.error("Failed to authenticate", err);
        }            
    }
}
