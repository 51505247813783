import * as d3 from "d3";

const formatMinute = d3.timeFormat("%d/%m/%Y %I:%M"),
    formatDay = d3.timeFormat("%d/%m/%Y"),
    formatMonth = d3.timeFormat("%B %Y"),
    formatYear = d3.timeFormat("%Y");

export default (date: Date) => (d3.timeDay(date) < date ? formatMinute
                : d3.timeMonth(date) < date ? formatDay
                    : d3.timeYear(date) < date ? formatMonth
                        : formatYear)(date);
