import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import IconButton from "Components/Buttons/IconButton";
import { ArrowDownward } from "@mui/icons-material";

interface DownloadDataButtonProps {
    color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
    isDisabled?: boolean;
    onClick(event?: MouseEvent): void;
    className?: string;
}

const DownloadDataButton: FunctionComponent<DownloadDataButtonProps> = ({ color, isDisabled, onClick, className }) => (
    <IconButton
        icon={<ArrowDownward color={color} />}
        isDisabled={isDisabled}
        onClick={event => onClick(event)}
        className={className}
    />
);

export default DownloadDataButton;
