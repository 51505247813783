import * as MaterialUiIcons from "@mui/icons-material";
import * as React from "react";
import { FunctionComponent } from "react";

interface EditIconProps {
    fontSize?: "inherit" | "large" | "medium" | "small";
    color?:  "inherit" | "primary";
}

const EditIcon: FunctionComponent<EditIconProps> = ({ fontSize,color }) => <MaterialUiIcons.Edit fontSize={fontSize} color={color}/>;

export default EditIcon;
