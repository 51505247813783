import * as React from "react";
import { Component } from "react";
import TextFieldControlled from "Components/Input/TextFieldControlled";
import Button from "Components/Buttons/Button";

interface SystemDelayEntryFormProps {
    onSubmit(newValue: number): void;
    currentSystemDelay: number;
}

interface SystemDelayEntryFormState {
    systemDelay: number;
}

export default class SystemDelayEntryForm extends Component<SystemDelayEntryFormProps, SystemDelayEntryFormState> {
    constructor(props: SystemDelayEntryFormProps) {
        super(props);

        const { currentSystemDelay } = this.props;

        this.state = {
            systemDelay: currentSystemDelay
        };
    }

    render() {
        const { systemDelay } = this.state;

        return (
            <>
                <TextFieldControlled
                    type="number"
                    value={systemDelay}
                    onChange={ev => this.setState({ systemDelay: parseFloat(ev.target.value) })}
                    suffix="s"
                />
                <Button
                    label="Submit"
                    onClick={() => this.props.onSubmit(this.state.systemDelay)}
                />
            </>
        );
    }
}
