import { IoTDeploymentsApi, Iotdeploymentpost, PostDeploymentAttemptLogRequest } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class IoTDeploymentsService extends InductosenseService<IoTDeploymentsApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(IoTDeploymentsApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async iotDeploymentsIdGet(id: string) {
        await this.ensureLatestConfig();
        return this.serverApi.iotDeploymentsIdGet({ id });
    }

    @wrapWithErrorHandling()
    async iotDeploymentsGet(deviceId: string) {
        await this.ensureLatestConfig();
        return this.serverApi.iotDeploymentsGet({ deviceId });
    }

    @wrapWithErrorHandling()
    async deploymentAttemptLogs(deviceId?: string) {
        await this.ensureLatestConfig();
        return this.serverApi.deploymentAttemptLogs({ deviceId });
    }

    @wrapWithErrorHandling()
    async deploymentAttemptLogPost(request: PostDeploymentAttemptLogRequest) {
        await this.ensureLatestConfig();
        return this.serverApi.postDeploymentAttemptLog(request);
    }

    @wrapWithErrorHandling()
    async dataExtractionAttemptLogs(deviceId?: string) {
        await this.ensureLatestConfig();
        return this.serverApi.dataExtractionAttemptLogs({ deviceId });
    }

    // Temporary change: Increase timeout for this query due to RDCs taking too long to load
    @wrapWithErrorHandling(20 * 60 * 1000)
    async getDevicesIotDeployments(deviceIds?: string[]) {
        await this.ensureLatestConfig();
        console.log("getting dd");
        const dd = await this.serverApi.getDevicesIotDeployments({ deviceIds });
        console.log("dd", dd);
        return dd;
    }

    @wrapWithErrorHandling()
    async iotDeploymentsPost(iotdeploymentpost: Iotdeploymentpost) {
        await this.ensureLatestConfig();
        console.log("iotDeploymentsPost", iotdeploymentpost);
        return this.serverApi.iotDeploymentsPost({ iotdeploymentpost });
    }

    @wrapWithErrorHandling()
    async getWirelessSettingsPresets() {
        await this.ensureLatestConfig();
        return this.serverApi.getWirelessSettingsPresets();
    }

    @wrapWithErrorHandling()
    async getRdcFirmwareVersions() {
        await this.ensureLatestConfig();
        return this.serverApi.getRdcFirmwareVersions();
    }
}
