import * as React from "react";
import { CSSProperties, FunctionComponent, useState, useEffect, useRef } from "react";
import { Dimensions } from "Types/Sizing";
import { inductosenseBoldOrange, inductosenseDeepTeal, inductosenseDuckEggTeal, inductosenseSmokeGrey } from "Styling/Palette/BrandColours";
import { MarginComponents } from "Types/Styling";
import { NumberOfPresamplesBeforeTimeZero } from "Constants/DateAndTime";
import { Readingdetailed, Sensor } from "@inductosense/typescript-fetch";
import { FormControlLabel, Checkbox, TableCell, Link } from "@mui/material";
import { Table, TableBody, TableRow } from "@mui/material";
import { GetReadingSamplesPerMicrosecond, GetReadingClips, GetFirstPeakAmplitude } from "../../Utilities/ReadingUtils";
import IconButton from "../Buttons/IconButton";
import CloseIcon from "../Graphics/Icons/CloseIcon";
import * as D3 from "d3";
import GraphAxis from "./GraphAxis";
import YEqualsZeroLine from "./YEqualsZeroLine";
import TimeBoundThresholdLine from "./TimeBoundThresholdLine";
import ReadingLines from "./Line/ReadingLines";
import ToggleButton from "@mui/material/ToggleButton";
import * as Unicode from "Constants/UnicodeCharacters";
import EditIcon from "../Graphics/Icons/EditIcon";
import { PanTool, PhotoSizeSelectSmall, Save, ZoomOutMap } from "@mui/icons-material";
import html2canvas from "html2canvas";
import { D3ZoomEvent, ZoomTransform, D3BrushEvent } from "d3";
import { isNotNull, isNotNumberArr } from "./D3SupportFunctions";
import DisplacementText from "../Text/DisplacementText";
import UiSettings from "../../Model/UiSettings";
import { thicknessAlgorithmTooltip } from "../../Utilities/EnumDescriptionUtil";
import CasesIcon from "@mui/icons-material/Cases";

const yAxisSpaceAboveAmplitudeThresholdMultiplier = 7.5;

const containerStyle: CSSProperties = {
    height: "100%",
    width: "100%"
};

const yZeroLineStyle: CSSProperties = {
    strokeWidth: 1,
    stroke: inductosenseSmokeGrey,
    strokeDasharray: "5, 5"
};

const pathColour = inductosenseDuckEggTeal;
const comparisonPathColour = inductosenseBoldOrange;

const envelopeStyle: CSSProperties = {
    fill: "none",
    strokeWidth: 3,
    stroke: inductosenseDeepTeal
};

const thresholdStyle: CSSProperties = {
    strokeWidth: 2,
    stroke: "black"
};

const graphMargins: MarginComponents = {
    top: 5,
    right: 0,
    bottom: 45,
    left: 70
};

interface Window {
    threshold: number;
    startTime: number;
    endTime: number;
}

interface AscanLineGraphProps {
    initialSize: Dimensions;
    readingDetailed: Readingdetailed;
    sensor: Sensor | null;
    samples: number[] | null;
    comparisonReading: Readingdetailed | null;
    comparisonSensor: Sensor | null;
    onCloseComparison?: () => void;
    onAddComparisonClick?: () => void;
    amplitudeThreshold: number | null;
    startTime: number | null;
    endTime: number | null;
    onNewWindowChosen?(window: { threshold: number; startTime: number; endTime: number }): void;
    showToggles?: boolean;
    showModeButtons?: boolean;
    uiSettings: UiSettings;
    children?: React.ReactNode;
    compact?: boolean;
}

const AscanLineGraph: FunctionComponent<AscanLineGraphProps> = props => {
    const { amplitudeThreshold, endTime, comparisonReading, comparisonSensor, startTime, readingDetailed,
        showToggles, onNewWindowChosen, onCloseComparison, onAddComparisonClick, initialSize, sensor,
        showModeButtons = true, compact } = props;

    // Keep track of reset count so event handler can be re-added when zoom is reset
    const [selectedTool, setSelectedTool] = useState<"drawBox" | "pan" | "window">("pan");

    const [transform, setTransform] = useState<ZoomTransform | null>(null);
    const effectiveTransform = transform != null ? transform : D3.zoomIdentity;
    const zoom = D3.zoom<SVGSVGElement, unknown>().on("zoom", (ev: D3ZoomEvent<SVGSVGElement, unknown>) => setTransform(ev.transform));
    
    const containerWidth = initialSize.width;
    const containerHeight = initialSize.height;

    const horizontalMargins = graphMargins.left + graphMargins.right;
    const verticalMargins = graphMargins.top + graphMargins.bottom;

    const width = containerWidth - horizontalMargins;
    const height = containerHeight - verticalMargins;

    const [showEnvelope, setShowEnvelope] = useState(true);
    const [showRF, setShowRF] = useState(true);
    const [showComparisonEnvelope, setShowComparisonEnvelope] = useState(true);
    const [showComparisonRF, setShowComparisonRF] = useState(true);
    const [normalise, setNormalise] = useState(false);

    const microsecondsBeforeTimeZero = NumberOfPresamplesBeforeTimeZero / GetReadingSamplesPerMicrosecond(readingDetailed);

    const yAxisClippingAmplitude = amplitudeThreshold !== null
        ? amplitudeThreshold * yAxisSpaceAboveAmplitudeThresholdMultiplier
        : undefined;

    const xAxisClippingTime = endTime !== null
        ? startTime !== null
            ? startTime + endTime + microsecondsBeforeTimeZero
            : (endTime * 2) + microsecondsBeforeTimeZero
        : undefined;

    const thresholdValues = amplitudeThreshold !== null && startTime !== null && endTime !== null ? {
        threshold: amplitudeThreshold,
        startTime: startTime,
        endTime: endTime
    } : undefined;

    const styles = {
        yZeroLine: yZeroLineStyle,
        envelope: envelopeStyle,
        threshold: thresholdStyle
    };

    const xAxisLabel = `Time (${Unicode.Mu}s)`;
    const yAxisLabel = "Amplitude (mV)";

    const [previewWindow, setPreviewWindow] = useState<Window | null>(null);

    const [selectedRect, setSelectedRect] = useState<{ x1: number; x2: number; y1: number; y2: number } | null>(null);

    const { xMinimum, yMinimum, xMaximum, yMaximum } = GetReadingClips(readingDetailed, xAxisClippingTime, yAxisClippingAmplitude);
    const effectiveRect = selectedRect || {
        x1: xMinimum,
        x2: xMaximum,
        y1: yMaximum,
        y2: yMinimum
    };

    const xScalePre = D3
        .scaleLinear()
        .domain([effectiveRect.x1, effectiveRect.x2])
        .range([0, width])
        .clamp(false);

    const xScale = effectiveTransform.rescaleX(xScalePre);

    const yScalePre = D3
        .scaleLinear()
        .domain([effectiveRect.y2, effectiveRect?.y1])
        .range([height, 0]);

    const yScale = effectiveTransform.rescaleY(yScalePre);

    const svgContainerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const gRef = useRef<SVGGElement>(null);

    const brush = D3.brush()
        .on("end", (ev: D3BrushEvent<unknown>) => {
            const selection = ev.selection;
            if (isNotNull(selection) && isNotNumberArr(selection)) {

                setSelectedRect({
                    x1: xScale.invert(selection[0][0] - graphMargins.left),
                    x2: xScale.invert(selection[1][0] - graphMargins.left),
                    y1: yScale.invert(selection[0][1] - graphMargins.top),
                    y2: yScale.invert(selection[1][1] - graphMargins.top)
                });
                setTransform(null);

                if (gRef.current) {
                    D3.select(gRef.current).call(brush.move, null);
                } else {
                    console.error("g element ref not available", gRef.current);
                }
            }
        });

    const windowBrush = D3.brush()
        .on("brush", (ev: D3BrushEvent<unknown>) => {
            const selection = ev.selection;
            if (isNotNull(selection) && isNotNumberArr(selection)) {
                const rect = ev.sourceEvent.target.getBoundingClientRect();

                const y = ev.sourceEvent.offsetY || (ev.sourceEvent.changedTouches[0].clientY - rect.top); // TODO: changedTouches length

                setPreviewWindow({
                    startTime: xScale.invert(selection[0][0] - graphMargins.left),
                    endTime: xScale.invert(selection[1][0] - graphMargins.left),
                    threshold: Math.max(yScale.invert(y - graphMargins.top), 0.1)
                });
            }
        })
        .on("end", (ev: D3BrushEvent<unknown>) => {
            const selection = ev.selection;
            if (isNotNull(selection) && isNotNumberArr(selection)) {
                const rect = ev.sourceEvent.target.getBoundingClientRect();

                const y = ev.sourceEvent.offsetY || (ev.sourceEvent.changedTouches[0].clientY - rect.top); // TODO: changedTouches length

                if (onNewWindowChosen) {
                    onNewWindowChosen({
                        startTime: xScale.invert(selection[0][0] - graphMargins.left),
                        endTime: xScale.invert(selection[1][0] - graphMargins.left),
                        threshold: Math.max(yScale.invert(y - graphMargins.top), 0.1)
                    });
                }
            }

            if (gRef.current) {
                D3.select(gRef.current).call(brush.move, null);
            } // TODO error?
        });

    useEffect(() => {
        if (gRef.current && svgRef.current) {
            if (selectedTool === "pan") {
                D3.select(svgRef.current).call(zoom);
                return () => {
                    D3.select(svgRef.current).on(".zoom", null);
                };
            }

            return () => null;
        } else {
            console.error("svgRef.current/gRef.current is not defined");
            return () => null;
        }
    }, [selectedTool, selectedRect, svgRef.current !== null, gRef.current !== null]);

    useEffect(() => {
        if (gRef.current && svgRef.current) {
            if (selectedTool === "drawBox") {
                D3.select(gRef.current).call(brush);
                return () => {
                    D3.select(gRef.current).on(".brush", null);
                    D3.select(".overlay").remove();
                };
            }
            else if (selectedTool === "window") {
                D3.select(gRef.current).call(windowBrush);
                return () => {
                    D3.select(gRef.current).on(".brush", null);
                    D3.select(".overlay").remove();
                };
            }

            return () => null;
        } else {
            console.error("svgRef.current/gRef.current is not defined");
            return () => null;
        }
    }, [selectedTool, selectedRect, svgRef.current !== null, gRef.current !== null, xScale.domain()[0], xScale.domain()[1],
        yScale.domain()[0], yScale.domain()[1]]);

    const graphTranslation = `translate(${graphMargins.left}, ${graphMargins.top})`;

    const xAxisGenerator = D3.axisBottom(xScale);
    const yAxisGenerator = D3.axisLeft(yScale);

    useEffect(() => {
        setSelectedRect(effectiveRect); // TODO: Or set it to null?
        setPreviewWindow(null);
    }, [normalise]);

    // TODO: Fix null cases
    const normAdjust = comparisonReading
        ? (GetFirstPeakAmplitude(readingDetailed) || 1) / (GetFirstPeakAmplitude(comparisonReading) || 1)
        : 1;

    return (
        <div style={containerStyle}>
            {showToggles ?
                <Table size="small" style={{ width: 850, marginBottom: 15 }}>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <span style={{ color: pathColour }}>
                                    {sensor?.description || sensor?.rfid}<br />
                                    {readingDetailed?.timestamp.toLocaleString()}
                                </span>
                            </TableCell>
                            <TableCell style={{ color: pathColour }}>
                                <DisplacementText
                                    displacementInMetres={readingDetailed.analysis?.thickness}
                                    unitsMode={props.uiSettings.unitsMode}
                                    tooltip={readingDetailed.analysis?.workings || ""}
                                />
                            </TableCell>
                            <TableCell>
                            </TableCell>
                            <TableCell>
                                <FormControlLabel
                                    label="RF"
                                    control={<Checkbox
                                        size="small"
                                        checked={showRF}
                                        onChange={(_ev, newChecked) => setShowRF(newChecked)}
                                    />}
                                />
                            </TableCell>
                            <TableCell>
                                <FormControlLabel
                                    label="Envelope"
                                    control={<Checkbox
                                        size="small"
                                        checked={showEnvelope}
                                        onChange={(_ev, newChecked) => setShowEnvelope(newChecked)}
                                    />}
                                />
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                        {(comparisonReading && comparisonSensor) ?
                            <TableRow>
                                <TableCell>
                                    <span style={{ color: comparisonPathColour }}>
                                        {comparisonSensor.description || comparisonSensor.rfid}<br />
                                        {comparisonReading.timestamp.toLocaleString()}
                                    </span>
                                </TableCell>
                                <TableCell style={{ color: comparisonPathColour }}>
                                    <DisplacementText
                                        displacementInMetres={comparisonReading.analysis?.thickness}
                                        unitsMode={props.uiSettings.unitsMode}
                                        tooltip={thicknessAlgorithmTooltip(comparisonReading.parameters?.thicknessAlgorithm) /* TODO: Will params always match */}
                                    />
                                </TableCell>
                                <TableCell>
                                    {onAddComparisonClick
                                        ? <IconButton icon={<EditIcon />} onClick={() => onAddComparisonClick()} size="small" />
                                        : null
                                    }&nbsp;
                                    {onCloseComparison
                                        ? <IconButton icon={<CloseIcon />} onClick={() => onCloseComparison()} size="small" />
                                        : null
                                    }
                                </TableCell>
                                <TableCell>
                                    <FormControlLabel
                                        label="RF"
                                        control={<Checkbox
                                            size="small"
                                            checked={showComparisonRF}
                                            onChange={(_ev, newChecked) => setShowComparisonRF(newChecked)}
                                        />}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControlLabel
                                        label="Envelope"
                                        control={<Checkbox
                                            size="small"
                                            checked={showComparisonEnvelope}
                                            onChange={(_ev, newChecked) => setShowComparisonEnvelope(newChecked)}
                                        />}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControlLabel
                                        label={`Normalise (${normAdjust.toFixed(2)}x)`}
                                        control={<Checkbox
                                            size="small"
                                            checked={normalise}
                                            onChange={(_ev, newChecked) => setNormalise(newChecked)}
                                        />}
                                    />
                                </TableCell>
                            </TableRow> :
                            <TableRow>
                                <TableCell>
                                    {
                                        onAddComparisonClick ?
                                            <Link
                                                href="#"
                                                style={{ color: comparisonPathColour }}
                                                onClick={() => onAddComparisonClick()}
                                            >
                                                Add comparison reading...
                                            </Link>
                                        : null
                                    }
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
                : null
            }
            <div ref={svgContainerRef} onWheel={ev => {
                const scaleFactor = ev.deltaY < 0 ? 1.1 : 1 / 1.1;

                const rect = (ev.target as HTMLElement).getBoundingClientRect();

                const offsetX = ev.clientX - rect.left - graphMargins.left;
                const offsetY = ev.clientY - rect.top - graphMargins.top;

                setTransform(effectiveTransform.translate(offsetX, offsetY).scale(scaleFactor).translate(-offsetX, -offsetY));
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width={containerWidth} height={containerHeight} ref={svgRef}>
                    <g width={width} height={height} transform={graphTranslation}>
                            <ReadingLines
                                timeBoundThresholdValues={previewWindow || thresholdValues}
                                reading={readingDetailed}
                                xScale={xScale}
                                yScale={yScale}
                                colour={pathColour}
                                showRF={showRF}
                                showEnvelope={showEnvelope}
                            />
                            {comparisonReading ?
                                <ReadingLines
                                    reading={comparisonReading}
                                    xScale={xScale}
                                    yScale={yScale}
                                    colour={comparisonPathColour}
                                    showRF={showComparisonRF}
                                    showEnvelope={showComparisonEnvelope}
                                    normalisationFactor={normalise ? normAdjust : 1}
                                />
                                : null
                            }
                            {
                                (thresholdValues || previewWindow) && (
                                    <TimeBoundThresholdLine
                                        threshold={previewWindow?.threshold ? previewWindow.threshold : thresholdValues!.threshold}
                                        startTime={previewWindow?.startTime || thresholdValues!.startTime}
                                        endTime={previewWindow?.endTime || thresholdValues!.endTime}
                                        xMinimum={xMinimum}
                                        xMaximum={xMaximum}
                                        yMinimum={yMinimum}
                                        yMaximum={yMaximum}
                                        xScale={xScale}
                                        yScale={yScale}
                                        style={styles.threshold}
                                    />
                                )
                            }

                            <rect
                                x={-graphMargins.left}
                                y={-graphMargins.top}
                                width={props.initialSize.width + graphMargins.left + graphMargins.right}
                                height={graphMargins.top}
                                fill="white"
                            />
                            <rect
                                x={-graphMargins.left}
                                y={0}
                                width={graphMargins.left}
                                height={props.initialSize.height}
                                fill="white"
                            />
                            <rect
                                x={-graphMargins.left}
                                y={props.initialSize.height - graphMargins.bottom - graphMargins.top}
                                width={props.initialSize.width + graphMargins.left + graphMargins.right}
                                height={graphMargins.bottom}
                                fill="white"
                            />
                            <rect
                                x={props.initialSize.width - graphMargins.right - graphMargins.left}
                                y={0}
                                width={graphMargins.right}
                                height={props.initialSize.height}
                                fill="white"
                            />

                            <GraphAxis axis="x" axisGenerator={xAxisGenerator.ticks(compact ? 3 : 10)} containerSize={props.initialSize} graphMargin={graphMargins} label={xAxisLabel} gapBetweenXAxisAndLabel={35} />
                            <GraphAxis axis="y" axisGenerator={yAxisGenerator.ticks(compact ? 3 : 10)} containerSize={props.initialSize} graphMargin={graphMargins} label={yAxisLabel} />
                            {
                                yMinimum < 0
                                    ? <YEqualsZeroLine xMaximum={xMaximum} xScale={xScale} yScale={yScale} style={styles.yZeroLine} />
                                    : null
                            }
                    </g>
                    <g ref={gRef} className="brush" style={{ visibility: selectedTool === "window" ? "hidden" : "visible" }}></g>
                </svg>
            </div>
            {showModeButtons ?
                <div>
                    <IconButton
                        icon={<ZoomOutMap />}
                        title="Reset Zoom"
                        onClick={() => {
                            if (svgRef.current) {
                                D3.select(svgRef.current).call(zoom.transform, D3.zoomIdentity);
                            }
                            setSelectedRect(null);
                        }}
                    />
                    <ToggleButton
                        key="drawbox"
                        value="x"
                        selected={selectedTool === "drawBox"}
                        onChange={() => setSelectedTool("drawBox")}
                        title="Draw box to zoom"
                    >
                        <PhotoSizeSelectSmall />
                    </ToggleButton>
                    <ToggleButton
                        key="toggle"
                        value="x"
                        selected={selectedTool === "window"}
                        onChange={() => setSelectedTool("window")}
                        title="Peak Windowing"
                    >
                        <CasesIcon />
                    </ToggleButton>
                    <ToggleButton
                        key="pan-tool"
                        value="x"
                        selected={selectedTool === "pan"}
                        onChange={() => setSelectedTool("pan")}
                        title="Pan"
                    >
                        <PanTool />
                    </ToggleButton>
                    <IconButton
                        key="save"
                        onClick={() => {
                            if (svgContainerRef.current) {
                                html2canvas(svgContainerRef.current).then(canvas => {
                                    const dataUrl = canvas.toDataURL("image/png");
                                    const link = document.createElement("a");

                                    if (typeof (link.download) === "string") {
                                        link.href = dataUrl;
                                        link.download = "graph";

                                        document.body.appendChild(link);

                                        link.click();

                                        document.body.removeChild(link);
                                    } else {
                                        window.open(dataUrl);
                                    }
                                });
                            }
                        }}
                        icon={<Save />}
                    />
                    {props.children}
                </div>
                : null
            }
        </div>
    );
}

export default AscanLineGraph;
