import * as D3 from "d3";
import * as React from "react";
import { CartesianCoordinate, PointsInCartesianSpace } from "Types/CartesianSpace";
import { CSSProperties, FunctionComponent } from "react";
import { LinearOrTimeScale } from "Types/D3Extensions";
import { ScaleLinear } from "d3";

interface PathLineProps {
    points: PointsInCartesianSpace;
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    style?: CSSProperties;
}

const PathLine: FunctionComponent<PathLineProps> = ({ points, style, xScale, yScale }) => {
    const line = D3
        .line<CartesianCoordinate>()
        .x(point => xScale(point.x))
        .y(point => yScale(point.y))

    const pathData = line(points) as string;

    return (
        <g>
            <path d={pathData} style={style} />
        </g>
    );
}

export default PathLine;
