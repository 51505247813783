import * as MaterialUi from "@mui/material";
import * as React from "react";
import { Component, CSSProperties, MouseEvent, TouchEvent, ReactNode } from "react";
import HorizontalLoadingSpinner from "Components/Animations/HorizontalLoadingSpinner";
import merge from "Utilities/Merge";
import Precedence from "Types/Precedence";
import StartOrEnd from "Types/StartOrEnd";

const containerStyle: CSSProperties = {
    display: "inline-block"
};

const spinnerStyle: CSSProperties = {
    transform: "scale(0.5)"
};

interface ButtonProps {
    gateway?: boolean;
    isLoginSetting?: boolean | null;
    isLogin?: boolean | null;
    label: string;
    width?: number;
    precedence?: Precedence;
    icon?: ReactNode;
    iconPosition?: StartOrEnd;
    isDisabled?: boolean;
    isProhibited?: boolean;
    isSpinning?: boolean;
    shouldPreventFurtherClicksWhileSpinning?: boolean;
    onClick(event: MouseEvent<HTMLButtonElement>): void;
    variant?: "text" | "outlined" | "contained";
    onMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
    onMouseUp?: (event: MouseEvent<HTMLButtonElement>) => void;
    onMouseLeave?: (event: MouseEvent<HTMLButtonElement>) => void;
    onTouchStart?: (event: TouchEvent<HTMLButtonElement>) => void;
    onTouchEnd?: (event: TouchEvent<HTMLButtonElement>) => void;
    type?: "button" | "reset" | "submit";
    className?: string;
    children?: React.ReactNode;
    sx?: MaterialUi.SxProps;
}

const defaultProps: Partial<ButtonProps> = {
    precedence: "secondary",
    iconPosition: "start",
    shouldPreventFurtherClicksWhileSpinning: true
};

export default class Button extends Component<ButtonProps> {
    render() {
        const { icon, iconPosition, isDisabled, isSpinning, precedence,
            width, variant, onMouseDown, onMouseUp, onMouseLeave, onTouchStart, onTouchEnd, isProhibited, type, className, sx} = this.props;

        let startIcon: ReactNode = null;
        let endIcon: ReactNode = null;

        if (icon && !isSpinning) {
            if ((iconPosition ?? defaultProps.iconPosition) === "start") startIcon = icon;
            else endIcon = icon;
        }

        const height=this.props.isLogin? 32: this.props.gateway ? 28 : 36;

        const style = width
            ? merge(containerStyle, { width, height })
            : merge(containerStyle, { height });


        const materialUiButtonProps: MaterialUi.ButtonProps = {
            children: this.content(),
            variant: variant || "contained",
            color: precedence || "secondary",
            disabled: isDisabled || isProhibited,
            onClick: event => this.onClick(event),
            fullWidth: width !== undefined,
            startIcon,
            endIcon,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            onTouchStart,
            onTouchEnd,
            type,
            className
        };

        const loginStyle = {
            color: "#fff",
            height: "2rem",
            width: "15.625rem",
            borderRadius: "1rem",
            ...sx
        }

        const settingStyle = {
            fontSize: "0.75rem",
            height: "2rem",
            ...sx
        }

        const button =  this.props.gateway? <MaterialUi.Button 
                style={{ textTransform: "none"}}
                {...materialUiButtonProps}  
                sx={{"& .Mui-disabled":{border:"unset"}, color:"#ec6f41",width:"160px",borderRadius:"6px",height:"28px",lineHeight:"28px",backgroundColor: "#f9ede7",border:"1px solid #ec6f41"}}
            />
            :
            <MaterialUi.Button {...materialUiButtonProps} sx={this.props.isLogin ? loginStyle : this.props.isLoginSetting ? settingStyle : null}/>

        return (
            <div
                className="button"
                style={style}
                title={isProhibited ? "This action is prohibited" : undefined}
            >
                {button}
            </div>
        );
    }

    private content() {
        if (this.props.isSpinning) return this.spinner();
        return this.props.label;
    }

    private spinner() {
        return (
            <div style={spinnerStyle}>
                <HorizontalLoadingSpinner />
            </div>
        );
    }

    private onClick(event: MouseEvent<HTMLButtonElement>) {
        const { isSpinning, onClick, shouldPreventFurtherClicksWhileSpinning } = this.props;
        if (isSpinning && (shouldPreventFurtherClicksWhileSpinning ?? defaultProps.shouldPreventFurtherClicksWhileSpinning)) return;
        onClick(event);
    }
}
