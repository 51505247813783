import * as React from "react";
import { FunctionComponent } from "react";
import { getReleaseNotes } from "Services/ReleaseNotesService";
import { Link } from "@mui/material";
import ModalPanel from "Panels/ModalPanel";


const ReleaseNotes: FunctionComponent = () => {
    const releaseNotes = getReleaseNotes();

    const [showModal, setShowModal] = React.useState(false);

    return (
        <>
            <p style={{ textAlign: "center" }}>
                <Link onClick={() => setShowModal(true)} style={{ cursor: "pointer",color: "EC6F41" }}>Release Notes</Link>
            </p>

            <ModalPanel
                shouldBeShown={showModal}
                onClose={() => setShowModal(false)}
                title="Release notes"
                content={
                    <div>
                        {releaseNotes
                            .sort((a, b) => b.version.localeCompare(a.version))
                            .map((release) => (
                                <div key={release.version}>
                                    <strong>{release.version}</strong>
                                    <ul>
                                        {release.notes.map((notes, index) => (
                                            <li key={index}>{notes}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                    </div>
                }
            />
        </>
    );
};


export default ReleaseNotes;
