// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --padding-between-panels: 20px;
    --padding-inside-panels: 12px;
    --padding-inside-elevated-panels: 17px;
}
`, "",{"version":3,"sources":["webpack://./Styling/Spacing/Padding.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,6BAA6B;IAC7B,sCAAsC;AAC1C","sourcesContent":[":root {\n    --padding-between-panels: 20px;\n    --padding-inside-panels: 12px;\n    --padding-inside-elevated-panels: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
