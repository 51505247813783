import * as React from "react";
import { Component } from "react";
import { Device, Dataextractionattemptlog } from "@inductosense/typescript-fetch";
import ScatterPlotGraph from "../Components/Graphs/Scatter/ScatterPlotGraph";
import Services from "Services/Platform/Services";
import { DateCoordinate } from "../Types/DateCoordinate";

interface BatteryPanelProps {
    device: Device;
}

interface BatteryPanelState {
    highlightedPoint?: DateCoordinate;
    logs?: Dataextractionattemptlog[];
}

export default class BatteryPanel extends Component<BatteryPanelProps, BatteryPanelState> {
    constructor(props: BatteryPanelProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        const { device } = this.props;

        const dataLogs = await Services.IoTDeployments.dataExtractionAttemptLogs(device.id);
        this.setState({ logs: dataLogs });
    }

    render() {
        const { highlightedPoint, logs } = this.state;

        if (logs === undefined) return <p>Loading...</p>;
        
        const points = logs?.map(l => {
                return { x: l.dateTime, y: l.batteryPercentage || -1 }
            })
            .filter(p => p.y !== -1);

        if (points.length === 0) return <p>No battery history available</p>;

        return (<>
            <div style={{ width: 800, height: 440 }}>
                <ScatterPlotGraph
                    plots={[{ label: "Battery", points, highlightedPoint: highlightedPoint }]}
                    pointsRadius={5}
                    startXAxisScaleFrom="minimumValue"
                    startYAxisScaleFrom="zero"
                    xAxisLabel="Date"
                    yAxisLabel="Battery (%)"
                    styles={{}}
                    onHoveredPointChange={point => this.setState({ highlightedPoint: point || undefined })}
                    showLocalTrendButton={false}
                />
            </div>
            {
                highlightedPoint !== undefined ?
                <p>{highlightedPoint.x.toString()}: {highlightedPoint.y.toString()}%</p>
                : <p>&nbsp;</p>
            }
        </>);
    }
}
