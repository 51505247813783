import { Material } from "@inductosense/typescript-fetch";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import * as React from "react";
import { FunctionComponent } from "react";
import Button from "../Components/Buttons/Button";
import DropDownListControlled from "../Components/Input/DropDownListControlled";
import TextFieldControlled from "../Components/Input/TextFieldControlled";
import VelocityText from "../Components/Text/VelocityText";
import ModalPanel from "../Panels/ModalPanel";
import Services from "../Services/Platform/Services";

interface CalibrateDCModalPanelProps {
    shouldBeShown: boolean;
    onCancel(): void;
    onSave(): void;
    unitsMode: "metric" | "imperial";
}

export type WaveModes = "longitudinal" | "shear";

const CalibrateDCModalPanel: FunctionComponent<CalibrateDCModalPanelProps> = ({ shouldBeShown, onCancel, unitsMode }) => {
    const [adjustedThicknessMm, setAdjustedThicknessMm] = React.useState(9.9);
    const [unadjustedThicknessMm, setUnadjustedThicknessMm] = React.useState(8.8);
    const [materials, setMaterials] = React.useState<Material[] | null>(null);
    const [selectedMaterial, setSelectedMaterial] = React.useState<Material | undefined>(undefined);
    const [waveMode, setWaveMode] = React.useState<WaveModes>("longitudinal");

    React.useEffect(() => {
        Services.Materials.getAllMaterials().then(newMaterials => {
            setMaterials(newMaterials);
        });
    }, []);

    const getMaterialVelocity = (material: Material) => {
        if (waveMode === "longitudinal") {
            return material.longitudinalVelocityAtRoomTemperatureInMetresPerSecond;
        } else {
            return material.shearVelocityAtRoomTemperatureInMetresPerSecond;
        }
    }

    if (materials === null) return <p>Loading...</p>;

    // TODO: Use VelocityText!!

    /* ({ id, name, longitudinalVelocityAtRoomTemperatureInMetresPerSecond, shearVelocityAtRoomTemperatureInMetresPerSecond }) => {
                                    const displayName = name ?? id;

                                    const velocityInMetresPerSecond = this.state.typeFieldValue.ultrasonicWaveMode === Wavemode.Longitudinal
                                        ? longitudinalVelocityAtRoomTemperatureInMetresPerSecond
                                        : shearVelocityAtRoomTemperatureInMetresPerSecond;

                                    return <>{displayName} (<VelocityText
                                        velocityInMetresPerSecond={velocityInMetresPerSecond}
                                        unitsMode={this.props.uiSettings.unitsMode}
                                    />)</>;
                                }*/

    return (
        <ModalPanel
            shouldBeShown={shouldBeShown}
            title="Calibrate HDC"
            content={<div style={{ width: 480 }}>
                <p>Units: {unitsMode}</p>
                <div>
                    <FormControlLabel
                        label={<div style={{ width: 200 }}>Material</div>}
                        labelPlacement="start"
                        control={
                            <DropDownListControlled
                                selectedOption={selectedMaterial}
                                options={materials}
                                labelFor={m => <span>
                                    {m.name} (<VelocityText
                                        velocityInMetresPerSecond={getMaterialVelocity(m)}
                                        unitsMode={unitsMode}
                                    />)
                                </span>}
                                onChange={newMaterial => setSelectedMaterial(newMaterial)}
                            />
                        }
                    />
                </div>
                <div>
                    <FormControlLabel
                        label={<div style={{ width: 200 }}>Type</div>}
                        labelPlacement="start"
                        control={
                            <RadioGroup row value={waveMode} onChange={(_ev, newWaveMode) => {
                                newWaveMode === "longitudinal" ? setWaveMode("longitudinal") : setWaveMode("shear")
                            }}>
                                <FormControlLabel value="longitudinal" control={<Radio />} label="Longitudinal" />
                                <FormControlLabel value="shear" control={<Radio />} label="Shear" />
                            </RadioGroup>
                        }
                    />
                </div>
                <div>
                    <FormControlLabel
                        label={<div style={{ width: 200 }}>Actual thickness</div>}
                        labelPlacement="start"
                        control={
                            <TextFieldControlled
                                type="number"
                                suffix="mm"
                                width={150}
                                value={adjustedThicknessMm}
                                onChange={t => setAdjustedThicknessMm(parseFloat(t.target.value))}
                            />
                        }
                    />
                </div>
                <div>
                    <FormControlLabel
                        label={<div style={{ width: 200 }}>Measured thickness</div>}
                        labelPlacement="start"
                        control={
                            <TextFieldControlled
                                type="number"
                                suffix="mm"
                                width={150}
                                value={unadjustedThicknessMm}
                                onChange={t => setUnadjustedThicknessMm(parseFloat(t.target.value))}
                            />
                        }
                    />
                </div>
                <p>
                    System delay: {selectedMaterial !== undefined ?
                        `${(adjustedThicknessMm - unadjustedThicknessMm) * 0.001 / getMaterialVelocity(selectedMaterial)}s`
                        : null
                    }
                </p>
                <div>
                    <Button
                        label="Set system delay"
                        precedence="primary"
                        onClick={() => null}
                    />
                </div>
            </div>}
            onClose={onCancel}
        />
    );
};

export default CalibrateDCModalPanel;
