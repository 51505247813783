import * as React from "react";
import { useEffect, FunctionComponent, useRef } from "react";
import * as d3 from "d3";
import { LinearOrTimeScale } from "../../../Types/D3Extensions";
import { ScaleLinear } from "d3";
import { DateCoordinate } from "Types/DateCoordinate";

interface ErrorPointProps {
    x: DateCoordinate;
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    onPointMouseEnter: (point: DateCoordinate) => void;
    onPointMouseLeave: () => void;
    onPointDoubleClicked?(): void;
    onPointRightClicked?(): void;
    colour: string;
}

const ErrorPoint: FunctionComponent<ErrorPointProps> = (props: ErrorPointProps) => {
    const cx = props.xScale(props.x.x);

    // TODO: Remove type assertions
    const cy = (props.yScale.range()[0] as unknown) as number + 2;

    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        d3.select(svgRef.current).on("dblclick", (ev: MouseEvent) => {
            ev.stopImmediatePropagation();
            if (props.onPointDoubleClicked) {
                props.onPointDoubleClicked();
            }
        });
        d3.select(svgRef.current).on("touchstart", (ev: MouseEvent) => {
            ev.stopImmediatePropagation();
            // Stopping the default touch behaviour is enough to allow a double-click to be registered
        });

        return () => {
            d3.select(svgRef.current)
                .on("dblclick", null)
                .on("touchstart", null);
        };
    });

    return (
        <svg
            ref={svgRef}
            x={cx - 10}
            y={cy}
            width={20}
            height={20}
            onMouseEnter={() => props.onPointMouseEnter(props.x)}
            onMouseLeave={() => props.onPointMouseLeave()}
            onContextMenu={ev => {
                if (props.onPointRightClicked) {
                    ev.preventDefault();
                    props.onPointRightClicked();
                }
            }}
        >
            <rect
                x={0}
                y={0}
                strokeWidth={2}
                width="100%"
                height="100%"
                style={{ stroke: props.colour, fill: "transparent" }}
            />
            <text
                x={"50%"}
                y={"50%"}
                r={6}
                style={{ fill: props.colour, userSelect: "none" }}
                alignmentBaseline="middle" textAnchor="middle"
            >?</text>
        </svg>
    );
}

export default ErrorPoint;
