import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import { Device } from "@inductosense/typescript-fetch";
import DeviceErrorLogsPanel from "./DeviceErrorLogsPanel";

interface DeviceErrorLogsModalPanelProps {
    shouldBeShown: boolean;
    onClose(): void;
    device: Device;
}

export default class DeviceErrorLogsModalPanel extends Component<DeviceErrorLogsModalPanelProps> {
    constructor(props: DeviceErrorLogsModalPanelProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { onClose, shouldBeShown, device } = this.props;

        return (
            <ModalPanel
                title="Device Error Logs"
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={<DeviceErrorLogsPanel device={device} />}
            />
        );
    }
}
