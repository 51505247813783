import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import Button from "Components/Buttons/Button";
import SaveIcon from "Components/Graphics/Icons/SaveIcon";

interface SaveButtonProps {
    isDisabled?: boolean;
    isSpinning?: boolean;
    onClick(event?: MouseEvent): void;
}

const SaveButton: FunctionComponent<SaveButtonProps> = ({ isDisabled, isSpinning, onClick }) => (
    <Button
        label="Save"
        width={87}
        precedence="primary"
        icon={<SaveIcon />}
        isDisabled={isDisabled}
        isSpinning={isSpinning}
        onClick={event => onClick(event)}
    />
);

export default SaveButton;
