import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import { Device } from "@inductosense/typescript-fetch";
import UiSettings from "../Model/UiSettings";
import DcDeviceDataExtractionLogsPanel from "./DcDeviceDataExtractionLogsPanel";

interface DcDeviceDataExtractionLogsModalPanelProps {
    shouldBeShown: boolean;
    onClose(): void;
    device: Device;
    uiSettings: UiSettings;
}

export default class DcDeviceDataExtractionLogsModalPanel extends Component<DcDeviceDataExtractionLogsModalPanelProps> {
    constructor(props: DcDeviceDataExtractionLogsModalPanelProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { onClose, shouldBeShown, device, uiSettings } = this.props;

        return (
            <ModalPanel
                title="Data Extraction Logs"
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={<DcDeviceDataExtractionLogsPanel device={device} uiSettings={uiSettings} />}
            />
        );
    }
}
