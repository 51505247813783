export const alertInformationBackgroundBlue = "#e8f4fd";
export const alertInformationForegroundBlue = "#2196f3";
export const alertInformationTextBlue = "#0d3c61";

export const alertWarningBackgroundAmber = "#fff4e5";
export const alertWarningForegroundAmber = "#ff9800";
export const alertWarningTextAmber = "#663c00";

export const alertErrorBackgroundRed = "#fdecea";
export const alertErrorForegroundRed = "#f44336";
export const alertErrorTextRed = "#611a15";

export const alertSuccessBackgroundGreen = "#edf7ed";
export const alertSuccessForegroundGreen = "#4caf50";
export const alertSuccessTextGreen = "#1e4620";
