import * as MaterialUi from "@mui/material";
import * as React from "react";
import { FunctionComponent, MouseEvent, ReactNode } from "react";

interface IconButtonProps {
    isDevice?: boolean;
    icon: ReactNode;
    isDisabled?: boolean;
    testId?: string;
    isProhibited?: boolean;
    onClick(event?: MouseEvent): void;
    size?: "small" | "medium";
    title?: string;
    className?: string;
}

const IconButton: FunctionComponent<IconButtonProps> = ({ icon, isDisabled, onClick, size, isProhibited, title, className,isDevice, testId }) => (
    <span title={isProhibited ? "This action is prohibited" : undefined}>
        <MaterialUi.IconButton
            disabled={isDisabled || isProhibited}
            onClick={event => onClick(event)}
            size={size}
            title={title}
            className={className}
            style={{color:isDevice?"#fff":""}}
            data-testid={testId}
        >
            {icon}
        </MaterialUi.IconButton>
    </span>
);

export default IconButton;
