import * as MaterialUi from "@mui/material";
import * as React from "react";
import { Component } from "react";
import { TextAlignment, textAlignmentToCssTextAlignValue } from "Types/TextAlignment";

interface DropDownListProps<T> {
    textAlignment?: TextAlignment;
    label?: string;
    helperText?: string;
    width?: number;
    minimumWidth?: number;
    options: T[];
    labelFor(option: T): string | React.ReactNode;
    initiallySelectedOption?: T;
    unsetOption?: T;
    isDisabled?: boolean;
    onChange(newSelectedOption: T): void;
}

export default class DropDownList<T> extends Component<DropDownListProps<T>> {
    constructor(props: DropDownListProps<T>) {
        super(props);
    }

    render() {
        const { isDisabled, minimumWidth, width } = this.props;

        return (
            <div className="drop-down-list" style={{ width, minWidth: minimumWidth }}>
                <MaterialUi.FormControl disabled={isDisabled} fullWidth>
                    {this.label()}
                    {this.select()}
                    {this.helperText()}
                </MaterialUi.FormControl>
            </div>
        );
    }

    private label() {
        const { label } = this.props;
        if (!label) return null;
        return <MaterialUi.InputLabel>{label}</MaterialUi.InputLabel>;
    }

    private select() {
        const { initiallySelectedOption, onChange, unsetOption, options } = this.props;

        const optionsVal = (unsetOption && !options.some(option => option === unsetOption)) ?
            [unsetOption, ...options] :
            options;

        return (
            <MaterialUi.Select
                defaultValue={JSON.stringify(initiallySelectedOption || unsetOption)}
                onChange={event => onChange(JSON.parse(event.target.value as string))}
                style={{ height:  "40px" }}
            >
                {...optionsVal.map(option => this.item(option))}
            </MaterialUi.Select>
        );
    }

    private helperText() {
        const { helperText } = this.props;
        if (!helperText) return null;
        return <MaterialUi.FormHelperText>{helperText}</MaterialUi.FormHelperText>;
    }

    private item(option: T) {
        return (
            <MaterialUi.MenuItem
                key={JSON.stringify(option)}
                value={JSON.stringify(option)}
            >
                {this.labelFor(option)}
            </MaterialUi.MenuItem>
        );
    }

    private labelFor(option: T) {
        const { labelFor, textAlignment, unsetOption } = this.props;

        const labelText = labelFor(option);
        const labelNode = option === unsetOption ? <i>{labelText}</i> : labelText;

        return (
            <div style={{
                width: "100%",
                textAlign: textAlignmentToCssTextAlignValue(textAlignment)
            }}>
                {labelNode}
            </div>
        );
    }
}
