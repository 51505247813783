import * as React from "react";
import { Component, CSSProperties } from "react";
import { Gateway } from "@inductosense/typescript-fetch";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "Components/Graphics/Icons/AddIcon";
import Button from "Components/Buttons/Button";
import CloseButton from "Components/Buttons/Composite/CloseButton";
import IconButton from "Components/Buttons/IconButton";
import SimplePanel from "Panels/SimplePanel";
import Services from "Services/Platform/Services";
import Table from "Components/Tables/Table";
import TableColumn from "Types/TableColumn";
import EditIcon from "../../Components/Graphics/Icons/EditIcon";
import UiSettings from "../../Model/UiSettings";
import CreateOrEditGatewayModalPanel from "Views/Gateways/Modals/CreateOrEditGatewayModalPanel";
import GatewayDevicesModalPanel from "Views/Gateways/Modals/GatewayDevicesModalPanel";
import LogsModal from "Views/Gateways/Modals/LogsModal"
import ConfirmActionModalPanel from "Panels/Composite/Modals/ConfirmActionModalPanel";
import { Delete, RestartAlt } from "@mui/icons-material";
import FourgSvg from "Graphics/Icons/FourgSvg.svg";
import PortSvg from "Graphics/Icons/PortSvg.svg";
import WifiSvg from "Graphics/Icons/WifiSvg.svg";
import LogsSvg from "Graphics/Icons/LogsSvg.svg";

const containerStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column"
};

const addButtonContainerStyle: CSSProperties = { marginBottom: "20px" };

interface GatewaysListPanelProps {
    onDbUploadRequired(): Promise<void>;
    gateways: Gateway[] | null;
    onClose(): void;
    onGatewaysChange(): void;
    uiSettings: UiSettings;
}

interface GatewaysListPanelState {
    selectedGateway: Gateway | null;
    isCreateModalShown: boolean;
    isEditModalShown: boolean;
    isDevicesModalShown: boolean;
    isDeleteGatewayModalShown: boolean;
    isGatewayDeleting: boolean;
    isLogsModalShown: boolean;
}

export default class GatewaysListPanel extends Component<GatewaysListPanelProps, GatewaysListPanelState> {
    private readonly tableColumns: TableColumn<Gateway>[];

    constructor(props: GatewaysListPanelProps) {
        super(props);

        this.tableColumns = [
            {
                id: "name",
                titleRenderer: () => "Gateway name",
                cellRenderer: gateway => this.nameCell(gateway)
            },
            {
                id: "address",
                titleRenderer: () => "Hostname/IP",
                cellRenderer: gateway => this.addressCell(gateway)
            },
            {
                id: "status",
                titleRenderer: () => "Status",
                cellRenderer: gateway => this.viewStatusCell(gateway)
            },
            {
                id: "connectionType",
                titleRenderer: () => "connectionType",
                cellRenderer: gateway => this.viewConnectionTypeCell(gateway)
            },
            {
                id: "activeDevices",
                titleRenderer: () => "Devices",
                cellRenderer: gateway => this.viewDevicesCell(gateway)
            },
            {
                id: "action",
                titleRenderer: () => "Action",
                cellRenderer: gateway => this.actionCell(gateway)
            },
        ];

        this.state = {
            selectedGateway: null,
            isCreateModalShown: false,
            isEditModalShown: false,
            isDevicesModalShown: false,
            isDeleteGatewayModalShown: false,
            isGatewayDeleting: false,
            isLogsModalShown:false,
        };
    }

    render() {
        return (
            <div style={containerStyle}>
                <SimplePanel
                    isDevice={true}
                    isNewStyleTitle={true}
                    title="Gateway Manager"
                    titleAlignment="centre"
                    actionButton={this.closeButton()}
                    content={this.table()}
                    shouldApplyPadding={false}
                />
                {this.createModal()}
                {this.editModal()}
                {this.devicesModal()}
                {this.deleteModal()}
                {this.logsButton()}
            </div>
        );
    }

    private addButton() {
        return (
            <div style={addButtonContainerStyle}>
                <Button
                    label="Add gateway"
                    isProhibited={!Services.userHasPolicy("AddGateways")}
                    precedence="primary"
                    icon={<AddIcon />}
                    onClick={() => this.setState({ isCreateModalShown: true })}
                />
            </div>
        );
    }

    private closeButton() {
        return <CloseButton isDevice={true} onClick={() => this.props.onClose()} />;
    }

    private createModal() {
        return (
            <CreateOrEditGatewayModalPanel
                shouldBeShown={this.state.isCreateModalShown}
                onClose={() => {
                    this.setState({
                        isCreateModalShown: false
                    });
                    this.props.onGatewaysChange();
                }}
                uiSettings={this.props.uiSettings}
            />
        );
    }

    private editModal() {
        return (
            <CreateOrEditGatewayModalPanel
                gateway={this.state.selectedGateway || undefined}
                shouldBeShown={this.state.isEditModalShown}
                onClose={() => {
                    this.setState({
                        isEditModalShown: false
                    });
                    this.props.onGatewaysChange();
                }}
                uiSettings={this.props.uiSettings}
            />
        );
    }

    private logsButton() {
        return (
            <LogsModal
                gateway={this.state.selectedGateway || undefined}
                shouldBeShown={this.state.isLogsModalShown}
                onClose={() => {
                    this.setState({
                        isLogsModalShown: false
                    });
                }}
                uiSettings={this.props.uiSettings}
            />
        );
    }

    private deleteModal() {
        return (
            <ConfirmActionModalPanel
                shouldBeShown={this.state.isDeleteGatewayModalShown}
                onNoOrClose={() => {
                    this.setState({
                        isDeleteGatewayModalShown: false,
                        isGatewayDeleting: false
                    });
                }}
                onYes={async () => {
                    this.setState({
                        isGatewayDeleting: true
                    });
                    await Services.Gateways.deleteGateway(this.state.selectedGateway!.id);
                    this.setState({
                        isDeleteGatewayModalShown: false,
                        isGatewayDeleting: false
                    });
                    this.props.onGatewaysChange();
                }}
                isCommittingAction={this.state.isGatewayDeleting}
                questionToUser="Devices are connected to this Gateway. You will not be able to use these devices until they automatically connect to a different gateway. Please ensure that all Devices will remain in range of another gateway."
                title="Delete the gateway?"
            />
        );
    }

    private devicesModal() {
        return (
            <GatewayDevicesModalPanel
                onDbUploadRequired={this.props.onDbUploadRequired}
                gateway={this.state.selectedGateway || undefined}
                shouldBeShown={this.state.isDevicesModalShown}
                onClose={() => {
                    this.setState({
                        isDevicesModalShown: false
                    });
                }}
                uiSettings={this.props.uiSettings}
            />
        );
    }

    private table() {
        const { gateways: gateways } = this.props;

        return (<div style={{minWidth:"1000px",padding:"20px 30px",background:"#fff"}}>
                {this.addButton()} 
                <Table
                    columns={this.tableColumns}
                    rows={ gateways ? gateways : []}
                    minimumHeaderHeight={57}
                    getKey={row => row.id}
                /> 
            </div>
        );
    }

    private viewDevicesCell(gateway: Gateway) {
        return <Button
            gateway={true}
            icon={<WifiSvg style={{width:"18px"}}/>}
            label={this.deviceButtonMessage(gateway.activeDeviceIds.length)}
            onClick={() => this.onDevicesButtonClick(gateway)}
            isProhibited={!Services.userHasPolicy("ViewDevices")}
            isDisabled={gateway.activeDeviceIds.length < 1}
        />;
    }

    private viewStatusCell(gateway: Gateway){

        const updateTime = new Date(gateway.updated);
        const year = updateTime.getFullYear();
        const month = updateTime.getMonth() + 1;
        const day = updateTime.getDate();
        const hour = updateTime.getHours();
        const minute = updateTime.getMinutes();

        const showUpdateTime = (hour < 10 ? "0" + hour : hour) + ":" + (minute < 10 ? "0" + minute : minute)  + " on " + day + "/" +  (month < 10 ? "0" + month : month) + "/" + year;

        const tooltipContent = (
            <div style={{ color: "white" }}>
                <div>{gateway.status?.online ? "The gateway is online." : "The gateway is offline."}</div>
                <div>Last updated: {year ? showUpdateTime : "no update"} </div>
            </div>
        );

        return <div>
            {gateway.status?.online && 
                <Tooltip title={tooltipContent}>
                    <div style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        color:"#fff",
                        padding:"0 10px",
                        borderRadius:"18px",
                        height:"28px",
                        lineHeight:"28px",
                        backgroundColor: "#ec6f41",
                        border:"1px solid #ec6f41"
                        }}
                    >
                        online
                    </div>
                </Tooltip>
            }         
            {!gateway.status?.online && 
                <Tooltip title={tooltipContent}>
                    <div style={{ padding: "0 10px", borderRadius:"12px", border:"1px solid #000" }}>offline</div>
                </Tooltip>
            }
        </div> 
    }


    private viewConnectionTypeCell(gateway: Gateway){
        const tipTitle = gateway.connectionType === "Cellular"  ? " 4G Connection" :  gateway.connectionType === "Internet" ? "Ethernet Connection" : "Unknown"
        return <div>
                <Tooltip title={tipTitle}>
                    <div style={{textAlign:"center",display: "flex",justifyContent: "center"}}>
                        { gateway.connectionType === "Cellular" && <FourgSvg style={{width:"20px",height:"28px",marginRight:"10px"}}/>}
                        { gateway.connectionType === "Internet" && <PortSvg style={{width:"20px",height:"28px",marginRight:"10px"}}/>}
                    </div>
                </Tooltip>          
        </div>   
    }

    private deviceButtonMessage(length: number) {
        return `View ${length} Device${length != 1 ? "s" : ""}`
    }

    private actionCell(gateway: Gateway) {
        return <div style={{ display: "flex" }}>
        <Tooltip title="View Logs" >
            <div>
                <IconButton
                    icon={<LogsSvg style={{ width: "24px", height: "24px" }} />}
                    onClick={() => this.onLogsButtonClick(gateway)}
                    isProhibited={!Services.userHasPolicy("ChangeGateways")}
                />
            </div>
        </Tooltip>
        <Tooltip title= "Edit Configuration" >
            <div>
                <IconButton
                    icon={<EditIcon color="primary" />}
                    isProhibited={!Services.userHasPolicy("ChangeGateways")}
                    onClick={() => this.onEditButtonClick(gateway)}
                />
            </div>
        </Tooltip>
        <Tooltip title= "Reboot" >
            <div>
                <IconButton
                    icon={<RestartAlt  color="primary" />}
                    isProhibited={!Services.userHasPolicy("ChangeGateways")}
                    onClick={() => this.onRebootButtonClick(gateway)}
                />
            </div>
        </Tooltip>
        <Tooltip title= "Delete" >
            <div>
                <IconButton
                    icon={<Delete  color="primary" />}
                    onClick={() => this.onDeleteButtonClick(gateway)}
                    isProhibited={!Services.userHasPolicy("DeleteGateways")}
                />
            </div>
        </Tooltip>
        </div>;
    }

    private nameCell(gateway: Gateway) {
        return gateway.displayName ?? "Unnamed gateway";
    }

    private addressCell(gateway: Gateway) {
        return gateway.address;
    }

    private async onDevicesButtonClick(gateway: Gateway) {
        this.setState({
            selectedGateway: gateway,
            isDevicesModalShown: true
        });
    }

    private async onDeleteButtonClick(gateway: Gateway) {
        if (gateway.activeDeviceIds.length > 0) {
            this.setState({
                selectedGateway: gateway,
                isDeleteGatewayModalShown: true
            });
        } else {
            await Services.Gateways.deleteGateway(gateway.id);
            this.props.onGatewaysChange();
        }
    }

    private onLogsButtonClick(gateway: Gateway) {
        console.log(gateway,"gateway")
        this.setState({
            selectedGateway: gateway,
            isLogsModalShown: true
        });
    }

    private onEditButtonClick(gateway: Gateway) {
        this.setState({
            selectedGateway: gateway,
            isEditModalShown: true
        });
    }

    private async onRebootButtonClick(gateway: Gateway) {
        await Services.Gateways.rebootGateway(gateway.id);
        const a = await Services.Gateways.rebootGateway(gateway.id);
        console.log(a ,"Gateway will be rebooted")
        alert("Gateway will be rebooted");
    }
}
