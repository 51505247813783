import * as React from "react";
import { FunctionComponent } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

interface RelativeDateProps {
    date?: Date | string;
}

const RelativeDate: FunctionComponent<RelativeDateProps> = ({ date }) => {
    if (date === undefined) return null;

    const dayJsObject = dayjs(date);

    return <span title={dayJsObject.format()}>
        {dayJsObject.fromNow()}
    </span>;
};

export default RelativeDate;
