import Validator from "Validation/Validator";

export class FormField<T = string> {
    constructor(public value: T | null, public validators: Validator<T>[]) { }

    public isValid() {
        return this.validators.every(validator => {
            if (validator.isValid(this.value)) {
                return true;
            } else {
                return false;
            }
        });
    }
}
