import { UsersApi, User, Usernew, Userupdate } from "@inductosense/typescript-fetch";
import AuthenticationService from "Services/Platform/AuthenticationService";
import InductosenseService from "Services/Platform/InductosenseService";
import wrapWithErrorHandling from "Services/Platform/WrapWithErrorHandling";

export default class UsersService extends InductosenseService<UsersApi> {
    constructor(protected authenticationService: AuthenticationService) {
        super(UsersApi, authenticationService);
    }

    private async ensureLatestConfig() {
        await this.ensureAccessConfiguration();
    }

    @wrapWithErrorHandling()
    async getUsers(): Promise<User[]> {
        await this.ensureLatestConfig();
        return this.serverApi.getUsers();
    }

    @wrapWithErrorHandling()
    async getUser(username: string): Promise<User> {
        await this.ensureLatestConfig();
        return this.serverApi.getUsersUsername({ username });
    }

    @wrapWithErrorHandling()
    async createUser(usernew: Usernew) {
        await this.ensureLatestConfig();
        const createUserResp = await this.serverApi.postUsersRaw({ usernew });
        console.log("createUserResp", createUserResp);
    }

    @wrapWithErrorHandling()
    async updateUser(username: string, userupdate: Userupdate) {
        await this.ensureLatestConfig();
        return this.serverApi.putUsersUsername({ username, userupdate });
    }
}
