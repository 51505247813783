import React, { FunctionComponent } from "react";
import Slider from "@mui/material/Slider";

interface DiscreteSliderProps {
    currentVal: number | undefined;
    marks: {
        value: number;
        label?: string;
    }[];
    onChange(event: Event, value: number | number[]): void;
}

const DiscreteSlider: FunctionComponent<DiscreteSliderProps> = ({ currentVal, marks, onChange}) => (
    
    <div className="date-slider" >
        <Slider
            aria-label="date-slider"
            aria-labelledby="date-slider"
            defaultValue={0}
            value={currentVal}
            step={null}
            marks={marks}
            onChange={(ev, val) => onChange(ev, val)}
        />
    </div>
);

export default DiscreteSlider;
