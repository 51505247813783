import * as React from "react";
import { FunctionComponent } from "react";
import InductosenseEmblemGraphic from "Graphics/Logos/InductosenseEmblemGraphic.svg";

interface InductosenseEmblemProps {
    width?: number;
    height?: number;
}

const InductosenseEmblem: FunctionComponent<InductosenseEmblemProps> = ({ height, width }) => (
    <InductosenseEmblemGraphic
        width={width ?? "100%"}
        height={height ?? "100%"}
        alt="Inductosense Emblem"
    />
);

export default InductosenseEmblem;
