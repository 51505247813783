import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import CloudUploadIcon from "Components/Graphics/Icons/CloudUploadIcon";
import IconButton from "Components/Buttons/IconButton";

interface CloudUploadButtonProps {
    color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
    isDisabled?: boolean;
    onClick(event?: MouseEvent): void;
    title?: string;
}

const CloudUploadButton: FunctionComponent<CloudUploadButtonProps> = ({ color, isDisabled, onClick, title }) => (
    <IconButton
        icon={<CloudUploadIcon color={color} />}
        isDisabled={isDisabled}
        onClick={event => onClick(event)}
        title={title}
    />
);

export default CloudUploadButton;
