import * as React from "react";
import { Component } from "react";
import { Material } from "@inductosense/typescript-fetch";
import MaterialForm from "Views/Materials/Forms/CreateOrEditMaterialForm";
import ModalPanel from "Panels/ModalPanel";
import UiSettings from "../../../Model/UiSettings";

interface CreateOrEditMaterialModalPanelProps {
    material?: Material;
    shouldBeShown: boolean;
    onClose(): void;
    uiSettings: UiSettings;
}

interface CreateOrEditMaterialModalPanelState {
    isSaving: boolean;
}

export default class CreateOrEditMaterialModalPanel
    extends Component<CreateOrEditMaterialModalPanelProps, CreateOrEditMaterialModalPanelState> {

    constructor(props: CreateOrEditMaterialModalPanelProps) {
        super(props);

        this.state = {
            isSaving: false
        };
    }

    render() {
        const { onClose, shouldBeShown, material } = this.props;
        const { isSaving } = this.state;

        return (
            <ModalPanel
                title={`${material ? "Edit" : "Create"} material`}
                shouldBeShown={shouldBeShown}
                onClose={() => !isSaving && onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        const { onClose, material, uiSettings } = this.props;

        return (
            <MaterialForm
                material={material}
                onSave={() => this.onSave()}
                onSaveComplete={() => this.onSaveComplete()}
                onCancel={() => onClose()}
                uiSettings={uiSettings}
            />
        );
    }

    private onSave() {
        this.setState({ isSaving: true });
    }

    private onSaveComplete() {
        this.props.onClose();
        this.setState({
            isSaving: false
        });
    }
}
