import * as React from "react";
import { CSSProperties, ReactNode, FunctionComponent } from "react";
import merge from "Utilities/Merge";
import Plane from "Types/Plane";
import { Resizable } from "re-resizable";

const commonContainerStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    display: "flex"
};

const horizontalContainerStyle: CSSProperties = {
    flexDirection: "row"
};

const verticalContainerStyle: CSSProperties = {
    flexDirection: "column"
};

interface SplitPanelProps {
    firstContent: ReactNode;
    secondContent?: ReactNode;
    plane?: Plane;
    splitRatio?: number;
    tabletMode?: boolean;
}

const SplitPanel: FunctionComponent<SplitPanelProps> = (props) => {
    const gripSize = props.tabletMode ? 30 : 10;
    const visibleGripSize = props.tabletMode ? 10 : 6;

    const secondContent = () => {
        const { secondContent, plane, splitRatio } = props;
        if (!secondContent) return null;

        const sizeInPercent = (1 - splitRatio!) * 100;

        return <Resizable
            defaultSize={plane === "horizontal" ? { width: `${sizeInPercent}%`, height: "100%" } : { width: "100%", height: `${sizeInPercent}%` }}
            enable={{
                top: plane === "vertical",
                right: false,
                bottom: false,
                left: plane === "horizontal",
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false
            }}
            handleStyles={{
                "left": { width: gripSize, zIndex: 1000 },
                "top": { height: gripSize, zIndex: 1000 }
            }}
            style={plane === "horizontal" ?
                { marginLeft: visibleGripSize / 2, paddingLeft: visibleGripSize }
                : { marginTop: visibleGripSize / 2, paddingTop: visibleGripSize }}
        >
            {secondContent}
        </Resizable>
    };

    const firstContent = () => {
        const { plane, firstContent } = props;

        if (plane === "horizontal") {
            return <div
                style={{ display: "block", width: "100%", height: "100%" }}
            >
                {firstContent}
            </div>;
        } else {
            return <div
                style={{ flex: 1, "position": "relative" }}
            >
                <div style={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0 }}>
                    {firstContent}
                </div>
            </div >;
        }
    };

    const mergedStyle = props.plane === "horizontal"
        ? merge(commonContainerStyle, horizontalContainerStyle)
        : merge(commonContainerStyle, verticalContainerStyle);

    return (
        <div style={mergedStyle}>
            {firstContent()}
            {secondContent()}
        </div>
    );
};

export default SplitPanel;
