import * as React from "react";
import { Component } from "react";

interface FlexibleAndFixedPanelProps {
    flexibleContent: React.ReactNode;
    topFixedContent?: React.ReactNode;
    bottomFixedContent?: React.ReactNode;
}

export default class FlexibleAndFixedPanel extends Component<FlexibleAndFixedPanelProps> {
    render() {
        const { flexibleContent, topFixedContent, bottomFixedContent } = this.props;

        return (
            <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
                {topFixedContent ? <div style={{ flex: "0 1 auto" }}>{topFixedContent}</div> : null}
                <div style={{ flex: "1 1 auto" }}>{flexibleContent}</div>
                {bottomFixedContent ? <div style={{ flex: "0 1 auto" }}>{bottomFixedContent}</div> : null}
            </div>
        );
    }
}
