import * as React from "react";
import { CSSProperties, FunctionComponent } from "react";

interface LoadingSpinnerProps {
    colour?: string;
}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({ colour }) => {
    const spinnerStyle: CSSProperties = colour
    ? { "--loading-spinner-colour": colour } as CSSProperties
    : {};

    return (
        <div className="loading-spinner" style={spinnerStyle}>
            <div className="loading-spinner-circle-1"></div>
            <div className="loading-spinner-circle-2"></div>
            <div className="loading-spinner-circle-3"></div>
            <div className="loading-spinner-circle-4"></div>
            <div className="loading-spinner-circle-5"></div>
            <div className="loading-spinner-circle-6"></div>
            <div className="loading-spinner-circle-7"></div>
            <div className="loading-spinner-circle-8"></div>
            <div className="loading-spinner-circle-9"></div>
            <div className="loading-spinner-circle-10"></div>
            <div className="loading-spinner-circle-11"></div>
            <div className="loading-spinner-circle-12"></div>
        </div>
    );
};

export default LoadingSpinner;
