import * as React from "react";
import { Component } from "react";
import Button from "../Components/Buttons/Button";
import Services from "../Services/Platform/Services";
import { Reading, Sensor, Wavemode } from "@inductosense/typescript-fetch";
import SimplePanel from "../Panels/SimplePanel";
import CloseButton from "../Components/Buttons/Composite/CloseButton";
import SensorGroup from "Model/SensorGroup";
import SensorSelectPanel from "../Panels/Composite/Primary/SensorSelectPanel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import FileSaver from "../Utilities/FileSaver";
import FirstIfNotEmpty from "../Utilities/FirstIfNotEmpty";
import * as ClientZip from "client-zip";
import * as ReadingUtils from "Utilities/ReadingUtils";
import { CircularProgress } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import sleep from "../Utilities/Sleep";
import * as Papa from "papaparse";
import dayjs from "dayjs";

interface ExportDashboardProps {
    onClose(): void;
}

interface ExportDashboardState {
    isExporting: boolean;
    rootSensorGroup?: SensorGroup;
    selectedSensors: Sensor[];
    fromDate: Date;
    toDate: Date;
    status?: string;
}

// Called ImportExport because future import functionality would be added here
export default class ExportDashboard extends Component<ExportDashboardProps, ExportDashboardState> {
    constructor(props: ExportDashboardProps) {
        super(props);

        this.state = {
            isExporting: false,
            selectedSensors: [],
            fromDate: new Date(0),
            toDate: new Date()
        };
    }

    async componentDidMount() {
        const rootSensorGroup = await Services.SensorGroups.getRootSensorGroup();
        this.setState({ rootSensorGroup });
    }

    render() {
        return <SimplePanel
            title="Export"
            titleAlignment="centre"
            actionButton={this.closeButton()}
            content={this.content()}
            shouldApplyPadding={false}
        />
    }

    private closeButton() {
        return <CloseButton onClick={() => this.props.onClose()} />;
    }

    content() {
        const { rootSensorGroup, fromDate, toDate } = this.state;

        return <div style={{ marginLeft: 20 }}>
            <h2>Export to CSV</h2>

            <p>Export the thicknesses for all readings to a CSV file</p>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="From"
                    value={dayjs(fromDate)}
                    onChange={d => {
                        if (d) {
                            this.setState({ fromDate: d.toDate() });
                        }
                    }}
                    slotProps={{ textField: { } }}
                />

                <DatePicker
                    label="To"
                    value={dayjs(toDate)}
                    onChange={d => {
                        if (d) {
                            this.setState({ toDate: d.toDate() });
                        }
                    }}
                    slotProps={{ textField: { style: { marginLeft: 20, marginRight: 20 } } }}
                />
            </LocalizationProvider>

            {rootSensorGroup !== undefined ?
                <SensorSelectPanel
                    rootSensorGroup={rootSensorGroup}
                    onSensorSelect={(_ids: string[], selectedSensors: Sensor[]) => this.setState({ selectedSensors })}
                    selectedIds={this.state.selectedSensors.map(s => s.id)}
                    multiSelect={true}
                    fastMultiSelectButtonsEnabled={true}
                />
                : null
            }

            <div style={{ marginTop: 20 }}>
                {
                    this.state.isExporting ?
                        <CircularProgress /> :
                        <>
                        <div style={{ display: "inline", marginRight: 10 }}>
                        <Button
                            precedence="primary"
                            onClick={() => this.onExportTable(1)}
                            isSpinning={this.state.isExporting}
                            label="Export table (template 1)"
                            isDisabled={this.state.selectedSensors.length === 0}
                            />
                        </div>
                        <div style={{ display: "inline", marginRight: 10 }}>
                        <Button
                            precedence="primary"
                            onClick={() => this.onExportTable(2)}
                            isSpinning={this.state.isExporting}
                            label="Export table (template 2)"
                            isDisabled={this.state.selectedSensors.length === 0}
                            />
                        </div>

                            <div style={{ display: "inline" }}>
                            <Button
                                onClick={this.onExportAscans.bind(this)}
                                label="Export a-scans"
                                isDisabled={this.state.selectedSensors.length === 0}
                                />
                            </div>
                        </>
                }
                <p>{this.state.status}</p>
            </div>
        </div>;
    }

    dateString(input: Date) {
        return input.getFullYear().toString() + "-"
            + (input.getMonth() + 1).toString().padStart(2, "0") + "-"
            + input.getDate().toString().padStart(2, "0")
    }

    datetimeString(input: Date) {
        return `${this.dateString(input)}-${input.getHours().toString().padStart(2, "0")}-`
            + `${input.getMinutes().toString().padStart(2, "0")}-${input.getSeconds().toString().padStart(2, "0")}`;
    }

    async getSensorReadings(sensor: Sensor, fromDate: Date, toDate: Date) {
        //TODO: Why are we filtering after asking for only readings within that range anyway? And why do we set a different upper threshold each time?
        const readings = await Services.Readings.getReadingsWithoutPayloadsForSensor(sensor.id, undefined, fromDate, this.getEndOfDate(toDate));
        const readingsWithinDatesSorted = readings
            .filter(r => r.timestamp >= fromDate && r.timestamp <= toDate)
            .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
        return readingsWithinDatesSorted;
    }

    async getSensorReadingsGroupedByDate(sensor: Sensor, fromDate: Date, toDate: Date) {
        const readingsWithinDatesSorted = await this.getSensorReadings(sensor, fromDate, toDate);

        const dates = [...new Set(readingsWithinDatesSorted.map(r => this.dateString(r.timestamp)))];

        this.setState({ status: "Got readings " + sensor.id });
        return dates.map(date =>
            readingsWithinDatesSorted
                .filter(r => this.dateString(r.timestamp) === date)
                .map((r, index) => ({
                    "sensor": sensor,
                    "date": `${this.dateString(r.timestamp)} ${(index + 1).toString().padStart(3, "0")}`,
                    "thickness": r.analysis?.thickness,
                    "temperature": r.analysis?.temperature
                }))
        ).reduce((acc, val) => acc.concat(val), []);
    }

    async getTrendAnalysesForSensor(sensorId: string) {
        const trendAnalyses = await Services.Sensors.getMostRecentTrendAnalysesForSensor(sensorId);
        this.setState({ status: "Got trends for " + sensorId });

        return trendAnalyses;
    }

    async onExportTable(templateId: number) {
        const { fromDate, toDate, selectedSensors } = this.state;

        const setAsyncState = (newState: object) => new Promise((resolve: (value: unknown) => void) => this.setState(newState, () => resolve(newState)));

        await setAsyncState({ isExporting: true });

        let fileData = "";
        switch (templateId) {
            case 1:
                fileData = (await this.exportTemplate1(selectedSensors, fromDate, toDate, setAsyncState))
                    .join("\n");
                break;
            case 2:
                fileData = Papa.unparse(await this.exportTemplate2(selectedSensors, fromDate, toDate, setAsyncState));
                break;

            default:
                throw new Error("Unknown template ID");
                break;
        }

        await setAsyncState({ status: "Ready to save" });

        FileSaver("text/csv", "table.csv", fileData);

        this.setState({ isExporting: false });
    }

    private async exportTemplate1(chosenSensors: Sensor[], fromDate: Date, toDate: Date, setAsyncState: (newState: object) => Promise<unknown>) {
        const sensorReadings = await Promise.all(chosenSensors.map(s => this.getSensorReadingsGroupedByDate(s, fromDate, this.getEndOfDate(toDate))));
        this.setState({ status: "Got all readings" });
        await sleep(1);

        const sensorReadingsFlat = sensorReadings.reduce((acc, val) => acc.concat(val), []);
        this.setState({ status: "Flattened readings" });
        await sleep(1);

        const dates = [...new Set(sensorReadingsFlat.map(sr => sr.date))].sort();
        this.setState({ status: "Got list of dates" });
        await sleep(1);

        const sensorAnalyses = await Promise.all(chosenSensors.map(s => this.getTrendAnalysesForSensor(s.id)));

        this.setState({ status: "Got all analyses" });
        await sleep(1);

        sensorAnalyses.flat();

        const periods = [...new Set(sensorAnalyses.flat().map(a => a.parameters?.trendPeriodInDays).sort())].sort((a, b) => (a || 0) - (b || 0));

        const lines = [];
        lines.push(["", "Sensor description", ...periods.map(p => `${p}d corrosion rate (mm/y)`), dates].join(","));
        await setAsyncState({ status: "Calculated dates" });
        await sleep(1);

        const getPeriodCorrosionRate = (p: number | undefined, index: number) => {
            const corrosionRate = sensorAnalyses[index].find(a => a.parameters?.trendPeriodInDays === p)?.corrosionRate;

            if (corrosionRate !== undefined) {
                return corrosionRate < 0 ? -corrosionRate : "No corrosion";
            } else {
                return "";
            }
        };

        // TODO: Handle 0 thickness falsy answer
        for (const [index, sensor] of chosenSensors.entries()) {
            console.log("--- Getting thickness " + index.toString());
            await setAsyncState({ status: "Getting thickness " + index.toString() });
            await sleep(1);

            lines.push([
                "Thickness",
                sensor.description,
                ...periods.map(p => getPeriodCorrosionRate(p, index)),
                ...dates.map(d => FirstIfNotEmpty(sensorReadingsFlat.filter(sr => sr.sensor.id === sensor.id && sr.date === d))?.thickness || "")
            ].join(","));
        }

        // TODO: Handle 0 thickness falsy answer
        for (const [index, sensor] of chosenSensors.entries()) {
            await setAsyncState({ status: "Getting temperature " + index.toString() });
            await sleep(1);

            lines.push([
                "Temperature",
                sensor.description,
                ...periods.map(p => getPeriodCorrosionRate(p, index)),
                ...dates.map(d => FirstIfNotEmpty(sensorReadingsFlat.filter(sr => sr.sensor.id === sensor.id && sr.date === d))?.temperature || "")
            ].join(","));
        }
        return lines;
    }

    private async exportTemplate2(chosenSensors: Sensor[], fromDate: Date, toDate: Date, setAsyncState: (newState: object) => Promise<unknown>) {
        const sensorReadingsPromises = chosenSensors.map(s => this.getSensorReadings(s, fromDate, this.getEndOfDate(toDate)));
        const dcUsersPromise = Services.DcUsersService.getUsers();
        const sensors = new Map(chosenSensors.map(s => [s.id, s]));
        await setAsyncState({ status: "Mapped sensors" });
        const sensorReadings = await Promise.all(sensorReadingsPromises);
        const dcUsers = await dcUsersPromise;
        await setAsyncState({ status: "Got all readings" });

        const userCompanyMap = new Map(dcUsers.map(u => [u.username, u.company]));

        const sensorReadingsFlat = sensorReadings.reduce((acc, val) => acc.concat(val), []);
        await setAsyncState({ status: "Flattened readings" });

        const lines: string[][] = [];
        for (const sensorReading of sensorReadingsFlat) {
            const s = sensors.get(sensorReading.sensorId)!;

            // Hardcoded values are as per Shell's specification in
            // https://dev.azure.com/Inductosense/Software/_workitems/edit/1427/
            lines.push([
                s.equipmentId ?? "",
                s.sapEquipmentNumber ?? "",
                s.measurementSetId ?? "",
                s.circuitId ?? "",
                s.subCircuitId ?? "",
                s.cmlId ?? "",
                "UT",
                getReadingTemperature(sensorReading),
                sensorReading.timestamp.toUTCString(),
                getReadingThickness(sensorReading),
                "False",
                "False",
                "Good",
                s.description ?? "",
                s.measurementPosition ?? "",
                s.matrixLocation ?? "",
                sensorReading.userName,
                userCompanyMap.get(sensorReading.userName) ?? "Unknown"
            ])
        }
        return {
            "fields": ["Equipment ID", "SAP Equipment Nr.", "Measurement Set ID", "Circuit ID", "Sub-Circuit ID", "CML ID", "Inspection Method", "Temperature [°C]", "Measurement Date", "WT Measurement [mm]", "Renewal/Baseline", "Must Get", "Quality Indicator", "PDMP Name", "PDMP Measurement Point", "PDMP MatrixLocation", "Technician", "Company"],
            "data": lines
        };
    }

    getEndOfDate(input: Date) {
        const newDate = new Date(input);
        newDate.setUTCHours(23, 59, 59, 999);

        return newDate;
    }

    async onExportAscans() {
        const { selectedSensors, fromDate, toDate } = this.state;

        this.setState({ isExporting: true });

        const chosenSensors = selectedSensors;

        const fileList: { name: string; lastModified: Date; input: string }[] = [];

        const devices = await Services.Devices.getAllDevices();

        for (const [index, chosenSensor] of chosenSensors.entries()) {

            this.setState({ status: "Getting readings for " + (index + 1).toString() + "/" + chosenSensors.length.toString() });
            await sleep(1);

            const readingList = await Services.Readings.getReadingsWithoutPayloadsForSensor(chosenSensor.id, undefined, fromDate, this.getEndOfDate(toDate));

            for (const reading of readingList) {
                try {
                    const readingDetailed = await Services.Readings.readingsIdGet(reading.id);

                    const points = ReadingUtils.GetReadingPoints(readingDetailed, 1);

                    const time = readingDetailed.timestamp;

                    const velocityInMetresPerSecond = chosenSensor.sensorType.ultrasonicWaveMode === Wavemode.Longitudinal
                        ? chosenSensor.structureMaterial.longitudinalVelocityAtRoomTemperatureInMetresPerSecond
                        : chosenSensor.structureMaterial.shearVelocityAtRoomTemperatureInMetresPerSecond;

                    const device = devices.find(d => d.id === readingDetailed.deviceId);

                    const metadata = [
                        `${time.getDate()}/${time.getMonth() + 1}/${time.getFullYear()} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`,
                        chosenSensor.description?.trim() || chosenSensor.rfid || "Unknown Sensor",
                        `Material_Vel_${velocityInMetresPerSecond}`,
                        readingDetailed.analysis !== undefined ? `${readingDetailed.analysis.thickness * 1000}mm` : "",
                        velocityInMetresPerSecond.toString(),
                        chosenSensor.rfid,
                        `${1 / readingDetailed.sampleIntervalInSeconds * 10**-6}MHz`,
                        `${chosenSensor.calibrationValue}mm`,
                        device !== undefined ? `${device.systemDelayInSeconds}s` : "",
                        `${readingDetailed.temperature}\xB0C`,
                        readingDetailed.userName,
                        "Company Unknown",
                        "Site Unknown",
                        device?.serialNumber || "0", // WAND serial, can use for RDC serial too
                        `v${readingDetailed.firmwareVersion || "1.3"}`, // WAND firmware version, can use for RDC version too. TODO: Include v?
                        `${readingDetailed.minimumThicknessInMillimetres}mm`,
                        readingDetailed.averageCount.toString()
                    ];

                    const csvText = [
                        metadata.join(","),
                        "Time(ns),Signal(mV)",
                        ...points.map(p => `${p.x * 1000},${p.y}`)
                    ].join("\n");

                    fileList.push({
                        name: chosenSensor.rfid + " "
                            + `${this.datetimeString(readingDetailed.timestamp)}.csv`,
                        input: csvText,
                        lastModified: new Date()
                    });
                }
                catch {
                    console.log("error", reading.id);
                }

            }
        }

        // get the ZIP stream in a Blob
        const blob = await ClientZip.downloadZip(fileList).blob();

        // make and click a temporary link to download the Blob
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "readings.zip";
        link.click();
        link.remove();

        this.setState({ isExporting: false });
    }
}

function getReadingTemperature(sensorReading: Reading): string {
    const temperatureFromAnalysis = sensorReading.analysis?.temperature?.toString();
    if (temperatureFromAnalysis?.length ?? 0 > 0) {
        return temperatureFromAnalysis!;
    }
    return sensorReading.temperature.toString();
}

function getReadingThickness(sensorReading: Reading): string {
    const thicknessFromAnalysis = sensorReading.analysis?.thickness;
    if (thicknessFromAnalysis ?? 0 > 0) {
        return (thicknessFromAnalysis! * 1000).toString();
    }
    if (sensorReading.thicknessFromDeviceInMetres === undefined) {
        return "";
    }
    return (sensorReading.thicknessFromDeviceInMetres * 1000).toString();
}

