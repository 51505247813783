import * as React from "react";
import { FunctionComponent, MouseEvent } from "react";
import CloseIcon from "Components/Graphics/Icons/CloseIcon";
import IconButton from "Components/Buttons/IconButton";

interface CloseButtonProps {
    isDevice?: boolean;
    isDisabled?: boolean;
    onClick(event?: MouseEvent): void;
    className?: string;
}

const CloseButton: FunctionComponent<CloseButtonProps> = ({ isDisabled, onClick, className, isDevice }) => (
    <IconButton
        isDevice={isDevice}
        icon={<CloseIcon />}
        isDisabled={isDisabled}
        onClick={event => onClick(event)}
        className={className}
    />
);

export default CloseButton;
