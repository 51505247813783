import * as React from "react";
import { FunctionComponent } from "react";
import ModalPanel from "../Panels/ModalPanel";
import CreateRDC from "./CreateRDC";

interface CreateRDCModalPanelProps {
    shouldBeShown: boolean;
    onCancel(): void;
    onSave(): void;
}

const CreateRDCModalPanel: FunctionComponent<CreateRDCModalPanelProps> = ({ shouldBeShown, onCancel, onSave }) => {
    return (
        <ModalPanel
            shouldBeShown={shouldBeShown}
            title="Create RDC"
            content={<CreateRDC onSave={onSave} onCancel={onCancel} />}
            onClose={onCancel}
            floatingActionButton
        />
    );
};

export default CreateRDCModalPanel;
