import * as React from "react";
import { coordsEqual } from "Types/CartesianSpace";
import { CSSProperties, FunctionComponent } from "react";
import { LinearOrTimeScale } from "Types/D3Extensions";
import { ScaleLinear } from "d3";
import ScatterPointForReadingTrend from "./ScatterPointForReadingTrend";
import { DateCoordinateForReadingTrend } from "../../../Types/DateCoordinateForReadingTrend";

interface ScatterPointsForReadingTrendProps {
    points: DateCoordinateForReadingTrend[];
    outliers?: DateCoordinateForReadingTrend[];
    highlightedPoint?: DateCoordinateForReadingTrend | null;
    xScale: LinearOrTimeScale;
    yScale: ScaleLinear<number, number>;
    onHoveredPointChange?(point: DateCoordinateForReadingTrend | null): void;
    onPointDoubleClicked?(point: DateCoordinateForReadingTrend): void;
    onPointRightClicked?(point: DateCoordinateForReadingTrend): void;
    radius: number;
    colour: string;
    colourLight: string;
}

const ScatterPointsForReadingTrend: FunctionComponent<ScatterPointsForReadingTrendProps> = (props: ScatterPointsForReadingTrendProps) => {

    const onPointMouseEnter = (point: DateCoordinateForReadingTrend) => {
        const { onHoveredPointChange } = props;
        if (onHoveredPointChange) onHoveredPointChange(point);
    }

    const onPointMouseLeave = () => {
        const { onHoveredPointChange } = props;
        if (onHoveredPointChange) onHoveredPointChange(null);
    }

    const point = (coordinates: DateCoordinateForReadingTrend, key: string | number, radius: number, style: CSSProperties | undefined) => {
        return (
            <ScatterPointForReadingTrend
                key={key}
                coordinates={coordinates}
                onPointMouseEnter={onPointMouseEnter}
                onPointMouseLeave={onPointMouseLeave}
                radius={radius}
                style={style}
                xScale={props.xScale}
                yScale={props.yScale}
                onPointDoubleClicked={props.onPointDoubleClicked}
                onPointRightClicked={props.onPointRightClicked}
            />
        );
    }

    const highlight = () => {
        const { points, outliers, highlightedPoint, radius, colour } = props;
        
        if (!highlightedPoint) return null;

        if ([...points, ...outliers || []].filter(p => coordsEqual(highlightedPoint, p)).length > 0) {
            return point(highlightedPoint, "highlight", radius * 1.6, { stroke: colour, strokeWidth: 2, fill: colour });//Need to change this if background color is nolonger white.
        } else {
            return null;
        }
    }

    const points = () => {
        const { points, radius, colour } = props;
        return points.map((coordinates, index) => point(coordinates, index, radius, { stroke: colour, strokeWidth: 2, fill: "transparent" }));
    }

    const outliers = () => {
        const { outliers, radius, colourLight } = props;
        if (!outliers) return null;
        return outliers.map((coordinates, index) => point(coordinates, index, radius * 0.75, { stroke: colourLight, strokeWidth: 2, fill: "none" }));
    }

    return (
        <g>
            {points()}
            {outliers()}
            {highlight()}
        </g>
    );
}

export default ScatterPointsForReadingTrend;
