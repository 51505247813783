import * as React from "react";
import { FunctionComponent } from "react";

const HorizontalLoadingSpinner: FunctionComponent = () => (
    <div className="horizontal-loading-spinner">
        <div className="horizontal-loading-spinner-circle-1"></div>
        <div className="horizontal-loading-spinner-circle-2"></div>
        <div className="horizontal-loading-spinner-circle-3"></div>
    </div>
);

export default HorizontalLoadingSpinner;
