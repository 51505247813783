import * as React from "react";
import { Component } from "react";
import { deserialiseBase64PayloadData } from "Utilities/Deserialisation";
import AscanLineGraph from "Components/Graphs/AscanLineGraph";
import ModalPanel from "Panels/ModalPanel";
import Services from "Services/Platform/Services";
import { Readingdetailed, Sensor } from "@inductosense/typescript-fetch";
import SelectComparisonSignalModalPanel from "./SelectComparisonSignalModalPanel";
import { LooksOne, GroupWork } from "@mui/icons-material";
import { CircularProgress, Button } from "@mui/material";
import UiSettings from "../../../Model/UiSettings";
import ReadingTrendPoint from "../../../Model/ReadingTrendPoint";

interface AscanModalPanelProps {
    reading: ReadingTrendPoint | null;
    sensor: Sensor | null;
    sensorReadings: ReadingTrendPoint[];
    shouldBeShown: boolean;
    onSave(waitForGraphCreatedAfter: Date): void;
    onClose(): void;
    uiSettings: UiSettings;
}

interface AscanModalPanelState {
    readingDetailed: Readingdetailed | null;
    samples: number[] | null;
    samplingFrequencyInHertz: number | null;
    parametersHaveBeenSet: boolean;
    threshold: number | null;
    startTime: number | null;
    endTime: number | null;
    isSaving: boolean;
    comparisonReading: Readingdetailed | null;
    comparisonSensor: Sensor | null;
    comparisonSamples: number[] | null;
    selectComparisonSignalPanelShouldBeShown: boolean;
    closeConfirmModalShouldBeShown: boolean;
    progressText?: string;
    errorMessage?: string;
    isLoading: boolean;
}

export default class AscanModalPanel extends Component<AscanModalPanelProps, AscanModalPanelState> {
    private amplitudeThresholdInitialValue: number | null = null;
    private startTimeInitialValue: number | null = null;
    private endTimeInitialValue: number | null = null;

    constructor(props: AscanModalPanelProps) {
        super(props);

        this.state = {
            readingDetailed: null,
            samples: null,
            samplingFrequencyInHertz: null,
            parametersHaveBeenSet: false,
            threshold: null,
            startTime: null,
            endTime: null,
            isSaving: false,
            comparisonReading: null,
            comparisonSensor: null,
            comparisonSamples: null,
            selectComparisonSignalPanelShouldBeShown: false,
            closeConfirmModalShouldBeShown: false,
            isLoading: true
        };
    }

    async loadReading() {
        this.setState({ isLoading: true });

        try {
            await this.fetchSamples();

            const { reading } = this.props;
            const { readingDetailed } = this.state;
            
            if (!reading || !readingDetailed) {
                return;
            }

            const threshold = readingDetailed.parameters?.threshold;
            const gateLower = readingDetailed.parameters?.gateLower;
            const gateUpper = readingDetailed.parameters?.gateUpper;

            this.amplitudeThresholdInitialValue = threshold || 10;
            this.startTimeInitialValue = gateLower ? gateLower * 1e6 : null;
            this.endTimeInitialValue = gateUpper ? gateUpper * 1e6 : null;

            this.setState({
                parametersHaveBeenSet: true,
                threshold: this.amplitudeThresholdInitialValue,
                startTime: this.startTimeInitialValue,
                endTime: this.endTimeInitialValue,
                isLoading: false
            });
        } catch (error){
            console.error("LoadReading - error", error);
        }
    }

    async componentDidMount() {
        await this.loadReading();
    }

    async componentDidUpdate(previousProps: AscanModalPanelProps) {
        if (this.props.reading?.id !== previousProps.reading?.id) {
            await this.loadReading();
        }
    }

    private isDirty() {
        const { threshold, startTime, endTime } = this.state;

        if (threshold !== this.amplitudeThresholdInitialValue) return true;
        if (startTime !== this.startTimeInitialValue) return true;
        if (endTime !== this.endTimeInitialValue) return true;

        return false;
    }

    render() {
        const { onClose, reading, shouldBeShown } = this.props;

        return (
            <ModalPanel
                shouldBeShown={!!reading && shouldBeShown}
                onClose={() => {
                    if (this.isDirty()) {
                        this.setState({ closeConfirmModalShouldBeShown: true });
                    } else {
                        onClose();
                    }
                }}
                content={this.content()}
                floatingActionButton
            />
        );
    }

    private footer() {
        return <div>
            {this.state.progressText !== undefined ? <p>{this.state.progressText}</p> : null}
        </div>;
    }

    private applyWindowButtons(showDontApply: boolean, variant: "text" | "outlined" | "contained") {
        // Material UI buttons rather than our button class are used here because they're inline
        return <>
            <Button
                variant={variant}
                startIcon={<LooksOne />}
                onClick={() => this.saveWindow(false)}
            >
                Apply to reading
            </Button>
            <Button
                variant={variant}
                color="secondary"
                startIcon={<GroupWork />}
                onClick={() => this.saveWindow(true)}
                style={{ marginLeft: 10 }}
            >
                Apply to sensor
            </Button>
            {showDontApply ?
                <Button
                    variant={variant}
                    color="secondary"
                    onClick={() => {
                        this.setState({ closeConfirmModalShouldBeShown: false });
                        this.props.onClose();
                    }}
                    style={{ marginLeft: 10 }}
                >
                    Don&apos;t apply
                </Button>
                : null
            }
        </>;
    }

    private content() {
        if (this.state.isLoading) {
            return <div style={{ width: 200, height: 200 }}>
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}>
                    <CircularProgress />
                </div>
            </div>;
        }

        if (this.state.errorMessage) {
            return <div>{this.state.errorMessage}</div>;
        }

        if (!this.props.reading || !this.state.parametersHaveBeenSet) {
            return null;
        }

        return (
            <div>
                {this.graph()}
                {this.footer()}
                <SelectComparisonSignalModalPanel
                    onClose={() => {
                        this.setState({ selectComparisonSignalPanelShouldBeShown: false });
                    }}
                    onSelect={async (readingId, comparisonSensor) => {
                        this.setState({ selectComparisonSignalPanelShouldBeShown: false });

                        // TODO: Make comparisons single object
                        const reading = await Services.Readings.readingsIdGet(readingId);
                        const samples = deserialiseBase64PayloadData(reading.payload.data, reading.payload.bitDepth);
                        this.setState({ comparisonReading: reading, comparisonSensor, comparisonSamples: samples });
                    }}
                    shouldBeShown={this.state.selectComparisonSignalPanelShouldBeShown}
                    initialSensorId={this.props.reading.sensorId}
                />
                <ModalPanel
                    title="You have altered the window"
                    shouldBeShown={this.state.closeConfirmModalShouldBeShown}
                    onClose={() => this.setState({ closeConfirmModalShouldBeShown: false })}
                    content={<div>
                        <p>Would you like to apply it?</p>
                        {this.applyWindowButtons(true, "contained")}
                    </div>}
                />
            </div>
        );
    }

    private graph() {
        const { threshold, endTime, samples, readingDetailed, startTime, comparisonReading, comparisonSensor } = this.state;

        if (readingDetailed === null) return <p>Loading...</p>;

        return (
            <>
                <button hidden={!Services.userHasPolicy("DeleteReadings")} onClick={async () => {
                    if (confirm("Really delete?")) {
                        const d = new Date();
                        await Services.SensorReadingsService.readingsIdDelete(readingDetailed.id);

                        this.props.onSave(d);
                        this.props.onClose();
                    }
                }}>Delete</button>
                <AscanLineGraph
                    samples={samples}
                    readingDetailed={readingDetailed}
                    sensor={this.props.sensor}
                    comparisonReading={comparisonReading}
                    comparisonSensor={comparisonSensor}
                    onCloseComparison={() => this.setState({ comparisonReading: null })}
                    onAddComparisonClick={() => this.setState({ selectComparisonSignalPanelShouldBeShown: true })}
                    amplitudeThreshold={threshold}
                    startTime={startTime}
                    endTime={endTime}
                    onNewWindowChosen={window => this.setState(window)}
                    initialSize={{
                        width: 770,
                        height: 400
                    }}
                    showToggles
                    uiSettings={this.props.uiSettings}
                >
                    {
                        this.isDirty() ?
                            <div style={{ display: "inline", marginLeft: 10 }}>{this.applyWindowButtons(false, "outlined")}</div>
                            : null
                    }
                </AscanLineGraph>
            </>
        );
    }

    private async saveWindow(applyToAllReadings: boolean) {
        const { threshold, startTime, endTime, readingDetailed } = this.state;
        const { reading } = this.props;
        // TODO: Resolve reading !

        if (readingDetailed === null) throw new Error("Reading not loaded"); // TODO: Reading not loaded

        const d = new Date();
        if (applyToAllReadings) {
            this.setState({ progressText: "Getting readings..." });

            await Services.Readings.updateParametersForSensorReadings(reading!.sensorId, [], {
                threshold: threshold ?? undefined,
                gateLower: startTime ? startTime / 1e6 : undefined,
                gateUpper: endTime ? endTime / 1e6 : undefined
            });
        } else {
            await Services.Readings.createNewParametersForReading(readingDetailed, {
                threshold: threshold ?? undefined,
                gateLower: startTime ? startTime / 1e6 : undefined,
                gateUpper: endTime ? endTime / 1e6 : undefined
            });
        }

        this.props.onSave(d);
        this.props.onClose();
    }

    private fetchSamples(): Promise<void> {
        return new Promise((resolve, reject) => {
            const readingId = this.props.reading?.id;

            if (!readingId) {
                this.setState({ isLoading: false });
                return;
            }

            Services.Readings.readingsIdGet(readingId)
                .then(readingDetailed => {
                    const samples = deserialiseBase64PayloadData(readingDetailed.payload.data, readingDetailed.payload.bitDepth);
                    this.setState({
                        readingDetailed,
                        samples,
                        samplingFrequencyInHertz: readingDetailed.payload.samplingFrequencyInHertz,
                        isLoading: false
                    }, resolve);
                })
                .catch(error => {
                    this.setState({ isLoading: false, errorMessage: "Failed to fetch samples" }, () => reject(error));
                });
        });
    }
}
