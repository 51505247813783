export type RelativePlacement = "above" | "below" | "left" | "right" | undefined;

export function relativePlacementToMaterialUiLabelPlacementValue(placement: RelativePlacement) {
    switch (placement) {
        case "above": return "top";
        case "below": return "bottom";
        case "left": return "start";
        case "right": return "end";
        case undefined: return undefined;
        default: throw new Error("Unexpected relative placement encountered.");
    }
}

