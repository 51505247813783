import * as React from "react";
import { FunctionComponent, useState } from "react";
import { Sensorreadertype, Device } from "@inductosense/typescript-fetch";
import { FormControlLabel, Link } from "@mui/material";
import TextFieldControlled from "../Components/Input/TextFieldControlled";
import Services from "Services/Platform/Services";
import SaveAndCancelButtonsFormFooter from "../Forms/Shared/SaveAndCancelButtonsFormFooter";
import * as PhysicalWANDDevice from "Services/Electron/PhysicalWANDDevice";
import { Alert } from "@mui/lab";
import sleep from "../Utilities/Sleep";
import CalibrateDCModalPanel from "./CalibrateDC";

const footerStyle: React.CSSProperties = {
    marginTop: 16
}

interface CreateDCProps {
    onSave(): void;
    onCancel(): void;
    currentDevices: Device[];
    unitsMode: "metric" | "imperial";
}

const CreateDC: FunctionComponent<CreateDCProps> = ({ onSave, onCancel, currentDevices, unitsMode }) => {
    const [serialNumber, setSerialNumber] = useState(0);
    const description = `Probe ${serialNumber}`;
    const [systemDelay, setSystemDelay] = useState("");
    const [showCalibration, setShowCalibration] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [showReplugMessage, setShowReplugMessage] = useState(false);

    const sideEffects = async () => {
        const initResult = await PhysicalWANDDevice.initWand();
        console.log("initial initResult", initResult);

        if (!initResult) {
            setShowReplugMessage(true);
            console.log("finding hdc...");
            await PhysicalWANDDevice.findWand();
            setShowReplugMessage(false);
            console.log("initialising hdc...");
            const initResult = await PhysicalWANDDevice.initWand();
            console.log("inited hdc", initResult);

            await sleep(2000);
        }

        console.log("getting setup info...");
        const info = await PhysicalWANDDevice.getDeviceSetupInfo();

        console.log(info.calibrationValue.toString());
        setSystemDelay(info.calibrationValue.toString());
        setSerialNumber(info.serialNumber); // TODO: Remove ID from entity
    }

    React.useEffect(() => {
        //setTimeout(sideEffects, 1000);
        sideEffects();
    }, []);

    const deviceAlreadyExists = currentDevices.filter(d => serialNumber !== null && d.serialNumber === serialNumber.toString()).length > 0;

    return (
        <div>
            <CalibrateDCModalPanel
                shouldBeShown={showCalibration}
                onCancel={() => setShowCalibration(false)}
                onSave={() => setShowCalibration(false)}
                unitsMode={unitsMode}
            />
            {
                showReplugMessage ?
                    <div style={{ marginBottom: 10 }}>
                        <Alert severity="info">Plug in a HDC to copy the details (or unplug and plug it back in)</Alert>
                    </div>
                    : null
            }
            {
                Services.userHasPolicy("CalibrationUi") ?
                    <Link
                        href="#"
                        onClick={() => setShowCalibration(true)}
                    >
                        Calibrate (beta)
                    </Link>
                    : null
            }
            <div>
                <FormControlLabel
                    control={<TextFieldControlled
                        value={serialNumber}
                        onChange={ev => setSerialNumber(parseInt(ev.target.value))}
                        type="number"
                        width={200}
                        inputProps={{ min: 0 }}
                    />}
                    label={<div style={{ width: 200 }}>Serial Number</div>}
                    labelPlacement="start"
                />
            </div>
            <div>
                <FormControlLabel
                    control={<TextFieldControlled
                        value={systemDelay}
                        onChange={ev => setSystemDelay(ev.target.value)}
                        type="text"
                        width={200}
                        error={isNaN(+systemDelay)}
                    />}
                    label={<div style={{ width: 200 }}>System Delay</div>}
                    labelPlacement="start"
                />
            </div>
            <div style={footerStyle}>
                {
                    deviceAlreadyExists ?
                        <p>Device with serial {serialNumber} already exists</p> :
                        <SaveAndCancelButtonsFormFooter
                            isSaving={isSaving}
                            isValid={!isNaN(+systemDelay) && description !== ""}
                            onSaveButtonClick={async () => {
                                setIsSaving(true);

                                await Services.Devices.devicesPost({
                                    macAddress: [],
                                    readerType: Sensorreadertype.DataCollector,
                                    systemDelayInSeconds: parseFloat(systemDelay),
                                    clientKeyEncrypted: [],
                                    deviceKeyEncrypted: [],
                                    description,
                                    serialNumber: serialNumber.toString()
                                });

                                setIsSaving(false);
                                onSave();
                            }}
                            onCancelButtonClick={() => onCancel()}
                        />
                }
            </div>
        </div>
    );
};

export default CreateDC;
