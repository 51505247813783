import { AuthenticationApi, Configuration } from "@inductosense/typescript-fetch";
import { jwtDecode } from "jwt-decode";
import UiSettings from "../../Model/UiSettings";
import ServiceGeneralError from "Services/Platform/ServiceGeneralError";
import ServiceTimeoutError from "Services/Platform/ServiceTimeoutError";
import { ModelErrorFromJSONTyped } from "@inductosense/typescript-fetch";
import AliveSemaphore from "./AliveSemaphore";
import { AuthenticationStateMachine, AuthenticationState } from "./AuthenticationStateMachine";
import { serviceTimeout } from "Constants/Timings";
import sleep from "../../Utilities/Sleep";

const windowSize = 15;
const subWindowSize = 5;
const badThreshold = 10;
const goodRatioThreshold = 0.7;
const minMessages = 20;

export default class AuthenticationService {
    private endpointStubs: AuthenticationApi;
    private tenant: string | null = null;
    private serverUrl = "";
    private localAccessToken: string | null = null;
    private localRefreshToken: string | null = null;
    private localRememberMeState = false;
    private sessionActive = false;
    private _authenticatingConfiguration: Configuration;
    private _alivesemaphore: AliveSemaphore;
    private authStateMachine: AuthenticationStateMachine;

    private messages: { isGood: boolean; time: number }[] = [];
    private subWindow: { isGood: boolean; time: number }[] = [];

    private totalMessages = 0;
    private goodMessages = 0;
    private badMessages = 0;
    private subWindowBad = 0;

    private warningState = false;
    private currentTime = 0;

    private constructor(serverUrl: string, tenant: string | null) {
        this.serverUrl = serverUrl;
        this.tenant = tenant;
        // Initializing the state machine.
        this.authStateMachine = new AuthenticationStateMachine();
        this._alivesemaphore = new AliveSemaphore(1); // creating a binary semaphore
        this._authenticatingConfiguration = new Configuration({
            basePath: this.serverUrl,
            accessToken: this.getLatestToken.bind(this),
        });

        this.endpointStubs = new AuthenticationApi(this._authenticatingConfiguration);
    }

    public static async createDummyForTests(serverUrl: string, tenant: string | null): Promise<AuthenticationService> {
        const instance = new AuthenticationService(serverUrl, tenant);
        return instance;
    }

    public static async createSimple(serverUrl: string, tenant: string | null): Promise<AuthenticationService> {
        const instance = new AuthenticationService(serverUrl, tenant);
        instance.authStateMachine.transitionTo(AuthenticationState.Authenticated);
        return instance;
    }

    public static async create(serverUrl: string, tenant: string | null): Promise<AuthenticationService> {
        const instance = new AuthenticationService(serverUrl, tenant);
        instance.tenant = tenant;
        await instance.authStateMachine.transitionTo(AuthenticationState.Started);
        return instance;
    }

  /**
  * Initialise the AuthenticationService post login form actions completed.
  */
    public async initialise(): Promise<void> {

        //We should only get here after we have completed either login mode and have a valid refresh token
        if (this.localRefreshToken) {
            this.authStateMachine.transitionTo(AuthenticationState.Reauthenticating);

            if (this.tenant !== null)
                await this.refreshAccessToken(this.tenant);
        }
        //so we should now have both tokens and the tenant
        if (!this.localAccessToken || !this.localRefreshToken || !this.tenant)
            // Handle scenarios where neither an access token nor a refresh token exist.
            throw new Error("No access or refresh tokens or tenant found. User might need to re-authenticate.");
        // Check the access token and transition state accordingly.
        if (this.localAccessToken) {
            this.authStateMachine.transitionTo(AuthenticationState.Authenticated);
        } else {
            this.authStateMachine.transitionTo(AuthenticationState.Unauthenticated);
        }
    }
    
    public async authenticationFederatedPost(code: string, redirectUri: string)
    {
        return this.endpointStubs.authenticationFederatedPost({
            federatedrequest: {
                code,
                redirectUri
            }
        });
    }

    public async getRefreshTokenFromServer(username: string, password: string, rememberMe: boolean)
    {
        try
        {
            // Transitioning to 'Authenticating' state when trying to get a token.
            this.authStateMachine.transitionTo(AuthenticationState.Authenticating);

            this.localRememberMeState = rememberMe; //save a copy of the state

            const result = await this.endpointStubs.authenticationTokenPost({
                usercredentials: {
                    username: username,
                    password: password,
                    rememberMe
                }
            });

            if (rememberMe) {
                // Latest Token will only be set as RefreshToken and stored when application closes if rememberMe is ticked
                localStorage.setItem("latestToken", result.refreshToken);
            }

            // Access Token stored only in memory and will be removed when AuthenticationService is disposed
            //this.localAccessToken = result.tokenResponse.accessToken;
            // This Refresh Token is stored only in memory and will be removed when AuthenticationService is disposed
            this.localRefreshToken = result.refreshToken;

            return result;
        }
        catch (error)
        {
            console.error("Authentication failed:", error);
            this.authStateMachine.transitionTo(AuthenticationState.Unauthenticated);
            throw error;  // Re-throw to let caller handle the error
        }
    }

    public async handleUnauthorizedResponse() {
        console.log("Unauthorized response, attempt to reauthenticate");
        this.authStateMachine.transitionTo(AuthenticationState.Reauthenticating);

        if (this.tenant) {
            await this.refreshAccessToken(this.tenant); // TODO: Remove !
            await this.waitForState(AuthenticationState.Authenticated, 10000);
        } else {

            throw new Error("No tenant set");
        }
    }

    /**
     * Set the current authentication state.
     * @param newState - The new state to transition to.
     * @returns A boolean indicating whether the transition was successful.
     */
    public setAuthState(newState: AuthenticationState): boolean {
        try {
            // Add any logic needed to validate or handle the state transition
            // ...

            this.authStateMachine.transitionTo(newState);
            return true;
        } catch (error) {
            console.error("State transition failed:", error);
            return false;
        }
    }

    // Return the current authentication state.
    public getCurrentAuthState(): AuthenticationState {
        return this.authStateMachine.getCurrentState;
    }

    public waitForState(desiredState: AuthenticationState, timeout = serviceTimeout): Promise<void> {
        return new Promise((resolve, reject) => {
            // Immediately resolve if we're already in the desired state
            if (this.authStateMachine.getCurrentState === desiredState) {
                resolve();                
                console.log(`The service is already in state: ${desiredState}`);

                return;
            }

            // Create a timeout to reject the promise after a certain time
            const timeoutId = setTimeout(() => {
                unsubscribe(); // Prevent memory leaks by unsubscribing on timeout
                reject(new Error(`Timeout waiting for state: ${desiredState}`));
            }, timeout);

            // Subscribe to state changes
            const unsubscribe = this.authStateMachine.subscribe((state) => {
                if (state === desiredState) {
                    clearTimeout(timeoutId); // Clear the timeout if we’re in the desired state
                    unsubscribe(); // Clean up the subscription
                    resolve();                    
                    console.log(`The service is now in state: ${desiredState}`);
                }
            });
        });
    }


    // Utility function to check if the service is authenticated.
    public isAuthenticated(): boolean {
        return this.authStateMachine.getCurrentState === AuthenticationState.Authenticated;
    }

    public async getAuthenticationTokenRefresh() {
        console.log("Getting auth...");
        this.authStateMachine.transitionTo(AuthenticationState.Reauthenticating);
        this.refreshAccessToken(this.tenant!);
        console.log("Got auth");
    }

    public setSessionActive(active: boolean): void {
        this.sessionActive = active;
    }

    public setConfiguration() {
        const authConfig = this.getAuthenticatingConfiguration(true);
        new AuthenticationApi(authConfig);
        return;
    }

    public ClearAuthenticationTokens(): void {
        this.localAccessToken = null;
        this.localRefreshToken = null;
    }

    public isSessionActive(): boolean {
        return this.sessionActive;
    }

    public isServerState(): boolean {
        return !this.warningState;
    }

    addMessage(isGood: boolean) {
        this.currentTime++;

        // Remove old messages from the main window
        while (this.messages.length && this.currentTime - this.messages[0].time > windowSize) {
            const oldMsg = this.messages.shift();
            this.totalMessages--;
            if (oldMsg!.isGood) {
                this.goodMessages--;
            } else {
                this.badMessages--;
            }
        }

        // Remove old messages from the sub-window
        while (this.subWindow.length && this.currentTime - this.subWindow[0].time > subWindowSize) {
            const oldSubMsg = this.subWindow.shift();
            if (!oldSubMsg!.isGood) {
                this.subWindowBad--;
            }
        }

        // Add new message
        const newMsg = { isGood, time: this.currentTime };
        this.messages.push(newMsg);
        this.subWindow.push(newMsg);
        this.totalMessages++;
        if (isGood) {
            this.goodMessages++;
        } else {
            this.badMessages++;
            this.subWindowBad++;
        }

        // Check conditions
        //let logMessage = '';
        if (!this.warningState && this.badMessages >= badThreshold) {
            this.warningState = true;
            console.log(`Time ${this.currentTime}: Warning: Bad message threshold exceeded`);
        } else if (this.warningState) {
            if (this.totalMessages >= minMessages) {
                const goodRatio = this.goodMessages / this.totalMessages;
                if (goodRatio >= goodRatioThreshold && this.subWindowBad === 0) {
                    this.warningState = false;
                    console.log(`Time ${this.currentTime}: Warning cleared: Good message ratio restored`);
                }
            } else {
                // If we don't have enough messages, use a more lenient condition
                if (this.goodMessages > this.badMessages && this.subWindowBad === 0) {
                    this.warningState = false;
                    console.log(`Time ${this.currentTime}: Warning cleared: More good messages than bad`);
                }
            }
        }

        console.log({
            goodMessages: this.goodMessages,
            badMessages: this.badMessages,
            totalMessages: this.totalMessages,
            warningState: this.warningState,
            subWindowBad: this.subWindowBad
        });
    }

    //Method to call on periodic send of Alive Protocol
    public async postAliveMessage() {

        if (this.authStateMachine.getCurrentState === AuthenticationState.Authenticated) { // WTF is this code?

           //OK once authenticated lock the resource to prevent multiple messages at once
            await this._alivesemaphore.acquire(); // Requesting access to the resource.
            const authConfig = this.getAuthenticatingConfiguration(true);

            const serverApi = new AuthenticationApi(authConfig);
            try {
                const response = await serverApi.authenticationAlivePostRaw();
                const rawResponse = response.raw;
                const responseBody = await rawResponse.json();

                this.addMessage(true);

                if (responseBody.success) {

                    switch (responseBody.action) {
                        case "None":
                            // Do nothing
                            console.log("Alive: Do Nothing");
                            break;
                        case "Update":
                            // Process the updated data
                            console.log("Alive: Update");
                            break;
                        case "Renew":
                            // Process the updated data
                            console.log("Alive: Renew");
                            this.authStateMachine.transitionTo(AuthenticationState.Reauthenticating);
                            await this.refreshAccessToken(this.tenant!);
                            break;
                        // Handle any other actions as needed
                    }
                }
            } catch (error) {

                console.error(error);

                this.addMessage(false);

                if (error instanceof TypeError) {
                    console.error("Network fail");

                    //this.serverStates = [...this.serverStates.slice(-1), false];
                } else { // other error, presumably auth error
                    //There has been an auth error lets try to reuthenticate
                    this.authStateMachine.transitionTo(AuthenticationState.Reauthenticating);
                    console.error("Error in sending alive message:", error);
                    await this.refreshAccessToken(this.tenant!);
                }
            }
            finally {
                this._alivesemaphore.release(); // Releasing the resource so it can be used elsewhere.
            }
        }
    }

    public async refresh() {

        // Get the local or stored refresh token
        const refreshToken = this.getLatestRefreshToken();
        if (!refreshToken) {
            this.authStateMachine.transitionTo(AuthenticationState.Unauthenticated);
            throw new Error("No refresh token found");
        }

        const refreshConfiguration = new Configuration({
            basePath: this.serverUrl,
            accessToken: () => refreshToken
        });
        const refreshEndpoint = new AuthenticationApi(refreshConfiguration);

        const refreshResult = await refreshEndpoint.getAuthenticationTokenRefresh();

        this.localRefreshToken = refreshResult.refreshToken;
        return refreshResult;
    }

    //Method to retrieve an new access token using the current refresh token. Method also returns an updated refresh token.
    public async refreshAccessToken(tenant: string) {

        const state = await this.getCurrentAuthState();
        // Check if the user is Unauthenticated before processing a Refresh
        // Since this should only be in response to a 401
        if (state !== AuthenticationState.Reauthenticating) {
            console.log("Attempt to Refresh from wrong state");
            this.authStateMachine.transitionTo(AuthenticationState.Reauthenticating);
        }


        // Get the local or stored refresh token
        const refreshToken = this.getLatestRefreshToken();
        if (!refreshToken) {
            this.authStateMachine.transitionTo(AuthenticationState.Unauthenticated);
            throw new Error("No refresh token found");
        }

        // Configure the API to use the refresh token for this message in authentication header 
        const refreshConfiguration = new Configuration({
            basePath: this.serverUrl,
            accessToken: () => refreshToken
        });
        const refreshEndpoint = new AuthenticationApi(refreshConfiguration);

        // Put an infinite loop here so that if the exchange times out we retry it
        for (;;) {
            try {
                const result = await refreshEndpoint.authenticationTokenExchangePost({ tokenexchangerequest: { tenant } });
                // Update localAccessToken and return it
                if (!result.tokenResponse.accessToken || !result.tokenResponse.refreshToken) {
                    throw new Error("Server did not return new authentication tokens.");
                }
                if (this.localRememberMeState) {
                    //Only store to local storage when rememberMe enabled
                    localStorage.setItem("latestToken", result.tokenResponse.refreshToken);
                }
                // internal strorage 
                this.localRefreshToken = result.tokenResponse.refreshToken;
                this.localAccessToken = result.tokenResponse.accessToken;
                //should already be the case
                this.setSessionActive(true);
                this.authStateMachine.transitionTo(AuthenticationState.Authenticated);
                const authConfig = this.getAuthenticatingConfiguration(true);
                new AuthenticationApi(authConfig);
                return;

            } catch (error) {
                //Going to now need to fall back to credentials log in
                this.authStateMachine.transitionTo(AuthenticationState.Unauthenticated);
                if (error instanceof ServiceTimeoutError) {
                    // It has timed out so wait a second and retry
                    await sleep(1000);
                    continue;
                }
                if (error instanceof Response) {
                    if (error.status === 401 || error.status === 403 /* TODO: Handle forbidden error when specific action restricted */) {
                        console.log("The stored Token Request was not authenticated. User will need to Re Authenticate With Credentials.");
                        //localStorage.removeItem("latestToken");
                        this.ClearAuthenticationTokens();
                        this.authStateMachine.transitionTo(AuthenticationState.Unauthenticated);
                    }
                    const json: object[] = await error.json();
                    const errors = json.map(object => ModelErrorFromJSONTyped(object, false));
                    throw new ServiceGeneralError(undefined, errors);
                }
                // so just return our most general error if nothing else matches
                throw error;
            }
        }
    }

    public getLatestRefreshToken(): string | null {
        if (this.localRefreshToken !== null && this.localRefreshToken !== "") {
            return this.localRefreshToken;
        }
        if (localStorage.getItem("latestToken") && localStorage.getItem("latestToken") !== "") {
            return localStorage.getItem("latestToken")
        }

        throw new Error("No refresh token found in local storage");
    }

    public setRefreshToken(token: string) {
        this.localRefreshToken = token;
    }

    public setTenant(tenant: string) {
        this.tenant = tenant;
    }

    public IsRefreshTokenSet(): boolean {
        const token = this.getLatestRefreshToken();
        return token !== null && token !== undefined;
        // Or simply: return token != null;  // This checks for both null and undefined
    }

    public getLatestToken(): string {

        if (this.localAccessToken === null) throw new Error("No auth token found");
        return this.localAccessToken;
    }

    public getRole(): string | null {
        const token = this.localAccessToken;
        if (token === null) return null;

        const decodedToken = jwtDecode<{ "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string }>(token);

        return decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    }

    public getUsername(): string | null {
        const token = this.localAccessToken;
        if (token === null) return null;

        const decodedToken = jwtDecode<{ "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string }>(token);

        return decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    }

    public getTenantName(): string | null {
        const token = this.localAccessToken;
        if (token === null) return null;

        const decodedToken = jwtDecode<{ "TenantName": string }>(token);

        return decodedToken.TenantName;
    }

    public getUiSettings(): UiSettings {
        const token = this.localAccessToken;

        const decodedToken: { "UnitsMode": "metric" | "imperial"; "TemperatureUnits": "celsius" | "fahrenheit" }
            = token !== null ? jwtDecode<{
            "UnitsMode": "metric" | "imperial";
            "TemperatureUnits": "celsius" | "fahrenheit";
        }>(token) : { "UnitsMode": "imperial", "TemperatureUnits": "celsius" };

        return {
            unitsMode: decodedToken.UnitsMode,
            temperatureUnits: decodedToken.TemperatureUnits
        };
    }

    public getUnitsMode() {
        return this.getUiSettings().unitsMode;
    }

    public getPolicies() {
        const token = this.localAccessToken;
        if (token === null) return [];

        const decodedToken = jwtDecode<{ "PolicyNames": string }>(token);

        return decodedToken.PolicyNames.split(",");
    }

    public get authenticatingConfiguration(): Configuration {
        return this._authenticatingConfiguration;
    }

    public getAuthenticatingConfiguration(useAccessToken = false): Configuration {
        const token = useAccessToken ? this.getLatestToken() : this.getLatestRefreshToken();
        if (token === null) throw new Error("No auth token found");

        const newConfiguration = new Configuration({
            basePath: this.serverUrl,
            accessToken: () => token,
        });

        this._authenticatingConfiguration = newConfiguration;
        this.endpointStubs = new AuthenticationApi(this._authenticatingConfiguration);

        return this._authenticatingConfiguration;
    }
}
