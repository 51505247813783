import * as React from "react";
import { Component } from "react";
import { Device, Dataextractionattemptlog } from "@inductosense/typescript-fetch";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from "@mui/material";
import BatteryLevelIndicator from "../Components/Graphics/StatusIndicators/BatteryLevelIndicator";
import { CheckCircleOutline, ErrorOutlined } from "@mui/icons-material";
import TemperatureText from "../Components/Text/TemperatureText";
import UiSettings from "../Model/UiSettings";
import Services from "Services/Platform/Services";
import ModalPanel from "../Panels/ModalPanel";

interface DeviceDataExtractionLogsPanelProps {
    device: Device;
    uiSettings: UiSettings;
}

interface DeviceDataExtractionLogsPanelState {
    logs?: Dataextractionattemptlog[];
    showingLogTextFor?: Dataextractionattemptlog;
}

export default class DeviceDataExtractionLogsPanel extends Component<DeviceDataExtractionLogsPanelProps, DeviceDataExtractionLogsPanelState> {
    constructor(props: DeviceDataExtractionLogsPanelProps) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {
        // TODO: Should serial number be optional? Or should we allow passing device id? *

        const dataLogs = await Services.IoTDeployments.dataExtractionAttemptLogs(this.props.device.id);
        this.setState({ logs: dataLogs });
        console.log("dataLogs", dataLogs);
    }

    render() {
        const { uiSettings } = this.props;
        const { logs, showingLogTextFor } = this.state;
        if (logs === undefined) return <p>Loading...</p>;

        return (
            <div style={{ overflowY: "auto", width: "100%", height: "80vh" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Battery</TableCell>
                                <TableCell>Temperature</TableCell>
                                <TableCell>Readings Extracted</TableCell>
                                <TableCell>Error Logs Extracted</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs.map((log, index) =>
                                <TableRow key={index} className="rdcDataExtractionLogRow">
                                    <TableCell style={{ width: 200 }} title={log.dateTime.toString()} className="rdcDataExtractionLogDateCell">
                                        {log.dateTime.toDateString()} {log.dateTime.toLocaleTimeString()}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            (log.batteryPercentage !== null && log.batteryPercentage !== undefined) ?
                                                <>
                                                    <BatteryLevelIndicator levelInPercent={log.batteryPercentage} />
                                                    {log.batteryPercentage}%
                                                </>
                                                : null
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <TemperatureText temperatureUnits={uiSettings.temperatureUnits} temperatureInCelsius={log.deviceTemperatureInDegreesCelsius} />
                                    </TableCell>
                                    <TableCell>
                                        {
                                            log.allNewReadingsExtracted ?
                                                <CheckCircleOutline style={{ verticalAlign: "middle" }} />
                                                : <ErrorOutlined style={{ verticalAlign: "middle" }} />
                                        } &nbsp;
                                        {log.numberOfReadingsExtracted}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            log.allErrorLogsExtracted ?
                                                <CheckCircleOutline style={{ verticalAlign: "middle" }} />
                                                : <ErrorOutlined style={{ verticalAlign: "middle" }} />
                                        } &nbsp;
                                        {log.numberOfErrorLogsExtracted}
                                    </TableCell>
                                    <TableCell>
                                        {Services.userHasPolicy("ViewLogTextUi") ? <Link href="#" onClick={() => this.setState({ showingLogTextFor: log })}>Show logs</Link> : null}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {showingLogTextFor !== undefined ?
                    <ModalPanel
                        content={
                            <div style={{ overflowY: "auto", whiteSpace: "pre-line", width: "100%", height: "80vh" }}>
                                {showingLogTextFor.logText}
                            </div>
                        }
                        shouldBeShown={true}
                        title={`Log text ${showingLogTextFor.dateTime.toDateString()} ${showingLogTextFor.dateTime.toLocaleTimeString()}`}
                        onClose={() => this.setState({ showingLogTextFor: undefined })}
                    />
                    : null
                }
            </div>
        );
    }
}
