import * as MaterialUI from "@mui/material";
import * as React from "react";
import { Component, ReactNode } from "react";

interface ToggleButtonGroupProps {
    options: string[];
    initialSelected: string[] | string;
    size?: "small" | "medium" | "large";
    exclusive?: boolean;
    onChange(selected: string[] | string): void;
}

interface ToggleButtonGroupState {
    selected: string[] | string;
}

export default class ToggleButtonGroup extends Component<ToggleButtonGroupProps, ToggleButtonGroupState> {
    private buttons: ReactNode[];
    constructor(props: ToggleButtonGroupProps) {
        super(props);
        this.state = { selected: this.validateInitialSelection(props.initialSelected, props.exclusive) };
        this.buttons = props.options.map((value, index) => (
            <MaterialUI.ToggleButton key={index} value={value}>
                {value}
            </MaterialUI.ToggleButton>
        ));
    }

    render() {
        const { size, exclusive, onChange } = this.props;
        const { selected } = this.state;
        return (
            <MaterialUI.ToggleButtonGroup
                size={size}
                value={selected}
                exclusive={exclusive}
                onChange={(_, newSelected: string[] | string) => {
                    onChange(newSelected);
                    this.setState({ selected: newSelected });
                }}
            >
                {...this.buttons}
            </MaterialUI.ToggleButtonGroup>
        )
    }

    validateInitialSelection(initialSelected: string | string[], exclusive?: boolean) {
        if (exclusive && Array.isArray(initialSelected)) {
            console.warn("Array of initial values supplied to exclusive ToggleButtonGroup. We will ignore all except the first.");
            return initialSelected[0];
        } else if (!exclusive && !Array.isArray(initialSelected)) {
            console.warn("String initial value supplied to non-exclusive ToggleButtonGroup.");
            return [initialSelected];
        }
        return initialSelected;
    }
}
