import * as MaterialUi from "@mui/material";
import * as React from "react";
import { Component, CSSProperties, ReactNode } from "react";
import { paddingInsidePanels } from "Styling/Spacing/Padding";
import { TextAlignment } from "Types/TextAlignment";
import CloseButton from "Components/Buttons/Composite/CloseButton";
import PanelTitleBar from "Panels/Shared/PanelTitleBar";
import InductosenseEmblem from "../Components/Graphics/Logos/InductosenseEmblem";
import InductosenseLogotype from "../Components/Graphics/Logos/InductosenseLogotype";
const titleWithPaddingStyle: CSSProperties = { padding: `${paddingInsidePanels}px` };
const contentWithPaddingStyle: CSSProperties = { padding: `0 ${paddingInsidePanels}px ${paddingInsidePanels}px`, overflowY: "auto" };

const brandingBannerStyle: CSSProperties = {
    padding: paddingInsidePanels,
    display: "flex",
    alignItems: "center"
};

const inductosenseLogoStyle: CSSProperties = {
    display: "flex",
    flex: 1
};

const inductosenseLogotypeStyle: CSSProperties = {
    margin: "0 12px"
};

const actionButtonContainerStyle: CSSProperties = {
    position: "absolute",
    right: 0,
    top: 0
}

const  logsStyle: CSSProperties = {
    lineHeight: "30px",
    minHeight: "50px",
    maxHeight: "50vh",
    overflow: "auto",
    minWidth: "60px",
    maxWidth:"30vw",
    margin:"10px 0px",
    borderRadius: "5px",
    padding: "15px",
    height: "100%",
    textAlign:"center",
    backgroundColor:"rgb(255, 244, 229)"
}

interface ModalPanelProps {
    showBranding?: boolean;
    title?: string;
    titleAlignment?: TextAlignment;
    content: ReactNode;
    shouldApplyPaddingToTitleBar?: boolean;
    shouldApplyPaddingToContent?: boolean;
    shouldBeShown: boolean;
    depthIndex: 1 | 2;
    onClose?(): void;
    floatingActionButton?: boolean;
    hideBackdrop?: boolean;
    isGatewayLogs?: boolean;
}

export default class ModalPanel extends Component<ModalPanelProps> {
    static readonly defaultProps: Partial<ModalPanelProps> = {
        titleAlignment: "centre",
        shouldApplyPaddingToTitleBar: true,
        shouldApplyPaddingToContent: true,
        depthIndex: 1
    };

    render() {
        const { depthIndex, shouldBeShown, showBranding, hideBackdrop } = this.props;

        return (
            <MaterialUi.Dialog
                sx={{"& .MuiDialog-paper":{padding:"15px 20px 30px"}}}
                className={`modal ${depthIndex === 1 ? "first-level" : "second-level"}`}
                maxWidth="xl"
                open={shouldBeShown}
                onClose={() => null}
                hideBackdrop={hideBackdrop}
            >
                {showBranding ? this.branding() : null}
                {this.titleBar()}
                {this.props.floatingActionButton ? <div style={actionButtonContainerStyle}>{this.closeButton()}</div> : null}
                {this.content()}
            </MaterialUi.Dialog>
        );
    }

    private branding() {
        return (
            <div style={brandingBannerStyle}>
                <div style={inductosenseLogoStyle}>
                    <InductosenseEmblem width={55} />
                    <div style={inductosenseLogotypeStyle}>
                        <InductosenseLogotype width={150} />
                    </div>
                </div>
            </div>
        );
    }

    private titleBar() {
        return (
             <div style={this.props.shouldApplyPaddingToTitleBar ? titleWithPaddingStyle : undefined }>
                <PanelTitleBar
                    title={this.props.title}
                    titleAlignment={this.props.titleAlignment}
                    actionButton={this.props.floatingActionButton ? null : this.closeButton()}
                    size="regular"
                />
            </div>
        );
    }

    private content() {
        return (
            <div 
                style={  this.props.isGatewayLogs ? logsStyle : this.props.shouldApplyPaddingToContent ? contentWithPaddingStyle : undefined}
            >
                {this.props.content}
            </div>
        );
    }

    private closeButton() {
        const { onClose } = this.props;
        if (!onClose) return null;
        return <CloseButton className="modalCloseButton" onClick={() => onClose()} />;
    }
}
