import * as React from "react";
import { Component } from "react";
import ModalPanel from "Panels/ModalPanel";
import CreateDcDeployment from "./CreateDcDeployment";
import SensorGroup from "../Model/SensorGroup";
import { Dcdeployment, Dcdeploymentpost, Dcdevicedeployments } from "@inductosense/typescript-fetch";

interface CreateWandDeploymentModalPanelProps {
    rootSensorGroup: SensorGroup;
    initialDeployment: Dcdeployment | null;
    deviceId: string;
    onCreateClick(
        iotDeployment: Dcdeploymentpost
    ): void;
    shouldBeShown: boolean;
    onClose(): void;
    dcDeviceDeployments: Dcdevicedeployments[];
}

export default class CreateWandDeploymentModalPanel extends Component<CreateWandDeploymentModalPanelProps> {
    constructor(props: CreateWandDeploymentModalPanelProps) {
        super(props);

        this.state = {};
    }

    render() {
        const { onClose, shouldBeShown } = this.props;

        return (
            <ModalPanel
                title="Create DC configuration"
                shouldBeShown={shouldBeShown}
                onClose={() => onClose()}
                content={this.form()}
            />
        );
    }

    private form() {
        return (
            <>
                <CreateDcDeployment
                    initialDeployment={this.props.initialDeployment}
                    deviceId={this.props.deviceId}
                    onCreateClick={this.props.onCreateClick}
                    rootSensorGroup={this.props.rootSensorGroup}
                    dcDeviceDeployments={this.props.dcDeviceDeployments}
                />
            </>
        );
    }
}
