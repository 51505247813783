export function deserialiseBase64PayloadData(data: string, bitDepth: number) {
    const bytesInASingle = bitDepth / 8;
    const stride = bytesInASingle;
    const decoded = window.atob(data);

    const bytes: number[] = [];
    const samples: number[] = [];

    for (let i = 0; i < decoded.length; i++) bytes[i] = decoded.charCodeAt(i);

    for (let i = 0; i < bytes.length; i += stride) {
        //const array = [0, ...bytes.slice(i, i + stride)];
        //const buffer = new ArrayBuffer(stride + 1);

        let array;
        let buffer;
        if (bitDepth === 32) {
            array = bytes.slice(i, i + stride);
            buffer = new ArrayBuffer(stride);
        } else {
            array = [0, ...bytes.slice(i, i + stride)];
            buffer = new ArrayBuffer(stride + 1);
        }

        const view = new DataView(buffer);

        array.forEach((value, index) => view.setUint8(index, value));
        samples.push(view.getFloat32(0, true));
    }

    return samples;
}
